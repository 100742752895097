.playerVerificationWaitingForApproval {
  position: relative;

  $_header-height: 76px;
  $_stepper-height: 68px;

  &-header {
    height: $_header-height;
    display: flex;
    align-items: center;
    padding-left: 16px;
    background: var(--register-help-bar);

    &__logo {
      width: 42px;
    }
  }

  .stepper-wrap {
    @include media-xs {
      padding: 16px;
    }

    & .stepper-step p {
      white-space: nowrap;
    }
  }

  &__stepper {
    height: $_stepper-height;

    div .stepper-root {
      display: flex;
      align-items: center;

      @include media-sm {
        padding: 0 20px;
        justify-content: space-between;
      }
    }
  }

  &-verification {
    left: $width-help-bar;
    top: 100px;
    right: 0;
    bottom: 0;
    width: calc(100% - $width-help-bar);
    height: calc(100% - 100px);
    display: flex;
    align-items: flex-start;
    padding-top: 135px;

    @include media-sm {
      top: $_header-height + $_stepper-height;
      left: 0;
      padding-top: 0;
      width: 100%;
    }

    .modal-background {
      left: inherit;
      top: inherit;
      right: inherit;
      bottom: inherit;
      width: inherit;
      height: inherit;
    }

    .modalContentWrapper {
      .modal-titleRoot {
        margin-bottom: 8px;
      }

      .modal-contentRoot {
        gap: 24px;
        display: flex;
        flex-direction: column;
      }
    }

    label {
      margin-bottom: 8px;
    }

    .markdown-root {
      color: var(--primary-light);
      font-size: 14px;
      line-height: 20px;

      p {
        margin: 0;
      }

      strong {
        font-weight: normal;
        margin-top: 8px;
        display: block;
      }
    }

    .button-root {
      width: 100%;
    }
  }
}
