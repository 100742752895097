/* Animation */

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

/*------------------------------------*\
          Style Guideline
\*------------------------------------*/

$font-fallback: // Safari for macOS and iOS (San Francisco)
  -apple-system,
    // Chrome < 56 for macOS (San Francisco)
  BlinkMacSystemFont,
    // Windows
  "Segoe UI",
    // Android
  "Roboto",
    // Basic web fallback
  "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-primary: 'Poppins', 'Roboto', $font-fallback;
$font-weight-base: 400;
$font-weight-alt: 700;

/* Size Variables */
$height-mobile-sticky-menu: 71px;
$width-help-bar: 440px;

/* color variable map */
$colors: (
  primary: (main: #E7BA31,
    light: #F1F1F1,
    dark: #161819,
    white:#fff,
    gold-light: #FFCC33,
    gold-dark: #443D27,
    gold-dark-background: #22211B,
    disabled-color: #8B7226,
    disabled-color-light:#838485,
    disabled-background: #22211B,
    // light-color:#838485,
    light-background:#D0D1D1,
    light-color:#2D2F30,
    dark-color:#E6E6E6,
    gold-light-theme:#838485,
    deposit-radio-light:#4C4E4F,
    button-color:#161819,
    button-background:#FFCC33,
    button-background-light: lighten(#FFCC33, 5%),
    button-hover:#e2a415,
    button-hover-light:#2D2F30,
    button-active:#E7BA31,
    button-active-light:#2D2F30,
    button-active-background:#373839,
    button-active-background-light:#FFCC33,
    main-light:#E6E6E6,
    alt-black: #17181a,
    section-background: #212324),
  secondary: (main: #373839,
    light: #4C4E4F,
    disabled-background: #212324,
    disabled-background-light: #f1f1f1,
    light-background:#D0D1D1,
    light-border-background:#838485,
    settings-input-disable:#BBBBBB),
  default: (main: #BBBBBB,
    normal: #4C4E4F,
    light: #838485,
    light-background:#E6E6E6),
  casino: (main: #e3a52f,
    light: #ffffff,
    dark: #111111,
    dark-light: #262626,
    gold-light: #f6b235,
    box-shadow: none),
  betting: (main: #007e00,
    green: #008900,
    light: #ffffff,
    dark: #111111,
    dark-light: #262626,
    gold: #e3a52f,
    gold-light: #f6b235,
    box-shadow: none),
  common: (main: #262626,
    light: #1D1D1D),
  accent: (main: #e3a52f),
  info: #22211B,
  error: (main: #D71D4A,
    dark: #20181C,
    dark-light:#E6E6E6,
  ),
  success: (default: #49a06a,
    dark: #18211E),
  warning: #212324,
  expired: #d7b354,
  attention: #C65252,
  disable: (dark: #a09f9f,
  ),
  label: (color: #bebebe,
    unfilled: #5E6061),
  input: (text: #5E6061,
    background: #E0E0E0,
  ),
  input-dark: (text: #bebebe,
    background: #211f22,
    text-light:#4C4E4F,
    background-light:#E6E6E6),
  input-mobile: (text: #bebebe,
    background: #2e2e2e,
  ),
  select: (text: #5E6061,
    background: #2e2e2e,
    list: #bebebe,
    arrow: #e3a52f,
    shadow-right: #19181a,
    shadow-left: #414141),
  button-default: (color: #e3a52f,
    hover: #daa01c),
  button-danger: (color: #e3a52f,
    hover: #610f0d),
  button-accent: (color: #1d1d1d,
    hover: #b74343),
  modal: (header: #1d1d1d,
    title: #e3a52f,
    title-mobile: #f1f1f1,
    title-background: #1d1d1d,
    text: #bebebe,
    border: #141414,
    background: #141414,
    background-login: #2e2e2e,
    text-light:#4C4E4F,
    game-info:#161819,
    game-info-light:#E6E6E6,
  ),
  close-button: (main: #e3a52f,
    background: #2e2e2e),
  footer: (text: #454647,
    link: #e3a52f,
    background: #211f22,
    content-version:#BBBBBB,
    content-version-light:#838485,
    payment-provider:#373839,
    payment-provider-light:#D0D1D1,
    payment-privacy:#161819,
    payment-privacy-light:#D0D1D1,
    footer-banner-background: #E6E6E6,
    list-title-dark:#cd961a),
  turnover-progress: (text: #fff,
    progress: #49A06A,
    background: #211f22),
  content: (title: #fff,
    background: #fff),
  text: (main: #666,
    light: #fff,
    dark: #333,
    grey: #DDDDDD),
  link: (default: #e2a415,
    hover: #DAA01C,
    active: #DAA01C),
  main-menu: (link: #BCBEC0,
    link-hover: #f1f1f1,
    link-active: #ffcc00,
  ),
  static-menu: (border: #e8e8e8,
    background: #f8f8f8),
  loyalty-statuses: (bronze: #6398e6,
    silver: #f9813f,
    gold: #ffcd13,
    platinum: #339999,
  ),
  limit-amount: (progress-back: #ccc,
    progress-path: #ffcc00,
    switch-default: #ccc,
    background: #f6f6f6,
    color: #606060,
    date-background: #dedede),
  background: (header: #141414,
    body: #222325,
    footer: #a4a6a7,
    aside-menu: #2D2F30,
    home-menu: #1D1D1D,
    protection: #333,
    modal: #fff,
    post-login-menu: #fff,
    post-login-content: #fff,
    static-menu: #fff,
    static-content: #fff,
    filter: #fff,
    game-window: #fff,
    game-window-footer: #0B0C0C,
    aside-menu-light:#E6E6E6,
    body-background-light:#F1F1F1,
    modal-light:#E6E6E6,
    content-background-light:#E6E6E6,
    header-background-light:#E6E6E6,
    footer-background-light:#E6E6E6,
    aside-menu-favourites-icon:#BBBBBB,
    aside-menu-favourites-icon-color:#373839,
    aside-menu-favourites-icon-color-light:#4C4E4F,
    aside-menu-favourites-icon-light:#E6E6E6,
  ),
  promotions-card: (title-color: #211f22,
    title-background: #e2a415,
    text: #bebebe,
    link-color: #1D1D1D,
    link-background: #E3A52F,
    background: #2E2E2E),
  table: (even-background: #ebebeb,
  ),
  popup: (summary-background: #171718),
  settings-menu: (background: #2b2b2b,
    border: #363636,
    shadow: #1d1d1d,
    link: #4f5051,
    active-link: #a5a6a9),
  details-summary: (color: #4f5051,
    border-top: #2E2E2E,
    border-bottom: #070707),
  notice: (color: #f1f1f1,
    background: #272628,
    hr: #0f0f10),
  verification: (label: #cccccc,
    background-left: #000000,
    background-right: #E5A618,
    title-left: #e3a52f,
    title-right: #2E2E2E,
    select-left: #1D1D1D,
    select-right: #39301c,
    option-name: #E7A700,
    close-button-backgrount: #1D1D1D),
  verification-mobile: (title: #2e2e2e,
    select-top: #e2a415,
  ),
  warning-notify: (background: #e0a32d,
    color: #373839),
  status: (ok: #3DCC81),
  toggle:(background-dark:#373839,
    background-light:#BBBBBB,
    border-dark:#bbb,
    border-light:#838485,
    button-background:#fff,
    button-background-light:#4C4E4F),
  locale-border:(border-gold: #e19c00,
    border-light:#838485,
  ),
  tournment:(item-background:#161819,
    item-background-light:#E6E6E6,
    table-background:#6d6d6d,
    table-background-light:#D0D1D1,

  )
);

$preloading-width: 120px;
$preloading-height: 4px;
$preloading-time: 5s;

// heights
$header-collapsed-height: 60px;
$header-expanded-height: 60px;
$header-desktop-height: 78px;
$header-mobile-height: 75px;

// retrieve color from map. Example: 'color: color(primary, main);', 'background: color(background)'
@function color($color-name, $color-variant: null) {

  // color variant is optional
  @if ($color-variant !=null) {
    @return map-get(map-get($colors, $color-name), $color-variant);
  }

  @else {
    @return map-get($colors, $color-name);
  }
}

/* Mixins */

@mixin svg-icon($name: 'Glyphter') {
  display: inline-block;

  @if $name =='custom' {
    font-family: 'Glyphter-custom';
  }

  @else if $name =='lvc' {
    font-family: 'Glyphter-lvc';
  }

  @else if $name =='lvc-regular' {
    font-family: 'Glyphter-lvc-regular';
  }

  @else if $name =='light' {
    font-family: 'Glyphter-light';
  }

  @else {
    font-family: 'Glyphter';
  }

  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

$main-transition-time: .25s;

$size-xl: 1920px;
$size-lg: 1440px;
$size-md: 1024px;
$size-sm: 767px;
$size-xs: 480px;


@mixin media($max, $min: null) {
  @if ($min ==null) {
    @media (max-width: $max) {
      @content;
    }
  }

  @else if($max ==null) {
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    @media (max-width: $max) and (min-width: $min) {
      @content;
    }
  }
}

@mixin media-xl {
  @media (max-width: $size-xl) {
    @content;
  }
}

@mixin media-to-xl {
  @media (min-width: $size-xl + 1px) {
    @content;
  }
}


@mixin media-lg {
  @media (max-width: $size-lg) {
    @content;
  }
}

@mixin media-to-lg {
  @media (min-width: $size-lg + 1px) {
    @content;
  }
}

@mixin media-md {
  @media (max-width: $size-md) {
    @content;
  }
}

@mixin media-to-md {
  @media (min-width: $size-md + 1px) {
    @content;
  }
}

@mixin media-sm-landscape {
  @media (max-width: $size-sm) and (orientation: landscape) {
    @content;
  }
}

@mixin media-to-sm {
  @media (min-width: $size-sm + 1px) {
    @content;
  }
}

@mixin media-sm {
  @media (max-width: $size-sm) {
    @content;
  }
}

@mixin invert-media-sm {
  @media (min-width: $size-sm) {
    @content;
  }
}

@mixin media-xs {
  @media (max-width: $size-xs) {
    @content;
  }
}

@mixin media-to-xs {
  @media (min-width: $size-xs + 1px) {
    @content;
  }
}

@mixin media-md-landscape {
  @media (max-width: $size-md) and (orientation: landscape) {
    @content;
  }
}

// Size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
@mixin customVh($vh: 100) {
  height: calc(var(--vh, 1vh) * #{$vh});
  will-change: height;
}

// Browsers check hacks
// Microsoft Edge
@mixin isMicrosoftEdge {
  @supports (-ms-ime-align: auto) {
    @content;
  }
}

// Mozilla Firefox
@mixin isMozillaFirefox {
  @-moz-document url-prefix() {
    @content;
  }
}

// WebP background images with fallback
@mixin webp-bg-img($webp, $fallback) {
  background-image: url($webp);

  html.no-webp & {
    background-image: url($fallback);
  }
}

// Close button
@mixin close-button {
  @include size(20px);
  position: relative;
  min-width: 20px;
  min-height: 20px;
  padding: 0;
  flex: 0 0 auto;
  font-size: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background: none;
  backface-visibility: hidden;

  &::before {
    @include svg-icon('lvc');
    content: 'M';
    position: absolute;
    color: var(--close-button-color);
    font-size: 14px;
    transition: all $main-transition-time;
  }
}

@mixin down-caret ($top: 0, $right: 0, $border-color: color(default, main)) {

  &:before,
  &:after {
    content: " ";
    position: absolute;
    top: calc(50% - 3px);
    right: $right;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: color(default, main) transparent transparent transparent;
    color: #fff;

    &:hover {
      border-color: color(primary, main) transparent transparent transparent;
    }
  }

  &:after {
    top: calc(50% - 5px);
    border-color: color(secondary, main) transparent transparent transparent;
  }
}

@mixin up-caret ($top: 0, $right: 0, $border-color: color(default, main)) {

  &:before,
  &:after {
    content: " ";
    position: absolute;
    top: calc(50% - 3px);
    right: $right;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent color(default, main) transparent;
    color: #fff;
  }

  &:after {
    top: calc(50% - 1px);
    border-color: transparent transparent color(secondary, main) transparent;
  }
}

// Regular Vegas linear gradient background
@mixin regular-vegas-linear-gradient {
  background-image: linear-gradient(to bottom, color(background, body) 0, color(background, body) 50%, color(promotions-card, title-color) 50%, color(promotions-card, title-color) 100%);
}

@mixin regular-vegas-separator {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  box-shadow: -1px 0 0 #000000, -2px 0 0 var(--home-menu-icon-color);

  @include media-sm {
    margin-bottom: 0;
  }
}

@mixin modal-fullscreen-mode {
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  border-radius: 0;
}

@mixin custom-hint {
  .input-input {

    &--hint,
    &--hint__static {
      right: 9px;
      width: 14px;
      height: 14px;
      font-size: 14px;
      background: color(close-button, background);
      color: color(main-menu, link-hover);
      border-radius: 50%;
      text-align: center;
      font-weight: bold;
      cursor: help;
      line-height: 1;

      +span {
        display: none;
        padding: 2px 5px;
        border: 0;
        border-radius: 3px;
        color: color(input-dark, background);
        background-color: color(primary, main);
        z-index: 1100;
        right: 35px;
        position: absolute;
        font-size: 10px;
        font-weight: normal;
        max-width: 200px;
        text-align: left;
        line-height: 1.5;
        margin-top: 30px;
      }
    }

    &--hint {
      position: absolute;
      top: calc(50% - 7px);

      &__static {
        position: static;
        display: inline-block;

        +span {
          margin: 0;
          right: -90px;
          width: 200px;
          text-transform: inherit;
        }
      }
    }

    &--hint:hover,
    &--hint__static:hover {
      +span {
        display: block;
      }
    }
  }
}

@mixin scrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 7px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: #23252F;
    border-radius: 4px;
  }
}

@mixin info-box {
  font-size: 12px;
  line-height: 18px;
  border: 1px solid var(--secondary-light);
  margin: 14px 0 0 0;
  padding: 12px 12px 12px 36px;
  border-radius: 8px;
  position: relative;

  p {
    margin: 0;
  }

  &:before {
    @include svg-icon('custom');
    content: 'p';
    color: var(--primary-main);
    position: absolute;
    left: 12px;
    top: 14px;
  }
}

@mixin tag {
  background: var(--primary-gold-dark-background);
  padding: 8px 12px 8px 36px;
  position: relative;
  border-radius: 100px;

  &:before {
    @include svg-icon('custom');
    content: 'p';
    color: var(--primary-main);
    position: absolute;
    top: 12px;
    left: 12px;
  }
}

@mixin custom-scrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar:vertical {
    width: 7px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: var(--custom-scrollbar-background);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary-main);
    border-radius: 4px;
  }
}

@mixin transaction-table {

  table,
  table.custom-table {

    thead,
    .custom-table-head {
      tr {

        th,
        th.custom-table-cell {
          padding: 12px 24px;
          font-weight: 500;
          font-size: 12px;
          line-height: 1;
          text-align: left;
          text-transform: none;
          color: var(--default-light);
          border: none;
        }
      }
    }

    tbody {
      .custom-table-row {
        cursor: pointer;

        .custom-table-cell {
          &--created-time {
            color: var(--default-main);

            span {
              font-size: 12px;
            }
          }

          &--copy-value {
            min-width: 20px;
            height: 20px;
            padding: 5px;
            margin-left: 10px;

            &::before {
              @include svg-icon('custom');
              color: var(--default-main);
              content: '\0032';
            }
          }
        }

      }

      tr,
      div.custom-table-row {
        &:nth-child(odd) {
          background: var(--activity-table-row);
        }
      }
    }

    tr {
      height: auto;
    }

    th,
    td {
      position: relative;
      white-space: nowrap;

      &:not(:last-child):before {
        content: ' ';
        border-right: 1px solid var(--default-light);
        position: absolute;
        top: 35%;
        right: 0;
        height: 10px;
      }

      &:not(:last-child):after {
        content: ' ';
        border-right: 1px solid var(--default-light);
        position: absolute;
        top: 50%;
        right: 0;
        height: 10px;
      }
    }

    td {
      &:first-child:before {
        content: ' ';
        border-right: 1px solid var(--default-light);
        position: absolute;
        top: 35%;
        right: 0;
        height: 10px;
      }

      &:first-child:after {
        content: ' ';
        border-right: 1px solid var(--default-light);
        position: absolute;
        top: 50%;
        right: 0;
        height: 10px;
      }
    }

    .custom-table-row,
    td {
      color: var(--balance-primary-light);
      text-transform: none;
      padding: 19px 24px;
      line-height: 1;
      text-align: left;
      font-size: 14px;
      border: none;
      white-space: nowrap;

      .custom-table-cell {

        &--status.ok,
        &--status.win {
          color: var(--status-ok)
        }

        &--status.cancelled {
          color: var(--primary-gold-light)
        }

        &--status.loss {
          color: var(--default-main)
        }
      }
    }
  }
}

@mixin linear-progress {
  position: relative;

  .linearProgress-progressLine {
    width: 100%;
    height: 17px;

    svg {
      border-radius: 4px;

      .linearProgress-background {
        fill: var(--custom-scrollbar-background);
      }

      .linearProgress-progress {
        fill: var(--primary-main);
      }

    }
  }

  .linearProgress-content {
    position: absolute;
    z-index: 2;
    line-height: 18px;
    text-align: right;
    color: color(button-accent, color);
    font-weight: 700;
    font-size: 12px;
    left: 1%;

    @include media-sm {
      left: 4%;
    }
  }
}

@mixin icon-backgrounded {
  color: var(--bonuses-icon-color);
  background: var(--bonuses-icon-background);
  border-radius: 100px;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    @include svg-icon('lvc');
    font-size: 11px;
  }
}

@mixin pagination-bullets {
  @include size(5px);
  display: block;
  position: relative;
  cursor: pointer;
  margin: 0 5px;

  &::before {
    @include size(5px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    background-color: var(--paginator-bullet);
    border-radius: 50%;
    content: '';
  }

  &-active {
    cursor: default;
    width: 30px;
    border-radius: 100px;
    background-color: var(--paginator-bullet-active);

    &::before {
      background-color: transparent;
    }
  }
}

:root {

  .light-theme {
    --default-main: #{color(default, normal)};

    --primary-light: #{color(primary, light-color)};
    --primary-light-color: #{color(primary, light-color)};
    --primary-light-header: #{color(primary, light-color)};
    --primary-dark: #{color(primary, dark-color)};
    --primary-gold-light: #{color(primary, gold-light-theme)};
    --primary-disabled-color:#{color(primary, disabled-color-light)};
    --primary-gold-dark-background:#{color(primary, light-background)};
    --primary-disabled-background: #{color(primary, light-background)};

    --secondary-main: #{color(secondary, light-background)};
    --secondary-light: #{color(secondary, light-border-background)};
    --secondary-disabled-background: #{color(secondary, disabled-background-light)};

    --body-background: #{color(background, body-background-light)};

    --background-aside-menu: #{color(background, aside-menu-light)};

    --header-background: #{color(background, header-background-light)};

    --select-icon-color: #{color(secondary, light)};

    --aside-menu-favourites-icon-color: #{color(background, aside-menu-favourites-icon-color-light)};
    --aside-menu-favourites-icon-background: #{color(background, aside-menu-favourites-icon-light)};
    --aside-input-background:#{color(background, aside-menu-favourites-icon-light)};

    --content-title-color: #{color(secondary, light)};
    --content-background: #{color(background, content-background-light)};

    --footer-background: #{color(background, footer-background-light)};
    --footer-content-version:#{color(footer, content-version-light)};
    --footer-payment-provider:#{color(footer, payment-provider-light)};
    --footer-privacy-links:#{color(footer, payment-privacy-light)};
    --footer-content-image:#{color(footer, footer-banner-background)};
    --footer-list-title:#{color(primary, deposit-radio-light)};
    --footer-list-item:#{color(primary, deposit-radio-light)};

    --switcher-lang-bg:#{color(primary, light)};

    --notify-text:#{color(secondary, light)};

    --balance-popover-hover: #{color(primary, main-light)};
    --balance-popover-bgimage: transparent;
    --balance-popover-bgimage-mobile: #{color(primary, dark-color)};
    --balance-popover-total: #{color(secondary, light)};
    --balance-popover-background: #{color(primary, dark-color)};
    --balance-primary-light:#{color(secondary, light)};
    --balance-primary-main:#{color(primary, button-active-light)};
    --balance-default-main:#{color(default, light)};
    --balance-primary-dark: #{color(primary, dark)};
    --balance-default-light: #{color(secondary, light)};
    --balance-staff-text:#{color(default, light)};
    --balance-primary-gold-light:#{color(secondary, light)};
    --balance--primary-dark: #{color(primary, dark)};
    --balance--secondary-main:#{color(primary, main-light)};

    --activity-table-row:#{color(secondary, light-background)};

    --tournment-background:#{color(footer, footer-banner-background)};
    --tournment-table-background:#{color(tournment, table-background-light)};
    --tournment-item-background:#{color(tournment, item-background-light)};

    --static-page-background:#{color(footer, footer-banner-background)};
    --static-page-description:#{color(secondary, light)};

    --paginator-active-background: #{color(primary, gold-light )};
    --paginator-background:#{color(primary, light-background )};

    --paginator-bullet:#{color(primary, light-background)};
    --paginator-bullet-active:#{color(primary, gold-light)};

    --modal-background-login: #{color(secondary, light-background)};
    --modal-text-color: #{color(modal, text-light)};

    --deposit-modal-content-background:#{color(primary, light)};
    --deposit-background:#{color(footer, footer-banner-background)};
    --deposit-withdrawal-primary-gold-light: #{color(primary, gold-light)};
    --deposit-withdrawal-primary-gold-background: #{color(primary, deposit-radio-light)};
    --deposit-radio-background: #{color(primary, deposit-radio-light)};
    --deposit-radio-color: #{color(primary, disabled-color-light)};
    --deposit-payment-color:#{color(default, normal)};
    --deposit-withdrawal-payment-background:#{color(secondary, light-background)};
    --deposit-payment-button:#{color(primary, dark-color)};
    --deposit-payment-input:#{color(primary, dark-color)};
    --deposit-modal-background:#{color(secondary,disabled-background-light)};
    --deposit-back-button:transparent;

    --settings-button-color:#{color(primary, light-color)};
    --settings-button-background:#{color(secondary, light-background)};
    --settings-button-active-background:#{color(primary, gold-light)};
    --settings-input-background:#{color(secondary, settings-input-disable)};

    --playtime-input-background:#{color(secondary, disabled-background-light)};

    --notfound-title:#{color(primary, light-color)};
    --notfound-text:#{color(primary, light-color)};
    --notfound-background-image: url("/cms/img/icons/light/notFound.svg");

    --contactus-title:#{color(primary, light-color)};

    --input-color-login: #{color(input-dark, text-light)};
    --input-background-login: #{color(input-dark, background-light)};
    --input-webkit-text-fill-color-login: #{color(primary, light-color)};
    --input-webkit-autofill-box-shadow-login: inset 0 0 0 100px #{color(primary, main-light)};

    --game-provider-filter-button-icon: #{color(primary, light-color)};

    --select-menu-background:#{color(primary, light)};
    --select-menu-item-color: color(primary, light-color);
    --select-item-hover-color:#{color(primary, disabled-background)};

    --button-golden-background: #{color(primary, gold-light)};
    --button-hover-golden-background: #{color(primary, button-background-light)};
    --button-hover-color:#{color(primary, button-hover-light)};
    --button-active-color:#{color(primary, button-active-light)};
    --button-active-background:#{color(primary, button-active-background-light)};
    --button-default-color: #{color(primary, button-color)};
    --button-default-background: #{color(primary, button-background)};
    --button-primary-background:#{color(primary, button-active-background-light)};
    --button-link-bankground:#{color(primary, light-background)};
    --button-promotions-secondary:#{color(secondary, main)};

    --promotion-background:#{color(primary, dark-color)};
    --promotion-lightgray-background:#{color(primary, light)};

    --game-thumbnail-background: #{color(primary, dark-color)};
    --game-thumbnail-icon-color:#{color(secondary,light-border-background)};
    --game-thumbnail-border:#{color(secondary, light-background)};
    --game-thumbnail-border-active:#{color(primary, main)};
    --game-buttons-links:#{color(primary, disabled-color-light)};

    --page-slider-dot-color: #{color(primary, light-color)};
    --page-slider-active-dot-color: #{color(primary, light-color)};

    --game-info-page-inner-background: #{color(modal, game-info-light)};

    --winner-list-background: #{color(primary, dark-color)};
    --winner-list-amount-color: #{color(primary, section-background)};

    --error-main: #{color(error, main)};
    --error-dark: #{color(error, dark-light)};

    --toggle-switch-background:#{color(toggle, background-light)};
    --toggle-switch-border:1px solid #{color(toggle, border-light)};
    --toggle-button-background:#{color(toggle, button-background-light)};

    --locale-border:#{color(locale-border, border-light)};

    --progress--item-background:#{color(primary, gold-dark)};
    --progress--item-background-completed:#{color(primary, button-background)};

    --faq-icon-selected:#{color(primary, disabled-color-light)};
    --faq-que-background:#{color(primary, main-light)};

    --register-help-bar:#{color(footer, footer-banner-background)};
    --registration-success-background:#{color(primary, light)};

    --table-activity-cell:#{color(secondary, light)};

    --hogyan-text:#{color(primary, light-color)};

    --arrow-rotate-angle: rotate(0deg);

    --active-text-color:#{color(secondary, light)};
    --primary-alt-black: #{color(primary, main-light)};
    --primary-section-background: #{color(primary, light)};

    --protection-soon:#{color(primary, deposit-radio-light)};
    --protection-text:#{color(primary, deposit-radio-light)};
    --protection-lan-background:#{color(settings-menu, background)};
    --protection-background:#{color(default, main)};
    --protection-list-title:#{color(secondary, disabled-background)};
    --protection-background-login:#{color(secondary, light-background)};

    --burger-background: #{color(primary, light-background)};
    --burger-border: transparent;
    --primary-text: #{color(primary, dark)};
    --group-choice-item: #{color(primary, dark-color)};
    --group-choice-border: transparent;
    --welcome-background: #{color(default, main)};
    --casino-asidemenu-color: #{color(secondary, light)};

    --icon-color: inherit;
    --progress-background: transparent;

    --alarm-color: #{color(secondary, main)};
    --alarm-icon-color: #{color(secondary, settings-input-disable)};
    --alarm-close-btn-color: #{color(primary, button-active-background)};
    --limit-amount-background: #{color(default, light-background)};

    --amount-selector-color: #{color(default, light)};
    --amount-info-background: #{color(default, light-background)};

    --tournaments-link: underline;
    --limit-border: none;
    --vergastv-icon-live-color: #{color(primary, disabled-color)};
    --vergastv-icon-color: #{color(primary, gold-light)};
    --vergastv-icon-background: #{color(primary, disabled-background)};
    --lock-icon-color:#{color(default, light)};
    --disable-input-text: #{color(default, light)};
    --jackpot-amount-background: #{color(primary, gold-light)};
    --custom-scrollbar-background: #{color(default, main)};
    --cancel-bonus-color: #{color(primary, dark)};
    --action-bonus-block-bg: #{color(default, light)};
    --cancel-bonus-underline: underline;
    --close-button-color: #{color(secondary, light)};
    --bonuses-icon-background: #{color(secondary, light)};
    --bonuses-icon-color: #{color(primary, gold-light)};
    --forfeit-border: transparent;
    --forfeit-background: #{color(secondary, light-background)};
    --promotions-button-background: #{color(primary, light-background)};
    --lvc-todo-list: #4C4E4F;
    --primary-main-light: #{color(primary, main-light)};

    --progress-line-track-bg: #{color(default, main)};
    --live-games-background-image: none;
    --live-games-section-icon: transparent;

    //cookiebot banner
    --cookie-banner-bg: #fff;
    --cookie-banner-color: #000;
    --cookie-border-color: #000;
    --cookie-border-active-color: #e7ba31;
    --cookie-customize-allow-bg: #aaa;
    --cookie-customize-allow-border: #aaa;
    --cookie-customize-allow-color: #000;
    --cookie-all-allow-bg: #e7ba31;
    --cookie-all-allow-border: #e7ba31;
    --cookie-all-allow-color: #000;
    --button-slider-bg: #aaa;
    --cookie-info-count:#000;

    // icons light variables

    --star-icon-contet: url("/cms/img/icons/star-icon-light.svg");
    --star-icon-contet-active: url("/cms/img/icons/light/star-icon-active-light.svg");
    --info-icon: url("/cms/img/icons/info-icon.svg");
    --balance-coins-icon: url("/cms/img/icons/light/coins.svg");
    --balance-trophy-icon: url("/cms/img/icons/light/trophy.svg");
    --balance-money-icon: url("/cms/img/icons/light/real_money.svg");
    --balance-total-icon: url("/cms/img/icons/light/total_balance.svg");
    --balance-lvc-coins-icon: url("/cms/img/icons/light/coins.svg");
    --balance-lvc-trophy-icon: url("/cms/img/icons/light/trophy.svg");
    --balance-lvc-spin-icon: url("/cms/img/icons/light/spin.svg");
    --balance-lvc-frame-icon: url("/cms/img/icons/light/Frame.svg");
    --balance-lvu-user-icon: url("/cms/img/icons/light/profile.svg");
    --balance-lvu-finance-icon: url("/cms/img/icons/light/coins.svg");
    --balance-lvu-settings-icon: url("/cms/img/icons/light/settings.svg");
    --balance-lvu-logout-icon: url("/cms/img/icons/light/sign-out.svg");
    --custom-close-icon: url("/cms/img/icons/light/close-full.svg");
    --custom-arrow-up-icon: url("/cms/img/icons/light/arrow-up-from-bracket.svg");
    --custom-arrow-down-icon: url("/cms/img/icons/light/arrow-down-to-bracket.svg");
    --favourite-icon-selected: url("/cms/img/icons/light/favourite-selected.svg");
    --footer-licence-nq: url("/cms/img/icons/nqa.png");
    --footer-licence-auditors: url("/cms/img/icons/light/auditors_icon.png");
    --footer-licence-hunguard_hun: url("/cms/img/icons/light/hunguard_hun-light.svg");
    --footer-licence-hunguard_eng: url("/cms/img/icons/light/hunguard_hun-light.svg");
    --phone-input-icon: url("/cms/img/icons/light/phone-light.png");
    --game-provider-hover-icon: url("/cms/img/icons/light/game_providers_icon.svg");
    --game-provider-active-icon: url("/cms/img/icons/game_providers_icon.svg");
    --promotions-button-chevron: url("/cms/img/icons/light/chevron-left.svg");
    --footer-brand-facebook: url('/cms/img/icons/facebook-dark.svg');
    --footer-brand-youtube: url('/cms/img/icons/youtube-dark.svg');
    --footer-brand-insta: url('/cms/img/icons/instagram-dark.svg');
    --footer-brand-threads: url('/cms/img/icons/threads-dark.svg');
  }

  --progress-line-track-bg: #{color(primary, gold-dark)};
  --promotions-button-background: #{color(primary, main)};
  --cancel-bonus-color: #{color(primary, main)};
  --action-bonus-block-bg: url('/cms/img/logo-mobile.svg');
  --cancel-bonus-underline: none;

  --primary-main: #{color(primary, main)};
  --primary-light: #{color(primary, light)};
  --primary-light-header: #{color(default, main)};
  --primary-dark: #{color(primary, dark)};
  --primary-gold-light: #{color(primary, gold-light)};
  --primary-gold-dark: #{color(primary, gold-dark)};
  --primary-gold-dark-background: #{color(primary, gold-dark-background)};
  --primary-disabled-color: #{color(primary, disabled-color)};
  --primary-disabled-background: #{color(primary, disabled-background)};

  --secondary-main: #{color(secondary, main)};
  --secondary-light: #{color(secondary, light)};
  --secondary-disabled-background: #{color(secondary, disabled-background)};

  --default-main: #{color(default, main)};
  --default-normal: #{color(default, normal)};
  --default-light: #{color(default, light)};

  --background-aside-menu: #{color(background, aside-menu)};

  --error-main: #{color(error, main)};
  --error-dark: #{color(error, dark)};

  --game-window-footer: #{color(background, game-window-footer)};

  --status-ok: #{color(status, ok)};

  --body-background: #{color(primary, dark)};
  --body-text-dark-color: #{color(text, dark)};
  --body-text-light-color: #{color(text, light)};

  --content-title-color: #{color(content, title)};
  --content-background: #{color(content, background)};

  --home-menu-background: #{color(background, home-menu)};
  --home-menu-item-color: #5E6061;
  --home-menu-icon-color: #2f2f2f;
  --home-menu-swiper-from: rgba(255, 255, 255, 1);
  --home-menu-swiper-to: rgba(255, 255, 255, 0);

  --header-background: #{color(background, header)};
  --header-color: #{color(primary, dark)};
  --header-border: 1px solid #{lighten(color(primary, dark), 60%)};
  --header-box-shadow: none;

  --aside-menu-background: #{color(background, aside-menu)};
  --aside-menu-link-color: #{color(main-menu, link)};
  --aside-menu-link-color-hover: #{color(main-menu, link-hover)};
  --aside-menu-border-color: #ccc;
  --aside-menu-favourites-icon-color: #{color(background, aside-menu-favourites-icon-color)};
  --aside-menu-favourites-icon-background: #{color(background, aside-menu-favourites-icon)};
  --aside-input-background:#{color(secondary, disabled-background)};

  --close-button-color: #{color(primary, light)};

  --button-golden-background: #{color(primary, main)};
  --button-hover-golden-background: #{color(primary, gold-light)};
  --button-secondary-color: #{color(primary, dark)};
  --button-secondary-hover-color: #{color(primary, light)};
  --button-default-color:#{color(primary, dark)};
  --button-default-background: #{color(primary, main)};
  --button-hover-color:#{color(primary, button-hover)};
  --button-active-color:#{color(primary, button-active)};
  --button-active-background:#{color(primary, button-active-background)};
  --button-primary-background:#{color(primary, main)};
  --button-link-bankground:transparent;
  --button-promotions-secondary:#{color(secondary, main)};

  --promotion-background:#{color(primary, section-background)};
  --promotion-lightgray-background:#{color(primary, button-active-background)};

  --post-login-content-background: #{color(background, post-login-content)};
  --post-login-menu-wrap-background: #{color(background, post-login-menu)};
  --post-login-settings-menu-background: #f8f8f8;
  --post-login-settings-menu-border-color: #e8e8e8;
  --post-login-menu-link-color: #{color(primary, dark)};

  --footer-background: #{color(footer, background)};
  --footer-top-section-background: rgba(0, 0, 0, 0.10);
  --footer-link-hover-color: #{color(footer, link-active)};
  --footer-content-version:#{color(footer, content-version)};
  --footer-payment-provider:#{color(footer, payment-provider)};
  --footer-privacy-links:#{color(footer, payment-privacy)};
  --footer-content-image:linear-gradient(0deg, rgba(22, 24, 25, 0.95) 0%, rgba(22, 24, 25, 0.95) 100%),
  url("/cms/img/background-footer.png"),
  lightgray 50%/cover no-repeat;
  --footer-list-title:#{color(primary, gold-light)};
  --footer-list-item:#{color(primary, light)};

  --switcher-lang-bg:#{color(secondary, main)};

  --notify-text:#{color(primary, light)};

  --primary-light-color: #{color(primary, light)};
  --preloader-backgound: #151515;

  --modal-title-background: #{color(modal, title-background)};
  --modal-background: #{color(secondary, main)};
  --modal-background-login: #{color(secondary, main)};
  --modal-title-color: #{color(modal, title)};
  --modal-title-color-modal: #{color(modal, title-modal)};
  --modal-text-color: #{color(modal, text)};

  --deposit-modal-content-background:transparent;
  --deposit-background:#{color(primary, dark)};
  --deposit-withdrawal-primary-gold-light: #{color(primary, gold-light)};
  --deposit-withdrawal-primary-gold-background: #{color(primary, gold-dark)};
  --deposit-radio-background: #{color(primary, gold-light)};
  --deposit-radio-color: #{color(default, light)};

  --deposit-payment-color:#{color(default, light)};
  --deposit-withdrawal-payment-background:#{color(secondary, disabled-background)};
  --deposit-money-background:#{color(primary, button-background)};
  --deposit-money-color:#{color(primary, button-color)};
  --deposit-payment-button:#{color(primary, disabled-background)};
  --deposit-payment-input:#{color(primary, button-active-background)};
  --deposit-modal-background:#212324;
  --deposit-back-button:#4C4E4F;


  --settings-button-color:#{color(primary, light)};
  --settings-button-background:none;
  --settings-button-active-background:#{color(secondary, main)};
  --settings-input-background:#{color(secondary, disabled-background)};

  --playtime-input-background:#{color(secondary, disabled-background)};

  --notfound-title:#{color(primary, white)};
  --notfound-text:#{color(primary, white)};
  --notfound-background-image:url("/cms/img/icons/notFound.svg");

  --limit-border: none !important;

  --hogyan-text:#{color(primary, white)};

  --contactus-title:#{color(primary, main)};

  --static-page-background: linear-gradient(0deg, rgba(22, 24, 25, 0.95) 0%, rgba(22, 24, 25, 0.95) 100%),
  url("/cms/img/background-footer.png"),
  lightgray 50%/cover no-repeat;
  --static-menu-background: #{color(background, static-menu)};
  --static-item-color: #{color(primary, dark)};
  --static-page-description:#{color(primary, white)};

  --select-icon-color: #{color(select, arrow)};
  --select-menu-background:#{color(select, background)};
  --select-menu-item-color:#{color(label, color)};
  --select-item-hover-color:#{color(primary, white)};

  --form-secondary-background: #f2f2f2;

  --table-even-background: #{color(table, even-background)};
  --table-activity-cell:#{color(default, main)};

  --paginator-active-background: #{color(secondary, main)};
  --paginator-background:transparent;

  --notification-color: #{color(text, main)};
  --notification-success-background: #{color(success, default)};

  --limit-amount-color: #777;
  --limit-amount-second-color: #{color(primary, dark)};
  --limit-amount-link-color: #{color(modal, text)};

  --limit-modal-color: #{color(modal, border)};

  --transactions-bets-background: #{color(primary, light)};

  --game-thumbnail-background: var(--body-background);
  --game-slider-nav-background: rgba(255, 255, 255, 0.7);
  --game-slider-nav-icon-color: #{color(secondary, main)};

  --game-info-page-inner-background: #{color(modal, game-info)};
  --game-thumbnail-icon-color:#{color(primary, main)};
  --game-thumbnail-border:#{color(secondary, main)};
  --game-thumbnail-border-active:#{color(primary, main)};
  --game-buttons-links:#{color(primary, gold-light)};

  --page-slider-dot-color: #{color(primary, light-color)};
  --page-slider-active-dot-color: #{color(primary, light-color)};

  --winner-list-widget-box-shadow: inset 0 10px 10px -10px #eee;
  --winner-list-background: url("/cms/img/games/winner-list-background.png");
  --winner-list-amount-color: #{color(primary, gold-light)};

  --balance-popover-background: #{color(primary, button-active-background)};
  --balance-popover-border-color: #{lighten(color(primary, dark), 50%)};
  --balance-popover-border: 1px solid var(--balance-popover-border-color);
  --balance-popover-hover: #{color(secondary, disabled-background-light)};
  --balance-popover-bgimage: url('/cms/img/bg.png');
  --balance-popover-bgimage-mobile: url('/cms/img/bg.png');
  --balance-popover-total: #{color(primary, gold-light)};
  --balance-primary-light:#{color(primary, light)};
  --balance-primary-main:#{color(primary, main)};
  --balance-default-main:#{color(default, main)};
  --balance-primary-dark: #{color(primary, dark)};
  --balance-default-light: #{color(default, light)};
  --balance-staff-text:#{color(default, main)};
  --balance-primary-gold-light:#{color(primary, gold-light)};
  --balance--primary-dark: #{color(primary, dark)};
  --balance--secondary-main:#{color(secondary, main)};

  --protection-login-form-input-background: #{color(primary, light)};
  --protection-adornment-password-background: #{color(primary, light)};

  --adornment-password-color: #{color(primary, dark)};

  --input-color: #{color(input, text)};
  --input-color-login: #{color(input-dark, text)};
  --input-color-mobile: #{color(input-mobile, text)};
  --input-background: #{color(input, background)};
  --input-background-login: #{color(secondary, disabled-background)};
  --input-background-mobile: #{color(input-mobile, background)};
  --input-label-unfilled-color: #{color(label, unfilled)};
  --input-label-filled-color: #{color(label, filled)};
  --input-label-mobile-color: #{color(label, color)};
  --input-webkit-autofill-box-shadow: inset 0 0 0 100px #{color(input, background)};
  --input-webkit-autofill-box-shadow-login: inset 0 0 0 100px #{color(secondary, disabled-background)};
  --input-webkit-text-fill-color: #{color(input, text)};
  --input-webkit-text-fill-color-login: #{color(input-dark, text)};

  --games-filter-background: #{color(background, filter)};
  --games-filter-text-color: #{color(text, dark)};
  --games-filter-text-checked-color: #{color(text, dark)};

  --game-provider-filter-button-icon: #{color(secondary, settings-input-disable)};

  --gd-background: #{color(background, game-window)};
  --gd-text: #57595b;
  --gd-footer-background: #{color(primary, light)};
  --gd-error-background: #{color(background, game-window)};

  --activity-form-cell-color: #000;
  --activity-form-post-login-title: #fff;
  --activity-table-row: #{color(secondary, light)};

  --file-attachments-border: #637284;
  --file-attachments-color: #313131;

  --game-status-win: #2c774f;
  --game-status-loss: #C65252;

  --tournment-background:linear-gradient(0deg, rgba(22, 24, 25, 0.95) 0%, rgba(22, 24, 25, 0.95) 100%),
  url(/cms/img/background-footer.png),
  lightgray 50% /cover no-repeat;
  --tournment-item-background:#{color(tournment, item-background)};
  --tournment-table-background:#{color(tournment, table-background)};

  --toggle-switch-background:#{color(toggle, background-dark)};
  --toggle-switch-border:0 solid #{color(toggle, border-dark)};
  --toggle-button-background:#{color(toggle, button-background)};

  --progress--item-background:#{color(primary, gold-dark)};
  --progress--item-background-completed:#{color(primary, button-background)};

  --faq-que-background:#{color(secondary, light)};
  --faq-icon:#{color(default, main)};
  --faq-icon-selected:#{color(primary, gold-light)};

  --register-help-bar:linear-gradient(#000, rgba(00, 00, 00, 85%));
  --registration-success-background:var(--Blur-Linear, linear-gradient(180deg, rgba(22, 24, 25, 0.90) 0%, #0B0C0C 100%));

  --locale-border:#{color(locale-border, border-gold)};

  --arrow-rotate-angle:rotate(-90deg);

  --paginator-bullet:#{color(secondary, light)};
  --paginator-bullet-active:#{color(primary, light)};
  --burger-background: #{color(secondary, main)};
  --burger-border: transparent;
  --primary-text: #{color(primary, dark)};
  --welcome-background: transparent;
  --group-choice-item: transparent;
  --group-choice-border: #{color(secondary, light)};
  --casino-asidemenu-color: #{color(secondary, main)};

  --icon-color: #{color(primary, main)};
  --progress-background: #{color(common, light)};

  --page-header-border: #{color(background, body)};

  --alarm-color: #{color(secondary, main)};
  --alarm-icon-color: #{color(label, color)};
  --alarm-close-btn-color: #{color(primary, button-active-background)};

  --limit-amount-background: #{color(secondary, main)};
  --amount-selector-color: #{color(primary, gold-light)};
  --amount-info-background: #{color(primary, gold-dark-background)};

  --active-text-color:#{color(primary, gold-light)};

  --tournaments-link: none;

  --vergastv-icon-live-color: #{color(primary, disabled-color)};
  --vergastv-icon-color: #{color(primary, gold-light)};
  --vergastv-icon-background: #{color(primary, disabled-background)};

  --lock-icon-color:#{color(default, main)};
  --disable-input-text:#{color(primary, light)};
  --jackpot-amount-background: #{color(primary, main)};
  --custom-scrollbar-background: #{color(primary, gold-dark)};
  --bonuses-icon-background: transparent;
  --bonuses-icon-color: #{color(primary, main)};
  --lvc-todo-list:#E7BA31;
  --forfeit-border: inherit;
  --forfeit-background: none;
  --primary-main-light: #{color(primary, main-light)};
  --primary-alt-black: #{color(primary, alt-black)};
  --primary-section-background: #{color(primary, section-background)};

  --protection-soon:#{color(primary, light)};
  --protection-text:#{color(label,color )};
  --protection-lan-background:#{color(settings-menu, background)};
  --protection-background:#{color(footer, background)};
  --protection-list-title:#{color(footer, list-title-dark)};
  --protection-background-login:#{color(settings-menu, background)};


  // cookieBot

  --cookie-banner-bg: #000;
  --cookie-banner-color:#fff;
  --cookie-border-color:#fff;
  --cookie-border-active-color:#e7ba31;
  --cookie-customize-allow-bg:#373839;
  --cookie-customize-allow-border:#373839;
  --cookie-customize-allow-color:#fff;
  --cookie-all-allow-bg:#e7ba31;
  --cookie-all-allow-border:#e7ba31;
  --cookie-all-allow-color:#fff;
  --button-slider-bg:#fff;
  --cookie-info-count:#fff;



  // icons dark variables

  --star-icon-contet:'X';
  --info-icon:url("/cms/img/icons/info-icon.svg");
  --balance-coins-icon:'0';
  --balance-trophy-icon:'n';
  --balance-money-icon:'z';
  --balance-total-icon:'Z';
  --balance-lvc-coins-icon:'u';
  --balance-lvc-trophy-icon:'W';
  --balance-lvc-spin-icon:'9';
  --balance-lvc-frame-icon:'8';
  --balance-lvu-user-icon:'o';
  --balance-lvu-finance-icon:'u';
  --balance-lvu-settings-icon:'3';
  --balance-lvu-logout-icon:'Z';
  --custom-close-icon:'\0065';
  --custom-arrow-up-icon:'C';
  --custom-arrow-down-icon:'D';
  --favourite-icon-selected:'\0058';
  --footer-licence-nq:url("/cms/img/icons/nqa.png");
  --footer-licence-auditors:url("/cms/img/icons/auditors_icon.png");
  --footer-licence-hunguard_hun:url("/cms/img/icons/tanusitas_hu.svg");
  --footer-licence-hunguard_eng:url("/cms/img/icons/tanusitas_en-dark.svg");
  --phone-input-icon:'t';
  --game-provider-icon:url("/cms/img/icons/game_providers_icon.svg");
  --game-provider-active-icon:url("/cms/img/icons/game_providers_active_icon.svg");
  --game-provider-hover-icon:url("/cms/img/icons/game_providers_hover_icon.svg");
  --promotions-button-chevron:url("/cms/img/icons/chevron-left.svg");
  --live-games-section-icon:15px solid color(promotions-card, background);
  --live-games-background-image: url('/cms/img/vegastv/vegastv-mainbg.jpg');
  --footer-brand-facebook: url('/cms/img/icons/facebook-white.svg');
  --footer-brand-youtube: url('/cms/img/icons/youtube-white.svg');
  --footer-brand-insta: url('/cms/img/icons/instagram-white.svg');
  --footer-brand-threads: url('/cms/img/icons/threads-white.svg');
}
