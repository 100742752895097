.verification {
  .radioGroup-root {
    flex-direction: column;

    label + label {
      span {
        margin-top: 10px;
      }
    }
  }

  .stepper-wrap {
    padding: 32px 0;

    @include media-sm {
      padding: 16px 0;
    }
  }
}
