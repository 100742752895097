/* Generated by Glyphter (http://www.glyphter.com) on  Mon Aug 05 2024*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-custom']:before{
	display: inline-block;
   font-family: 'Glyphter-custom';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-custom-arrow:before{content:'\0042';}
.icon-custom-withdraw:before{content:'\0044';}
.icon-custom-deposit:before{content:'\0045';}
.icon-custom-chat:before{content:'\0050';}
.icon-custom-jackpot:before{content:'\0054';}
.icon-custom-tourn:before{content:'\0055';}
.icon-custom-dark:before{content:'\0056';}
.icon-custom-light:before{content:'\0057';}
.icon-custom-fav:before{content:'\0058';}
.icon-custom-prov:before{content:'\0059';}
.icon-custom-total:before{content:'\005a';}
.icon-custom-more:before{content:'\0061';}
.icon-custom-hide:before{content:'\0062';}
.icon-custom-email:before{content:'\0063';}
.icon-custom-chevron-right:before{content:'\0064';}
.icon-custom-close:before{content:'\0065';}
.icon-custom-calendar:before{content:'\0066';}
.icon-custom-time:before{content:'\0067';}
.icon-custom-exit:before{content:'\0068';}
.icon-custom-play:before{content:'\0069';}
.icon-custom-ready:before{content:'\006a';}
.icon-custom-negative-monets:before{content:'\006b';}
.icon-custom-circle:before{content:'\006c';}
.icon-custom-cash:before{content:'\006d';}
.icon-custom-cancelled:before{content:'\006e';}
.icon-custom-lost:before{content:'\006f';}
.icon-custom-info-circle:before{content:'\0070';}
.icon-custom-pending:before{content:'\0071';}
.icon-custom-rejected:before{content:'\0072';}
.icon-custom-bank:before{content:'\0073';}
.icon-custom-hash:before{content:'\0074';}
.icon-custom-complete:before{content:'\0075';}
.icon-upload:before{content:'\0076';}
.icon-camera:before{content:'\0077';}
.icon-warning:before{content:'\0078';}
.icon-trash:before{content:'\0079';}
.icon-custom-share:before{content:'\007a';}
.icon-custom-chat:before{content:'\0030';}
.icon-help:before{content:'\0031';}
.icon-copy:before{content:'\0032';}
.icon-tournament:before{content:'\0033';}
.icon-odds-booster:before{content:'\0034';}
.icon-icon-close:before{content:'\0035';}
.icon-icon-file-check:before{content:'\0036';}
.icon-icon-success:before{content:'\0037';}
.icon-message-bot:before{content:'\0038';}
.icon-info-circle:before{content:'\0039';}
.icon-copy:before{content:'\0021';}
.icon-iconcopy:before{content:'\0022';}
.icon-custom-important:before{content:'\0023';}
.icon-disabled:before{content:'\007e';}
.icon-info:before{content:'\003f';}
