.playerVerificationStep3 {
  .playerVerificationForm3Amatic {
    &__details {
      @include media-sm {
        padding: 0 20px;
      }
    }
    &__header {
      width: 100%;
      font-size: 32px;
      line-height: 42px;
      font-weight: 700;
      margin: 0;

      @include media-sm {
        font-size: 24px;
        line-height: 34px;
      }
    }
    &__description {
      margin: 10px 0;
      font-size: 14px;
      line-height: 1;
      text-transform: uppercase;
      font-weight: 600;
    }
    .lvcVerification {
      &__info {
        @include info-box();
        border: 1px solid var(--primary-gold-dark);
        background: var(--primary-gold-dark-background);
        margin: 24px 0;
      }
      &__buttons {
        margin-top: 32px;

        .gridItem-root {
          @include media-sm {
            flex-direction: column-reverse;
          }
        }
      }
    }

    &-notice {
      padding: 8px 0;
      margin-bottom: 30px;

      i {
        margin-right: 8px;
      }
    }

    &__fields.lvcCardField {
      .button-root {
        i {
          margin-left: 8px;
        }

        @include media-sm {
          width: 100%;
        }
      }
      .field-input.field-disabled {
        .input-input {
          margin-right: 24px;

          @include media-sm {
            margin: 0;
          }
        }
        .input-disabled {
          &:after {
            margin-right: 124px;

            @include media-sm {
              margin-right: auto;
            }
          }

          @include media-sm {
            display: flex;
            flex-direction: column;
            gap: 18px;
          }
        }
      }
    }

    &__fields {
      display: grid;
      gap: 24px;
      padding: 24px;
      background: var(--secondary-disabled-background);
      border-radius: 10px;
      grid-template-columns: repeat(2, 1fr);
      max-width: 580px;

      @include media-sm {
        gap: 18px;
        grid-template-columns: repeat(1, 1fr);
      }

      hr {
        width: 100%;
        border-top: 1px solid var(--secondary-main);
        border-left: none;
        border-right: none;
        border-bottom: none;
        grid-column: 1 / 3;
        margin: 0;

        @include media-sm {
          grid-column: 1;
        }
      }

      .lvcVerification {
        &__header {
          grid-column: 1 / 3;
          text-align: left;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 16px;
          line-height: 1;

          @include media-sm {
            grid-column: 1;
          }
        }
      }

      .gridItem-root {
        flex: 1 1 340px
      }
    }
  }
}
