.limit-amount {
  .input-root {
    background: var(--limit-amount-background);
    border-radius: 8px;
    padding-right: 15px;
    color: var(--default-light);
    font-size: 14px;
    line-height: 1;
    align-items: center;
    border: 1px solid var(--secondary-main);

    input {
      border: var(--limit-border);

      &:active, &:focus, &:hover {
        border: none!important;
      }
    }
  }

  &__revert-button,
  &__remove-button {
    border-radius: 50%;
    min-height: 26px;
    min-width: 26px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #e0e0e0;
    border:2px solid #e0e0e0;
    border-radius: 5px;
    margin: auto 0 auto 10px;
    &:not(.button-loading) .button-label:before {
      @include svg-icon;
    }
  }
  &__revert-button .button-label:before {
    content: '\007d';
  }
  &__remove-button .button-label:before {
    content: '|';
  }
}
