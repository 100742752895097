.contact-us {
  &__modal {

    .modal-rootButtons {
      position: absolute;
      align-self: flex-end;
      background: none;

      .modal-buttonRoot::before {
        @include svg-icon('custom');
        content: '\0065';
      }
    }

    .modal-paper .modalContentWrapper {
      min-height: auto;

      .modal-contentRoot {
        padding: 0;
        max-width: 420px;


        .button-home {
          width: 100%;
          margin-top: 24px;
        }

        h2 {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
          color: var(--primary-light);
        }

        p {
          margin: 0;
          color: var(--primary-light);
          font-size: 14px;
        }

        ul li {
          list-style-type: disc;

          &::before {
            content: none;
          }
        }
      }
    }
  }
}
