.game-info-modal {
  .modal-rootButtons {
    gap: 24px;

    .modal-buttonRoot {
      padding: 22px;
      background: var(--secondary-main);
      border-radius: 8px;

      &:before {
        border-radius: 50%;
        color: var(--primary-light);
        @include svg-icon('lvc');
        content: 'M';
      }
    }
  }
  .modal-paper {
    @include scrollbar();

    @include media-sm {
      margin: 0;
      border: none;
    }

    .modalContentWrapper {
      padding: 0;
      width: 680px;
      min-height: 300px;

      .game-decorator__description {
        max-height: 200px;
        overflow: auto;
        @include media-xs {
          margin-bottom: 2rem;
        }
        @media (max-width: 391px) {
          max-height: 120px;
          margin-bottom: 0;

          body.is-ios & {
            max-height: 75px;
          }
        }
      }

      @include media-sm {
        width: auto;
      }

      .modal-rootButtons {
        position: absolute;
        right: 24px;
        top: 1.6em;
        z-index: 1;
        width: auto;
        background: none;

        @include media-sm{
          top: 0.6em;
        }
      }

      .modal-contentRoot {
        border-radius: 12px;
        position: relative;
        overflow: hidden;

        .loading-progress {
          margin-top: calc(25% - 40px);

          @include media-sm {
            margin-top: calc(50% - 40px);
          }
        }

        @include media-sm {
          border-radius: 0;
          overflow: auto;
        }

        @media (max-width: 391px) {
          background-color: var(--game-info-page-inner-background);
        }
      }
    }
    @media (max-width: 391px) {
      height: 100%;
      max-height: none;
    }
  }

  &--background {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 12px;
    width: -webkit-fill-available;
    width: -moz-available;

    @include media-sm {
      border-radius: 0;
    }
  }

  &-button {
    &--favourite {

      @include media-sm {
        width: 44px;
        min-width: 44px;
      }

      &:before {
        @include svg-icon();
        margin-right: 6px;

        @include media-sm {
          margin: 0;
        }
      }
      &-on:before {
        content: 'X';
      }
      &-off:before {
        content: 'W';
      }
    }
  }

  &--img {
    img {
      height: 32px;

      @include media-sm{
        height: 25px;
      }
    }
  }

  &--icon {
    margin-top: -40px;

    img {
      width: 220px;
      height: 110px;
      border: 5px solid var(--secondary-disabled-background);
      border-radius: 10px;

      @include media-sm {
        width: 100%;
        height: auto;
        gap: 12px;
      }
    }
  }

  &--caption {
    position: relative;
    background: var(--game-info-page-inner-background);
    color: var(--balance-primary-light);
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    flex-direction: row;
    display: flex;
    gap: 24px;
    align-items: end;

    @include media-sm {
      flex-direction: column;
      align-items: unset;
    }

    &-text {
      padding-bottom: 5px;
      gap: 8px;
      display: flex;
      flex-direction: column;
    }
  }

  &--groups-link {
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    color: var(--primary-main);
    display: flex;
    gap: 6px;

    a:not(:first-child) {
      border-left: 1px solid var(--secondary-light);
      padding-left: 6px;
    }
  }

  &--section {
    &-top {
      height: 116px;
      padding: 24px;

      .game-info-modal--img {
        position: relative;

        @include media-sm{
          top: -15px;
        }
      }
    }
    &-bottom {
      position: relative;
      z-index: 10;
      background: var(--game-info-page-inner-background);
      padding: 0 24px 24px 24px;

      .content-block {
        &-text {
          font-size: 14px;
          line-height: 20px;
          color: var(--primary-light);
        }
        &-content {
          display: flex;
          flex-direction: column-reverse;

          dl {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            font-size: 14px;
            gap: 10px;

            dd {
              color: var(--primary-light);
              font-weight: 600;
            }
          }

          .text-center {
            display: none;
          }
        }
      }
    }
  }

  &--buttons {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 16px;

    .button-root, .button-label {
      width: 100%;
      margin: 0;

      i {
        margin-right: 6px;
      }
    }
    @include media-xs {
      margin-top: -2.5rem
    }
    @media (max-width: 391px) {
      margin-top: 10px;
    }
  }

  @media (max-width: 391px) {
    align-items: flex-start;
  }
}
