.home-content {
  .choice-group-container {
    margin-bottom: 32px;
  }

  &--loading:has(.home-menu__wrapper) {
    .home-menu__wrapper{
      display: none;
    }
  }

  & + #WBannerapp {
    margin-top: 34px;
  }

  .games-not-found {
    &__container {
      margin: 10% auto 0;

      .markdown-root {
        p {
          font-size: 14px;
          line-height: 20px;
          color: var(--default-main);

          em {
            font-size: 0;

            &:after {
              @include svg-icon('lvc-regular');
              content: 'c';
              color: var(--primary-main);
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &--slider {
    .home-content__title {
      margin-top: 15px;
      margin-bottom: 5px;
      padding-right: 90px;
    }

    .game-thumbnail {
      @include media-xs {
        margin: 0;
      }
    }
  }

  @include media-sm {
    &__games {
      order: 2;
    }
    &__filter-wrapper {
      order: 1;
    }
  }

  .game-list {
    &__content {
      display: flex;
      flex-direction: column-reverse;
      order: 1;
    }

    &__title {
      margin: 0;
    }

    &__features {
      margin-bottom: 28px;
      margin-top: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .favourites {
        background: transparent;
        padding: 12px 15px;
        font-weight: 500;
        font-size: 14px;

        span {
          margin-right: 4px;

          &:before {
            @include svg-icon('custom');
            content: "X";
            font-size: 16px;
            color: var(--primary-main);
            margin-right: 7px;
          }
        }

        &:hover {
          background: transparent;
        }
      }

      .search {
        background: transparent;
        padding: 12px 15px;

        &:before {
          @include svg-icon;
          content: "Y";
          font-size: 16px;
        }

        &:hover {
          background: transparent;
        }
      }
    }

    &__empty{
      order: 3;
    }

    &__buttons-panel{
      order: 4;
    }
  }

  &__games {
    position: relative;
    display: flex;
    flex-direction: column;

    .game-list {
      order: 3;
      position: relative;
      top: -23px;
    }

    .home-content__game-list--similar {
      top: 23px;
      margin-bottom: 5px;
    }

    .home-menu__wrapper {
      order: 2;
      top: -37px;
      margin: 0;
      padding-right: 0;

      @include media-sm {
        margin-top: 15px;
        top: 0;
        gap: 0;
        margin-bottom: 35px;
      }
    }

    &-favourite {
      & > :nth-child(1) {
        order: inherit;
      }
      & > :nth-child(2) {
        order: inherit;
      }
      & > :nth-child(3) {
        order: inherit;
      }
      & > :nth-child(4) {
        order: inherit;
      }
      & > :nth-child(5) {
        order: inherit;
        top: 23px;
      }
      .home-content__game-list--favourite{
        margin-top: 40px;

        @include media-sm {
          margin-top: 80px;
        }
      }
      .home-menu__wrapper {
        position: absolute;
        right: 0;
        top: -7px;

        @include media-sm {
          width: 100%;
          margin-top: 38px;
        }
      }
      .game-list__buttons-panel {
        margin-bottom: 40px;
        margin-top: 20px;
      }
    }
  }

  &__game-list {
    transition: all 0.3s;
    position: relative;
  }

  &__spinner {
    position: absolute;
    left: 50%;
    opacity: 0;
    top: 0;
    transition: all 0.1s;
    transform: translateX(-50%);
  }

  &__game-group-description {
    margin-top: 30px;
    padding: 30px;
    background: rgba(255, 255, 255, .7);

    @include media(null, $size-md) {
      margin-bottom: -40px;
      padding: 30px 17%;
    }

    h1, h2, h3, h4, h5, h6 {
      text-align: center;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &--loading {
    .home-content__game-list {
      opacity: 0;
      min-height: calc(100vh - #{$header-collapsed-height});
    }

    .home-content__spinner {
      opacity: 1;
      //transition: all 0.3s 0.3s; // TODO: add when game filter smooth scrolling bug will be fixed
    }
  }
  &__providers.game-list__empty {
    grid-template-columns: 1fr;
  }
  &__providers {
    display: inline-grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px 22px;

    @include media-md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 12px;
      gap: 0;
      row-gap: 22px;
    }

    .provider-list__buttons-panel {
      grid-column: 1/4;
      text-align: center;

      @include media-sm {
        grid-column: 1;

        .button-root {
          width: 100%;
        }
      }
    }

    .image-root {
      display: block;

      img {
        height: auto;
        width: 100%;
        border-radius: 12px;
        aspect-ratio: 2/1;
        object-fit: contain;
        object-position: center;
      }

      .image-content {
        position: static;

        &-title {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: var(--primary-light);
        }

        &-details {
          color: var(--primary-gold-light);
          font-weight: 500;
        }

        .button-root {
          position: absolute;
          background: none;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: calc(100% - 45px);
        }
      }
    }
  }
}
