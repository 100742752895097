.account__content {
  .fields-container {
    .gridItem-root {

      &__receiveEmail,
      &__receiveSms,
      &__campaignsEnabled,
      &__publicPerson {
        max-width: 100%;
        grid-column: 1 / 3;

        @include media-sm {
          grid-column: 1;
        }
      }
    }
  }
  .cookiebot-text {
    text-decoration: none ;
    font-size: 14px;
    color: var(--balance-primary-light);
    font-size: 14px;
    display: inline-block;
    margin-top: 2.2rem;
    max-width: 110px;

    &:hover {
      text-decoration: none;
      color: var(--balance-primary-light);
    }
  }
}

.profile-form {
  &__verify {
    border-radius: 50%;
    min-height: 26px;
    min-width: 26px;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--error-main);

    &>.button-label:before {
      @include svg-icon;
      content: '\00b1';
    }
  }
}

