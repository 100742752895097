.feeConfirm {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  &-head {
    &__hint {
      font-size: 1rem;
      font-weight: 700;
      color: var(--balance-primary-light);
      ;
      text-transform: uppercase;
    }
  }

  .details-info {
    display: flex;

    &-deposit {
      flex-direction: column;
      background: var(--deposit-modal-content-background);
      gap: 1rem;

      .details-info-details {
        gap: 24px;

        legend {
          text-transform: uppercase;
        }
      }

      .fee-info-details {
        display: flex;
        flex-direction: row;
        gap: 24px;

        &__item {
          flex: 1 1 0;
        }

        &__label {
          font-size: 14px;
          text-transform: uppercase;
          color: var(--balance-primary-light);
          ;
        }

        &__value {
          font-size: 14px;
          color: var(--balance-primary-light);
          margin-top: -0.3rem;

          span {
            font-size: 16;
            font-weight: 500;
          }
        }
      }

      input {
        font-size: 1.5rem;
        height: 3.375rem;
        border: 1px solid color(primary, main);

        &::placeholder {
          font-size: 1.4rem;
          opacity: 0.5;
        }

        ::-ms-input-placeholder {
          /* Edge 12 -18 */
          font-size: 1.4rem;
          opacity: 0.7;
        }

        &:focus,
        &:active,
        &:hover {
          border: 1px solid color(primary, main);
        }
      }

      .fee-form-amount__currency {
        position: absolute;
        top: 2.5rem;
        right: 1rem;
      }

      .noteInfo {
        margin-bottom: 4rem;
      }

      @include media-xs {
        margin-top: 18px;
      }
    }

    &-withdraw {
      flex-direction: column;
      margin-top: 1rem;

      .details-info-details {
        legend {
          text-transform: uppercase;
        }

        .withdraw-monetary {
          @include invert-media-sm {
            width: 50%;
          }
        }

        .withdrawal-form__withdrawal--info--balance {
          color:var(--balance-staff-text);
          font-size: 14px;
          strong {
          color: var(--footer-list-title);
          margin-left: 1px;
        }
      }
      }

      .fee-info-details {
        display: flex;
        flex-direction: row;
        gap: 24px;
        margin-bottom: 0.5rem;

        &__item {
          flex: 1 1 0;
        }

        &__label {
          font-size: 14px;
          text-transform: uppercase;
          color: var(--balance-primary-light);
          ;
        }

        &__value {
          font-size: 14px;
          color: var(--balance-primary-light);
          margin-top: -0.3rem;

          span {
            font-size: 16;
            font-weight: 500;
          }
        }
      }

      input {
        font-size: 1.5rem;
        height: 3.2rem;
        border: 1px solid color(primary, main);

        &::placeholder {
          font-size: 1.4rem;
          opacity: 0.5;
        }

        ::-ms-input-placeholder {
          /* Edge 12 -18 */
          font-size: 1.4rem;
          opacity: 0.7;
        }

        &:focus,
        &:active,
        &:hover {
          border: 1px solid color(primary, main);
        }
      }

      .fee-form-amount__currency {
        position: absolute;
        top: 2.5rem;
        right: 1rem;
      }
    }

    &-details {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include media-sm {
        flex-direction: column;
        gap: 1em;
      }

      &>* {
        width: 100%;
      }
    }
  }

  &__info {
    font-weight: 400;

    &_deposit {
      display: none;
    }

    &_withdraw {
      display: block;
      font-size: 1.167em;
      color: var(--primary-light);
    }
  }

  &-buttons {
    display: flex;
    justify-content: space-between;
    gap: 2em;

    &__back {
      width: 15%;
      background-color: var(--deposit-back-button);
      color: var(--primary-light);

      &:before {
        @include svg-icon('custom');
        content: '\0042';
        color: var(--default-main);
        transition: none;
        font-size: .8em;
        font-weight: 500;
        rotate: 270deg;
        margin-right: .2em;
      }
    }

    &__confirm {
      width: 81%;
    }
  }
}

.withdraw-feeConfirm{
  .details-info{
    .noteInfo{
      @include media-sm{
        &::before{
          margin-top: 0.25rem;
        }

      }
    }
  }
}