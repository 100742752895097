.choice-group {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  gap: 8px;
  position: relative;

  &-container {
    position: relative;
    padding-bottom: 20px;
  }

  &-row {
    display: flex;
    flex-direction: row;
    gap: 8px 24px;

    &_desktop {
      flex-wrap: wrap;
    }

    &__item {
      font-size: 0.75rem;
      border: 1px solid var(--group-choice-border);
      border-radius: 1rem;
      background: var(--group-choice-item);
      color: var(--default-main);
      white-space: nowrap;

      .link {
        color: inherit;
        white-space: nowrap;
        padding: .667em 2em;
        display: flex;
        align-items: center;
        text-decoration: none;
        line-height: 1em;
      }
    }

    &__item_selected {
      background: color(primary, gold-light);
      color: var(--button-secondary-color);
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    @include media-sm {
      display: none;
    }
  }
}
