.cancelFreespinModal {
  .modal-paper {
    max-width: 420px;

    .modalContentWrapper {
      .modal-rootButtons {
        position: absolute;
        top: 16px;
        right: 24px;
        background: none;
        width: 24px;
        height: 24px;

        .modal-buttonRoot::before {
          @include svg-icon('custom');
          content: '\0065';
        }
      }
    }
  }

  &--info {
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-light);
    margin-top: 8px;

    p {
      margin: 0;
    }

    em {
      color: var(--cancel-bonus-color);
      font-style: normal;
      text-decoration: var(--cancel-bonus-underline);
    }
  }

  &--notice {
    @include info-box();
    background: var(--secondary-disabled-background);
    margin: 24px 0;

    &::before {
      color: var(--cancel-bonus-color);
    }
  }

  .button-root {
    width: 100%;

    i {
      margin-left: 8px;
      line-height: 1;
    }
  }
}
