.playerVerificationStep0 {
  .playerVerificationForm0 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background: black;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1;

    .field-root label {
      color: color(verification, label);
    }
    .notification-root.notification-error {
      background: initial;
      color: color(attention) !important;
      border: 1px solid color(attention);
      border-radius: 5px;
    }

    &__left,
    &__right {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      overflow: hidden;

      @media (max-width: 1120px) {
        flex-basis: 100%;
      }

      &::before {
        content: '';
        position: absolute;
        width: 350px;
        height: 350px;
        transform: rotate(45deg);
        opacity: 86%;
      }
    }

    &__right > div:first-child,
    &__left > div:last-child {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      color: color(verification, option-name);

      > div {
        max-width: 115px;
      }
    }
    &__left {
      display: flex;
      justify-content: space-between;
      padding-top: 100px;
      padding-bottom: 100px;
      background-size: cover;
      background-image: url('/cms/img/verification/quick-verify-bg.png');
      background-repeat: no-repeat;
      background-position: top center;
      background-color: #000000;

      > div:first-child {
        width: calc(100% - 220px);
      }

      > div:last-child {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        min-width: 180px;
        z-index: 100;
        justify-content: center;
        align-items: center;
        text-align: center;

        &::before {
          display: block;
          content: '';
          width: 60px;
          height: 45px;
          bottom: 40px;
          left: 50%;
          background: url('/cms/img/icons/run.svg') no-repeat;
        }
      }

      &::before {
        right: -200px;
        background: color(verification, select-left);
        border-radius: 0 0 0 10px;
      }

      h2 {
        margin: 0 20px;
      }
    }

    &__left-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__right {
      display: flex;
      justify-content: space-between;
      padding: 150px 0;
      background: color(verification, background-right);

      > div:first-child {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        min-width: 180px;
        z-index: 100;
        justify-content: center;
        align-items: center;
        text-align: center;

        &::before {
          display: block;
          content: '';
          width: 25px;
          height: 45px;
          bottom: 40px;
          left: 50%;
          background: url('/cms/img/icons/walk.svg') no-repeat;
        }
      }

      > div:last-child {
        width: calc(100% - 220px);
        text-align: center;
      }

      &::before {
        left: -200px;
        background: color(verification, select-right);
        border-radius: 0 10px 0 0;
      }

      .playerVerificationForm0__title {
        color: color(verification, title-right);
      }

      h2 {
        max-width: 350px;
        margin: 0 auto 30px auto;
      }
    }
    &__close {
      @include close-button;
      border-radius: 50%;
      position: absolute;
      top: 13px;
      right: 16px;
      z-index: 101;

      @media (min-width: 1121px) {
        background: color(verification, close-button-backgrount);
      }
    }
    &__title {
      max-width: 375px;
      margin: 0 auto 10px auto;
      font-size: 26px;
      font-weight: 400;
      letter-spacing: 0.26px;
      line-height: 1;
      text-align: center;
      color: color(verification, title-left);
    }

    &__form {
      max-width: 272px;
      padding: 35px 0;
    }

    h2 {
      text-transform: initial;
    }
  }
}

.mobile-page-theme .playerVerificationStep0 {
  .playerVerificationForm0 {
    top: -10px;
    left: -15px;
    right: -15px;
  }
  .playerVerificationForm0__close {
    display: none;
  }
  .playerVerificationForm0__title {
    font-size: 20px;
  }
  .playerVerificationForm0__left {
    flex-direction: column-reverse;
    padding-top: 0;
    padding-bottom: 0;

    > div:last-child {
      height: 150px;
      justify-content: initial;
      font-size: 10px;
      color: color(verification-mobile, title);
      word-spacing: 9999rem;

      &::before {
        margin-top: 9px;
        width: 36px;
        height: 28px;
        background: url('/cms/img/icons/run-black.svg') no-repeat;
        background-size: 36px auto;
      }
    }

    &::before {
      top: -92px;
      right: initial;
      width: 185px;
      height: 185px;
      border-radius: 0 0 10px;
      background-color: color(verification-mobile, select-top);
      opacity: 1;
    }
  }
  .playerVerificationForm0__left-content {
    width: 100% !important;
    padding-bottom: 40px;

    h2 {
      margin: 0;
      max-width: initial;
    }
  }

  .playerVerificationForm0__right {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 70px;

    > div:first-child {
      justify-content: initial;
      padding: 17px 0 25px 0;
      font-size: 10px;
      color: color(verification-mobile, title);
      word-spacing: 9999rem;

      &::before {
        width: 16px;
        height: 30px;
        background: url('/cms/img/icons/walk-black.svg') no-repeat;
        background-size: 16px auto;
      }
    }
    > div:last-child {
     width: 100%;

      h2 {
        width: 100%;
        max-width: initial;
      }
    }

    &::before {
      display: none;
    }
  }

  .playerVerificationForm0__form {
    padding: 20px 0;
  }
}
