.activity-form {
  table {
    margin: 24px 0;
  }
  @include transaction-table();
  @include custom-scrollbar();
}

.custome-table-cell-creat-time {
  color: var(--table-activity-cell) !important;
}

.post-login-content-wrap--activity {
  @media (max-width: 668px) {
    background: none;

    .post-login__title {
      background: var(--activity-form-post-login-title);
      margin: 0;
      padding: 20px 0;
    }
  }
}
