/* Generated by Glyphter (http://www.glyphter.com) on  Wed Nov 29 2023*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-logout:before{content:'\0048';}
.icon-roulette:before{content:'\004a';}
.icon-star:before{content:'\0057';}
.icon-favourite:before{content:'\0058';}
.icon-search:before{content:'\0059';}
.icon-registration:before{content:'\0061';}
.icon-chevron-right:before{content:'\0062';}
.icon-login:before{content:'\006d';}
.icon-freeSpins:before{content:'\007a';}
.icon-monets:before{content:'\0030';}
.icon-sport:before{content:'\0031';}
.icon-settings:before{content:'\0033';}
.icon-more:before{content:'\0039';}
.icon-hide:before{content:'\0021';}