.transactions {
  @include custom-scrollbar();
  .empty {
    @media (min-width: 668px) {
      &::before {
        content: '—';
      }
    }
  }
  .custom-table--transactions {
    margin-bottom: 10px;
    @include transaction-table();

    &-game {
      tbody {
        .custom-table-row {
          cursor: inherit;
        }
      }
    }

    @media (max-width: 667px) {
      margin-bottom: 0;
    }
  }

  &-paginator {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 24px;
    margin-top: 24px;

    @include media-sm {
      justify-content: space-between;
      gap: 12px;
    }

    .paginator-first, .paginator-last {
      display: none;
    }
  }
}

.transactions-bets {
  $color-muted: #777;

  margin: 12px 0 24px;
  padding: 30px;
  color: var(--body-text-dark-color);
  background: var(--transactions-bets-background);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);

  @include media-sm {
    padding: 20px;
  }

  &__title {
    margin-bottom: 16px;
    color: $color-muted;
    font-size: 14px;
    line-height: inherit;
    text-transform: uppercase;
  }

  &__stats {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px -15px -10px;

    @include media-md {
      margin: 20px -10px -10px;
    }
  }

  &__stat {
    margin: 0 15px 10px;

    @include media-md {
      margin: 0 10px 10px;
    }

    &--bet,
    &--win {
      position: relative;
      padding-left: 24px;

      &::before {
        @include size(16px);
        position: absolute;
        top: 2px;
        left: 0;
        display: block;
        border-radius: 50%;
        content: "";
      }
    }
    &--bet {
      &::before {
        background: $color-muted;
      }
    }
    &--win {
      &::before {
        background: var(--primary-main);
      }
    }
    &--notice {
      flex: 1;
      color: #a09f9f;
      font-size: 12px;
      text-align: right;

      @include media-md {
        text-align: center;
      }
    }

    &-title {
      font-weight: $font-weight-alt;
      text-transform: uppercase;
    }
    &-amount {
      font-size: 12px;
    }
  }

  .linearProgress-root {
    .linearProgress-progressLine {
      height: 20px;

      .linearProgress-line {
        border-radius: 10px;
      }
      .linearProgress-progress {
        animation: none;
        transition: none;
      }
    }
  }
}

.transactions-form {
  &__grid {
    width: auto;
    margin: 0 -20px;

    @include media-sm {
      margin: 0;
    }
  }

  &__grid-item {
    padding: 0 20px;

    @include media-sm {
      padding: 0;
    }
  }

  > .grid-typeContainer > .gridItem-root > .transactions-form__grid > .transactions-form__grid-item:has(.field-success .select-root input[value="38"]) {
    display: none;
  }

  &--promo {
    &-loader {
      margin-bottom: 20px;
    }
  }
}

.transaction-details {
  &__modal {
    .button-root {
      p {
        margin: 0;
      }
      strong {
        font-weight: $font-weight-base;
      }
    }
  }
  &__table {
    @include transaction-table();
  }
  &__buttons {
    padding: 36px 0 30px;
  }
}



.post-login-content-wrap_transactions {
  h2 {
    font-size: 24px;
    text-transform: inherit;
  }

  .transactions-result-message {
    font-size: 18px;

    p {
      margin: 0 0 14px 0;
    }
  }

  @media (max-width: 667px) {
    background: none;

    .container {
      padding: 0;
    }
    h1 {
      margin-top: -15px;
      margin-bottom: 0;
      padding: 15px;
      background: var(--content-background);
    }
    .transactions-form {
      background: var(--content-background);
    }
    .transactions-result-loading,
    .transactions-result-message {
      margin-bottom: 20px;
      padding: 0 20px 20px;
      background: var(--content-background);
    }
  }
}
