.help-bar {
  width: $width-help-bar;
  min-width: 440px;
  background: var(--register-help-bar);
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 100vh;

  &-content {
    color: var(--balance-primary-light);
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid var(--default-normal);
    padding: 24px;

    .notification-warning {
      border: 1px solid var(--balance-primary-main);
      border-radius: 100px;
      background: none;
      font-weight: 500;
      font-size: 12px;
      width: fit-content;
      padding: 8px 12px;
      min-width: auto;

      .notification-message {
        align-items: center;
        display: flex;
        color: var(--balance-primary-main);
      }

      i {
        color: var(--balance-primary-main);
        margin-right: 10px;
        font-size: 15px;
        line-height: 12px;
      }
    }

    strong {
      display: block;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &--label {
      background: var(--secondary-disabled-background);
      padding: 10px 12px;
      border-radius: 50%;
      font-size: 12px;
      font-weight: 500;
      color: var(--default-main);
    }

    &__support {
      p {
        display: inline-block;
      }
      &:before {
        @include svg-icon('lvc');
        content: 'q';
        margin-right: 8px;
      }
    }
  }

  &-stepper {
    background: none;
    gap: 12px;

    .button-secondary {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      padding: 10px;
    }
  }

  @include media-sm {
    display: none;
  }
  @media (max-width: 1024px) and (orientation: landscape)  {
    display: none;
  }
}
