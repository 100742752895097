.page-slider {
  @include size(100%);
  position: relative;
  margin-right: auto;
  margin-left: auto;
  white-space: nowrap;
  padding-bottom: 17px;
  overflow: hidden;

  &__slide {
    @media all and (max-width: 480px) {
      .desktop {
        display: none;
      }
    }
    @media (min-width: 480px) {
      .mobile {
        display: none;
      }
    }
  }

  &::after {
    display: none;
  }

  .swiper-slide {
    position: relative;
    z-index: 1;
    display: inline-block;
    width: 100%;
    padding-top: 26%;
    border-radius: 18px;
    overflow: hidden;

    @include media-md {
      padding-top: 26.2%;
    }

    @include media-sm {
      padding-top: 26.2%;
    }
    @include media-xs{
      padding-top: 42%;
    }
  }

  &__image {
    @include size(100%);
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center top;
    background-size: cover;
  }

  &__content {
    position: absolute;
    z-index: 2;
    bottom: 15%;
    right: 0;
    left: 0;
    color: #fff;
    white-space: initial;

   .vegas_tv_title{
      color: #fff;
    }

  .vegas_tv_action {
      color: #fff;
      border: 1px solid #fff;

      &:hover {
        border: 1px solid #fff !important;
      }
    }

    .btn_grey {
      color: #4C4E4F;
      border: 1px solid #4C4E4F;

      &:hover {
        color: #4C4E4F !important;
        border: 1px solid #4C4E4F !important;
      }
    }

    @include media-sm {
      bottom: 12px;
    }

    .container {
      margin: 0 auto;
      max-width: initial;
      padding: 0 7.5%;
      display: flex;
      flex-direction: column;

      @include media-xs {
        padding: 0 3%;
      }
    }

    .slide-center & {
      .container {
        @include media(null, $size-xs) {
          text-align: center;
        }
      }
    }
  }

  h3 {
    margin: 0;

    &.black {
      color: #000;
    }
  }

  &__title {
    color: var(--content-title-color);
    font-size: 2rem;
    text-transform: none;
    line-height: 1.2;
    margin: 0;

    span {
      color: #e2aa1e;
    }

    @include media-lg {
      font-size: 1.5rem;
    }

    @include media-md {
      font-size: 1.3rem;
    }

    @include media-sm {
      font-size: 1rem;
    }

    &[class*="mobile"] {
      font-size: 3.8vw;
    }

    .slide-center & {
      font-size: 2.35rem;

      @include media-md {
        font-size: 1.1rem;
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    margin: 0;

    &.black {
      color: #000;
    }

    &[class*="mobile"] {
      margin: 0 0 5px;
      font-size: 3.8vw
    }

    .slide-center & {
      @include media-md {
        font-size: .8rem;
        margin: 0 0 .5rem;
      }
    }
  }

  &__action:not(.button-disabled) {
    display: flex;
    width: fit-content;
    margin-top: 18px;

    @include media-sm {
      margin-top: 12px;
    }

    i {
      margin-right: 8px;
    }

    &:hover {
      color: inherit;
    }

    &.register {
      margin: 18px auto 0;
    }
  }

  // Wrapper
  .swiper-wrapper {
    position: relative;
    z-index: 1;
  }

  // Pagination
  .swiper-pagination {
    position: absolute;
    z-index: 2;
    bottom: 1px;
    left: 50%;
    display: flex;
    transform: translateX(-50%);

    &-bullet {
      @include pagination-bullets()
    }
  }

  .swiper-button {
    &-next,
    &-prev {
      position: absolute;
      @include size(44px);
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
      background: #373839;
      z-index: 1;
      cursor: pointer;

      @include media-sm {
        display: none;
      }

      &::before {
        content: '';
        @include size(8px);
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &:hover {
        opacity: 1;
      }
    }

    &-next {
      right: 18px;

      &::before {
        border-top: 1px solid white;
        border-right: 1px solid white;
        transform: translate(-60%, -50%) rotate(45deg);
      }
    }

    &-prev {
      left: 18px;

      &::before {
        border-left: 1px solid white;
        border-bottom: 1px solid white;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
