.support-live-chat {
  position: absolute;
  left: 0;
  margin-top: 35px;
  padding: 10px 10px 15px 10px;
  border-radius: 5px;
  background: color(footer, background);
  text-align: center;
  &__title {
    margin: 0 0 14px 0;
    color: color(label, color);
    letter-spacing: .5px;
    line-height: 1.3;
    font-size: 12px;
  }
  &__button {
    min-height: 36px;
    padding: 11px 14px 8px;
    border-radius: 4px;
    background: linear-gradient(to bottom,#398f68 0,#398f68 50%,#247e55 50%,#247e55 100%);
    color: color(modal, title-mobile);
    font-size: 10px;
    letter-spacing: .5px;
    &:before {
      @include svg-icon('custom');
      content: '\0050';
      font-size: 14px;
      padding-right: 6px;
    }
    &:hover {
      color: color(input-dark, background);
    }
  }
}
