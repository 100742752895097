custom-scrollbar {
  &::part(custom-scrollbar) {
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    height:6px;
  }

  &::part(custom-scrollbar__track) {
    width: 100%;
    height: 6px;
    background-color: var(--custom-scrollbar-background);
    border-radius: 4px;
  }

  &::part(custom-scrollbar__thumb) {
    position: absolute;
    animation: top 0.25s ease-in;
    border-radius: 100px;
    height: 6px;
    background: color(primary, gold-light);
  }
}
