.game-transactions-form__game-selector-loader {
  height: 10px;
}

.transactions-form {
  &--game {
    width: 100%;
    .field-select > div::after {
      content: none;
    }
    .transactions-result-message {
      p {
        font-size: 20px;
      }
    }
  }

  &__table {
    .transactions-result-message {
      font-size: 20px;
      font-weight: 300;
      letter-spacing: 0.5px;

      strong {
        font-weight: 300;
      }
    }

    .transactions-form__table .custom-table-body .custom-table-cell--reference span {
      text-overflow: initial;
      width: auto;
    }
    .transactions-form__table .custom-table-body .custom-table-cell--device {
      span:hover {
        color: #428bca;
      }
    }

    .Mobile::after {
      content: "\0053";
    }

    .Desktop::after {
      content: "\0052";
    }

    .Mobile, .Desktop {
      &::after {
        font-family: 'Glyphter-custom';
      }

      &:hover {
        color: #428bca!important;
      }
    }

    .mobile-table-row {
      background: var(--input-background);
      padding: 10px 15px;
      border-radius: 5px;
      margin: 10px 0;
    }

    .custom-table--transactions {
      .hidden {
        height: 0;
        margin: 0;
        overflow: hidden;

        dt, dd {
          text-transform: initial;

          .button-root {
            padding: 4px 6px 2px;
            min-height: auto;
            min-width: auto;
            font-size: 9px;
            line-height: 1.3;
            letter-spacing: .5px;
          }

          p {
            margin: 0;
          }
        }
      }

      .show-hidden + .hidden {
        height: auto;
        padding-top: 20px;
      }

      dl {
        color: var(--file-attachments-border);
        margin: 0;
        padding: 0;
        transition: all 0.5s ease;

        dt, dd {
          line-height: 1.5;
        }
      }
    }
  }
}
