.modal-root .modal-paper .modalContentWrapper .modal-buttonRoot {}
.games-search-modal {
  .modal-paper {
    justify-content: initial;
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.5);

    .modalContentWrapper {
      overflow: hidden;

      .modal-buttonRoot {
        @include close-button;
        position: absolute;
        top: 31px;
        right: 50px;
        z-index: 35;
        background: var(--primary-main);
        border-radius: 50%;
        color: var(--primary-dark);

        @include media-xs {
          top: 21px;
          right: 10px;
        }
      }
      .modal-contentRoot {
        padding: 0;

        > .game-search {
          position: sticky;
          top: 0;
          z-index: 30;
          display: block;
          padding: 30px 107px 30px 50px !important;

          @include media-xs {
            padding: 20px 62px 20px 10px !important;
          }
          &::before {
            top: 38px;
            left: 64px;

            @include media-xs {
              top: 28px;
              left: 20px;
            }
          }
          &:hover {
            &::before {
              background: none;
            }
          }
          input {
            width: 100%;
            border: 0;
            background: var(--primary-light);
          }
          .game-search-loading {
            right: 112px;

            @include media-xs {
              right: 67px;
            }
          }
        }
        > .game-list {
          height: 100% !important; // for FF
          margin-top: -94px;
          padding: 100px 50px 50px ;
          -webkit-mask-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 94px, white 104px);

          @include media-xs {
            padding: 100px 10px 20px;
          }
        }
      }
    }
  }
  .modal-contentRoot {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .game-list--search {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .game-list__empty {
    background: none;
  }
}
