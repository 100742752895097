.appBanner {
  display: flex;

  &__img {
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
  }
  &.aside-menu__banner img {
    position: absolute;
    bottom: 0;
  }
  &.top-indent {
    margin-top: 35px;
  }
}
