.footer-menu {
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 0 20px;

  .tablet & {
    justify-content: space-around;
  }

  a {
    display: inline-block;
    color: var(--default-main);
    line-height: 22px;
    font-weight: $font-weight-base;
    font-size: 0.875rem;
    font-style: normal;
    text-decoration: underline;

    @include media-sm {
      padding-left: 12px;
      padding-right: 12px;
      font-size: 14px;
      line-height: 1;
    }
    @include media-xs {
      width: 100%;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}
