.promo-lock-modal {
  .modal-paper {
    width: 430px;

    .modalContentWrapper {
      padding: 30px;
    }
  }

  .modal-contentRoot {
    padding: 0;
  }

  &__description {
    font-size: 18px;
  }

  &__button {
    margin-top: 14px;

    .button-accent {
      width: 100%;
    }
  }
}
