.progress-line-wrap {
  &__label {
    color: var(--balance-primary-light);
    margin-bottom: 0.7em;
  }

  .progress-line {
    position: relative;
    width: 100%;
    font-size: 12px;
    height: 1.167em;
    display: flex;
    align-items: center;

    --progress-line-track-height: 0.667em;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      width: 4em;
      height: var(--progress-line-track-height);
      border-radius: 10px;
    }

    &:before {
      position: absolute;
      background-color: color(primary, main);
      left: 0;
    }

    &:after {
      position: absolute;
      background-color: var(--progress-line-track-bg);
      right: 0;
    }

    .range-root {
      position: absolute;
      left: 37px;
      right: 27px;

      .range-content {
        cursor: auto;
        margin: 0;
        height: var(--progress-line-track-height);
        background-color: var(--progress-line-track-bg);

        .Range-range-125:after {
          display: none;
        }

        .Range-range-125:before {
          width: 3em;
          border-radius: 10px;
          z-index: 1;
          cursor: auto;
        }

        .range-range {
          cursor: auto;
          background-color: color(primary, main);
          height: var(--progress-line-track-height);

          &:before {
            cursor: auto;
          }

          &:after {
            display: none;
          }

          .range-left {
            cursor: auto;
            margin-right: 0;
            display: none;

            .range-valueWrapperLeft {
              display: none;
            }
          }

          .range-right .range-valueWrapperRight {
            .custom-range-value {
              position: absolute;
              top: -1.4em;
              right: -2.2em;
              color: black;
              font-weight: 500;
              padding: 2px 6px;
              border-radius: 10px;
              white-space: nowrap;
              line-height: 1em;
              z-index: 1;
              background-color: color(primary, gold-light);
              min-width: 5.3em;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
