body .popup-decorator {
  height: auto;

  &__content {
    overflow: visible;
  }
}
.popup-decorator-wrapper .home-menu__wrapper {
  top: 30px;
}
.game-info-page {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
  padding-top: 60px;

  .game-list, .game-list__empty {
    margin: 0 15px 30px 15px;
    width: calc(100% - 30px);
  }

  @include media-sm {
    padding-top: 60px;
    background: none;
  }

  @include media-xs {
    padding-top: 0;
  }

  &__inner {
    display: flex;
    width: 100%;
    min-height: 100%;
    padding: 0 10px;
    max-width: 500px;
    margin: 0 auto;
  }
  &__content {
    width: 100%;
    display: block;
    padding: 1px;
    margin-bottom: 20px;
    border-radius: 5px;
    background: color(modal, background);

    @include media-sm {
      margin-bottom: 0;
      background: color(input-dark, background);
    }

    h4 {
      padding: 11px 10px 9px;
      margin-bottom: 2px;
      border-radius: 4px 4px 0 0;
      background: color(popup, summary-background);;
      box-shadow: 0 1px 0 0 #0f0f10, 0 2px 0 0 #272628;
      color: color(modal, title-modal);
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: .5px;
    }
    h4 {
      margin: 0 8px;
    }
  }
  &__body {
    display: block;
    padding: 10px 8px;
    overflow: hidden;
    -webkit-perspective: 1px;
    border-radius: 0 0 5px 5px;
    width: 100%;

    p {
      font-size: 14px;
      margin: 0 10px 14px 10px;

      @include media-sm {
        font-size: inherit;
      }
    }
  }
  &__summary {
    margin-bottom: 14px;
    overflow: hidden;
    border-radius: 5px;

    @include media-xs {
      background: color(popup, summary-background);
      margin-bottom: 0;
    }
  }

  &__more-link {
    cursor: pointer;
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    color: var(--primary-main);

    @include media-xs {
      display: none;
    }
  }

  &__more-link::before, &__more-link::after {
    content: ' ';
    position: absolute;
    left: 50%;
    top: calc(100% + 5px);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #DAA01C transparent transparent transparent;
  }

  &__more-link::after {
    top: calc(100% + 5px - 2px);
    border-color: #161516 transparent transparent transparent;
  }

  &__more-link:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &__more-link.btn-close::before, &__more-link.btn-close::after {
    content: ' ';
    position: absolute;
    left: 50%;
    top: calc(100% + 5px);
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #DAA01C transparent;
  }

  &__more-link.btn-close::after {
    top: calc(100% + 5px + 2px);
    border-color: transparent transparent #161516 transparent;
  }

  &__title {
    display: block;
    margin-top: 0;
    color: color(notice, color);
    font-size: 14px;
    font-weight: 300;
    line-height: 15px;
    text-transform: uppercase;
    letter-spacing: .5px;
    padding: 11px 10px 9px;
    margin-bottom: 2px;
    border-radius: 4px 4px 0 0;
    background: color(popup, summary-background);
    box-shadow: 0 1px 0 0 color(notice, hr), 0 2px 0 0 color(notice, background);

    &--img {
      padding: 15px 0;

      .image-img {
        max-width: 100px;
        height: auto;
      }

      .image-root {
        width: 100%;
        justify-content: center;
      }
    }
  }
  &__icon {
    display: block;
    margin-bottom: 10px;

    img {
      margin:0 20px;
      width: calc(100% - 40px);
      border-radius: 5px;

      @include media-sm {
        display: block;
        width: 100%;
        margin: 0 auto;
        border-radius: 0;
      }
    }
  }
  &__license-info {
    text-align: center;

    img {
      max-width: 100px;
    }
  }

  &__button {
    margin:0 12px;
    width: calc(100% - 24px);
    text-transform: uppercase;
    margin-bottom: 20px;
    padding: 13px 15px 11px;
    font-size: 16px;
    line-height: 1.5;
    min-height: auto;

    @include media-sm {
      width: 80%;
      margin: 15px auto;
      display: flex;
      font-size: 10px;
      line-height: 1.4;
      padding: 11px 9px 8px;
      border-radius: 4px;
    }

  }
  &__error {
    margin: 15px 4px 0 4px;
  }
  &__rtp {
    margin-top: 15px;
    text-align: center;
  }

  .content-block--summary {
    @include media-xs {
      background: color(popup, summary-background);
      padding: 10px;
      margin-bottom: 14px;
    }
  }

  .content-block-content {
    margin: 10px;

    @include media-xs {
      margin: 0;
    }

    dl {
      width: 100%;
      display: block;
      font-size: 14px;
      color: color(label, color);

      @include media-sm {
        font-size: 10px;
      }
    }

    dl:after, dl:before {
      content: ' ';
      display: table;
    }

    dl:after {
      clear: both;
    }

    dl>dt, dl>dd {
      display: block;
      float: left;
      line-height: 2;
    }

    dl>dt {
      width: 70%;
      font-weight: 400;
      clear: left;
      color: var(--home-menu-item-color);

      @include media-sm {
        color: inherit;
        width: 45%;
      }
    }

    dl>dd {
      width: 30%;
      margin: 0;

      @include media-sm {
        width: 55%;
        text-align: right;
      }
    }
  }

  #content-block-text-trigger+ .content-block-text {
    display: none;

    @include media-xs {
      display: block;
    }
  }

  #content-block-text-trigger:checked + .content-block-text {
    display: block;
  }
}

#gameInfoPage {
  .content-block {
    &-sub-header,
    &-header {
      display: none;
    }
  }

  .btn {
    display: none;
  }
}
