.mobile-game-filter {}
.mobile-game-filter__title {
  margin: 14px 0;
  color: color(modal, title-mobile);
  font-size: 15px;
  text-transform: uppercase;
  line-height: 1.2;
}
.button-primary.mobile-game-filter__button {
  min-width: inherit;
  min-height: inherit;
  margin: 10px 0 20px 0;
  padding: 12px 14px;
  background-color: color(link, default);
  color: color(background, body);
  font-size: 10px;
  letter-spacing: .5px;
  text-transform: uppercase;
  &:hover{
    background-color: color(link, default);
    color: color(button-accent, hover);
  }
}
.game-provider-filter-modal {
  &.game-provider-filter .provider-28 {
    display: none;
  }
  .game-provider-filter {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    > div {
      flex-basis: 33.3%;
      margin: 0 0 8px 0;
      padding: 0;
      .field-helper:empty {
        display: none;
      }
    }
    span.checkbox-default {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 13px;
      height: 13px;
      border: 1px solid #767676;
      border-radius: 2px;
      margin-right: 3px;
    }
    span.checkbox-checked {
      background: #005cc8;
      color: initial;
      border: none;
      &:before {
        color: color(background, filter);
        font-size: 8px;
      }
    }
  }
}
