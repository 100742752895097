@media (orientation: landscape) {
  .mobile .withdrawal-modal {
    align-items: start;

    .modal-paper {
      margin: 0;
    }
  }
}
.withdrawal-modal {
  @media (max-width: 559px) {
    width: 100%;
    align-items: start;
  }

  .modal-paper {
    background-color: var(--deposit-modal-background);
    width: 36.375rem;
    max-height: 92vh;

    @include media-sm {
      width: 100%;
      border: none;
      margin: 0;
    }

    .modalContentWrapper {
      overflow: hidden;

      @include media-xs {
        overflow: auto;
      }

      @include media-sm {
        height: calc(100vh - $height-mobile-sticky-menu);
      }

      &::-webkit-scrollbar {
          display: none;
          width: 0;
          height: 0;
        }

        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        scrollbar-width: none;
        -ms-overflow-style: none;

      .modal-rootButtons {
        background: none;

        button::before {
          @include svg-icon('custom');
          content: '\0035';
          position: absolute;
          color: var(--balance-primary-light);
          font-size: 14px;
          transition: all $main-transition-time;
        }
      }

      .modal-contentRoot {
        overflow: unset;

        @include media-xs {
          overflow: hidden;
          height: auto;
        }
      }
    }

    .other-documents-form {
      @include media-xs {
        margin-bottom: 30%;
      }
    }
  }

  .step {
    &:before {
      @media (max-width: 40.375rem) {
        & {
          height: 14em;
        }
      }

      @media (max-width: 30.688rem) {
        & {
          height: 16em;
        }
      }
    }
  }

  .step-third {
    &:before {
      height: 10em;
    }
  }

  &--shuftipro {
    .modal-paper {
      width: 100%;
      max-width: 870px;
    }
  }

  &__step-3 {
    .modal-paper .modalContentWrapper .modal-rootButtons {
      display: none;
    }
  }
}

.errorModal {
  @include media-sm {
    align-items: flex-start;
  }

  .step {
    &:before {
      @media (max-width: 30.688rem) {
        & {
          height: 8em;
        }
      }
    }
  }

  .modal-paper {
      @include media-sm {
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .modalContentWrapper {
        min-width: 550px;

        .modal-rootButtons {
          position: absolute;
          right: 1em;
          top: 1em;
        }

        @include media-xs {
          width: 100%;
          min-width: auto;
          min-height: 100%;
        }
      }
    }
}

.other-documents-form-modal {
  .modal-paper {
    .modalContentWrapper {
      .modal-rootButtons {

        button::before {
          top: 2rem;
        }
      }

    }
  }

}
.other-documents-form-modal-error {
  .modal-paper {
    .modalContentWrapper {
      .modal-contentRoot {
        margin-top: 1.75rem;
      }

    }
  }

}
