.promotions {
  &__card-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 12px;
    margin-bottom: 12px;

    @include media-md {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));

    }

    @include media-sm {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      margin-bottom: 36px;
    }
  }
  &__forfeit {
    padding: 0 15px;
    margin: 40px 0;

    @include media-sm {
      margin: 20px 0;
    }
  }

  &__error {
    .notification-root {
      color: color(select, background);
      text-align: center;
      padding: 19px 29px;

      p {
        font-size: 16px;
      }
    }
  }
}
.promotion-card{
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}
