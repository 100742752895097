@media (orientation: landscape) {
  .mobile .deposit-modal {
    align-items: start;

    .modal-paper {
      margin: 0;
    }
  }
}
.deposit-modal {
  &-notifications {
    float: right;
  }

  @media (max-width: 559px) {
    width: 100%;
    align-items: start;
  }

  &__progress {
    margin-bottom: 24px;

    @include media-xs {
      z-index: 4;
      position: relative;
    }
  }

  .modal-rootButtons {
    position: absolute;
    right: 2em;
    top:1.7rem;
    background:none;
    button::before {
      @include svg-icon('custom');
      content: '\0035';
      position: absolute;
      color: var(--balance-primary-light);
      font-size: 14px;
      transition: all $main-transition-time;
    }
    @include media-sm {
      top:1.2rem;
    }
  }

  .modal-contentRoot {
    z-index: 1;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;

    .loading-progress {
      margin-top: calc(30% - 40px);

      @include media-sm {
        margin-top: calc(50% - 40px);
      }
    }
    .deposit-feeConfirm {
      @include media-xs {
        overflow: auto;
        padding-bottom: 2.5rem;
        max-height: clamp(60vh, 64vh, 90vh);
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        body.is-ios & {
          padding-bottom: 6.25rem;
        }
      }

      @media (max-width: 391px) {
        padding-bottom: 6.25rem;
        max-height: clamp(65vh, 73vh, 90vh);

        body.is-ios & {
          padding-bottom: 6.25rem;
        }
      }

      .details-info {
        &__noteInfo {
          @include media-xs {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &--shuftipro {
    .modal-paper {
      width: 100%;
      max-width: 870px;
    }
  }

  .modal-paper {
    max-width: 56.667em;
    background-color:var(--deposit-modal-background) ;
    width:36.25rem;
    max-height: 92vh;

    .modalContentWrapper {
      position: relative;
      overflow: hidden;

      @include media-xs {
        overflow: hidden;
        position: relative;
      }

      @include media-sm {
        height: calc(100vh - $height-mobile-sticky-menu);
      }

      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }

      .modal-contentRoot {
        overflow: unset;
      }
    }

    @media (max-width: 623px) {
      width: 100%;
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }

  &__step-3{
    .modal-paper .modalContentWrapper .modal-rootButtons {
      display: none;
    }
  }

}

.thanksWindow {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-xs {
    position: fixed;
  }

  div {
    position: relative;
    background-color: #e0a32d;
    width: 400px;
    height: 247px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #000000;
    font-weight: 600;
    font-size: 25px;

    @include media-xs {
      width: 75%;
      margin: 0 auto;
      font-size: 20px;
    }
  }

  &__info {
    color: #f1f1f1;
    background-color: #398f68;
    padding: 19px 29px;
    margin-bottom: 10px;

    @include media-xs {
      margin: 0;
      border-radius: 5px;
      padding: 10px 15px;
    }
  }

  &__continue-button {
    margin-top: 10px;
  }

  &__close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    background: #e7b85e;
    border-radius: 50%;
    outline: 0;
    opacity: 1;
    cursor: pointer;

    &::before {
      content: 'X';
      position: absolute;
      color: #e0a32d;
      top: 1px;
      left: 50%;
      font-size: 10px;
      font-weight: 800;
      transform: translateX(-50%);
    }
  }
}

.errorModal-deposit {
  .modal-rootButtons {
    @include media-sm {
      right: 1em;
      top: 1em;
    }
  }

  .modal-contentRoot {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include media-sm {
      align-items: flex-start;
    }
  }

  .modal-paper {
    &:before {
      @media (max-width: 30.688rem) {
        & {
          height: 8em;
        }
      }
    }
    .modalContentWrapper {
      min-width: 550px;

      .modal-rootButtons {
        position: absolute;
        right: 1em;
        top: 1em;
      }

      @include media-sm{
        width: 100%;
        min-width: auto;
        min-height: 100%;
      }
    }
    margin: 0;
  }
  @media (max-width: 559px) {
    align-items: center;
  }
  .deposit-form__title{
    display: none;
  }
}
