.page-decorator {
  &__wrapper {
    .title {
      margin: 0;
      text-transform: uppercase;
      letter-spacing: .5px;
      color: var(--modal-title-color-mobile);
    }

    .mobile-page-theme {
      @include media-sm {
        > .container {
          position: relative;
          padding: 1px;
          border-radius: 5px;
          background: var(--modal-background-login);

          > div {
            padding: 10px;
          }
        }
        .title {
          display: block;
          border-radius: 5px 5px 0 0;
          color: var(--modal-title-color-mobile);
          background: transparent;
          font-size: 14px;
          letter-spacing: .5px;
          text-transform: uppercase;
          margin: 0;
          padding: 10px;
        }
        .page_container {
          > div {
            padding: 10px;
          }
        }
      }
    }
  }
  &__container {
    .container {
      padding: 0;
    }
  }
}
