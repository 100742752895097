.setup-cookie-modal {
  .modal-paper {
    width: auto;

    .modalContentWrapper {
      min-height: auto;

      .modal-rootButtons {
        top: 12px;
        right: 12px;
        background: none;

        .modal-buttonRoot {
          &:before {
            @include svg-icon('lvc-regular');
          }
        }
      }
    }
  }

  .modal-contentRoot {
    padding: 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 12px;

    @include media-xs {
      padding: 15px 0 0 0;
    }
  }
}

@include media-sm {
  body .setup-cookie-modal {
    top: 0;

    .modal-paper {
      .modalContentWrapper {
        .modal-titleRoot {
          h2 {
            margin-top: 0;
          }
        }
      }
    }
    &__buttons {
      display: grid;
      gap: 12px;
    }

    .step2 {
      display: flex;
      justify-content: right;
      flex-direction: row-reverse;
    }
  }
}
