.resume-modal--frame {
  .resume-form,
  .login-modal__form-wrapper {
    height: 100%;
  }
  .modal-contentRoot {
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}
body .login-modal {
  &--regular {
    top: 102px;
    left: auto;
    right: 0;
    height: auto;
    width: auto;

    @include media-sm {
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      top: 230px;
      align-items: baseline;
    }

    .modal-paper {
      width: 350px;
      margin: 0;

      @include media-sm {
        height: auto;
        border-radius: 0;
        width: 100%;
      }

      .modalContentWrapper {
        padding: 24px;
        background: var(--modal-background-login);
        min-height: auto;

        @include media-sm {
          padding: 10px!important;
        }

        .modal-contentRoot > div:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
        }

        .notification-root {
          background: none;

          .notification-action {
            display: none;
          }

          p {
            font-size: 16px;
            text-align: center;
          }
        }

        .notification-error {
          color: var(--error-main);
        }

        .notification-success {
          color: color(success, default);
        }

        .notification-warning {
          color: color(warning);
        }
      }
    }
    .modal-rootButtons {
      top: 24px;
      right: 24px;
      position: absolute;

      @include media-sm {
        display: none;
      }

      .modal-buttonRoot {
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        background: none;

        &::before {
          @include svg-icon('custom');
          content: '\0065';
          font-size: 14px;
          line-height: normal;
        }
      }
    }
    .modal-titleRoot {
      display: none;
    }
  }
  &--loginToPlay {
    .modal-paper {
      @include modal-fullscreen-mode;
      justify-content: center;
      align-items: center;

      .modalContentWrapper {
        width: 280px;
      }
    }
    .modal-titleRoot {
      text-align: center;
    }
  }
  &__sign-up {
    display: none;
  }
}
