.drawer-docked {
  .drawer-paper {
    position: sticky;
    top: $header-desktop-height;
    border: none;
    background: none;
    height: calc(100vh - #{$header-desktop-height});
    z-index: 24;

    @media screen and (max-height: 625px){
      height: fit-content;
      top: calc(100vh - 525px);
    }

    @include media-sm {
      height: -webkit-fill-available;
      top: auto;
      width: 100%;
      position: fixed;
    }
  }
}

.drawer-modal {
  .drawer-paper {
    background: none;
  }
}

.aside-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100vw;
  width: 240px;
  height: 100%;
  overflow: hidden;
  background: var(--background-aside-menu);

  @media screen and (min-width: 2560px) {
    justify-content: flex-start;
    gap: 30px;
  }

  @media screen and (min-height: 1440px) {
    justify-content: flex-start;
    gap: 30px;
  }

  @media screen and (max-height: 625px){
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 120px;
    overflow-y: auto;
  }

  @media (max-width: 896px) and (orientation: landscape)  {
    width: 100vw;
    max-width: 100vw;
    overflow-y: auto;
  }

  @include media-sm {
    overflow-y: auto;
    width: 100%;
    background: var(--secondary-disabled-background);
    margin-bottom: 120px;
    overflow-y: auto;
  }

  .theme-switcher-container {
    margin-top: 32px;
    justify-content: center;
  }

  &-opened {
    transition: all ease-out 195ms;

    + .main-decorator__content {
      width: calc(100% - 240px);

      @include media-sm {
        width: auto;
      }
    }
  }

  &-closed {
    transition: all ease-out 195ms;

    @media (max-width: 1024px) and (orientation: landscape)  {
     display: none;
    }
  }

  // specific class for mobile menu
  &-mode-mobile {
    position: absolute;
    z-index: 10;
  }

  &-panel {
    @include media-md {
      .balance {
        &__popover {
          padding: 18px 20px 24px 20px;
          background: none;
          margin: 0;

          @include media-md {
            .header__balance-profile-buttons {
              display: none;
            }
          }

          @include media-sm {
            padding-top: 0;

            .header__balance-static {
              i {
                margin-left: 8px;
                height: 14px;
              }
            }
          }

          .header__balance-menu {
            display: none;
          }
        }
      }
    }

    fieldset {
      border-top: 1px solid var(--secondary-main);
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-block-start: 0;
      padding-block-end: 0;

      @include media-sm {
        display: flex;
        gap: 12px;
        border-top: none;
      }

      legend {
        color: var(--default-light);
        text-transform: uppercase;
        margin-bottom: 30px;
        padding-right: 10px;

        @include media-sm {
          display: none;
        }
      }
    }

     .casinoWidget {
       .casinoMenu {
         gap: 12px;
         display: flex;
         flex-direction: column;

         @include media-sm {
           display: grid;
           grid-template-columns: 1fr 1fr;
           gap: 12px;
           width: 100%;
         }

        .active {
          color: var(--casino-asidemenu-color);
          background: var(--primary-main);

          .span {
            &:before {
              color: initial;
            }
          }

          i {
            color: var(--primary-main);
            background: var(--casino-asidemenu-color);
          }
        }

        .active.casinoWidget--providers{
          .span {
            &:before {
              color: initial;
              content: var(--game-provider-icon);
            }
          }
        }

       }
     }

    .casinoWidget, .balanceWidget {
      padding: 32px 24px;

      @include media-sm {
        padding: 0 20px 24px 20px;
      }

      .casino-link {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        @include media-sm {
          padding: 13px;
        }

        .span {
          white-space: nowrap;
          display: inline-flex;
          align-items: center;

          @include media-sm {
            white-space: inherit;
          }

          &:before {
            @include svg-icon('custom');
            font-size: 14px;
            margin-right: 7px;
          }
        }

        &--favorites {
          i {
            display: flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            border-radius: 50%;
            background: var(--aside-menu-favourites-icon-background);
            width: 24px;
            height: 24px;
            font-size: 12px;
            color: var(--aside-menu-favourites-icon-color);
            padding-top: 2px;

            @include media-md {
              display: flex;
            }

            @media(max-width: 320px) {
             display: none;
            }
          }

          .span {
            &:before {
              content: var(--star-icon-contet);
              color: var(--primary-main);
            }
          }
        }

        &--providers {
          justify-content: center;

          .span {
            &:before {
              @include svg-icon('lvc');
              content: var(--game-provider-hover-icon);
              font-size: 12px;
              line-height: 12px;
            }
          }
          .active{
            .span {
              &:before {
                @include svg-icon('lvc');
                content: var(--game-provider-icon);
                font-size: 12px;
                line-height: 12px;
              }
            }

          }
          &:hover{
            .span {
              &:before {
                @include svg-icon('lvc');
                content: var(--game-provider-active-icon);
                font-size: 12px;
                line-height: 12px;
              }
            }

          }
        }
      }
    }

    .balanceWidget {
      &-container {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .button-primary {
          justify-content: left;

          &:after {
            @include svg-icon('custom');
            position: absolute;
            right: 20px;
            content: 'B';
          }

          &--opened {
            &:after {
              transform: rotate(180deg);
            }
          }
        }

        .button-primary + .button-secondary {
          margin-top: 12px;
        }

        .button-root {
          i {
            margin-right: 10px;
          }
        }
      }
    }

    .mobile &,
    .tablet & {
      @include customVh;
      margin-bottom: 5rem;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .welcome {
      &-details {
        color: color(label, color);
        margin-bottom: 12px;
        background: none;
        border: none;
        box-shadow: none;
        padding: 0;

        & > .group-root {
          padding-bottom: 2px;
        }

      }
    }

    &__locale {
      .select-select {
        max-width: 100%;
        height: auto;
        padding: 12px 15px 10px;
        line-height: normal;
        font-size: 12px;
        border: 0;
        border-radius: 0;
        color: var(--primary-main);
        background: none;
        box-shadow: none;
        text-transform: uppercase;

        .select-locale__item {
          text-indent: 20px;
        }
      }

      .select-select::before {
        content: "\f1ab";
        font: normal normal normal 14px/1 FontAwesome;
        position: absolute;
        top: 13px;
      }
    }

    &__deposit, &__withdrawal {
      width: 50%;

      @include media-sm {
        width: 100%;
      }

      i {
        margin-left: 10px;
      }
    }

    &__resume {
      display: block;
      margin: 0 auto;
      font-weight: $font-weight-base;
    }

    &__logout {
      grid-area: logout;
      padding: 0 0 18px 46px; // margin is working incorrectly with grid-area in Firefox

      .is-ios & {
        padding-bottom: 100px;
      }
    }

    &__sign-in, &__login {
      width: 50%;

      @include media-sm {
        width: 100%;
      }
    }

    &__sign-in {
      @include media-sm {
        i {
          margin-left: 10px;
        }
      }
    }

    &__login {
      @include media-sm {

        i {
          margin-left: 10px;
        }
      }
    }

    &__links-wrapper {
      grid-area: menu;
      flex-direction: column;
      grid-column: span 2;
      width: 100%;

      fieldset {
        margin-inline-start: 24px;
        margin-inline-end: 24px;

        padding-block-end: 12px;

        @include media-sm {
          padding: 0;
        }

        legend {
          margin: 0;
        }
      }

      .settings-menu {
        background: var(--secondary-main);
        padding-right: 0;
        border-radius: 8px;

        @include media-sm{
          border-radius: 8px 8px 0px 0px;
        }
      }

      .profile-aside-menu {
        background: none;

        .settings-menu {
          &__item-override {
            a.active {
              color: var(--balance-primary-main);
            }

            a:before {
              @include svg-icon('lvc');
              margin-right: 15px;
            }

            &--theme-switcher, &--leave {
              @include media-sm {
                background: var(--secondary-disabled-background);
              }
            }

            &--profile {
              a:before {
                content: 'o';
              }
            }

            &--communication {
              a:before {
                content: 's';
              }
            }

            &--finance {
              a:before {
                content: 'u';
              }
            }

            &--current-bonuses {
              a:before {
                content: 'W';
              }
            }

            &--restriction {
              a:before {
                content: 'l';
              }
            }

            &--activity {
              a:before {
                content: '(';
              }
            }
          }
        }

        @include media-sm {
          background: var(--background-aside-menu);
        }

        .button-secondary {
          background: var(--settings-button-background);

          &.active {
            color: var(--settings-button-color);
            background: var(--settings-button-active-background);
          }
        }
      }
      &__casino{
      @include media-sm {
          margin-bottom: 5rem;
          padding-bottom: 7rem;
        }
      }
    }

    &__links-wrapper {
      &.sports-betting {

        body.is-ios & {
          @include media-xs {
            padding-bottom: 90px;
          }
        }

        fieldset {
          margin-top: 1.5rem;
          @include media-xs{
            margin-top: 0;
          }
        }

        .settings-menu {
          border-radius: 8px;

          @include media-sm {
            border-radius: 0px 0px 8px 8px;
          }
        }
      }
    }

    &__hot-links {
      grid-area: payment;
      grid-column: span 2;
      width: 100%;
      padding: 0 10px 15px 10px;

      @include media-sm {
        padding: 0 20px 24px 20px;
      }

      .group-root {
        @include media-md {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px;
        }
      }
    }

    &__close {
      @include close-button();
      position: relative;
      grid-area: close;
      margin: 32px 16px;
      cursor: pointer;

      &::before {
        font-size: 18px;
      }
    }

    //TODO: refactor the remaining part
    .detailsSummary-root {
      padding: 0;
      min-height: 0;
    }

    .detailsSummary-content {
      margin: 0;
    }

    .settings-menu__item-override {
      & a {
        display: block;
        text-align: left;
      }

      a.active {
        color: var(--balance-primary-main);
        background-color: var(--button-active-background);

      }

      &--leave {
        padding: 24px 20px 24px 20px;

        a {
          text-align: center;
          font-weight: 500;
          line-height: 1;
        }

        i {
          margin-right: 8px;
          height: 14px;

          &:before {
            transform: rotate(-90deg);
          }
        }
      }

      .details-root {
        &.details-expanded {
          .detailsSummary-content {
            font-weight: $font-weight-alt;

            &::after {
              transform: rotateX(180deg)
            }
          }
        }

        &.menuAsideResponsibleGambling {
          margin-top: 14px;
          padding-top: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }

      .detailsSummary-content {
        &::after {
          content: '';
          position: absolute;
          top: 45%;
          right: 20px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid var(--aside-menu-link-color);
        }
      }

      &--vegas-tv, &--jackpot-games, &--tournaments, &--casino, &--promotions, &--betting, &--help, &--live-chat, &--liveCasino, &--liveBetting, &--oddsBooster, &--football, &--megaversum {
        a {
          position: relative;
          padding-left: 40px;

          &:before {
            @include svg-icon('custom');
            position: absolute;
            left: 16px;
            top: 15px;
          }
        }
      }

      &--casino a:before {
        @include svg-icon();
        content: "J";
      }

      &--promotions a:before {
        @include svg-icon();
        content: "1";
      }

      &--betting a:before {
        @include svg-icon('lvc');
        content: "g";
      }

      &--help a:before {
        content: "?";
        color: var(--secondary-main);
        background: var(--default-main);
        border-radius: 50%;
        width: 14px;
        height: 14px;
        display: block;
        text-align: center;
        font-weight: 900;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      }

      &--liveCasino a:before {
        @include svg-icon();
        content: 'J';
      }

      &--vegas-tv a:before {
        content: 'R';
      }

      &--jackpot-games a:before {
        content: 'T';
      }

      &--tournaments a:before {
        content: 'U';
      }

      &--liveBetting a:before {
        content: 'R';
      }

      &--oddsBooster a:before {
        content: '4';
      }

      &--football  a:before {
        content: '3';
      }

      &--live-chat a:before {
        content: 'P';
      }

      &--megaversum a:before {
        @include svg-icon('lvc');
        content: ',';
      }

      &--search {
        a {
          position: relative;
          padding-left: 40px;

          &:before {
            @include svg-icon;
            position: absolute;
            left: 16px;
            top: 15px;
            content: 'Y';
          }
        }
      }

      &--logout {
        a {
          color: #7d3132;
        }

        a:before {
          font-family: 'Glyphter-custom';
          content: '\0044';
          transform: rotate(-90deg);
        }
      }
    }

    [class*='menuAsideGameGroups'] {
      .settings-menu__item-override {
        a {
          margin-bottom: 22px;
          margin-left: 40px;

          &::before {
            @include svg-icon;
            content: '\0078';
            position: absolute;
            top: 1px;
            left: -13px;
            color: var(--aside-menu-link-color);
            font-size: 20px;
          }

          &:hover,
          &.active {
            &::before {
              color: color(main-menu, link-active);
            }
          }

          &[href*='favourite'] {
            &::before {
              content: '\0058';
              font-size: 22px;
            }
          }

          &[href*='popular'] {
            &:not(:hover):not(.active) {
              color: var(--error-main);

              &:before {
                color: var(--error-main);
              }
            }

            &::before {
              content: '\0079';
            }
          }

          &[href*='slots'] {
            &::before {
              content: '\007a';
            }
          }

          &[href*='jackpot'] {
            &::before {
              content: '\0030';
            }
          }

          &[href*='table'] {
            &::before {
              content: '\0031';
            }
          }

          &[href*='betting'] {
            &::before {
              content: '\002c';
            }
          }

          &[href*='liveBlackjack'] {
            &::before {
              content: '\002d';
            }
          }

          &[href*='livePoker'] {
            &::before {
              content: '\002e';
            }
          }

          &[href*='liveRoulette'] {
            &::before {
              content: '\002f';
            }
          }

          &[href*='liveBaccarat'] {
            &::before {
              content: '\005b';
            }
          }

          &[href*='liveAll'] {
            &::before {
              content: '\005c';
            }
          }

          &[href*='promo'] {
            &::before {
              content: '\007e';
            }
          }
        }
      }
    }
  }
}
