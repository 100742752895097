.lvcVerificationConfirmSnapshots {
  &__images {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: center;

    @include media-xs {
      display: block;
    }
  }
}
