.noteInfo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 1em;
  border-radius: 8px;
  border: 1px solid var(--default-normal);
  background: var(--secondary-disabled-background);
  gap: .667em;
  color: var(--primary-light);

  &__icon {
    width: 12px;
    height: 12px;
    margin-top: 0.25em;
  }

  &__text {
    font-size: 0.75rem;
    color:var(--notify-text)
  }
  &:before {
    @include svg-icon('custom');
    content:'\0039';
    color: var(--active-text-color);
    margin-right: 0.25rem;
    display: flex;
    align-items: center;
    font-size: 20px;

  }
  @include media-xs {
    margin-top: 16px;
  }
}
