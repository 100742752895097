.typography {
  font-size: 12px;

  h1 {
    position: relative;
    font-size: 32px;
    font-weight: 700;
  }
  .underlined-title {
    h1 {
      display: inline-block;

      @include media-xs {
        font-size: 22px;
        margin-bottom: 14px;
      }
    }
    + .border {
      margin-top: 20px;
    }
  }
  h2 {
    margin: 16px 0 4px;

    @include media-xs {
      font-size: 16px;
    }
  }
  h3 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 14px 0;

    @include media-xs {
      font-size: 14px;
    }
  }
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  p {
    color: var(--primary-light);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    border-radius: 12px;
  }
  ul,
  ol {
    list-style-type: none;

    li {
      margin-bottom: 4px;
      position: relative;
      font-size: 12px;

      &::before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        position: absolute;
        left: -21px;
        top: 6px;
        background-color: var(--primary-main);
      }
    }
  }
  img {
    max-width: 100%;
  }
  .border {
    padding: 20px 50px;
    border: 1px solid var(--body-text-dark-color);
    white-space: nowrap;

    @include media-sm {
      padding: 10px;
    }
  }
}
