.altenar-user-not-validated {
  display: flex;
  justify-content: center;

  &__content {
    width: 100%;
    max-width: 444px;
    background-color: var(--primary-main);
    padding: 36px 36px 24px 36px;
    border-radius: 12px;
  }

  & &__header {
    font-size: 70px;
    margin: 0 0 30px 0;
    line-height: initial;
  }

  &-button__wrapper {
    text-align: center;
    margin-top: 20px;
  }

  &__button {
    display: inline-block;
    position: relative;
    padding: 15px 20px;
    color: var(--primary-dark);
    background: var(--primary-light);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    border-radius: 8px;

    &:hover {
      color: initial;
    }
  }

  &__text {
    h3, p, span {
     color: var(--secondary-main);
    }
    h3 {
      margin: 0 0 12px 0;
    }
    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
