.standard-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}
.static-table {
  display: table;

  .static-table__payment-provider {
    .pay-and-play & {
      display: none;
    }
  }
  img.static-table__payment-provider-icons {
    max-width: 80px;
    max-height: 30px;
  }
  &__nowrap {
    white-space: nowrap;
  }
}

/* Tables */

.custom-table {
  display: table;
  width: 100%;

  &-head {
    display: table-header-group;
  }
  &-body {
    display: table-row-group;
  }
  &-row {
    display: table-row;
  }
  &-cell {
    display: table-cell;
    vertical-align: middle;
  }
}
.table-root,
.custom-table {
  margin-bottom: 20px;
  border: 0;
  border-radius: 0;
  border-collapse: collapse;

  @include media-sm {
    display: block;
  }

  @media (max-width: 459px) {
    & {
      overflow-x: scroll;
    }
  }
  .table-row--fake {
    display: none !important;
  }
  .table-cell {
    &--rejected-reasons {
      text-align: left;
      padding: 0;
    }
    &--amount {
      font-weight: bold;
    }
    &--status--rejected {
      color: var(--error-main);
    }
    &--status--expired {
      color: color(expired);
    }
    &--status--approved {
      color: color(success, default);
    }
    &--status--uploaded {
      color: color(disable, dark);
    }
  }
  th,
  &-head .custom-table-cell {
    padding: 15px 4px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    font-weight: $font-weight-base;
    color: color(text, main);
    border-bottom: 1px solid var(--primary-dark);
    font-family: Poppins, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

    @include media-xs {
      font-size: 10px;
    }
    &:last-child {
      padding-right: 4px;
    }
  }
  td,
  &-cell {
    padding: 15px 4px;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px solid var(--primary-dark);

    &:last-child {
      padding-right: 4px;
    }
  }
}
.static-content {
  .table-root {
    width: 100%;
    border-spacing: 0;
  }
}
.tableHead-Lowercase {
  text-transform: lowercase;
  font-size: 12px;
}
.complaint-procedure__table {
  @include media-sm {
    margin: 0 -20px;
  }
}
