.search-modal {
  .modal-paper {
    .modalContentWrapper {
      min-height: auto;
    }
  }
  .search {
    &__field,
    &__button {
      margin-top: 15px;
    }

    &__button {
      width: 100%;
    }
  }

  .games-filter__name {
    input {
      padding-left: 15px;
      padding-right: 40px;
    }

    .search {
      left: initial;
      right: 15px;
    }
  }
}
