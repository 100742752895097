.forgot-password-modal {
  .modal-paper {
    width: 26.25rem;

    @include media-xs {
      margin: 32px 20px;
    }

    .modalContentWrapper {
      .modal-rootButtons {
        position: absolute;
        right: 24px;
        top: 24px;
        background: transparent !important;
        width: fit-content;
        height: fit-content;

        .modal-buttonRoot:before {
          @include svg-icon('custom');
          content: var(--custom-close-icon);
        }
      }
    }
  }
}

