.success-verification-modal {
  .modal-paper {
    .modalContentWrapper {
      max-width: 420px;
      min-height: auto;

      .modal-rootButtons {
        background: none;
      }
      .modal-buttonRoot::before {
        @include svg-icon('custom');
        content: '\0065';
      }

      .modal-contentRoot {
        .button-primary {
          width: 100%;

          .button-label:after {
            @include svg-icon('custom');
            content: 'E';
            margin-left: 5px;
          }
        }

        .markdown-root {
          font-size: 14px;
          margin-bottom: 24px;
        }
      }
    }
  }
}
