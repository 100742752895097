.playerVerificationForm2Idenfy {
  max-width: 780px;
  margin: 0 auto;

  &__error {
    margin-top: 58px;
  }

  @include media-sm {
    width: auto;
    padding: 0 20px;
  }

  &__header {
    text-align: center;
    padding: 12px 0;
    margin: 0 0 6px 0;

    @include media-sm {
      text-align: left;
      font-size: 24px;
      line-height: 34px;
      padding: 20px;
    }
  }

  &-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: var(--default-main);
    padding: 10px 0 4px 0;
  }

  &-icon {
    height: 24px;
    width: auto;
    filter: grayscale(1) invert(1) contrast(5);
  }

  &__idenfy-iframe {
    width: 100%;
    height: 600px;
  }
  &__modal {
    .modal-paper {
      width: 800px;
    }
  }
  .button-default {
    margin-top: 10px;
  }
}
