@mixin depositRowWithItems {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @include media-sm {
  display: block;
  }
}

@mixin inputIconProperties {
  position: absolute;
  font-size: 12px;
  color: var(--default-main);
  transition: none;
  top: 15px;
  right: 16px;
  left: auto;
  background: none;
  transform: none;
}

.deposit-form {
  font-size: .75rem;
  color: var(--balance-primary-light);
  overflow: auto;
  max-height: calc(100dvh - 184px);

  @include media-xs {
    max-height: clamp(70vh, 71vh, 90vh);
    overflow-y: auto;
    position: relative;
  }

  @media (max-width: 391px) {
    padding-bottom: 3.5rem;
    max-height: clamp(65vh, 73vh, 90vh);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--balance-primary-light);
  }

  &__hint {
    font-size: 1rem;
  }

  .button-wrapper {
    text-align: center;

    @include media-xs {
      text-align: left;
    }

    button.button-accent:not([disabled])::before {
      font-family: 'Glyphter-custom';
      content: '\0044';
      margin-right: 10px;

      @include media-xs {
        content: none;
      }
    }
  }

  @include media-xs {
    .select-root {

      .select-select {
        align-items: center;
        color: color(label, color);
      }
    }

    input {
      color: color(label, color) !important;
    }
  }

  &-deposit {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--deposit-modal-content-background);
    overflow: hidden;

    @media (max-width: 559px) {
      flex-direction: column;
      gap: 2em;

      &>* {
        min-width: 100%;
      }
    }

    @include media-xs {
      background-color: var(--deposit-modal-background);
      padding-bottom: 2.5rem;

      body.is-ios & {
        padding-bottom: 10rem;
      }
    }

    @media (max-width: 391px) {
      background-color: var(--deposit-modal-background);
      padding-bottom: 2.5rem;

      body.is-ios & {
        padding-bottom: 2.5rem;
      }
    }

    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
      width: 3px;
    }

    ::-webkit-scrollbar-thumb {
      background: color(settings-menu, border);
      border-radius: 3px;
    }

    &--short-header {
      display: flex;
      align-items: center;
      margin: -5px 0 15px 0;

      img {
        width: auto;
        margin-right: 10px;
      }
    }

    &--info {
      font-size: 15px;
      font-weight: initial;
      color: var(--home-menu-item-color);

      @include media-xs {
        font-size: 12px;
        margin: 5px 0;
        color: color(label, color);

        .markdown-root {
          display: none;
        }
      }
    }

    .deposit-form-block {
      margin-top: 1rem;
      width: 100%;

      &__toNextStep {
        width: 100%;

        &.button-disabled {
          background-color: #4c4e4f;
          color: #fff;
        }

        & .button-label:after {
          @include svg-icon('custom');
          content: '\0042';
          rotate: 90deg;
          font-size: .8em;
          margin-left: .5em;
          padding-top: .1em;
        }
      }

      &-fields .monetary-range,
      .method-description .monetary-range {
        @include depositRowWithItems;

        .monetary-details {
          padding: 0 1em 0 3em;

          &__text {
            font-size: 12px;
            white-space: nowrap;
            font-weight: 500;
          }
        }
      }
    .method-description {
      &_details {
        display: flex;

        &_info {
          display: flex;
          flex-direction: column;
          width: 48%;
          gap: 10px;
          span{
            color: var(--balance-primary-light);
            font-size: 14px;
          }
          &_bottom{

            span:nth-child(2n+1){
            display: none;
            }
          }

          @include media-sm{
            &_top{
              display: none;
            }
            &_bottom{
              width: 96%;
              span:nth-child(2n+1){
                display: flex;
                }
              span:nth-child(2n){
                margin-top:-0.5rem;
                margin-bottom: 0.3rem;
              }
            }
            &-hide{
              display: flex;
            }
          }

        }

        .method-description_copy {
          position: relative;
          width: 4%;
          cursor: pointer;
          span::after {
            @include svg-icon('custom');
            content: '\0022';
            color: var(--balance-primary-light);
          }
          .method-description_copyIcon{
            @include media-sm{
              margin:1.25rem 0 0.5rem 0;
            }
          }
          }
        }
      }
      .method-description-info-text{
        color: var(--active-text-color);
        font-size: 14px;
        margin:6px 0;
      }

      &-fields {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .bonus-block {
          @include depositRowWithItems;
          gap: 16px;

        &-code {
            .group-root {
                width: 100%;
              .input-root{
                width: 100%;
              &::after {
                @include inputIconProperties;
                @include svg-icon;
                top: 20px;
                content: 'V';
              }
            }
          }
        }
          &-type {
            flex: 1 1 0;
              @include media-sm{
                margin-bottom: 1rem;
              }

            label {
              text-transform: uppercase;
            }
          }

          &-code {
            flex: 1 1 0;
            @include media-sm{
              flex: none;
            }

            input {
              padding-right: 35px;
              width: 100%;
            }

            label {
              text-transform: uppercase;
            }
          }


        }
        .redirectToPromotion{
          margin-top: 2px;
          margin-bottom: 2px;
        }

        .redirectToPromotion .fpLink {
          white-space: nowrap;
        }

        .stored-payment-details-field{
          label{
            text-transform: uppercase;
            margin-top: 0.7rem;
          }
        }
        .deposit-form__field{
          &--moneybookersEmail, &--netellerAccountId {
          margin-top: 1rem;
        }
         &--netellerAccountId {
          margin-top: 1rem;
        }
      }

        .redirectToPromotion {
          a {
            text-decoration: underline;
          }
        }
      }

      &--amount {
        &__rangeInfo {
          margin: -1rem 0 -0.5rem 0;
          font-size: 14px;
          color: var(--default-main);
        }

        legend {
          text-transform: uppercase !important;
        }

        &__currency {
          position: absolute;
          right: 1rem;
          font-size: 14px;
          top: 2.25rem;
        }

        .selector-amount {
          margin-top: 1rem;
          display: flex;
          flex-wrap: wrap;
          gap: .667em;

          label {
            cursor: pointer;
            white-space: nowrap;
            border-radius: 2em;
            background: var(--deposit-payment-button);
            display: flex;
            align-items: center;
            height: fit-content;
            padding: 8px 12px;
            color: var(--deposit-payment-color);

            >span {
              width: auto;
              height: auto;
              margin-right: 6px;

            }

            svg {
              color: var(--amount-selector-color);
              border-radius: 50%;
              width: 16px;
              height: 16px;
            }

            .custom-checkbox {
              width: 16px;
              height: 16px;
              background: var(--deposit-radio-background);
              border-radius: 50%;
              position: relative;

              &:after {
                content: "";
                width: 6px;
                height: 6px;
                background: #161819;
                background: var(--primary-dark);
                display: block;
                border-radius: 50%;
                position: absolute;
                top: 5px;
                left: 5px;
              }
            }
          }

        }

        // ==


        .field-root {
          display: flex;
          flex-direction: column;

          label {
            text-transform: uppercase;
          }

          @include media-xs {
            flex-direction: column;
            align-items: normal;
          }

          .field-helperError {
            padding-left: 5px;

            @include media-xs {
              padding: 0 0 5px 0;
            }
          }

          .input-root {
            width: 100%;

            input {
              font-size: 1.5rem;
              height: 3.375rem;

              &::placeholder {
                font-size: 1.4rem;
                opacity: 0.5;
              }

              ::-ms-input-placeholder {
                /* Edge 12 -18 */
                font-size: 1.4rem;
                opacity: 0.7;
              }

              &:focus,
              &:active,
              &:hover {
                border: 1px solid color(primary, main);

              }
            }
          }
        }
      }
    }

    &--method {
      display: flex;
      flex-direction: column;
      gap: 1em;

      @include media-xs {
        height: auto;
        width: auto;

        .field-root .field-helper {
          font-size: 0;
        }
      }

      label {
        display: flex;
        align-items: center;
        height: 34px;

        @include media-xs {
          height: auto;
        }

        .radio-default {
          width: auto;
          height: 34px;
          margin-right: 15px;

          svg {
            color: var(--deposit-radio-color);
            border-radius: 50%;
            width: 16px;
            height: 16px;
          }
        }

        .radio-checked {

          .custom-checkbox {
            width: 16px;
            height: 16px;
            background: var(--deposit-radio-background);
            border-radius: 50%;
            position: relative;
          }

          .custom-checkbox::after {
            content: '';
            width: 6px;
            height: 6px;
            background: var(--primary-dark);
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            left: 5px;
          }
        }

        .radio-checked+img+span.payment-method-field__info {
          opacity: 1;
        }
      }

      .label {
        padding: .375em .5em;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &_checked {
          background: var(--input-background-login);
        }
      }
    }
  }

  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid var(--primary-main);

    strong {
      font-size: 20px;
    }

    .exchange {
      display: block;
      text-align: right;
    }

    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }

  .content-deposit {

    &:not(:empty) {
      margin-bottom: 20px;
    }

    .notification-root {
      &:not(:last-child) {
        margin-bottom: 10px !important;
      }
    }
  }

  &__ukgc-message {
    text-align: justify;
  }

  &__fields-title {
    p {
      margin: 0;
    }
  }

  &__fields {

    .gridItem-root[class*='deposit-form__field--']:not(:only-child) {
      max-width: 50%;
      flex-basis: 50%;
      margin: 0 -5px;
      padding: 0 5px;
    }

    .gridItem-root[class*='deposit-form__field--customer_cc_number']:not(:only-child) {
      @include media-sm {
        max-width: 100%;
        flex-basis: 100%;
        margin: 0;
        padding: 0;
      }
    }
  }

  &__declarations-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
  }

  &__declarations-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}

.deposit-form__frame,
.resume-form__frame {
  width: 100%;
  height: 90vh;
  border: none;

  // cubits
  &.deposit-form__frame_provider_410004 {
    margin: 5px 0;
    height: 560px;
  }

  // voucher
  &.deposit-form__frame_provider_150001 {
    display: none;

    &+.deposit-step-back {
      display: none !important;
    }
  }

  // trustly
  &_provider_300001,
  &_provider_300002 {
    height: 600px;

    .tablet &,
    .mobile & {
      background-color: var(--primary-light);
    }
  }

  // zimpler
  &_provider_440001 {
    height: 580px;

    .tablet &,
    .mobile & {
      height: 500px;
    }
  }

  &--authentication {
    display: none;

    &_visible {
      height: 100%;
      width: 100%;
      border: none;
      min-height: 400px;
    }
  }
}

.deposit-form__frame {
  display: block;
  height: calc(90vh - 140px);

  @include media-sm {
    min-height: calc(100vh - 155px);
    max-height: calc(100vh - 155px);
  }

  .applied-country--gbr & {
    height: calc(90vh - 190px);

    @include media-sm {
      min-height: calc(100vh - 190px);
      max-height: calc(100vh - 190px);
    }
  }

  &.loading {
    height: 0 !important;
    min-height: 0 !important;
  }

  &+.deposit-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }

  &.loading+.deposit-form__circular-progress {
    display: block;
  }
}

.payment-method-field,
.deposit-form__field--bank_issuer_id {
  .select-select {
    display: inline-flex;
    width: 100%;
    height: 48px;

    span {
      display: inline-block;
      text-transform: uppercase;
    }
  }
}

.payment-method-field label {
  text-transform: uppercase;
  font-size: 16px !important;
  margin-bottom: 0.5rem;
  font-weight: 600;
}


.payment-method-field__img,
.payment-select-field__img {
  width: auto;
  height: 30px;
  max-width: 112px;
  margin-right: 18px;
  object-fit: contain;
}

.payment-method-field__img {
  position: absolute;
  right: 24px;

  &--70002,
  &--1330003 {
    right: 15px;
  }

  @include media-sm {
    display: none;
  }

}

.payment-method-field__info {
  padding: .375em .5em;
  background-color: #443D27;
  color: #FFCC33;
  border-radius: 4px;
  font-size: 12px;

  &_checked {
    background-color: #443D27;
    color: #FFCC33;
    -webkit-text-fill-color: #FFCC33;
    position: absolute;
    right: 7rem;

    &-70002 {
      right: 7.9rem;

      @include media-sm {
        right: 2.2rem;
      }
    }

    &-10001 {
      right: 10.2rem;

      @include media-sm {
        right: 2.2rem;
      }
    }

    &-1080001 {
      right: 10.1rem;

      @include media-sm {
        right: 2.2rem;
      }
    }
  }

  & .markdown-root {
    p {
      margin: 0;
    }
  }

  @include media-sm {
    margin-left: 0.5rem;
  }
}

.payment-method-field__img-country {
  width: auto;
  max-height: 30px;
  margin-right: 5px;
}

.payment-method-field__instant {
  margin-left: 0.6rem;
  text-transform: uppercase;
  font-size: 12px;
  color: #BBBBBB;
  -webkit-text-fill-color: #BBBBBB;
}

.save-card-info-text {
  display: flex;
  border-radius: 8px;
  padding: 0.1rem 1.75rem;
  background: color(primary, main);
  color: color(warning-notify, color);
  font-size: 14px;

  &::before {
    @include svg-icon('custom');
    content: 'Q';
    position: relative;
    margin-right: 4px;
    font-size: 24px;
    vertical-align: middle;
    top: 12px;
    left: -7px;
  }
}

.deposit-info-text,
.bonus-info-text, .transfer-info-text {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  color: var(--deposit-title-color);
  margin-bottom: -0.75rem;
  margin-top: -0.2rem;
}
.transfer-info-text{
  margin-bottom:1rem;
}

.bonus-info-text {
  margin-top: -1rem;
  margin-bottom: -1rem;

}

.deposit-summary {
  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }

  &__turnover-title {
    p {
      margin: 0;
    }

    em {
      font-size: 12px;
      font-style: normal;
      color: color(input, hint);
    }
  }

  &__deduct,
  &__receive {
    max-width: 45%;

    @include media-xs {
      max-width: 100%;
    }

    .group-root {
      p {
        margin: 0;
        line-height: 2;
        border-bottom: 1px solid #ccc;
      }

      strong {
        float: right;
      }
    }

    .exchange {
      display: block;
      text-align: right;
    }
  }

  &__fee {
    margin-top: 30px;
    font-size: 13px;
    color: color(text, light);

    @include media-xs {
      margin: 10px 0 20px;
    }

    &::before {
      @include svg-icon;
      content: '\0044';
      padding-right: 5px;
      vertical-align: top;
      color: color(warning);
    }
  }
}

.deposit-limit-confirmation {
  margin-top: 30px;
  text-align: center;
}

.deposit-step-back,
.resume-step-back {
  position: absolute;
  top: 40px;
  left: 24px;
  display: flex;
  padding: 5px;
  font-size: 14px;
  border: 0;
  background: var(--secondary-main);
  overflow: hidden;
  border-radius: 0.667em;
  color: var(--primary-light);

  &:hover {
    background-color: var(--secondary-main);
  }

  @include media-sm {
    left: 15px;
  }

  &::before {
    @include svg-icon;
    content: '\0062';
    margin-right: 5px;
    font-size: 15px;
    transform: rotate(180deg);
  }
}

.supported-currency-field .field-helperHint,
.exchange {
  font-size: 12px;
  color: color(input, hint);
}

/** ePro */
.deposit-form--370001 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--customer_cc_name,
      &--customer_cc_surname {
        max-width: 45%;
        flex-basis: 45%;
      }

      &--customer_cc_expmo,
      &--customer_cc_expyr,
      &--customer_cc_cvc {
        max-width: 30%;
        flex-basis: 30%;
      }
    }
  }
}

/* **/

/** Neteller */
.deposit-form--110002 {
  @media (min-width: 480px) {
    .deposit-form__field {
      &--netellerAccountId {
        max-width: 65%;
        flex-basis: 65%;
      }

      &--secureId {
        max-width: 25%;
        flex-basis: 25%;
      }
    }
  }
}

/* **/

// Cryptopay
.deposit-form--910002,
.deposit-form--910003 {
  display: flex;
  flex-direction: column;
  flex: none;
  height: auto;

  .field-payAmount--910002,
  .field-address--910002 {
    align-self: flex-end;
    width: 70%;

    @include media-sm {
      order: 2;
      width: 100%;
      margin-top: 0;
    }
  }

  .field-payAmount--910002,
  .field-address--910002,
  .field-address--910003 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .wrapper {
      display: flex;
    }

    .input-root {
      flex: 1;
    }

    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;
      color: var(--secondary-main);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(select, text);
      }
    }

    .button-default::after {
      display: none;
    }
  }

  .field-uri--910002 {
    position: absolute;
    width: inherit;
    margin-top: 155px;

    @include media-sm {
      position: relative;
      order: 1;
      margin-top: 0;
    }

    label {
      display: none;
    }

    .button-primary {
      margin-top: 10px;
    }
  }

  .field-expiresAt--910002 {
    padding-top: 35px;

    @include media-sm {
      order: 3;
      padding-top: 0;
    }

    .expired::before {
      background: var(--error-main);
    }

    label {
      right: 0;
      text-align: center;
    }

    input[type="text"] {
      text-align: center;
    }
  }
}

.deposit-form--910002 {
  .notification-root {
    margin-bottom: 8px;
    animation: none;
  }
}

.field-qrCode--950001 {
  label {
    display: none;
  }

  .button-primary {
    margin-top: 10px;
  }
}

// OXXOPAY, SendAbono
.deposit-form--400002,
.deposit-form--1210001,
.deposit-form--1290001 {
  flex-direction: column;

  .field-reference--400002,
  .field-stpAbonosAccountNumber--1210001,
  .field-reference--1290001 {
    input[type="text"] {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .wrapper {
      display: flex;
    }

    .input-root {
      flex: 1;
    }

    .button-default {
      margin-top: 20px;
      margin-bottom: 5px;
      color: var(--secondary-main);

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        pointer-events: none;
        background: color(select, text);
      }
    }

    .button-default::after {
      display: none;
    }
  }
}

// Sr.Pago
.deposit-form--1010001 {
  >iframe+.deposit-form__circular-progress {
    display: block;
  }
}

.deposit-form__applePayButton {
  button {
    width: 156px;
    height: 42px;
    border-radius: 0;
    cursor: pointer;
    margin: 20px 0;
  }

  div[role="progressbar"] {
    margin: 20px 0;
  }
}

.deposit-form__notifications {
  margin-left: -28px;
  margin-top: -30px;
  max-width: calc(100% + 56px);
  width: calc(100% + 56px);

  .notification {
    &-root {
      padding: 19px 29px;
      justify-content: center;
      font-size: 16px;
      color: color(select, background);

      p {
        font-size: 16px;
        color: color(select, background);
      }
    }

    &-message {
      width: 100%;
    }
  }
}

.select-deposit-method {

  ul li:not(.select-search) {
    padding: 15px 16px !important;
    text-transform: uppercase;
    color: var(--balance-primary-light);
  }

  .payment-method-field__img {
    margin-right: -12px;
  }

  ul li[value="70002"] img {
    margin-right: -8px !important;
  }

  ul li[value="1080001"] .payment-method-field__info {
    position: absolute;
    right: 8.1rem;

    @include media-sm {
      right: 1rem;
    }
  }

  ul li[value="70002"] .payment-method-field__info {
    position: absolute;
    right: 6.5rem;

    @include media-sm {
      right: 1rem;
    }
  }

  ul li[value="10001"] .payment-method-field__info {
    position: absolute;
    right: 8.2rem;

    @include media-sm {
      right: 1rem;
    }
  }
}
