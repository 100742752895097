.limits__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include media-md {
    grid-template-columns: repeat(1, 1fr);
  }

  .limit-form {
    &-block {
      display: flex;
      flex-direction: column;
      padding: 48px 24px 24px 24px;

      @include media-sm {
        padding: 24px 0;
        gap: 18px;
      }

      &:nth-child(3), &:nth-child(4) {
        background: var(--primary-dark);

        @include media-sm {
          background: transparent;
        }
      }

      &:nth-child(3) {
        border-radius: 12px 0 0 12px;
      }

      &:nth-child(4) {
        border-radius: 0 12px 12px 0;
      }
    }

    &-row {
      display: flex;
      gap: 24px;

      &__buttons {
        display: flex;
        justify-content: right;

        .button-root {
          margin-top: 24px;

          @include media-sm {
            width: 100%;
          }

          i {
            margin-left: 8px;
            height: 14px;
          }
        }
      }

      @include media-sm {
        flex-direction: column;
      }

      &__item {
        flex: 1;
        gap: 8px;
        display: flex;
        flex-direction: column;

        &_small-extra {
          min-width: 110px;
          flex-basis: 110px;
        }

        .notification-root {
          align-items: center;
        }

        &-label {
          position: relative;
          line-height: 14px;
          font-size: 14px;
          display: flex;
          align-items: center;

          &_hint {
            position: static;
            margin-left: 1em;
          }

          .form-control-hint-block__text {
            top: -35px;
            right: 10%;
          }
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
