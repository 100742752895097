.taxable-jackpot-modal {
  div .modalContentWrapper {
    .modal-contentRoot {
      .submit-modal-form {
        .submit-modal-content {
          .markdown-root p {
            font-size: 14px;
            line-height: 20px;
            color: var(--primary-light);
          }
        }

        .submit-modal-buttons {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
