.monetary {
  font-size: 0.875rem;
  width: 13.857em;

  &__label {
    margin-bottom: .3em;

    &:first-letter {
      text-transform: uppercase
    }
  }

  .monetary-details {
    display: flex;
    border-radius: 0.667em;
    overflow: hidden;
    font-weight: 500;
    gap: 10px;
    justify-content: flex-start;
    flex-direction: column;

    &__text {
      color: var(--balance-primary-light);
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;

      &--amount {
        font-weight: 500;
        font-size: 16px;
      }
    }

    &__icon {
      font-size: 1em;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 2.5em;
      background: var(--deposit-money-background);
      color: var(--deposit-money-color);
      display: flex;
      justify-content: center;
      align-items: center;

      &_coins:after {
        @include svg-icon;
      }

      &_wallet:after,
      &_cash:after,
      &_withdraw:after,
      &_deposit:after {
        @include svg-icon('custom');
      }

      &_coins:after {
        content: '\0030';
      }

      &_wallet:after {
        content: '\005a';
      }

      &_cash:after {
        content: '\006d';
      }

      &_withdraw:after {
        content: '\0044';
      }

      &_deposit:after {
        content: '\0045';
      }
    }
  }
}