.game-slider {
  position: relative;
  overflow: hidden;

  &__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    gap: 0 10px;

    @include media-xs {
      gap: 9px 16px;
    }
    @include media-md-landscape {
      height: 312px;
    }

    .game-thumbnail {
      @include media-md-landscape {
        flex-basis: auto;
        justify-content: flex-start;
      }
    }
  }

  @media screen and (max-width: 850px) and (orientation: landscape) {
    .swiper &__wrapper {
      height: 293px;
    }
  }

  @media screen and (max-width: 670px) and (orientation: landscape) {
    .swiper &__wrapper {
      height: 249px;
    }
  }

  &__slide {
    display: flex;
    flex: none;
    flex-shrink: 0;
    justify-content: center;
    align-items: baseline;
    width: 180px;
    margin: 0;
  }
  &__nav-prev,
  &__nav-next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    cursor: pointer;
    background-color: var(--game-slider-nav-background);
    transition: .2s;

    .home-content--slider & {
      top: 14px;
    }
    @include media-sm {
      width: 34px;
    }
    &::before {
      // 1
      // Something wrong with icon glyph positioning causes its crop when rendering in Safari (both desktop and mobile)
      // This combination of properties and values fixes that behavior in Safari and leaves icon glyph proper rendering in other browsers
      @include svg-icon;
      content: '\0065';
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 10px);
      width: 21px;                    // 1
      color: var(--game-slider-nav-icon-color);
      font-size: 21px;                // 1
      text-indent: -1px;              // 1
      transform: rotate(180deg);
    }
    &.is-disabled {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__nav-prev {
    left: 0;
  }
  &__nav-next {
    right: 0;

    &::before {
      transform: rotate(0deg);
    }
  }
  &__loading {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    padding-bottom: 14.1%;
    opacity: .6;
    background-color: var(--primary-light);

    @include media-md {
      padding-bottom: 17.65%;
    }
    @include media-sm {
      padding-bottom: 23.7%;
    }
    @include media-xs {
      padding-bottom: 34.8%;
    }
  }
  &__loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &--home-page {
    overflow: hidden;

    .game-slider__nav-prev,
    .game-slider__nav-next {
      top: 15px;
    }
  }
  &--game-page {
    .game-thumbnail__badges {
      top: 0;
      right: 0;
    }
  }
}


