.content-activation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .customize-decorator {
    &--header {
      display: none;
    }

    &--content {
      padding: 0;
      width: 100%;

      .container {
        padding: 0;
      }
    }
  }

  .notification-root {
    border-radius: 5px;
    font-weight: bold;
  }
}

.activation-page {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 26;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-xs {
    background: color(background, body);
    padding: 0;

    .container {
      margin: 0;
    }
  }
  &__title {
    position: relative;
    color: var(--content-title-color);
    font-size: 32px;
    display: inline-flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin: 0;

    &_success:before {
      @include svg-icon('custom');
      content: '\0075';
      position: absolute;
      top: -1.2em;
      font-size: 3em;
      color: var(--primary-main);
    }
  }

  &__description {
    font-size: 16px;
    text-align: center;

    @include media-xs {
      font-size: 12px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    @include media-xs {
      gap: 0;
    }

    .stepper-wrap {
      padding: 2em 0;

      div {
        .stepper-root {
          padding: 0;
          gap: 48px;

          @include media-xs {
            gap: 24px;
          }
        }
      }
    }

    .error-wrapper {
      height: 450px;

      @include media-xs {
        height: auto;
      }

      .button-root {
        @include close-button;
        position: absolute;
        top: 20px;
        right: 30px;
      }
    }

    .button-accent {
      .button-label {
        margin-right: .5em;
      }

      &:after {
        @include svg-icon('custom');
        content: '\0044';
        rotate: -90deg;
      }
    }
  }

  .stepper__stepper {
    margin-bottom: 20px;
    background: var(--secondary-main);
  }

  .stepper-root {
    padding: 50px 0;
    justify-content: center;
    flex-wrap: wrap;

    @include media-xs {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;

      .stepper-step > div:nth-child(2) {
        display: none;
      }

      .stepper-item {
        flex-direction: row;
      }
    }
  }

  .stepper-step {

    @include media-xs {
      max-width: 100%;
    }

    svg {
      fill: var(--primary-main);

      @include media-xs {
        width: 30px;
        height: 30px;
      }
    }

    text {
      fill: color(footer, background);
    }

    p {
      color: var(--primary-light);

      @include media-xs {
        margin:0 0 0 10px;
      }
    }
  }
}
