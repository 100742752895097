.form-control-hint-block {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
}
.form-control-hint-block_offset {
  top: 32px;
  right: 50px;
}
.form-control-hint-block__icon{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    @include svg-icon('lvc');
    font-size: 14px;
    color: var(--primary-main);
  }
  @include media-xs {
    background: initial;
  }
}
.form-control-hint-block__icon_valid {
  &:before {
    content: 'P';
    color: var(--status-ok);
  }
}
.form-control-hint-block__icon_questions {
  &:before {
    content: 'j';
    cursor: help;
  }
}
.form-control-hint-block__icon_error {
  &:before {
    content: '\004d';
    color: var(--error-main);
  }
}
.form-control-hint-block__text {
  @include info-box();
  position: absolute;
  top: 29px;
  right: -60px;
  z-index: 3;
  max-width: 200px;
  width: max-content;
  display: none;
  background: var(--secondary-disabled-background);
  color: var(--primary-light);
  @include media-sm {
    max-width: -webkit-fill-available;
    right: 0;
    left: 0;
  }
}
.form-control-hint-block:hover + .form-control-hint-block__text {
  display: block;
}
