.promotions-page,
.static-page-content.promotions-page {
  .content-blocks {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    @include media-md{
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-sm {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 12px;
      gap: 120px;
    }

    .content-block {
      @include media-xs {
        &:first-child {
          margin-top: 40px;
        }
      }

      &:not(:first-child) {
        @include media-sm {
          border-top: 1px solid var(--secondary-light);
          padding-top: 24px;
          border-radius: 0;
        }
      }

      &-sub-header {
        display: flex;
        flex-direction: column;
        position: relative;

        img {
          border-radius: 12px;
          object-fit: cover;
        }

        p {
          position: absolute;
          background: var(--primary-main);
          left: 15px;
          bottom: 15px;
          padding: 8px 12px;
          border-radius: 100px;
          color: var(--balance-primary-dark);
          line-height: 1;
          font-weight: 500;
          margin: 0;
          display: none;

          i {
            margin-right: 6px;
          }
        }
      }

      &-content {
        height: 100%;

        &__text {
          margin-bottom: 15px;
        }
      }
      .button-root {
        margin-top: auto;
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--button-default-color);
        background-color: var(--promotions-button-background);
        font-weight: 500;

        i {
          margin-right: 6px;
          height: 16px;

          &::before {
            content: var(--promotions-button-chevron);
          }
        }
      }
    }
  }
}
