.modal-root,
.popup-decorator {
  overflow: visible;
  border-radius: 0;

  &.is-submodal {
    .modal-paper {
      width: 420px;
      margin: 16px;

      .modalContentWrapper {
        min-height: auto;
      }
    }
  }

  .modal-background {
    background: var(--Blur-Linear, linear-gradient(180deg, rgba(22, 24, 25, 0.90) 0%, #0B0C0C 100%));
    backdrop-filter: blur(5px);
  }

  .modal-buttonRoot {
    @include close-button;
    border-radius: 50%;
  }

  .modal-rootButtons {
    position: absolute;
    top: 21px;
    right: 24px;
    width: 2.667em;
    height: 2.667em;
    display: flex;
    justify-content: center;
    background: var(--secondary-main);
    border-radius: .667em;
    z-index: 2;

    @include media-xs {
      top: 12px;
    }
  }

  .modal-rootButton {
    >.button-default {
      min-height: 32px;
      padding: 4px 8px;
      color: var(--secondary-main);
      font-size: 16px;
      text-decoration: none;

      &:after {
        display: none;
      }

      >.button-label {
        white-space: nowrap;

        &::before {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--secondary-main);
          transition: all $main-transition-time;
        }

        &:hover::before {
          opacity: 0;
        }
      }
    }
  }

  .modal-paper {
    border-radius: 12px;
    border: 1px solid var(--default-normal);
    background: var(--primary-dark);
    overflow: hidden;

    .modalContentWrapper {
      display: flex;
      flex-direction: column;
      overflow-y: auto; // [FIN-21054] - wrong style in FireFox
      padding: 24px;
      min-height: auto;
      min-width: 300px;

      .modal-titleRoot {
        padding: 0;
        background: transparent;

        h2 {
          text-transform: none;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          color: var(--balance-primary-light);
          font-family: Poppins, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        }

        &+.modal-contentRoot {
          padding: 0;

          h1 {
            margin-top: 10px;
          }
        }
      }

      .modal-contentRoot,
      .popup-decorator__content {
        color: var(--modal-text-color);
        border: 0;
        border-radius: 0;
      }

      .modal-contentRoot {
        padding: 0;
      }

      .modal-contentRoot>div:last-child {
        &.notification-root {
          // if you remove it notifications in modals cannot use margin (a bug of browser, at last Chrome)
          animation: none;
        }
      }

      .modal-contentRoot>div:not(.notification-root):not(.grid-root):not(.deposit-form):not(.withdrawal-form):not(.resume-form):not(.verification-form):not(.game-search):not(.pep-page-content) {

        @include isMozillaFirefox {
          height: auto;
        }

        @include isMicrosoftEdge {
          height: auto;
        }
      }

      .field-root {
        padding-right: 0;
      }

      @include media-xs {
        padding: 16px;
      }
    }

    @media (max-width: 480px), (orientation: landscape) {
      #root[aria-hidden="true"] #main & {
        z-index: 500;
      }
      #root[data-url="/registration"] ~ div[data-mui-portal='true'] & {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        padding-bottom: 71px;
        border-radius: 0;
        border: 0;
      }
    }
  }

  .content__wrapper {
    width: 100%;

    .group-root {
      width: 100%;
    }
  }
}

.static-content-modal {
  .modal-paper {
    min-width: 300px;
    min-height: 300px;

    @include media-sm {
      max-width: 100%;
      margin: 0;
      border: none;
      border-radius: 0;
    }

    .modalContentWrapper {
      padding: 0;

      .modal-rootButtons {
        position: absolute;
        right: 24px;
        top: 24px;
      }

      .modal-titleRoot {
        display: none;
      }
    }
  }
}

.page-modal--id-pep {
  .modalContentWrapper {
    .confirmation-modal__buttons {
      gap: 20px;

      @include media-xs {
        padding-bottom: 0px;
      }

      @media (max-width: 1024px) and (orientation: landscape) {
        padding-bottom: 40px;
      }
    }
  }
}

.page-modal--id-terms-of-service,
.page-modal--id-pep,
.page-modal--id-terms-conditions,
.page-modal--id-privacy-policy,
.page-modal--id-responsible-gambling,
.page-modal--id-bonus-terms {
  .modal-paper {
    width: 1168px;

    .modalContentWrapper {
      padding: 20px;

      .modal-rootButtons {
        position: absolute;
        right: 5px;
        top: 5px;
      }

      .modal-titleRoot {
        display: none;
      }
    }
  }

  .underlined-title {
    display: none;
  }
}

.page-modal--id-terms-of-service .modal-paper .modalContentWrapper {
  padding-top: 48px;
  padding-right: 5px;
}

.modal__gambling-message {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: auto !important;
  margin: 20px 0;
  padding-bottom: 0 !important; // override: .modal-root .modal-paper .modalContentWrapper .modal-contentRoot > div:last-child `padding-bottom`
  text-align: center;

  @include media-sm {
    text-align: left;
  }

  img {
    @include size(32px);
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 8px;
    filter: brightness(0) grayscale(100%);
  }

  p {
    margin: 0;
    font-size: 14px;
    color: color(text, light);
  }
}

.confirmation-modal__buttons {
  margin-top: 20px;
  justify-content: center;
  gap: 0 20px;

  .gridItem-root {
    max-width: initial;
    flex-basis: auto;
  }
}

/* FreeSpins Finished modal */

.free-spins-finished-modal {
  &__buttons {
    text-align: center;

    button,
    button+button {
      margin: 0 7.5px;
    }

    @include media-xs {
      button+button {
        margin-top: 15px;
      }
    }
  }
}

.password-confirmation {
  .modalContentWrapper {

    .modal-rootButtons {
      background: transparent;

      .modal-buttonRoot {
        &:before {
          @include svg-icon('custom');
          content: '\0065';
        }
      }
    }
  }
}
