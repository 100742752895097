.how-to-start__wrapper {
  max-width: 680px;

  @include media(null, $size-sm) {
    margin: 0 auto;
  }

  &.aspect-ratio-16x9 {
    position: relative;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    @include size(100%);
    max-height: 380px;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: none;
  }
}
