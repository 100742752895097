.playerVerificationStep1 {
  @include invert-media-sm {
    padding-bottom: 64px;
  }

  .playerVerificationForm1 {
    @include media-sm {
      padding: 20px;
    }
    &__header {
      text-align: center;
      margin: 0 0 2px 0;
      font-size: 24px;
      line-height: 34px;

      @include media-sm {
        text-align: left;
      }
    }
    &-notice {
      padding: 16px 0;

      i {
        margin-right: 8px;
      }

      @include media-sm {
        padding: 8px 0 20px 0;
      }
    }
    &-container {
      display: flex;
      gap: 22px;

      .card {
        position: relative;
        filter: blur(1px);
        cursor: pointer;

        @include media-sm {
          filter: none;
        }

        &-decorator {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
        }

        &--quick {
          border: 2px solid var(--primary-gold-light);
          border-radius: 12px;
          padding: 24px;

          .field-input {
            .field {
              &-email {
                position: relative;

                &:after {
                  @include svg-icon('lvc');
                  content: 'n';
                  position: absolute;
                  top: 14px;
                  right: 16px;
                  left: auto;
                  color: var(--default-main);
                  background: none;
                  transform: none;
                  transition: none;
                  font-size: 14px;
                }


              }
            }
          }
          .field-input.field-error {
            .field {
              &-email {
                &:after {
                  @include svg-icon('lvc');
                }
              }
            }
          }

          .field-input.field-empty {
            &:hover {
              .field {
                &-email {
                  &:after {
                    @include svg-icon('lvc');
                  }
                }
              }
            }
            .field {
              &-email {
                &:after {
                  @include svg-icon('lvc-regular')
                }
              }
            }
          }
        }

        &--manual {
          gap: 24px;
          display: flex;
          flex-direction: column;
        }
        &--identify {
          gap: 24px;
          display: flex;
          flex-direction: column;
        }

        &--quick.card--active {
          border: 2px solid var(--primary-main);
          background: var(--primary-dark);
        }

        &--active {
          filter: none;
        }

        &--full-width {
          flex-basis: 75%;
        }
      }

      .swiper {
        width: 100%;
        overflow: hidden;

        @include media-sm {
          display: flex;
          flex-direction: column-reverse;
        }

        &-wrapper {
          display: flex;

          .card {
            flex: none;
            flex-shrink: 1;
            display: flex;

            @include media-sm {
              display: flex;
              flex-shrink: 0;
              flex-direction: column;
              flex-basis: 100%;

              &.swiper-slide-active {
                filter: none;
              }
            }
          }
        }

        &-pagination {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;

          &-bullet {
            @include pagination-bullets()
          }
        }

      }
    }

    &__methods {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 24px;

      .card--quick {
        width: 50%;
      }

      .idenfy-manual-cards {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 380px;

        @include media-sm {
          gap: 10px;
        }
      }
    }


    &__idenfy-verification,
    &__auto-verification {
      &-icon {
        height: 24px;
        width: auto;
        filter: grayscale(1) invert(1) contrast(5);
      }

      &-header {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
      }

      &-text {
        @include info-box();
        border: none;
        margin: 0 0 6px 0;
        padding: 0 0 0 22px;
        line-height: 20px;

        &:before {
          top: 3px;
          left: 0;
        }
      }

      &-description {
        font-size: 16px;
        line-height: 24px;
      }

      &-button-wrapper {
        @include media-sm {
          margin-top: 13px;
        }
        .button-root {
          width: 100%;

          i {
            margin-left: 10px;
          }
        }
      }
    }

    &__auto-verification {
      gap: 24px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 20px;
      color: var(--primary-light);

      &-header {
        margin: 0;

        @include media-sm {
          font-size: 24px;
          line-height: 34px;
          margin-bottom: 8px;
        }
      }

      &-text {
        border: 1px solid var(--primary-gold-dark);
        background: var(--primary-gold-dark-background);
        margin: 0;
      }
    }

    &__idenfy-verification, &__manual-verification {
      display: flex;
      flex-direction: column;
      gap: 25px;
      flex-basis: auto;
      border: 2px solid var(--primary-light);
      border-radius: 12px;
      padding: 30px;
      background: var(--secondary-disabled-background);

      @include media-sm {
        padding: 0;
        background: none;
        border: none;
      }
    }

    &__idenfy-verification {
      &-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        color: var(--default-main);
        border-top: 1px solid var(--secondary-main);
        padding: 10px 0 4px 0;

        @include media-sm {
          justify-content: left;
        }
      }
    }

    &__manual-verification {
      border: none;
      padding: 12px 0;
      flex-direction: row;
      align-items: center;
      gap: 0;
      border-radius: 0;

      @include media-sm {
        flex-direction: column;
        gap: 18px;
        padding: 10px 0;
        border-top: 1px solid var(--secondary-main);
      }

      &-text {
        margin: 0;

        .markdown-root {
          strong {
            line-height: 12px;
          }

          em {
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
          }

          strong, em {
            font-weight: 500;
            display: block;
            font-style: normal;
            margin-bottom: 6px;
          }
          ul {
            padding-left: 20px;
            margin: 0;

            li {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }

      &-button-wrapper {
        white-space: nowrap;

        @include media-sm {
          width: 100%;
        }

        .button-secondary {
          i {
            margin-left: 6px;
          }
          @include media-sm {
            width: 100%;
          }
        }
      }
    }

    & + .lvcVerification__buttons {
      @include media-xs {
        margin-top: 10px;
      }
    }
  }
}
