/* Generated by Glyphter (http://www.glyphter.com) on  Fri Oct 06 2023*/
@font-face {
    font-family: 'Glyphter-lvc-regular';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='lvc-regular-']:before{
	display: inline-block;
   font-family: 'Glyphter-lvc-regular';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.lvc-regular-arrow-down:before{content:'\0041';}
.lvc-regular-arrow-left:before{content:'\0042';}
.lvc-regular-arrow-right:before{content:'\0043';}
.lvc-regular-arrow-up:before{content:'\0044';}
.lvc-regular-bin:before{content:'\0045';}
.lvc-regular-blank:before{content:'\0046';}
.lvc-regular-blanks:before{content:'\0047';}
.lvc-regular-bot:before{content:'\0048';}
.lvc-regular-building:before{content:'\0049';}
.lvc-regular-calendar:before{content:'\004a';}
.lvc-regular-camera:before{content:'\004b';}
.lvc-regular-cancel:before{content:'\004c';}
.lvc-regular-cancel-circle:before{content:'\004d';}
.lvc-regular-chat:before{content:'\004e';}
.lvc-regular-checked:before{content:'\004f';}
.lvc-regular-checked-circle:before{content:'\0050';}
.lvc-regular-chevron-down:before{content:'\0051';}
.lvc-regular-chevron-left:before{content:'\0052';}
.lvc-regular-chevron-right:before{content:'\0053';}
.lvc-regular-chevron-up:before{content:'\0054';}
.lvc-regular-coffee:before{content:'\0055';}
.lvc-regular-controller:before{content:'\0056';}
.lvc-regular-cup:before{content:'\0057';}
.lvc-regular-deposit:before{content:'\0058';}
.lvc-regular-desktop:before{content:'\0059';}
.lvc-regular-exit:before{content:'\005a';}
.lvc-regular-eye:before{content:'\0061';}
.lvc-regular-factory:before{content:'\0062';}
.lvc-regular-favourite:before{content:'\0063';}
.lvc-regular-favourites:before{content:'\0064';}
.lvc-regular-film:before{content:'\0065';}
.lvc-regular-fire:before{content:'\0066';}
.lvc-regular-gift:before{content:'\0067';}
.lvc-regular-home:before{content:'\0068';}
.lvc-regular-id:before{content:'\0069';}
.lvc-regular-info:before{content:'\006a';}
.lvc-regular-list:before{content:'\006b';}
.lvc-regular-lock:before{content:'\006c';}
.lvc-regular-login:before{content:'\006d';}
.lvc-regular-mail:before{content:'\006e';}
.lvc-regular-man:before{content:'\006f';}
.lvc-regular-man-add:before{content:'\0070';}
.lvc-regular-man-help:before{content:'\0071';}
.lvc-regular-man-run:before{content:'\0072';}
.lvc-regular-message:before{content:'\0073';}
.lvc-regular-mobile:before{content:'\0074';}
.lvc-regular-money:before{content:'\0075';}
.lvc-regular-money-bag:before{content:'\0076';}
.lvc-regular-open-new:before{content:'\0077';}
.lvc-regular-people:before{content:'\0078';}
.lvc-regular-pig:before{content:'\0079';}
.lvc-regular-play:before{content:'\007a';}
.lvc-regular-redo:before{content:'\0030';}
.lvc-regular-road:before{content:'\0031';}
.lvc-regular-search:before{content:'\0032';}
.lvc-regular-settings:before{content:'\0033';}
.lvc-regular-sharp:before{content:'\0034';}
.lvc-regular-smile:before{content:'\0035';}
.lvc-regular-smile-likes:before{content:'\0036';}
.lvc-regular-snow:before{content:'\0037';}
.lvc-regular-speaker:before{content:'\0038';}
.lvc-regular-spin:before{content:'\0039';}
.lvc-regular-time:before{content:'\0021';}
.lvc-regular-todo-list:before{content:'\0022';}
.lvc-regular-trees:before{content:'\0023';}
.lvc-regular-upload:before{content:'\0024';}
.lvc-regular-wallet:before{content:'\0025';}
.lvc-regular-warning:before{content:'\0026';}
.lvc-regular-wheel:before{content:'\0027';}
.lvc-regular-window:before{content:'\0028';}
.lvc-regular-withdraw:before{content:'\0029';}
.lvc-regular-woman:before{content:'\002a';}
.lvc-regular-lvc-number:before{content:'\002b';}
