.progress-wrap {
  .stepper {
    &-root {
      padding: 0;
      display: flex;
      justify-content: center;
      background: none;
      gap: 12px;
    }

    &-step {
      padding: 0;
      //flex: inherit;
      width: 100%;

      .progress-item {
        // background: var(--primary-gold-dark);
        background: var(--progress--item-background);
        height: 5px;
        border-radius: 5px;
      }

      .progress-item--completed {
        // background: var(--primary-gold-light);
        background: var(--progress--item-background-completed);
      }

      & > div:nth-child(2) {
        display: none;
      }

      svg {
        display: none;
      }
    }
  }
}
