.altenar {
  width: 100vw;
  max-width: 1345px;
  transition: all 0.3s;
  margin: auto;

  &-confirm-action-modal {
    div .modalContentWrapper .modal-contentRoot {
      .button-root {
        width: 100%;
      }
    }
  }

  &__loader {
    margin: 20px 0;
  }
  &__notification-wrapper {
    margin-top: 40px;

    @include media-sm {
      .gridItem-root {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  .prefill {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 60vw;
    min-height: 60vh;
    margin: auto;
    padding: 40px;
    background: var(--content-background);

    @include media-md {
      max-width: 100vw;
    }
    &__description {
      color: color(text, light);
    }
    .button-root {
      margin-top: 10px;
    }
  }
}
