.playerVerificationStep3 {
  .lvcVerification__content-wrapper .lvcVerification__buttons .gridItem-root {
    .button-root {
      i {
        margin-left: 8px;
      }
    }
  }
}
.lvcVerificationConfirmDocumentDetails {

  &__auto-verification {
    &-text {
      font-size: 12px;
      line-height: 18px;
      padding: 12px 12px 12px 36px;
      border-radius: 8px;
      position: relative;
      border: 1px solid var(--primary-gold-dark);
      background: var(--primary-gold-dark-background);
      margin: 0;

      &:before {
        @include svg-icon('custom');
        content: 'p';
        color: var(--primary-main);
        position: absolute;
        left: 12px;
        top: 14px;
      }
    }
  }

  &__header {
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    padding: 12px 0;
    margin: 20px 0 12px;
  }

  &__document {
    display: flex;
    gap: 24px;
    margin-bottom: 24px;

    .gridItem-root {
      flex-basis: 50%;
      flex-grow: 1;
      flex-shrink: initial;
    }

    @include media-xs {
      flex-direction: column;
      gap: 18px;
      margin-bottom: 18px;
    }
  }
}
