.playerVerificationStep2 {
  .playerVerificationForm2 {
    &__details {
      @include media-sm {
        padding: 0 20px;
      }
    }
    &__notice {
      padding: 8px 0;

      i {
        margin-right: 8px;
      }
    }
    &__content {
      gap: 20px;
    }
    .lvcVerification {
      &__header {
        font-size: 16px;
        line-height: 1;
        font-weight: 700;
        padding: 12px 0;
        margin: 0 0 10px 0;
        text-align: left;
        text-transform: uppercase;
      }
    }
    &__auto-verification {
      &-text {
        font-size: 12px;
        line-height: 18px;
        padding: 12px 12px 12px 36px;
        border-radius: 8px;
        position: relative;
        border: 1px solid var(--primary-gold-dark);
        background: var(--primary-gold-dark-background);
        margin: 0;

        &:before {
          @include svg-icon('custom');
          content: 'p';
          color: var(--primary-main);
          position: absolute;
          left: 12px;
          top: 14px;
        }
      }
    }

    &__manual-verification {
      &-text, &-combo {
        @include info-box();
        margin: 0;
        border: 1px solid var(--primary-gold-light);
        background: var(--secondary-disabled-background);
      }
      &-combo {
        padding: 12px;

        p {
          display: flex;
          gap: 8px;
          align-items: center;

          @include media-md {
            flex-wrap: wrap;
          }
          @include media-sm {
            flex-wrap: nowrap;
          }
        }
        img {
          width: 63px;
          height: 40px;
        }
        &:before {
          content: none;
        }
      }
    }

    &__section {
      column-gap: 10px;
    }

    &__playerInfo,
    &__lvcCardField,
    &__playerAddressFields {
      border-radius: 10px;
      background: var(--primary-section-background);
      padding: 24px;
      height: fit-content;

      .markdown-root {
        font-size: 16px;
        line-height: 24px;
        color: var(--balance-primary-light);

        @include media-sm {
          font-size: 12px;
          line-height: 18px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
        }
      }

      .field-checkbox {
        margin: 0;
        padding: 0;

        label {
          padding: 0;
        }
      }
    }

    &__playerInfo,
    &__lvcCardField,
    &__playerAddressFields {
      max-width: 580px;
    }

    &__section {
      margin-bottom: 40px;
      gap: 18px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__fields {
      display: grid;
      flex-direction: row;
      gap: 18px;
      grid-template-columns: repeat(2, 1fr);

      &.playerAddress {
        .fields-container__grid-root {
          &--country {
            order: 1;
          }
          &--residentialZipCode, &--zipCode {
            order: 2;
          }
          &--residentialCity, &--city {
            order: 3;
          }
          &--residentialAddress, &--address {
            order: 4;
          }
          &--residentialHouseNumber, &--houseNumber {
            order: 5;
          }
        }
      }

      &.lvcCardField {
        margin-top: 22px;
        padding-top: 22px;
        border-top: 1px solid var(--secondary-main);

        em {
          font-style: normal;
          color: color(primary, main);
        }
      }

      @include media-sm {
        grid-template-columns: repeat(1, 1fr);
      }

      .gridItem-root {
        .field-input .input-root {
          &:after {
            @include svg-icon('lvc-regular');
          }

          &:hover {
            &:after {
              @include svg-icon('lvc');
            }
          }
        }
      }

      .gridItem-root {
        .field-input:not(.field-disabled) .input-root {
          &:after {
            position: absolute;
            top: 14px;
            right: 16px;
            left: auto;
            color: var(--default-main);
            background: none;
            transform: none;
            transition: none;
            font-size: 14px;
          }
        }
      }

      .fields-container__grid-root {
        &--firstName, &--lastName, &--maidenName {
          .field-input:not(.field-disabled) .input-root {
            position: relative;

            &:after {
              content: 'o';
            }
          }
        }
        &--mothersMaidenName {
          .field-input:not(.field-disabled) .input-root {
            &:after {
              content: '*';
            }
          }
        }
        &--birthPlace, &--residentialCity, &--city {
          .field-input:not(.field-disabled) .input-root {
            &:after {
              content: 'b';
            }
          }
        }
        &--residentialZipCode, &--zipCode {
          .field-input:not(.field-disabled) .input-root {
            &:after {
              content: '4';
            }
          }
        }
        &--residentialAddress, &--address {
          .field-input:not(.field-disabled) .input-root {
            &:after {
              content: '1';
            }
          }
        }
        &--residentialHouseNumber, &--houseNumber {
          .field-input:not(.field-disabled) .input-root {
            &:after {
              content: 'h';
            }
          }
        }

        &--maidenName,
        &--mothersMaidenName,
        &--birthPlace,
        &--citizenship,
        &--residentialCity,
        &--city {
          grid-column: 1 / 3;

          @include media-sm {
            grid-column: 1;
          }
        }
      }
    }

    .lvcCardField {
      grid-template-columns: repeat(1, 1fr);

      .button-root {
        margin-top: 22px;
        width: 100%;
        background: var(--status-ok);

        i {
          margin-left: 8px;
        }
      }

      .field-input.field-empty .input-root {
        &:after {
          @include svg-icon('lvc-regular');
        }

        &:hover {
          &:after {
            @include svg-icon('lvc');
          }
        }
      }

      .field-input:not(.field-disabled) .input-root {
        &:after {
          content: '+';
        }
      }
    }
    &__header {
      margin: 0;
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;

      @include media-xs {
        font-size: 24px;
        line-height: 34px;
      }
    }

    &__description {
      text-align: left;
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      margin: 10px 0;

      p {
        margin: 0;
      }
    }

    .playerResidentialAddress {
      margin-top: 22px;
      padding-top: 22px;
      border-top: 1px solid var(--secondary-main);

      .lvcVerification__header {
        margin: 0;
        padding-bottom: 0;
      }

      @include media-xs {
        .fields-container__grid-root {
          &--city {
            order: 3;
          }

          &--address {
            order: 1;
          }

          &--houseNumber {
            order: 2;
          }

          &--zipCode {
            order: 4;
          }
        }
      }
    }
  }
}
