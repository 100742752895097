.notification-root {
  flex-wrap: nowrap;
  flex: 0 0 auto;
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px !important;
  align-items: flex-start;
  box-shadow: none;
  text-align: left;
  border-radius: 24px;
  padding: 12px 16px;
  color: color(notice, color);
  font-size: 1em;
  line-height: 1.2;
  font-family: Poppins, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  position: relative;

  a,
  p a {
    color: color(primary, gold-light);
    text-decoration: none;
  }

  &:before {
    font-size: 1em;
  }

  @include media-sm {
    padding: 12px 24px;
  }

  p {
    color: color(notice, color);
    text-align: left;
    margin: 0;
    font-size: 1em;
    line-height: 1em;
  }

  ol {
    padding-left: 13px;
  }

  .notification-message {
    padding: 0 2em 0 1em;
  }

  .notification-action {
    position: absolute;
    top: 10px;
    right: 16px;
    margin: 0;
    padding: 0;

    button {
      width: 1em;
      height: 1em;
      font-size: 1em;

      span span {
        color: color(primary, light);
        font-size: 0.8em;
        font-weight: 700;
      }
    }
  }
}

.notification-error {
  background: var(--error-main);

  &:before {
    @include svg-icon('custom');
    content: 'Q';
  }
}

.notification-success {
  background: var(--notification-success-background);

  &:before {
    @include svg-icon('custom');
    content: '\006a';
  }
}

.notification-info {
  background: color(secondary, light);

  &:before {
    @include svg-icon('custom');
    content: '\0070';
    color: color(primary, gold-light);
  }
}

.notification-root:not(.notification-info):not(.notification-success):not(.notification-error):not(.notification-warning) {
  background: color(secondary, light);

  &:before {
    color: color(notice, color);
    @include svg-icon('custom');
    content: '0';
  }
}

.notification-warning {
  background: color(primary, main);
  color: color(warning-notify, color);

  p {
    color: color(warning-notify, color);
  }

  .notification-action button span span {
    color: color(warning-notify, color);
  }

  &:before {
    @include svg-icon('custom');
    content: "Q";
  }

  .notification-message {
    a {
      color: #212121;
      text-decoration: underline;

      &:hover {
        color: color(warning-notify, color);
      }
    }
  }
}

.notifications {
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 99998;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
  pointer-events: none;

  @include media-md {
    width: 60%;
  }
  @include media-sm {
    right: 10px;
    left: 10px;
    width: auto;
  }
  &:has(.pending-deposit-notification) {
    @include media-sm {
      margin-top: 4rem;
    }
  }

  .notification {
    display: flex;
    align-items: flex-end;
    min-width: 100%;
    margin-top: 5px;
    padding-bottom: 10px;
    overflow: hidden;
    pointer-events: all;

    &:last-child {
      padding-bottom: 0;
    }

    &-root {
      margin-top: 0;
      margin-bottom: 0;

      &:not(:first-child) {
        margin-top: 30px;
      }
    }

    ul {
      padding-left: 10px;
    }
  }
}