.stepper-wrap {
  padding: 32px 0;

  @include media-sm {
    padding: 16px 0;
  }
  .stepper {
    &-item {
      flex-direction: row;
      gap: 12px;

      &--completed {
        .stepper-item--icon {
          &:after {
            @include svg-icon('lvc-regular');
            content:'\004f';
            font-size: 16px;
            padding: 6px;
            color: var(--primary-disabled-color);
            border: 1px solid var(--primary-disabled-color);
            border-radius: 50%;
            margin-top: 3px;
          }
          svg {
            display: none;
          }
        }
      }

      @include media-sm {
        gap: 0;
      }
    }

    &-root {
      padding: 0;
      background: none;
      gap: 32px;

      @include media-sm {
        gap: 16px;
        display: inline-grid;
        grid-auto-flow: column;
      }
    }

    &-step {
      padding: 0;
      flex: inherit;

      & > div:nth-child(2) {
        display: none;
      }

      p {
        margin: 2px 0 0 0;
        font-size: 16px;
        font-weight: 500;
        color: var(--default-main);
        font-family: Poppins, Roboto, sans-serif;
      }

      text {
        font-size: 10px;
        font-weight: 500;
        line-height: 1;
        fill: var(--default-main);
        font-family: Poppins, Roboto, sans-serif;
      }

      svg {
        width: 36px;
        height: 36px;
        fill: none;

        @include media-sm {
          margin-right: 0;
        }
      }

      &.active {
        margin-left: 0;

        .stepper-item {
          @include media-sm {
            gap: 12px;
            display: inline-grid;
            grid-auto-flow: column;
          }
        }

        p {
          color: var(--balance-primary-light);
        }

        text {
          fill: var(--primary-dark);
        }

        svg {
          fill: var(--balance-primary-main);
        }
      }
    }

    &-item--completed {
      svg {
        stroke: var(--primary-disabled-color);

        @include media-sm {
          margin: 0;
        }
      }

      text {
        display: none;
      }
    }
  }
}
