.playerVerificationStep3 {
  .playerVerificationForm4 {
    grid-column: 1 / 3;

    @include media-sm {
      grid-column: 1;
    }

    &__header {
      line-height: 1.3;
      padding-bottom: 10px;

      @include media-xs {
        text-align: left;
        padding-bottom: 0;
      }
    }

    &__snapshots {
      display: grid;
      gap: 18px;
      grid-template-columns: repeat(1, 1fr);

      .snapshot-common {
        border-bottom: 1px solid var(--secondary-main);
        padding-bottom: 28px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
        }
      }
    }
    &__left,
    &__right,
    &__wide {
      display: flex;
      padding: 20px 10px 7px;

      @include media-xs {
        padding: 0;
      }
    }
    &__left {
      border-right: 1px solid color(settings-menu, border);

      @include media-md {
        border-right: none;
      }
    }
    &__right {
      border-left: 1px solid #101010;

      @include media-md {
        border-left: none;
      }
    }
    &__wide {
      .snapshot__headers {
        min-height: 45px;

        @include media-xs {
          min-height: auto;
        }
      }
    }
    &__left,
    &__right {
      width: 100%;
      max-width: 100%;
    }
    &__blocks {
      display: flex;
      justify-content: center;
      align-items: end;

      @include media-xs {
        display: block;
      }

      .sub-block {
        display: flex;
        align-items: end;
        height: 100%;
        padding: 0 10px 10px;

        @include media-xs {
          height: initial;
          padding: 0 0 10px;
        }
      }
    }
    &__image {
      &.sub-block {
        display: block;

        @include media-xs {
          display: none;
        }
      }
    }
  }
}
