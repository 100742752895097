.authentication {
  background: var(--modal-background-login);

  &__process {
    .markdown-root {
      font-size: 20px;
      font-weight: bold;
      border-bottom: 1px solid var(--secondary-light);
      margin-bottom: 14px;

      p {
        margin: 0 0 14px 0;
      }

      strong {
        font-weight: normal;
        font-size: 14px;
        display: block;
      }
    }

    .login__field, .password__field {
      border-bottom: 1px solid var(--secondary-light);
      margin-bottom: 14px;
      padding-bottom: 12px;
    }

    .login__field.field-input {
      .input-root {
        position: relative;

        &:after {
          @include svg-icon('lvc');
          content: 'n';
          position: absolute;
          top: 14px;
          right: 16px;
          left: auto;
          color: var(--default-main);
          background: none;
          transform: none;
          transition: none;
          font-size: 14px;
        }
      }
    }

    .login__field.field-input.field-empty {
      &:hover {
        .input-root {
          &:after {
            @include svg-icon('lvc');
          }
        }
      }
      .input-root {
        &:after {
          @include svg-icon('lvc-regular')
        }
      }
    }

    .password__field {
      padding-bottom: 14px;

      .login__forgot-password {
        display: block;
        text-align: right;
      }

      .field-root {
        margin-bottom: 12px;
      }
    }
  }
  &__methods {
    margin-bottom: 20px;
    .radioGroup-root {
      label {
        min-width: 20%;
      }
    }
  }
  &__success {
    text-align: center;
  }
}
