.game-thumbnail {
  background-color: var(--game-thumbnail-background);
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: 3rem;
  border: 1px solid var(--game-thumbnail-border);
  border-radius: 8px 8px 16px 16px;
  height: 108%;

  @include media-xs {
    body.is-ios & {
      height: auto;
      padding-bottom: 3rem;
      margin-bottom: 0;
    }
  }

  &:has(.game-thumbnail__placeholder.game-thumbnail__placeholder_rendering) {
    flex-direction: row;
  }
  &:has(a[href="/en/?modal=gameInfoModal&gameId=undefined"]), &:has(a[href="/?modal=gameInfoModal&gameId=undefined"]) {
    display: none;
  }

  img {
    position: relative;
    aspect-ratio: 600 / 295;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-main);
  }

  &--favourite {
    img {
      border: 1px solid var(--primary-main);
    }
  }

  &__play {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 65px;
    height: calc(100% - 65px);
    z-index: 4;

    &:hover {
      background: none;
    }

    @include media-sm {
      bottom: 42px;
      height: calc(100% - 42px);
    }
  }

  @include media(null, 954px) {
    flex-basis: 25%;
  }

  @include media(null, 1600px) {
    flex-basis: 25%;
  }

  @include media(null, 2600px) {
    flex-basis: 20%;
    max-height: none;
  }

  @media screen and (max-width: 954px) {
    flex-basis: 24%;
  }
  @include media-sm {
    flex-basis: 32%;
  }

  @include media-xs {
    flex-basis: 50%;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0;

    @include media-xs {
      padding-bottom: 0;
    }
  }

  .image-content {
    @include media-xs {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__placeholder {
    display: flex;
    height: calc(100% - 110px);
    aspect-ratio: 600 / 295;
    left: 50%;
    transform: translate(-50%, 50%);
    position: absolute;
    align-content: center;
    justify-content: center;
  }

  &--loaded {
    &::after,
    .game-thumbnail__placeholder:not(.game-thumbnail__placeholder_rendering) {
      display: none;
    }
  }

  &__badges {
    position: absolute;
    z-index: 4;
    top: 2px;
    left: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    opacity: 1;
    transition: opacity $main-transition-time;
    pointer-events: none;

    &:not(:empty) ~ .game-thumbnail__liveCasino {
      .game-thumbnail__liveCasino {
        &__top {
          justify-content: flex-end;
        }

        &__bottom {
          justify-content: flex-end;
          display: flex;
        }
      }
    }

    &:empty {
      display: none;
    }
  }

  &__badge {
    flex: {
      grow: 0;
      shrink: 0;
    }
    background: {
      color: transparent;
      position: center;
      repeat: no-repeat;
      size: contain;
    }

    &--new {
      @include size(50px);
      background-image: url('/cms/img/icons/new.png');
    }

    &--free-spins {
      @include size(49px, 40px);
      background-image: url('/cms/img/icons/free-spins.svg');
    }
  }

  &__jackpot-amount {
    width: 100%;
    color: #000;
    white-space: nowrap;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    background: var(--jackpot-amount-background);
    pointer-events: none;
    display: block;
    height: 30px;
    padding: 1px 5px;
    border-radius: 8px;
    margin-bottom: 6px;
    @media (max-width: 500px) {
      font-size: 12px;
    }

    &-all {
      z-index: 3;
      position: absolute;
      background: var(--primary-main);
      border-radius: 100px;
      color: var(--primary-text);
      padding: 8px 12px;
      font-size: 12px;
      font-weight: 500;
      bottom: 80px;
      left: 5px;
      line-height: 1;
      align-items: center;
      min-width: max-content;
      @media (max-width: 1200px) {
        padding: 6px;
      }

      i {
        margin-right: 6px;
      }

      @include media-md-landscape {
        bottom: 45px;
        left: 7px;
      }
    }

    &--title {
      color: color(promotions-card, title-background);
      margin-top: 12px;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
    }
  }

  &__jackpot-badge {
    @include size(53px, 40px);
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 3px;
    background: url('/cms/img/icons/jackpot.svg') transparent center/contain no-repeat;
  }

  &__promo-img {
    .image-wrapper {
      position: relative;

      &-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
      }
    }
  }

  /* Fix jumping game images (Safari) (1) */
  > .image-wrapper > img {
    flex-shrink: 0;
    height: 100%; // 1
    width: 100%;

    @include media-sm {
      height: auto;
    }
    @include media-xs {
      padding-top: 0;
    }

    &[src=""] {
      height: 0; // 1
    }
  }

  &--loaded img {
    height: auto; // 1
    padding-top: initial; // 1
    visibility: initial;
    border-radius: 8px;
  }

  &__title,
  &__rtp {
    .desktop .game-slider & {
      @include media-md {
        font-size: 1.75vw;
      }
    }
  }

  &__title {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;

    .desktop & {
      @media (min-width: 768px) {
        @include truncate;
      }
    }

    @include media-md {
      font-size: 14px;
    }
    @include media-lg {
      font-size: 12px;
    }
  }

  &__rtp {
    position: absolute;
    bottom: 6px;
    left: 8px;
    z-index: 1;
    font-size: 12px;
    color: #fff;
    opacity: 0;
    transition: opacity $main-transition-time;

    & > p {
      margin: 0;
    }
  }

  &__caption {
    color: var(--balance-primary-light);
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0px;
    @include media-sm {
      overflow: hidden;
    }
  }

  &__buttons {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    .fpLink {
      font-size: 12px;
      text-decoration: none;
      margin-top: 2px;
      color: var(--game-buttons-links);

      @include media-xs {
        font-size: 8px;
      }
    }

    .game-thumbnail__button--favourite:has(~ .game-thumbnail__button-overlay) {
      position: relative;
      padding-right: 12px;

      &::after {
        content: '';
        position: absolute;
        right: 2px;
        top: 3px;
        bottom: 0;
        width: 1px;
        background-color: var(--default-normal);
        height: 12px;

        @include media-xs {
          top: 0;
        }
      }
    }
  }

  &__button {
    position: relative;
    min-height: 30px;
    margin: 2px;
    padding: 10px 23px;
    color: #fff;
    align-items: center;
    max-width: 100%;
    min-width: 133px;
    font-size: 10px;
    text-transform: uppercase;

    span {
      line-height: 1.4;
    }

    &--real,
    &--demo {
      .desktop & {
        @include media-sm {
          display: none;
        }
      }
    }

    &--real {
      font-weight: $font-weight-base;
      color: #2f2f2f;
      background-color: var(--primary-main);

      &:hover {
        color: color(button-accent, hover);
        background-color: var(--primary-main);
      }

      .is-logged-out & {
        @include media-lg {
          padding: 4px 12px;

          &::before {
            display: none;
          }
        }
      }

      [data-url="/group/all"] &,
      [data-url="/all-games"] &,
      .game-slider--game-page & {
        &::before {
          display: none;
        }
      }
    }

    &--demo {
      color: var(--primary-main);
      background: color(footer, background);

      &:hover {
        color: color(button-accent, hover);
        background: color(footer, background);
      }
    }

    &--favourite {
      margin: 0;
      padding: 0;
      line-height: 1;
      background: none;
      min-height: auto;
      height: auto;
      min-width: auto;

      &::before {
        @include svg-icon;
        content: '\0057';
        font-size: 14px;
        color: var(--game-thumbnail-icon-color);
        font-weight: bolder;

        @include media-xs {
          font-size: 10px;
        }
      }

      .button-label {
        display: none;
      }

      &:hover {
        background: none;

        &::before {
          color: var(--error-main);
        }
      }
    }

    &--favourite-on {
      &::before {
        content: var(--favourite-icon-selected);
      }
    }
  }

  #root[data-url*="ezugi"] .game-info-page__button_demo,
  .game-thumbnail--provider--4 .game-thumbnail__button--demo {
    display: none;
  }

  &--provider--28 {
    display: none;
  }

  &__button-overlay:not(:nth-of-type(1)) {
    cursor: pointer;
    position: relative;
    padding-left: 12px;

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 3px;
      bottom: 0;
      width: 1px;
      background-color: var(--default-normal);
      height: 12px;

      @include media-xs {
        top: 0;
      }
    }

    @media (pointer: coarse) {
      pointer-events: auto;
    }

    .desktop & {
      @include media-sm {
        pointer-events: auto;
      }
    }
  }

  &__content {
    position: static;
    display: flex;
    flex-direction: column;
    left: 12px;
  }

  &__dga__content {
    position: absolute;
    bottom: 10px;
    min-height: 2.75rem;
    width: 90%;
    left: 12px;
    @include media-xs {
      body.is-ios & {
        bottom: 3px;
      }
    }
  }

  &__content {
    position: absolute;
    bottom: 10px;
    min-height: 2.75rem;
    width: 90%;

    body.is-ios & {
      @include media-xs {
        min-height: 2.25rem;
      }
    }
  }

  &__last-results {
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 6px 3px 33px;
    background: {
      color: rgba(255, 255, 255, .25);
      position: 0 50%;
      repeat: no-repeat;
      size: contain;
    };
    border-radius: 16px;
    transition: opacity $main-transition-time;

    @include media-sm {
      transform: translateX(-50%) scale(.9);
    }

    &--roulette {
      background-image: url('/cms/img/games/game-thumbnail/last-results-roulette.svg');
    }

    &--blackjack {
      background-image: url('/cms/img/games/game-thumbnail/last-results-blackjack.svg');
    }

    &--baccarat {
      background-image: url('/cms/img/games/game-thumbnail/last-results-baccarat.svg');
    }
  }

  &__last-results-item {
    @include size(20px);
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: $font-weight-alt;
    line-height: 20px;
    text-align: center;

    &:not(:last-of-type) {
      margin-right: 3px;
    }

    &:first-of-type {
      @include size(24px);
      font-size: 12px;
      line-height: 24px;
    }

    &--red {
      background: #ff4747;
    }

    &--black {
      background: #000;
    }

    &--green {
      background: #71ac31;
    }
  }

  &__dealer {
    max-width: 100%;
  }

  &__info {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    width: 100%;
    transition: opacity $main-transition-time;

    @include media-sm {
      padding: 12px;
    }
  }

  &__info-item {
    color: #fff;
    font-weight: $font-weight-alt;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 1);

    &:not(:last-child) {
      margin-bottom: 2px;
    }

    &--name {
      font-size: 18px;

      .desktop & {
        @media (min-width: 768px) {
          @include truncate;
        }
      }
    }

    &--bets,
    &--seats {
      font-size: 12px;
    }

    &--seats {
      &-open {
        color: color(success, default);
      }

      &-taken {
        color: var(--error-main);
      }
    }
  }

  .featured-games-slider & {
    .game-thumbnail__badges,
    .game-thumbnail__jackpot-amount,
    .game-thumbnail__jackpot-badge {
      display: none;
    }

    &__title,
    &__rtp {
      font-size: 20px;
    }

    &__button--real {

      &::before {
        display: block;
      }
    }
  }

  &__liveCasino {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;

    &__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-inline: 5px;
      border-radius: 6px 6px 0px 0px;
    }

    &__bottom {
      padding-inline: 5px;
    }

    &--item {
      display: flex;
      font-weight: 700;
      color: white;
      align-items: baseline;
      position: relative;
      padding: 5px;
      gap: 4px;

      &--dealerName {
        display: inline-flex;
        font-size: 12px;
      }

      &--roulette {
        display: flex;
        gap: 6px;
        padding: 3px 5px;

        &:first-child {
          padding-top: 5px;
        }

        &:last-child {
          padding-bottom: 5px;
        }

        &__item {
          line-height: 1;
          min-width: 16px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }

        &--red {
          color: red;
        }

        &--green {
          color: green;
        }
      }

      &--blackjack {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;

        &--item {
          opacity: 0.5;

          &--active {
            opacity: 1;
          }

          &:before {
            display: inline-block;
            font-family: Glyphter;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            content: "E";
          }
        }
      }

      &--person {

        &:before {
          display: inline-block;
          font-family: Glyphter;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          content: "E";
        }
      }
    }

  }

  .image-wrapper {
    min-width: 100%;
    height: fit-content;
    @include media-sm {
      height: auto;
    }

  }
}
