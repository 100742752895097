/* Generated by Glyphter (http://www.glyphter.com) on  Tue Sep 24 2024*/
@font-face {
    font-family: 'Glyphter';
    src: url('../fonts/Glyphter.eot');
    src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Glyphter.woff') format('woff'),
         url('../fonts/Glyphter.ttf') format('truetype'),
         url('../fonts/Glyphter.svg#Glyphter') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='lvc-']:before{
	display: inline-block;
   font-family: 'Glyphter-lvc';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.lvc-arrow-down:before{content:'\0041';}
.lvc-arrow-left:before{content:'\0042';}
.lvc-arrow-right:before{content:'\0043';}
.lvc-arrow-up:before{content:'\0044';}
.lvc-bin:before{content:'\0045';}
.lvc-blank:before{content:'\0046';}
.lvc-blanks:before{content:'\0047';}
.lvc-bot:before{content:'\0048';}
.lvc-building:before{content:'\0049';}
.lvc-calendar:before{content:'\004a';}
.lvc-camera:before{content:'\004b';}
.lvc-cancel:before{content:'\004c';}
.lvc-cancel-circle:before{content:'\004d';}
.lvc-chat:before{content:'\004e';}
.lvc-checked:before{content:'\004f';}
.lvc-checked-circle:before{content:'\0050';}
.lvc-chevron-down:before{content:'\0051';}
.lvc-chevron-left:before{content:'\0052';}
.lvc-chevron-right:before{content:'\0053';}
.lvc-chevron-up:before{content:'\0054';}
.lvc-coffee:before{content:'\0055';}
.lvc-controller:before{content:'\0056';}
.lvc-cup:before{content:'\0057';}
.lvc-deposit:before{content:'\0058';}
.lvc-desktop:before{content:'\0059';}
.lvc-exit:before{content:'\005a';}
.lvc-eye:before{content:'\0061';}
.lvc-factory:before{content:'\0062';}
.lvc-favourite:before{content:'\0063';}
.lvc-favourites:before{content:'\0064';}
.lvc-film:before{content:'\0065';}
.lvc-fire:before{content:'\0066';}
.lvc-gift:before{content:'\0067';}
.lvc-home:before{content:'\0068';}
.lvc-id:before{content:'\0069';}
.lvc-info:before{content:'\006a';}
.lvc-list:before{content:'\006b';}
.lvc-lock:before{content:'\006c';}
.lvc-login:before{content:'\006d';}
.lvc-mail:before{content:'\006e';}
.lvc-man:before{content:'\006f';}
.lvc-man-add:before{content:'\0070';}
.lvc-man-help:before{content:'\0071';}
.lvc-man-run:before{content:'\0072';}
.lvc-message:before{content:'\0073';}
.lvc-mobile:before{content:'\0074';}
.lvc-money:before{content:'\0075';}
.lvc-money-bag:before{content:'\0076';}
.lvc-open-new:before{content:'\0077';}
.lvc-people:before{content:'\0078';}
.lvc-pig:before{content:'\0079';}
.lvc-play:before{content:'\007a';}
.lvc-redo:before{content:'\0030';}
.lvc-road:before{content:'\0031';}
.lvc-search:before{content:'\0032';}
.lvc-settings:before{content:'\0033';}
.lvc-sharp:before{content:'\0034';}
.lvc-smile:before{content:'\0035';}
.lvc-smile-likes:before{content:'\0036';}
.lvc-snow:before{content:'\0037';}
.lvc-speaker:before{content:'\0038';}
.lvc-spin:before{content:'\0039';}
.lvc-time:before{content:'\0021';}
.lvc-todo-list:before{content:'\0022';}
.lvc-trees:before{content:'\0023';}
.lvc-upload:before{content:'\0024';}
.lvc-wallet:before{content:'\0025';}
.lvc-warning:before{content:'\0026';}
.lvc-wheel:before{content:'\0027';}
.lvc-window:before{content:'\0028';}
.lvc-withdraw:before{content:'\0029';}
.lvc-woman:before{content:'\002a';}
.lvc-lvc-number:before{content:'\002b';}
.lvc-megaversum:before{content:'\002c';}
.lvc-help:before{content:'\007b';}
.lvc-ordered-list:before{content:'\007c';}
.lvc-clock:before{content:'\007d';}
.lvc-robot:before{content:'\007e';}
.lvc-pike:before{content:'\003f';}
