.login {
  margin: 0 -20px;

  &__welcome-block {
    padding: 12px 20px 24px;
    width:100%;


    .button-primary {
      width: 45%;
      i {
        margin-left: 10px;
      }
    }
    .button-secondary {
      margin-left: 12px;
      i {
        margin-left: 10px;
      }
    }

    .group-root {
      padding: 0;
      margin-bottom: 12px;

    }
  }
  &__buttons-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .container {
    max-width: 800px;
  }
  &-modal,
  .authentication {
    &--regular {

      & .dark-input {
        input {
          color: var(--input-color-login);
          background: var(--input-background-login);
          padding-right: 32px;

          &:-webkit-autofill {
            -webkit-box-shadow: var(--input-webkit-autofill-box-shadow-login) !important;
            -webkit-background-clip: text;
            -webkit-text-fill-color: var(--input-webkit-text-fill-color-login);
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: var(--input-webkit-autofill-box-shadow-login) !important;
          }

        }
      }

      .group-root--buttons {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;

        @include media-sm {
          justify-content: left;
          padding-top: 0;
        }
        .login__button {
          width: 100%;
          i {
            margin-left: 10px;
            margin-top: 3px;
          }
        }
        .login__forgot-password {
          position: absolute;
          right: 0;
          margin-left: 15px;

          @include media-sm {
            top: 0;
            right: initial;
            left: 0;
            margin-left: 0;
          }
        }
        .registration__button {
          @include media(null, $size-sm) {
            display: none;
          }
        }
      }
      &__button {
        @include media-xs {
          min-width: auto;
        }
      }
    }
  }
  .registration__button {
    margin-left: 15px;
  }
}

// for disabled icon hiding while login
.login-form .password__field .field-disabled .input-disabled {
  &:after {
    display: none;
  }
}
