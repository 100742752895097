@mixin inputIconProperties {
  position: absolute;
  font-size: 12px;
  color: var(--default-main);
  transition: none;
  top: 15px;
  right: 16px;
  left: auto;
  background: none;
  transform: none;
}

.notification {
  &-root {
    margin-top: 30px;
    justify-content: center;
  }

  &-message {
    width: 100%;
  }
}


.withdrawal-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--primary-light);

  @include media-sm {
    overflow: hidden;
  }

  .iban-form--title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--balance-primary-light);
    text-align: left;
  }

  .loading-progress {
    position: relative;
    z-index: 1;
    margin-top: calc(50% - 40px);
  }

  .internal-notification {
    width: 93%;
  }

  .confirm-button {
    height: fit-content;

    button {
      width: 100%;

      &.button-accent .button-label:after {
        @include svg-icon('custom');
        content: '\0042';
        rotate: 90deg;
        font-size: .8em;
        margin-left: .5em;
        padding-top: .1em;
      }

      &.button-disabled {
        background-color: #4c4e4f;
        color: #fff;
      }

    }
  }

  @include media-xs {
    .select-root {

      .select-select {
        background: color(select, background);
        align-items: center;
        color: color(label, color);
      }
    }
  }

  &__withdrawal {
    overflow: auto;
    max-height: calc(100dvh - 184px);

    @include media-xs {
      z-index: 4;
      background-color: var(--deposit-modal-background);
      overflow: auto;
      max-height: 100%;
      padding-bottom: 2.5rem;

      body.is-ios & {
        padding-bottom: 6.25rem;
      }
    }

    @media (max-width: 391px) {
      padding-bottom: 3.5rem;

      body.is-ios & {
        padding-bottom: 7.25rem;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &-header {
      position: relative;
      width: 100%;
    }

    &-title,
    &-hint {
      position: relative;
      z-index: 1;
    }

    &-title {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      color: var(--balance-primary-light);
    }

    &-hint {
      color: var(--balance-primary-light);
      text-transform: uppercase;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }

    &__noteInfo {
      margin-bottom: 24px;
      height: auto;
    }

    @include media-sm {
      flex-direction: column;
      width: 100%;
      gap: 12px 0;
      border: none;
    }

    &--form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px 24px;

      @include media-sm {
        flex-direction: column;
      }
    }

    &-monetary {
      font-size: 1.1em;
      width: 100%;
      margin-bottom: 1.5rem;
      margin-top: 0.85rem;
    }

    &--info {
      display: flex;
      gap: 8px;
      padding-top: 10px;
      flex-direction: column;
      color: var(--balance-staff-text);

      .markdown-root p {
        display: flex;
        flex-direction: row;
        font-size: 14px;

        strong {
          color: var(--footer-list-title);
          margin-left: 5px;
        }
      }
    }

    &--amount-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .markdown-root {
        font-size: 1.167em;

        p {
          margin: 0;
        }
      }

      .method-description {
        color: color(main-menu, link-hover);
        background-color: #272628;
        padding: 19px 29px;
        margin-bottom: 12px;
      }

      .amount-notification {
        background: var(--game-status-loss);
        font-size: 16px;
        text-align: center;
        padding: 10px 15px;
        color: color(input-mobile, background);
        margin-bottom: 20px;
      }
    }

    &--amount {
      & .field-root {
        .field-label {
          color: var(--balance-primary-light);
          text-transform: uppercase;
        }
        legend{
          text-transform: uppercase;
        }

        .input-root input {
          font-size: 1.5rem;
          height: 3.375rem;
          padding-top: 1.1rem;

          &::placeholder {
            font-size: 1.4rem;
            opacity: 0.5;
          }

          ::-ms-input-placeholder {
            /* Edge 12 -18 */
            font-size: 1.4rem;
            opacity: 0.7;
          }

          &:focus,
          &:active,
          &:hover {
            border: 1px solid color(primary, main);
          }
        }
      }

      &--currency {
        position: absolute;
        right: 1rem;
        font-size: 14px;
        top: 2.4rem;
      }
    }

    &--method {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 22px 0;

    .payment-method-field{
      label{
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px !important;
        text-transform: uppercase;
      }
      legend{
        text-transform: uppercase;
      }


    }

      @include media-sm {
        width: 100%;
      }

      @include media-xs {
        height: auto;
        width: auto;
        overflow: inherit;
        gap: 12px 0;

        .field-root .field-helper:empty {
          font-size: 0;
          margin-top: 0;
        }

        .select-root {
          .select-select {
            background:var(--balance--secondary-main);
            align-items: center;
            color: color(label, color);
          }
        }
      }

      .label {
        padding: .375em .5em;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_checked {
          background: var(--input-background-login);
        }

        & span {
          display: flex;
          align-items: center;
        }

        .withdrawal-method-field__img {
          width: 112px;
          max-height: 30px;
          margin-right: 18px;
          object-fit: contain;
        }

        .withdrawal-method-field__info {
          padding: .375em .5em;
          background: var(--deposit-withdrawal-payment-background);
          color: var(--default-light);
          border-radius: 4px;
          font-size: 12px;

          & .markdown-root {
            p {
              white-space: nowrap;
              margin: 0;
            }
          }
        }

        @include media-xs {
          height: auto;
        }

        .radio-default {
          width: auto;
          height: 34px;
          margin-right: 15px;

          svg {
            color: var(--default-light);
            border-radius: 50%;
            width: 17px;
            height: 17px;
          }
        }

        .radio-checked {

          .custom-checkbox {
            width: 16px;
            height: 16px;
            background: var(--primary-gold-light);
            border-radius: 50%;
            position: relative;
          }

          .custom-checkbox::after {
            content: '';
            width: 6px;
            height: 6px;
            background: var(--primary-dark);
            display: block;
            border-radius: 50%;
            position: absolute;
            top: 5px;
            left: 5px;
          }
        }

        .radio-checked+img+span.withdrawal-method-field__info {
          opacity: 1;
        }
      }
    }

    &--alert {
      height: 35px;
      margin: 0;
      color: color(button-accent, color);
      font-size: 14px;
      font-weight: 500;
      background: color(attention);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 5px 5px;
    }
  }

  &__notices {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    border-radius: 5px;
    margin: 0 0 20px 0;

    .field-helper {
      font-size: 0;
    }

    .group-root {
      color: color(notice, color);
      background: color(notice, background);
      padding: 10px 15px;
    }

    .error {
      color: var(--error-main);
    }

  }

  &__title {
    display: inline-block;
    margin: 25px 0 10px;
    padding-bottom: 2px;
    font-size: 16px;
    font-weight: $font-weight-alt;
    border-bottom: 6px solid var(--secondary-main);

    p {
      margin: 0;
    }

    &--method {
      margin-top: 0;
    }
  }

  &__sub-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0 5px;
    padding-bottom: 5px;
    font-size: 16px;
    border-bottom: 2px solid var(--secondary-main);

    strong {
      font-size: 20px;
    }

    .exchange {
      display: block;
      text-align: right;
    }

    p {
      margin: 0;
      font-weight: $font-weight-alt;
    }
  }

  &__fields-title {
    color: var(--balance-primary-light);
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
  }

  &__fields {
    gap: 24px 0;

    .withdrawal-form__field {
      max-width: 47%;
      flex-basis: 47%;

      label {
        text-transform: uppercase;
      }

      .field-root .input-root input {
        &::placeholder {
          font-size: 1rem;
          opacity: 0.5;
        }

        ::-ms-input-placeholder {
          font-size: 1.4rem;
          opacity: 1rem;
        }

        &:focus,
        &:active,
        &:hover {
          border: 1px solid color(primary, main);
        }

      }
    }

    .withdrawal-form__field--ibanAccountOwnerName {
      @include media-sm {
        max-width: 60%;
        flex-basis: 60%;
      }
    }

    .withdrawal-form__field--ibanBic {
      @include media-sm {
        max-width: 37%;
        flex-basis: 37%;
      }

    }

    .withdrawal-form__iban--ibanAccount {
      order: 3;
      position: relative;


      .field-root .input-root {
        input {
          -webkit-text-fill-color: var(--balance--secondary-main);
          &::placeholder {
            font-size: 1rem;
            opacity: 0;
          -webkit-text-fill-color: var(--balance--secondary-main);
          }

          ::-ms-input-placeholder {
            /* Edge 12 -18 */
            font-size: 1rem;
            opacity: 0.5;
          }

          &:focus,
          &:active,
          &:hover {
            border: 1px solid color(primary, main);
          }

        }
      }

      &--value {
        font-size: 14px;
        padding-left: 16px;
        position: absolute;
        top: 2.156rem;
        left: 0;
        pointer-events: none;
      }

      &--icon {
        position: absolute;
        top: 2.4rem;
        right: 1rem;

        &::before {
          @include svg-icon('custom');
          content: '\0037';
          font-size: 16px;
          color: #3DCC81;
        }
      }
    }

    .withdrawal-form__field--netellerAccountId {
      min-width: max-content;

      .field-root .input-root input {
        padding-right: 40px;
      }
    }
  }
}

.withdrawal-summary {
  &__bonuses-title {
    p {
      margin-bottom: 0;
      line-height: 1;
    }
  }

  &__deduct,
  &__receive {
    max-width: 45%;

    @include media-xs {
      max-width: 100%;
    }

    .group-root {
      p {
        margin: 0;
        line-height: 2;
        border-bottom: 1px solid #ccc;
      }

      strong {
        float: right;
      }
    }

    .exchange {
      display: block;
      text-align: right;
    }
  }
}

/** Bank account */
.withdrawal-form--1001 {
  @media (min-width: 480px) {
    .withdrawal-form__field {

      &--bankAccount,
      &--bankName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}

.withdrawal-modal__progress {
  margin-bottom: 24px;
  width: 100%;
  z-index: 4;
  position: relative;
}

/* **/

/** IBAN */
.withdrawal-form--1011 {
  @media (min-width: 480px) {
    .withdrawal-form__field {

      &--address,
      &--ibanAccount,
      &--ibanBankName,
      &--bankLocation,
      &--bankCity,
      &--beneficiaryCity,
      &--beneficiaryCountry,
      &--beneficiaryState,
      &--beneficiaryZipCode,
      &--beneficiaryStreet,
      &--beneficiaryHouseNumber,
      &--beneficiaryAddress,
      &--ibanBSB {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}

/* **/

.withdrawal-form__frame {
  width: 100%;
  height: 666px;
  border: none;

  &.loading {
    height: 0 !important;
    min-height: 0 !important;
  }

  &+.withdrawal-form__circular-progress {
    display: none;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }

  &.loading+.withdrawal-form__circular-progress {
    display: block;
  }
}

.modal-root {
  .modal-paper {
    .modalContentWrapper {
      position: relative;

      .modal-contentRoot {
        .withdrawal-form__back {
          position: absolute;
          top: 40px;
          left: 80px;
          display: flex;
          min-width: 0;
          min-height: 0;
          padding: 0;
          font-size: 14px;
          border: 0;
          background: none;
          overflow: hidden;
          transition: all 0.2s;

          .button-label {
            transform: translateY(-100%);
            transition: transform .2s;
          }

          &::before {
            @include svg-icon;
            content: '\0062';
            margin-right: 5px;
            font-size: 15px;
            transform: rotate(180deg);
          }

          &:hover {
            color: var(--secondary-main);

            .button-label {
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}

.revoke-confirmation-modal {
  .modal-paper {
    width: 550px;
  }
}

.withdraw {
  &-feeConfirm {
    .feeConfirm-head {
      margin-top: 24px;
      position: relative;

      @include media-sm {
        margin-bottom: 1em;
      }
    }
  }

  &-transactionStatus {
    .transactionStatus-head {
      position: relative;
    }
  }
}

.error-modal {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    position: relative;
    text-align: center;
    margin-bottom: 12%;
  }

  @include media-xs {
    margin-top: 0;
  }

  .withdrawal-form {
    @include media-xs {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.select-withdraw-method {
  ul li span{
    text-transform: uppercase;
  }

}
