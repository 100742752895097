.forgot-password {

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__info {
    font-weight: 400;
    font-size: 14px;
    color: var(--primary-light);

    p {
      margin: .5em 0 0 0;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @include media($size-sm) {
      .input-input {
        // redefinition
        background: var(--secondary-main);
      }
    }

    .email-forgot-password {

      .field-input .input-root {
        position: relative;

        &:hover:after {
          @include svg-icon('lvc');
        }

        &:after {
          content: '\006e';
          position: absolute;
          left: inherit;
          right: 15px;
          top: 15px;
          width: 14px;
          height: 14px;
          font-size: 14px;
          display: block;
          transition: none;
          transform: none;
          background: none;
          color: var(--default-main);
        }
      }
    }
  }

  &__submit {
    .button-primary {
      width: 100%;

      .button-label {
        padding-right: 5%;

        &:after {
          @include svg-icon('custom');
          font-size: .9em;
          content: '\0064';
          margin-left: 5px;
          padding-top: 3px;
        }
      }
    }
  }
}
