.home-page {

  &-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  & > div + div {
    margin-top: 32px;
  }

  &-jackpot-widget {
    h2 {
      margin-top: 0;
    }
  }

  .quick-deposit-form {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1000;
    opacity: 1;
    pointer-events: auto;
    background: rgba(var(--primary-dark), .7);
    transition: opacity $main-transition-time;

    @include media-md {
      display: none;
    }

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .group-root:not(:empty) {
      display: flex;
      padding: 20px;

      .grid-root:first-child {
        width: auto;
        margin-right: 15px;
      }
    }

    .payment-method-field {
      width: auto;

      label {
        display: none;

        + div {
          margin-top: 0;
        }
      }

      .select-selectMenu {
        span {
          display: none;
        }
      }
    }

    .input-valuesLabel,
    .input-values {
      display: none;
    }

    .field-inputValues {
      > label {
        display: none;
      }

      .input-root {
        margin-top: 0;
      }
    }

    .input-underline {
      padding: 8px 10px;
      background: var(--primary-light);

      &::before,
      &::after {
        right: 10px;
        bottom: 7px;
        left: 10px;
      }
    }

    .input-inputSingleline[type="number"] {
      max-width: 100px;
      height: 30px;
      padding: 0;
      font-size: 18px;
      text-align: center;
    }

    .field-helper {
      position: absolute;
      bottom: -14px;
      white-space: nowrap;

      &:empty {
        margin: 0;
      }
    }

    .button-accent {
      width: 100%;
      height: 46px;
      font-size: 20px;
    }

    .select-select {
      color: var(--primary-light);
    }

    .field-select label[data-shrink="true"] {
      color: var(--primary-light);
    }

    .select-icon {
      fill: var(--primary-light);
    }
  }

  .applied-country--swe {
    // #root
    [data-url="/group/all"],
    [data-url="/all-games"] {
      .quick-deposit-form {
        display: none;
      }
    }
  }

  .games-groups-container {
    position: relative;
    margin: 0;
    max-width: 100%;
    padding: 0;

    @include media-xs {
      gap: 16px;
      display: flex;
      flex-direction: column;
    }

    &:empty {
      &::before {
        content: attr(data-not-available);
        display: block;
        padding: 10px 0;
        background: rgba(var(--primary-dark), 0.5);
        color: #fff;
        font-size: 18px;
        text-align: center;
      }
    }

    &__button {
      @include media-xs {
        min-width: 120px;
        min-height: 35px;
        font-size: 10px;
        margin: 2px 0 16px;
        &:last-child {
          margin-top: 8px;
        }
      }
    }

    .game-list__buttons-panel {
      margin-top: 40px;

      @include media-xs {
        margin-top: 0;
      }
    }
  }

  #game-list-anchor {
    position: relative;
    top: -#{$header-collapsed-height};
  }

  .altenar-widget {
    + .choice-group-container {
      .choice-group-row:first-of-type {
        .choice-group-row__item:first-child {
          background: #FFCC33;
          color: var(--button-secondary-color);
        }
      }
    }
  }

  .home-page-section {
    + .choice-group-container {
      .choice-group-row:first-of-type {
        .choice-group-row__item:first-child {
          background: #FFCC33;
          color: var(--button-secondary-color);
        }
      }
    }
  }

  .game-list {
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-xs {
        margin-top: 20px;
      }

      @include media-sm {
        flex-direction: column;
        align-items: flex-start;
      }

      h2{
        margin: 0;
      }

      .home-menu__wrapper{
        margin: 0;
        padding-right: 0;
        gap: 0;

        @include media-sm {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
}
