.profile-account-form__field--ibanAccount {
  position: relative;
  .field-root .input-root {
    input {
      padding-top: 14px;

      &:not(:disabled) {
        -webkit-text-fill-color: var(--primary-light);
        &::placeholder {
          font-size: 1rem;
          opacity: 0;
          -webkit-text-fill-color: var(--primary-light);
        }
      }

      ::-ms-input-placeholder {
        /* Edge 12 -18 */
        font-size: 1rem;
        opacity: 0.5;
      }

      &:focus,
      &:active,
      &:hover {
        border: 1px solid color(primary, main)
      }
    }
  }

  .field-root:has(input:disabled) ~ &--value {
    display: none;
  }

  &--value {
    font-size: 14px;
    padding-left: 16px;
    position: absolute;
    left: 0;
    padding-top: 22.5px;
    top: 12px;
    bottom: 12px;
    pointer-events: none;
  }

  .field-root {
    .profile-account-form__field--ibanAccount--hidden {
     .input-inputSingleline:not(:disabled) {
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
