.playerVerificationStep2 {
  .playerVerificationIdenfyFailed {
    width: 380px;
    margin: 0 auto;
    text-align: center;

    .icon-custom-important {
      font-size: 52px;
      line-height: 52px;
    }

    &__header {
      margin-bottom: 4px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 42px;

      p {
        margin: 0;
      }
    }

    &__methods {
      background: var(--secondary-disabled-background);
      padding: 20px;
      border-radius: 12px;
      gap: 12px;

      .markdown-root {
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        border-bottom: 1px solid color(secondary, main);
        padding-bottom: 12px;
        margin-bottom: 12px;

        p {
          margin: 0;
        }

        strong {
          display: block;
          font-size: 16px;
          line-height: 24px;
          border-bottom: 1px solid color(secondary, main);
          padding-bottom: 12px;
          margin-bottom: 12px;
        }
      }

      .button-primary {
        width: 100%;

        i {
          margin-left: 8px;
          font-size: 12px;
          line-height: 12px;
          height: 12px;
        }
      }
    }
  }
}
