.game-provider-page {
  padding: 20px 10px 10px 10px;
  .games-filter__name {
    display: flex;
    align-items: self-start;
    margin-bottom: 20px;
    .input-before {
      width: 40px;
      padding: 10px;
      border: 1px solid color(select, background);
      border-right: 0;
      border-radius: 5px 0 0 5px;
      box-shadow: 1px 0 0 color(select, shadow-left), 2px 0 0 color(select, shadow-right);
      background-color: color(input-dark, background);
      text-align: center;
      .game-name-filter__icon::before {
        color: color(link, default);
      }
    }
    .input-inputSingleline {
      height: 41px;
      padding: 0 10px 0 20px;
      background: color(input-mobile, background);
      border-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    &:before,
    &:after {
      display: none;
    }
  }
  .game-list__empty {
    color: color(label, color);
    font-size: 12px;
    line-height: 1.5;
    text-transform: initial;
  }
}
