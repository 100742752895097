.how-to-start-modal {
  .modal-paper .modalContentWrapper .modal-contentRoot .how-to-start__wrapper.aspect-ratio-16x9 {
    padding-bottom: 56.25%;
  }
  &.iframe-aspect-ratio-16x9 {
    .modal-paper {
      .modalContentWrapper {
        .modal-contentRoot {
          position: relative;
          padding-bottom: 56.25%;
        }
      }
    }
  }
  .modal-contentRoot {
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: none;
    }
  }
}
