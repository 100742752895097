@import 'VegasTv';
@import 'Promotions';
@import 'Promotion';
@import 'Faq';
@import 'Press';
@import 'CookiePolicy';
@import 'TermsOfService';
@import 'LiveGames';

/* Layout */

.static-page {
  &__container {
    max-width: initial;
    margin: 0;
    padding: 0;

    .group-root {
      .static-content {
        .underlined-title {
          h1 {
            font-size: 40px;
          }
        }
      }
    }

    @include media-xs {
      padding: 0;
    }

    div.gray-wrapper {
      background: var(--promotion-background);

      p {
        color: var(--primary-light);
      }
    }

    article.lightgray-wrapper {
      background: var(--promotion-lightgray-background);
    }

    @include media-xs {
      div.banner img,
      div.content-block-sub-header img {
        max-width: 100%;
        height: auto;
        margin-top: -120px;
      }
      div.mobile-cover {
        height: 20vh;
      }
      div table {
        max-width: 100%;
      }
    }
  }
  &__menu {
    padding-right: 15px;

    @include media-md {
      display: none;
    }
  }
  &-content {
    .content-blocks {
      display: grid;
      gap: 24px;

      .content-block {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        padding: 0;
        gap: 12px;

        &-content {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          color: var(--balance-primary-light);

          &__header {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
          }

          &__text {
            font-size: 14px;
            line-height: 20px;
          }

          &__header,
          &__text {
            margin-top: 0;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
}

/* Promotions-static */

.promotions-static {
  text-align: center;
}

/* Other */

#static-page__anchor {
  position: relative;
  top: -75px;
}
