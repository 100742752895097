.jackpot-banner-egt {
  margin-bottom: 10px;

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include media-md {
      flex-flow: row wrap;
    }
    @include media-sm {
      display: flex;
      flex-direction: column;
      justify-content: left;
    }
  }
  &__box {
    position: relative;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;

    @include media-md {
      padding-right: 10px;
      padding-left: 10px;
    }
    @include media-sm {
      justify-content: center;
      flex: auto;
    }
  }
  &__box-value {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 2px solid var(--primary-light);
    background-color: var(--primary-main);

    &::before {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      left: -22px;
      background-repeat: no-repeat;
    }
  }
  &__title {
    display: flex;
    order: 3;
    flex: 0 0 auto;
    justify-content: center;
    height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;

    @include media-md {
      order: 1;
      flex: 0 0 100%;
    }
    a {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
  &__currency {
    padding-left: 5px;
  }
  &__box--I {
    order: 1;
    margin-right: 20px;

    @include media-md {
      order: 4;
      margin-right: 0;
      justify-content: flex-end;
    }
    @include media($size-md, $size-sm) {
      flex: 0 0 50%;
    }
    @include media-sm {
      order: 5;
      justify-content: center;
    }
    .jackpot-banner-egt__box-value {
      &::before {
        background-image: url('/cms/img/games/jackpot-banner/I.png');
      }
      @include media-md {
        width: 80%;
      }
      @include media-sm {
        width: 70%;
      }
    }
  }
  &__box--II {
    order: 2;
    margin-right: 20px;

    @include media-md {
      order: 4;
      justify-content: flex-start;
      margin-right: 0;
    }
    @include media-sm {
      order: 4;
      justify-content: center;
    }
    .jackpot-banner-egt__box-value {
      &::before {
        background-image: url('/cms/img/games/jackpot-banner/II.png');
      }
      @include media-md {
        width: 80%;
      }
    }
  }
  &__box--III {
    order: 4;
    margin-left: 20px;

    @include media-md {
      order: 1;
      margin-left: 0;
    }
    @include media($size-md, $size-sm) {
      flex: 0 0 50%;
    }
    @include media-sm {
      order: 2;
    }
    .jackpot-banner-egt__box-value {
      &::before {
        background-image: url('/cms/img/games/jackpot-banner/III.png');
      }
      @include media-md {
        width: 100%;
      }
      @include media-sm {
        width: 90%;
      }
    }
  }
  &__box--IV {
    order: 5;
    margin-left: 20px;

    @include media-md {
      order: 2;
      margin-left: 0;
    }
    @include media-sm {
      order: 1;
    }
    .jackpot-banner-egt__box-value {
      &::before {
        background-image: url('/cms/img/games/jackpot-banner/IV.png');
      }
      @include media-md {
        width: 100%;
      }
    }
  }
  &__info {
    position: absolute;
    top: 55px;
    z-index: 5;
    width: 155px;
    max-height: 0;
    font-size: 12px;
    opacity: 0;
    border: 2px solid var(--primary-light);
    background-color: color(info);
    pointer-events: none;
    transition: max-height .3s;

    &::before {
      content: '';
      position: absolute;
      top: -27px;
      left: 44%;
      display: block;
      border: 12px solid transparent;
      border-bottom: 15px solid color(info);
    }
    @include media-md {
      left: 33%;
    }
    @include media-sm {
      left: inherit;
    }
  }
  &__largest {
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    border-bottom: 1px solid;
  }
  &__largest-title {
    flex: 0 0 45%;
  }
  &__largest-time {
    flex: 0 0 55%;
    text-align: right;
  }
  &__largest-amount {
    padding-right: 3px;
  }
  &__win {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px 0 0;
    font-size: 15px;
  }
  &__number {
    display: flex;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid;
  }
  &__number-title {
    flex: auto;
  }
  &__last-winner {
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
  }
  &__last-winner-title {
    flex: 0 0 50%;
  }
  &__last-winner-time {
    flex: 0 0 50%;
    text-align: right;
  }
  &__number-title {
    display: inline-flex;
  }
  &__last-winner-user {
    flex: 0 0 100%;
    padding: 10px 0 5px;
    font-size: 12px;
  }
  &__box-value:hover + .jackpot-banner-egt__info {
    display: block;
    max-height: 700px;
    opacity: 1;
  }
}
.jackpot-banner-spinner {
  margin-bottom: 10px;
}
