.home-menu {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  text-align: center;
  transition: all $main-transition-time;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  .settings-menu__item-override--more {
    text-transform: capitalize;

    &:active {
      color: var(--primary-main);
    }

    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
    }
  }

  .more-container {
    position: absolute;
    width: 200px;
    z-index: 9999999999;
    display: block;
    background: color(select, background);
    border-radius: 5px;
    top: 52px;
    left: 0px;
    text-align: left;
    padding: 5px 0;

    a {
      color: var(--primary-main);
      padding: 3px 20px;
      width: 100%;

      &::before {
        box-shadow: none;
      }

      &:hover {
        background: rgba(0,0,0, 0.15);
      }
    }

    &--hidden {
      display: none;
    }
  }

  &.swiper-root {
    position: relative;
    overflow-x: hidden;

    .swiper-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 100%;
      gap: 10px;
    }

    .swiper-prev,
    .swiper-next {
      @include size(30px, 100%);
      position: absolute;
      z-index: 2;
      top: 0;

      &::before {
        @include svg-icon;
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        color: color(primary, accent);
        content: '\0062';
        font-size: 10px;
        transition: opacity $main-transition-time;
      }

      &.is-disabled {
        pointer-events: none;

        &::before {
          opacity: .1;
        }
      }
    }
    .swiper-prev {
      left: 0;
      background-image: linear-gradient(to right, var(--home-menu-swiper-from) 66%, var(--home-menu-swiper-to) 100%);

      &::before {
        transform: translate(-50%, -50%) rotate(180deg);
      }
    }
    .swiper-next {
      right: 0;
      background-image: linear-gradient(to left, var(--home-menu-swiper-from) 66%, var(--home-menu-swiper-to) 100%);

      &::before {
        transform: translate(-50%, -50%);
      }
    }
  }

  .settings-menu__item-override {
    transform: scale(1);
    transition: all $main-transition-time;

    a {
      position: relative;

      i {
        margin-right: 8px;
        line-height: 1;
        padding: 0;
        display: inline-block;
        font-style: normal;
        height: 12px;

        &:before {
          @include svg-icon('lvc');
        }
      }
    }

    &--all a {
      i:before {
        content: 'V';
      }
    }

    &--New a {
      i:before {
        content: 'd';
      }
    }

    &--table a {
      i:before {
        content: '6';
      }
    }

    &--megaways a {
      i:before {
        content: 'r';
      }
    }

    &--bonus_buy a {
      i:before {
        content: '#';
      }
    }

    &--promo a {
      i:before {
        content: 'y';
      }
    }

    &--providers a {
      i:before {
        content: 'Y';
      }
    }

    &--search-modal {
      margin-left: auto;

      .mobile & {
        margin-left: 0;
      }

      .button-root {
        min-width: 0;
        padding: 0;

        &:hover {
          background: none;

          .button-label {
            &::before {
              color: var(--primary-main);
            }
          }
        }
        .button-label {
          &::before {
            @include svg-icon;
            content: '\0059';
            font-size: 20px;
            font-weight: $font-weight-alt;
            transition: $main-transition-time;
          }
        }
      }
    }
    &--search {
      flex: 1;
      text-align: right;
      transform-origin: right;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 5;
    border: none;
    transition: all 0.3s;
    transform-origin: top;
    box-shadow: var(--header-box-shadow);
    margin: 0 0 30px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0 10px;
    padding-right: 10px;

    .game-provider-filter {
      position: relative;
      width: 268px;

      @include media-sm {
        width: 100%;
      }

      &-button{
        display: flex;
        justify-content: flex-start;
        width: 268px;

        @include media-sm {
          width: 100%;
        }

        &::after{
          @include svg-icon('custom');
          content: 'B';
          right: 0;
          margin-left: 10px;
          color: var(--game-provider-filter-button-icon);
          transition: transform .3s;
        }

        &.false{
          &::after {
            transform: rotate(180deg);
          }
        }

        &.open{
          border-radius: 8px 8px 0 0;
          &::after {
            transform: rotate(0deg);
          }
        }

        .button-label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-width: 0px;
          display: block;
          text-align: left;
        }
      }

      ::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      ::-webkit-scrollbar:vertical {
          width: 7px;
      }

      ::-webkit-scrollbar:horizontal {
          height: 7px;
      }

      ::-webkit-scrollbar-thumb {
          background: #23252F;
          border-radius: 4px;
      }

      &-popper {
        top: 0;
        width: 100%;
      }

      &-tooltip {
        @include scrollbar();
        width: 100%;
        max-height: 300px;
        overflow: auto;
        margin: 0;
        padding: 0;
        background: var(--secondary-main);
        opacity: 1;
        border-radius: 0 0 8px 8px;

        .game-provider-filter {
          display: flex;
          flex-direction: column;
          padding: 5px 0;

          .field-checkbox {
            margin: 0;

            label {
              flex-direction: row-reverse;
              justify-content: space-between;
              margin: 0;
              padding: 5px 5px 5px 15px;

              .checkbox-default {
                border: none;
                background: none;
              }

              .checkbox-checked {
                border: none;
                background: none;
                margin: 0;
              }

              .checkbox-checked::before {
                color: var(--balance-primary-light);
              }

              span {
                color: var(--primary-light);
                font-size: 12px;
                font-weight: 400;
                line-height: 1.5;
              }
            }
          }

          .field-helper {
            min-height: 0;
          }
        }

      }
    }

    .games-filter__name {
      margin: 0;
      width: 200px;
      display: flex;
      flex-direction: row-reverse;
      background: color(select, background);
      border-radius: 5px;
      height: 30px;
      align-items: center;
      flex-shrink: 0;

      .input-before {
        background: #211f22;
        height: 30px;
        width: 34px;
        border: 1px solid color(select, background);
        text-align: center;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        box-shadow: -1px 0 0 color(select, shadow-left), -2px 0 0 color(select, shadow-right);

        .game-name-filter__icon {
          position: relative;
        }

        .search::before {
          background: url('/cms/img/icons/search.png') no-repeat;
        }

        .reset::before {
          background: url('/cms/img/icons/reset.png') no-repeat;
          cursor: pointer;
        }

        .game-name-filter__icon::before {
          content: '';
          width: 17px;
          height: 17px;
          position: absolute;
          margin-top: 5px;
          margin-left: 5px;
          display: block;
        }
      }

      input::placeholder {
        font-size: 12px;
        line-height: 1.5;
      }

      input {
        font-size: 12px;
        line-height: 1.5;
        padding: 9px 15px;
        background: var(--input-dark-background);
      }

    }

    .games-filter__name::before, .games-filter__name::after {
      content: none;
    }
  }

  &__inner {
    position: relative;
    padding: 0;
    flex-grow: 1;
  }
  .button-root {
    i {
      margin-right: 8px;
    }
  }
  a, .button-root {
    padding: 8px 24px;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    line-height: 12px;
    display: flex;
    height: 28px;
    align-items: center;
    background-color:var(--button-link-bankground);
    min-height: 28px;

    &.active {
      background: var(--button-primary-background);
      color: var(--button-default-color);
      // background: var(--primary-main);
      // color: var(--primary-dark);

      &:hover {
        color: var(--primary-dark);
        background: var(--primary-gold-light);
      }

      &::before {
        color: var(--primary-main);
      }
    }

    &.game-search-button {
      flex: 1;

      &::before {
        @include svg-icon;
        content: '\0059';
        vertical-align: middle;
      }
      &::after {
        display: none;
      }
    }
  }
}
