/* Vegas Tv Page */

.vegas-tv-page {
  background: var(--secondary-disabled-background);

  .content-switcher-container {
    .title {
      i {
        margin-right: 10px;
      }
    }
  }

  @include media-xs {
    margin: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    gap: 48px;
    align-items: center;
    padding: 0 24px;

    @include media-md {
      flex-direction: column;
      align-items: center;
      padding: 0;
      gap: 22px;
    }
  }

  &__info {
    margin-bottom: 10px;
    width: 50%;

    img {
      margin-bottom: 30px;
    }

    .button-root {
      i {
        margin-right: 8px;
      }
    }

    @include media-md {
     width: 100%;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: var(--balance-primary-light);
    margin-bottom: 22px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media-sm {
      flex-direction: column;
      gap: 24px;

      .button-root {
        width: 100%;
      }
    }
  }

  &__video {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 12px;

    &--desktop {
      @include media-sm {
        display: none;
      }
    }

    &--mobile {
      display: none;
      margin-bottom: 22px;

      @include media-sm {
        display: block;
      }
    }

    @include media-sm {
      width: 100%;
    }
  }

  .typography &__schedule {
    font-size: 20px;
    line-height: 1.5;
    margin: 0 0 16px 0;
    gap: 12px;
    display: flex;
    flex-direction: column;
    color: var(--balance-primary-light);
    font-weight: 700;

    > div {
      display: flex;
      gap: 12px;

      &:before {
        @include svg-icon('lvc');
        font-size: 14px;
        line-height: 1;
        padding: 8px;
        color: var(--vergastv-icon-live-color);
        background: var(--vergastv-icon-background);
        border-radius: 100px;
        height: fit-content;
      }
    }

     > div:first-child {
      color: var(--balance-default-light);
       &:before {
         content: 'x';
       }
    }

    > div:last-child {
      &:before {
        color: var(--vergastv-icon-color);
        content: '~';
      }
    }
  }

  &.static-page-content .content-blocks {
    grid-template-columns: repeat(auto-fill, 250px);

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }

    &--hidden {
      display: none;
    }

    .content-block {
      background-color: transparent;

      @include media-sm {
        border: 0;
        padding: 0;
      }

      &-content {
        &__text {
          display: flex;
          flex-direction: column;
          gap: 4px;

          > span {
            color: var(--balance-staff-text);
            font-weight: 500;
          }
          > div {
            display: flex;
            gap: 6px;
            color: var(--primary-gold-light);
            flex-wrap: wrap;

            > span {
              line-height: 1;
              font-weight: 500;

              &:not(:first-child) {
                border-left: 1px solid var(--secondary-light);
                padding-left: 6px;
              }
            }
          }
        }
      }
    }

    @include media-sm {
      justify-content: center;
    }
  }

  &__about-games {
    &-section {
      position: relative;
      padding: 15px;
      margin: 0 -15px;
      background-color: color(promotions-card, background);
      margin-bottom: 30px;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid color(promotions-card, background);
        transform: translateX(-50%);
      }
    }

    &-title,
    &-description {
      text-align: center;
    }

    &-description {
      font-size: 14px;
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}
