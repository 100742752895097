@import './GdWindow';
@keyframes toggle {
  from {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.game-decorator {
  $bottom-indent-for-more-games-tail: 35px;

  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;

  .header {
    position: static;

    .header__free-spins--icon {
      display: none;
    }
  }
  &__demo-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    width: 100%;
    height: 80px;
    @include webp-bg-img('/cms/img/games/demo-banner.webp', '/cms/img/games/demo-banner.jpg');
    background: {
      color: var(--primary-dark);
      position: center;
      repeat: no-repeat;
      size: cover;
    }
    transition: all 0.4s ease;

    &.is-hidden {
      margin-top: -80px;
    }
    &-title {
      margin-right: 100px;
      font-size: 36px;
      color: #fff;
      font-weight: $font-weight-alt;
      text-shadow: 0 0 10px color(text, main);
      transition: all 0.4s;

      @include media-sm {
        margin-right: 30px;
        font-size: 20px;
      }
    }
  }
  &__content {
    display: flex;
    flex: auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;

    &.full-width {
      padding-bottom: 0 !important;
    }
  }
  &.game-decorator--has-error {
    .login {
      margin: 0;

      .container {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
    .limit-form {
      margin-bottom: 0;
    }
  }
  &:not(.game-decorator--has-error) {
    > .header {
      @include media-md {
        @at-root body:not(.desktop) & {
          display: none;
        }
      }
    }
    + .footer {
      padding-bottom: 42px;
    }
  }
  .aspectRatio-root {
    margin-right: auto;
    margin-left: auto;
    width: 100vw;
    z-index: 5;
    transition: 300ms ease;

    .aspectRatio-content {
      .center-content {
        overflow: auto;
        flex-wrap: wrap;
      }
      iframe.game__content {
        min-width: 100%;
        min-height: 100%;
        border: 0;
      }
    }
  }
  &__footer {
    padding: 19px;
    z-index: 10;
    background: var(--game-window-footer);

    .welcome--wrapper {
      position: relative;
      display: inline-block;
      width: 50%;

      .button-default {
        p {
          margin: 0;
        }
      }
      .welcome--content {
        position: absolute;
        bottom: 100%;
        left: 0;
        min-width: 160px;
        padding: 10px 16px;
        margin: 0 0 1px 0;
        white-space: nowrap;
        background-color: #2e2e2e;
        border-radius: 4px;
        display: none;

        &__title {
          position: relative;
          padding-right: 80px;
          font-weight: 300;
          text-transform: uppercase;
          font-size: 16px;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border-bottom: 1px solid #ccc;

          i {
            position: absolute;
            right: 0;
            cursor: pointer;

             &::after {
               font-family: 'FontAwesome';
               content: '\f021';
               font-style: normal;
             }
          }
        }

        &__value {
          float: right;
        }

        &__currency {
          font-size: 8px;
          text-transform: uppercase;
          margin-left: 5px;
        }
      }
    }

    button {
      min-height: 40px;
      border: none;

      i {
        margin-right: 6px;
        color: var(--primary-main);
      }

      &:hover:not(.button-disabled) {
        border: none;
      }
    }

    .button-accent {
      margin-right: 5px;

      &--real {
        width: 300px;

        i {
          margin: 0 6px 0 0;
        }
      }

      i {
        margin-left: 6px;
        color: inherit;
      }
    }

    .select-game {
      float: right;

      .select-root {
        width: 300px;

        .select-select {
          background: color(select, background);
          align-items: center;
          padding-right: 55px;
          display: flex;
        }
      }

      input {
        background: color(select, background)!important;
        color: color(label, color)!important;
      }
    }

    ::after {
      content: none;
    }
    &::before {
      height: 0;
    }
    &:not(.is-opened):hover {
      bottom: 20px;
    }
    &.is-opened {
      transform: translateY(0);

      .game-decorator__footer-toggle {
        top: -23px;
        max-height: 0;
        padding-bottom: 0;

        &::before {
          line-height: 0.7;
          transform: rotate(90deg);
        }
      }
    }
    .game-slider {
      &__nav-prev,
      &__nav-next {
        width: 35px;
      }
    }
    .game-thumbnail {
      &__title {
        font-size: 12px;
      }
      &__button--real {
        &::before {
          display: none;
        }
      }
      &__button--favourite {
        &::before {
          font-size: 24px;
        }
      }
    }
    .game-decorator__slider-no-games {
      color: color(text, light);
    }
  }
  &__footer-toggle {
    position: absolute;
    top: -42px;
    left: calc(50% - 50px);
    border: 1px solid #ccc;
    border-bottom: none;
    padding: 22px 10px 5px;
    min-width: 100px;
    max-height: 50px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    background: var(--gd-footer-background);
    cursor: pointer;
    overflow: hidden;
    transition: all 0.4s;
    animation: toggle 10s;
    z-index: 1;

    &::before {
      @include svg-icon;
      content: '\0065';
      position: absolute;
      top: 5px;
      left: calc(50% - 8px);
      width: 16px;
      color: var(--secondary-main);
      font-size: 16px;
      transition: top 0.3s;
      transform: rotate(-90deg);
    }
  }
  &__error-content {
    display: flex;
    flex: 1;

    // universal
    &--modal {
      align-items: center;

      & > .gridItem-root {
        background: color(modal, background);
        padding: 10px;
      }
      .gd-window__control-btn--close {
        margin-left: auto;
      }
    }

    &--hide-demo,
    &--login,
    &--documents,
    &--generic,
    &--prefill,
    &--verification,
    &--limits,
    &--account,
    &--sof,
    &--maxBalanceLimit,
    &--sessionTimeLimit,
    &--ibanCheck {
      margin: 20px auto 50px;
    }

    &--login {
      align-items: center;

      > div,
      > .gridItem-root {
        max-width: 800px;
      }

      .login {
        margin-bottom: 0;
      }

      .container .authentication {
        padding: 24px;
        border-radius: 10px;

        &__process {
          .login-form {
            .login__field, .field-label,
            .password__field .field-input .field-label {
              text-align: left;
            }
          }
        }
      }
    }

    &--generic {
      display: flex;
      align-items: center;
      margin: 0 auto;
      @include webp-bg-img('/cms/img/bg.webp', '/cms/img/bg.jpg');
      background: {
        position: center;
        repeat: no-repeat;
        size: cover;
      }

      .button-accent {
        margin-top: 20px;
      }
    }
    &--limits {
      .button-secondary {
        margin-bottom: 20px;
      }
    }
    &--account,
    &--sof,
    &--maxBalanceLimit,
    &--sessionTimeLimit,
    &--limits,
    &--ibanCheck {
      max-width: 800px;
      padding: 15px 15px 30px;
      background: var(--gd-error-background);
    }
    &--account,
    &--maxBalanceLimit,
    &--sessionTimeLimit,
    &--limits {
      > .gridItem-root {
        @include media(null, $size-md) {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
    }
    &--sof,
    &--ibanCheck {
      > .gridItem-root {
        @include media(null, $size-lg) {
          max-width: 66.6667%;
          flex-basis: 66.6667%;
        }
      }
    }
  }
  &__error-title {
    h1 {
      margin-top: 0;
      color: var(--primary-light);
    }
  }
}

body:fullscreen {
  .game-decorator {
    width: 100%;

    .game-decorator__header-fluid {
      .header__group-left {
        .language-selector {
          display: none;
        }
      }
      .header__group-right {
        .header__promotions {
          display: none;
        }
        .header__login {
          &:after {
            display: none;
          }
        }
      }
    }
    .game-decorator__content {
      align-items: center;
      padding-bottom: 0;
    }
    .game-decorator__footer {
      display: none;
    }
  }
}
.mobile,
.tablet {
  .game-decorator {
    &:not(.game-decorator--has-error) {
      min-height: auto;
      height: 100%;
    }

    &__game--mobile {
      height: 100%;

      .aspectRatio-root {
        width: 100vw;
        max-width: 100vw !important;
        height: 100%;
      }
      .aspectRatio-container {
        padding: 0 !important;
        width: 100%;
        height: 100%;
      }
      // ????????
      .prefill {
        max-width: 100vw;
      }
    }
  }
}
