.post-login-content-wrap--profile-close {
  h2 {
    display: none;
  }
  legend {
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--body-text-dark-color);
  }
  .button-secondary {
    margin: 30px 0 15px;
  }
}

.limit-form {
  padding: 24px;
  background: var(--primary-dark);
  border-radius: 12px 0 0 12px;

  @include media-sm {
    padding: 0px;
    background: transparent;
    margin-bottom: 24px;
  }

  &__content {
    display: flex;
    align-items: center;
    padding-right: 8px;

    @include media-md {
      flex-direction: column;
      align-items: start;
      padding-right: 0px;
    }

    h2 {
      flex-shrink: 0;
      margin-right: 36px;
    }

    p {
      font-size: 14px;
      color: var(--primary-light-header);
    }
  }
  &__actions {
    button {
      @include media-sm {
        width: 100%;
      }
      .button-label {
        i {
          margin-right: 8px;
        }
      }

    }
  }
}

.password-confirmation {
  .modal-paper {
    .modalContentWrapper {
      .modal-contentRoot {
        .required--children--empty {
          & .field-label {
            white-space: normal;
          }
        }
      }
    }
  }
}
