.take-snapshot-modal {
  .modal-paper {
    @include media-sm {
      margin: 0;
      max-height: 100vh;
      height: 100%;
      border-radius: 0;
      border: none;
    }

    .modalContentWrapper {
      @include media-sm-landscape {
        padding: 16px;
      }
    }
  }
  .modalContentWrapper {
    height: 100%;
  }
}
