.game-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5,minmax(0,1fr));
  grid-gap: 30px 16px;
  margin-bottom: 20px;

  body.is-ios & {
    @include media-md{
      margin-bottom: 35px;
    }

    @include media-xs{
      margin-bottom: 24px;
      grid-gap: 24px 16px;
    }
  }

  &--tournaments {
    .game-list {
      &__title {
        display: flex;
        align-items: center;
        gap: 18px;

        .group-root {
          display: inline-block;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
  }

  &--vegasTv {
    &--hidden {
      display: none;
    }
  }

  &--jackpot {
    .game-thumbnail {
      &__play {
        height: calc(100% - 77px);
        bottom: 77px;
      }
      &__button-overlay {
        &:first-child {
          border-left: none;
          padding-left: 0;
        }
      }
    }
  }

  .game-thumbnail + button {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin-top: 0;
    display: flex;
    align-items: center;
  }

  &__count {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    margin-left: 18px;
  }
  &__title-link {
    font-size: 12px;
    color: var(--primary-main);
    text-transform: none;
    &:hover {
      color: #302f30;
    }
  }
  &__empty,
  &__not-available {
    .games-not-found__container {
      width: auto;
      .games-not-found{
        background-image: var(--notfound-background-image);
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 8rem;
      }
      &__markdown{
        text-align: center;
      }
    }

    &--hidden {
      display: none;
    }
  }
  &__buttons-panel {
    /* load more games button */
    margin-top: 20px;
    text-align: center;
    grid-column: 1 / 6;
    @include media-md {
      grid-column: 1 / 5;
    }
    @media (max-width: 954px) {
      grid-column: 1 / 4;
    }
    @include media-sm {
      grid-column: 1;
    }
  }
  @include media(null, 2600px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @include media-md{
  grid-template-columns: repeat(4,minmax(0,1fr));
  }
  @media screen and (max-width: 954px){
    grid-template-columns: repeat(3,minmax(0,1fr));
  }
  @include media-sm {
    grid-template-columns: repeat(2,minmax(0,1fr));
  }

  &--favourite, &--similar {
    grid-template-columns: repeat(4, 1fr);
    grid-template-columns: repeat(4,minmax(0,1fr));

    @include media-lg {
      grid-template-columns: repeat(3, 1fr);
      grid-template-columns: repeat(3,minmax(0,1fr));
    }

    @include media-sm {
      grid-template-columns: repeat(1,minmax(0,1fr));
      gap: 24px;
    }

    .game-list__buttons-panel {
      grid-column: 1/5;

      @include media-lg {
        grid-column: 1/4;
      }

      @include media-sm {
        grid-column: 1;
      }
    }
  }

  &__button--more {
    &::after {
      @include svg-icon('custom');
      content: 'B';
      right: 0;
      margin-left: 10px;
      transform: rotate(90deg);
    }

    @include media-xs {
      width: 100%;
    }

    span {
      width: fit-content;
    }
  }

  &--jackpot, &--tournament {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    .game-list__buttons-panel {
      grid-column: 1 / -1;
    }

    @include media-xs {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
