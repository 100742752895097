.forgot-password {
  .container {
    max-width: 800px;
    margin-top: 15px;
    padding: 30px;
  }
  .button-accent {
    margin-top: 20px;
  }
  &__receive-email {
    padding-top: 30px;
    text-align: center;
  }
  &__sent-link {
    padding: 50px 0;
    text-align: center;
  }
}
