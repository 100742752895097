.welcome {
  color: var(--default-main);
  padding: 24px 20px;
  display: flex;
  justify-content: space-between;

  &-title {
    font-size: 14px;
    color: var(--primary-light);
    line-height: 1;

    p {
      margin: 0;
    }

    strong {
      margin-top: 8px;
      display: block;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
  }

  &-buttons {
    display: flex;
    gap: 10px;

    @include media-sm {
      @supports (-webkit-touch-callout: none) and (not(translate: none)) {
        display: grid;
        grid-template-columns: 1fr 1fr .1fr 1fr;
      }
    }

    .select-locale {
      height: 34px;

      .select-selectMenu {
        border: none;
        padding: 8px;
        border-radius: 50%;
        width: 34px;
        height: 34px;

        &:after {
          content: none;
        }

        .select-locale__flag {
          margin: 0;
          border-radius: 50%;
          width: 19px;
        }
      }
      .select-root::after, .select-root::before {
        content: none;
      }
    }

    &-limits, &-logout {
      border-radius: 50%;
      width: 34px;
      height: 34px;
      min-width: 34px;
      padding: 0;
      position: relative;

      &:before {
        @include svg-icon('lvc');
        position: absolute;
        font-size: 16px;
        top: 9px;
        left: 9px;
      }
    }

    &-limits {
      &:before {
        content: '3';
      }
    }

    &-separator {
      border-left: 1px solid var(--secondary-light);
      height: 34px;
    }

    &-logout {
      &:before {
        content: 'Z';
      }
    }
  }

  &-details {
      color: var(--home-menu-item-color);
      margin-bottom: 12px;
      background: color(settings-menu, background);
      padding: 10px 20px 10px 14px;
      border-bottom: 1px solid black;
      box-shadow: inset 0 -1px 0 var(--home-menu-icon-color);

      & > .group-root {
        padding-bottom: 5px;
        line-height: 1.5;
      }

      &--total {
        color: color(input, background);
      }

      &--count {
        float: right;

        p {
          margin: 0;

          strong {
            font-size: 8px;
            text-transform: uppercase;
            font-weight: normal;
          }
        }
      }

      &--currency {
        font-size: 8px;
        display: inline-block;
        margin-left: 3px;
      }

      .linearProgress-root {
        position: relative;

        .linearProgress-progressLine {
          width: 100%;
          height: 17px;

          svg {
            border-radius: 4px;
          }
        }

        .linearProgress-content {
          position: absolute;
          z-index: 10;
          line-height: 18px;
          text-align: right;
          color: color(button-accent, color);
          font-weight: 700;
          font-size: 12px;
          left: 5%;
        }
      }
    }
}
