.theme-switcher-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color:var(--default-light);
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
      font-size: 14px;
      line-height: 1;
      height: 14px;
    }

    &-left, &-right {
      &-active {
        color: var(--primary-light);
      }
    }
  }
  .theme-switcher {
    height: 0;
    width: 0;
    display: none;

    &-icons{
      display: flex;
      width: 100%;
      justify-content: center;

      .title-left, .title-right {
        cursor: pointer;
        padding-bottom: 20px;
        padding: 17px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .title-left{
        background-image: url('/cms/img/icons/light-theme.svg');
        width: 14px;
        height: 14px;
      }

      .title-right{
        background-image: url('/cms/img/icons/dark-theme.svg');
        width: 11px;
        height: 14px;
      }
    }

    &-label {
      cursor: pointer;
      text-indent: -9999px;
      width: 68px;
      height: 2px;
      display: block;
      position: relative;
      margin: 0 10px;
      background: var(--default-main);

      &:after {
        content: '';
        position: absolute;
        top: 0px;
        right: 0px;
        width: 34px;
        height: 2px;
        background: #{color(primary, gold-light)};
        transition: 0.3s;
      }

      &:active:after {
        width: 12px;
      }
    }

    &:checked + .theme-switcher-label:after {
      right: 0;
      transform: translateX(-100%);
    }
  }
}
