.account__communication {
  .post-login__title {
    margin-bottom: 24px;
  }

  .button-root {
    i {
      margin-left: 8px;
      line-height: 1;
    }
  }

  .field-checkbox {
    margin: 0;
  }

  .gridItem-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;

    @include media-sm {
      flex-direction: column;
    }
  }
  .field {
    width: 120px;
  }
  .tag {
    @include tag();
  }
}
