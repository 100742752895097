.content-transactions {
  @include transaction-table();

  &__noteInfo {
    width: fit-content;
    margin: 0px auto 0 0;

    @media (max-width: $size-lg){
      margin: 10px auto 0 0;
    }

    .noteInfo__text {
      font-size: 1.1em;
    }

    &:before {
      margin: auto 0;
      line-height: 1em;
    }
  }

  h3 {
    display: flex;
    gap: 48px;
    align-items: center;

    @include media-sm {
      flex-direction: column;
      gap: 12px;
      align-items: baseline;
    }
  }

  .content-switcher-container {
    .title {
      font-size: 14px;
      line-height: 1;
      font-weight: 500;
      height: 18px;

      i {
        margin-right: 10px;
      }
    }
  }
  .transactions {
    &__form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      margin-bottom: 24px;

      @include media-sm {
        .field-root:nth-child(1), .field-root:nth-child(2) {
          grid-column: 1/3;
        }
      }

      &-promo {
        grid-template-columns: repeat(3, 1fr);

        @include media-sm {
          .field-root:nth-child(2) {
            grid-column: 1/3;
          }
        }
      }
    }

    &__wrapper .container {
      overflow-x: auto;

      .group-root .custom-table .custom-table-body .custom-table-row .custom-table-cell .custom-table-cell--copy-value {
        &:active:before {
          color: var(--primary-light);
        }
      }
    }

    &-not-found {
      background-image: var(--notfound-background-image);
      background-position: center center;
      background-repeat: no-repeat;
      overflow: hidden;
      height: 8rem;
    }
  }
}

