.static-page-content.promotionDetailsPage {
  @include scrollbar();
  background-size: 100%;
  background-attachment: fixed;
  background-repeat: repeat;
  padding-bottom: 20px;

  @include media-sm{
    height: auto;
  }

  @include media-xs {
    margin: 0;

    .title {
      display: block;
    }
  }

  .mobile-only {
    display: none;

    @include media-xs {
      display: block;
    }
  }

  @include media-xs {
    padding: 0;
  }

  .content-blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;


    @include media-to-sm{
      max-height: none;
      overflow: unset;
    }

    @include media-xs {
      padding: 0;
    }

    .content-block {
      padding: 0 24px 24px 24px;
      margin: 114px 0 0 0;
      background-color: var(--primary-dark);
      border-radius: 0;
      gap: 24px;
      max-width: 768px;

      #root[data-url="/promotions/promo6"] #content-block-remain-of-time,
      #root[data-url="/promotions/1000spin"] #content-block-remain-of-time {
        visibility: hidden;
      }

      @media(max-height: 600px) {
        margin-top: 100px;

        .button-root {
          height: auto;
        }
      }

      @include media-sm {
        gap: 18px;
      }

      .action-buttons {
        display: flex;
        flex-direction: row;
        gap: 24px;

        .button-root {
          width: 100%;
          display: flex;
          justify-content: center;
          &__promotion-btn{
            background-color: var(--button-promotions-secondary);
            color:#f1f1f1;
            &.button-disabled {
              color: var(--default-light);
              background: var(--secondary-disabled-background);
              cursor: inherit;
            }

            &:hover:not(.button-disabled) {
              :not(.mobile) & {
                background-color: var(--secondary-light);
              }
            }
          }

          @include media-sm {
            height: auto;
            align-items: center;
          }
          @include media-xs {
            padding: 12px;
            white-space: nowrap;
          }

          i {
            margin-right: 8px;
            height: 14px;
          }
        }
      }

      &--summary {
        margin-top: 0;
        padding-bottom: 0;
      }

      .countdown-counter {
        display: flex;
        text-align: center;
        color: var(--balance-primary-light);
        position: relative;
        background: var(--secondary-main);
        border-radius: 0 8px 8px 0;
        font-size: 14px;
        line-height: 1;
        font-weight: 600;
        padding: 12px;
        margin-left: 39px;
        width: fit-content;

        span{
          color: var(--balance-primary-light) !important;
        }

        &:before {
          @include svg-icon('lvc-regular');
          content: '!';
          color: var( --balance--primary-dark);
          background: var(--primary-main);
          height: 38px;
          width: 38px;
          position: absolute;
          top: 0;
          left: -39px;
          border-radius: 8px 0 0 8px;
          line-height: 38px;
        }

        .countdown {
          position: relative;
          display: flex;
          flex-direction: row;

          &:not(:first-child) {
            margin-left: 6px;

            @include media-xs {
              margin-left: 5px;
            }
          }

          .value {
            display: flex;
            flex-direction: row;
            margin-right: 5px;

            @include media-xs {
              height: auto;
              margin: 0;
            }
          }
        }
      }

      &-sub-vegas_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        &:not(:has(#content-block-remain-of-time)) {
          justify-content: flex-end;
          display: none;
        }

        .btn.post-login-link {
          width: auto;
          margin: 0;
          display: none;
        }

        @include media-xs {
          padding: 10px 0 0 5px;
        }
      }

      &-sub-header {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: end;

        @include media-sm {
          flex-direction: column;
          align-items: inherit;
        }

        .column {
          display: flex;
          flex-direction: column;
          gap: 8px;

          @include media-sm {
            gap: 12px;
          }

          .title {
            color: var(--primary-light);
            font-size: 20px;
            line-height: 30px;
            font-weight: 700;
          }
        }

        img {
          border: 5px solid var(--secondary-disabled-background);
          border-radius: 10px;
          height: 110px;
          position: relative;
          margin-top: -15px;

          @include media-sm {
            height: auto;
            margin-top: -25px;
          }

          @media(max-height: 600px) {
            height: 100px;
            width: auto;
            margin: -25px auto 0 auto;
          }
        }

        p {
          min-height: 35px;
          padding: 5px 8px;
          margin: -5px 0 0 0;
          color: color(footer, background);
          font-size: 12px;
          font-weight: 300;
          line-height: 24px;
          text-transform: uppercase;
          letter-spacing: .5px;
          background: color(link, default);
          text-align: center;
        }
      }

      &-info {
        margin: 0 -24px;
        padding: 0;
        border-radius: 0 0 5px 5px;
      }

      @media only screen and (max-width: 600px) {
        &-info{
          height: 100vh;
          overflow: auto;
        }
      }

      >  .content-block-content:not(.content-block-info) {
        padding: 0;
      }

      &--summary {
        .content-block-header {
          margin-left: 8px;
          margin-right: 8px;
          padding: 11px 0 9px;
          margin-bottom: 2px;
          border-radius: 4px 4px 0 0;
          background: color(popup, summary-background);
          box-shadow: 0 1px 0 0 color(notice, hr), 0 2px 0 0 color(notice, background);
          color: color(notice, color);
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: .5px;

          h4 {
            display: block;
            padding-top: 1px;
            margin-top: 0;
            margin-bottom: 0;
            color: color(notice, color);
            font-size: 14px;
            font-weight: 300;
            line-height: 15px;
            text-transform: uppercase;
            letter-spacing: .5px;
          }
        }

        dl {
          width: 100%;
          display: block;
          margin: 0;
        }

        dl > dt {
          width: 45%;
          font-weight: 400;
          clear: left;
        }

        dl > dd {
          width: 55%;

          @include media-xs {
            width: 100%;
            margin-bottom: 15px;
          }
        }

        dl > dt, dl > dd {
          display: block;
          float: left;
          line-height: 1.5;
          margin-inline-start: 0;

          @include media-sm {
            float: inherit;
          }
        }
      }

      .content-block-content {

        &-head {
          display: none;

          @include media-xs {
            color: color(label, color);
            font-size: 12px;
          }
        }

        .content-block-details {
          text-align: left;
          padding: 0;
          height: auto;
          overflow: auto;
          font-size: 14px;

          max-height: none;
          overflow: unset !important;

          dt {
            color: var(--default-main);
          }

          dd {
            color: var(--primary-light);
            font-weight: 600;
            margin-left: 0;
            margin-bottom: 1rem;
          }

          .text-center {
            display: none;
          }

          @media (orientation: portrait) {
            @media(max-height: 914px) {
              max-height: 350px;
            }
            @media(max-height: 812px) {
              max-height: 300px;
            }
            @media(max-height: 786px) {
              max-height: 300px;
            }
            @media(max-height: 667px) {
              max-height: 195px;
            }
            @media(max-height: 629px) {
              max-height: 180px;
            }
          }
        }

        @media (orientation: portrait) and (max-height: 914px) {
          .content-block-details {
            max-height: fit-content !important;
          }
        }
      }

      &-footer {
        margin: 0 -20px;
        padding: 0 20px 30px;
        background-color: color(background, header);
        border-radius: 0 0 5px 5px;
        display: none;

        @include media-xs {
          padding: 10px 8px;
          margin: 0;
        }
        .content-block-up{
          color: var(--primary-light);
        }

        .content-block-up h4 {
          margin: 0 0 14px;
        }

        h4 {
          color: var(--primary-light);
          font-size: 18px;
        }

        ol {
          list-style-type: decimal;
          color: var(--primary-light);

          li::before {
            content: none;
          }
        }
      }

      &-text-trigger-button {
        cursor: pointer;
        width: 100%;
        display: block;
        text-align: center;
        position: relative;
        color: var(--active-text-color);

        &::before, &::after {
          content: ' ';
          position: absolute;
          left: 50%;
          top: calc(100% + 5px);
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: color(link, hover) transparent transparent transparent;
        }

        &::after {
          top: calc(100% + 5px - 2px);
          border-color: color(background, body) transparent transparent transparent;
        }

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }

        &.btn-close {
          &::before, &::after {
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent color(link, hover) transparent;
          }

          &::after {
            top: calc(100% + 5px + 2px);
            border-color: transparent transparent color(background, body) transparent;
          }
        }
      }

      .post-login-link {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
        background-color: var(--primary-main);
        color: color(background, body);
        padding: 13px 15px 11px;
        border-radius: 5px;

        @include media-xs {
          margin: 15px auto;
          padding: 9px 15px 8px;
          width: 80%;
        }
      }

      &-video-btn {
        margin: 20px auto;
        font-size: 14px;
        text-align: center;
        text-decoration: underline;
        display: none;

        @include media-xs {
          margin: 0 auto 10px auto;
          font-size: 12px;
        }

        &::before {
          font-family: 'Glyphter-custom';
          content: '\0049';
          display: inline-block;
          height: 24px;
          width: 36px;
          vertical-align: middle;
          font-size: 18px;
          margin-top: -3px;

          @include media-xs {
            content: none;
          }
        }
      }

      .btn {
        background-color: var(--primary-main);
        color: color(background, body);
        height: 50px;
        padding: 13px 15px 11px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 5px;
        text-transform: uppercase;
        display: none;

        @include media-xs {
          font-size: 14px;
          height: max-content;
          padding: 6px 10px;
        }
      }

      #content-block-text-trigger + .content-block-footer {
        display: none;

        @include media-xs {
          background: none;

          .text-center {
            display: none;
          }

          h4 {
            font-size: 12px;
            color: color(label, color);
          }

          p, ol, li {
            font-size: 10px;
            color: color(label, color);
          }

        }
      }

      #content-block-text-trigger:checked + .content-block-footer {
        display: block;
        background: var(--primary-dark);
        color: color(notice, color);
      }
    }
  }
}

.promotionDetailsPage__otherPromotionsTitle {
  display: inline-block;
  margin-left: 50%;
  padding: 5px 15px;
  background: color(background, header);
  color: var(--primary-main);
  font-size: 18px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transform: translateX(-50%);
  margin-top: 30px;

  @include media-xs {
    display: none;
  }
}

.is-logged-in {
   div .static-content-modal .modal-paper .modalContentWrapper .modal-contentRoot div .static-content .static-page-content .content-blocks .content-block .action-buttons .hide-for-post-login {
    display: none;
  }
}

.is-logged-out {
  div .static-content-modal .modal-paper .modalContentWrapper .modal-contentRoot div .static-content .static-page-content .content-blocks .content-block .action-buttons .hide-for-pre-login {
    display: none;
  }
}

.promotionDetailsPage__otherPromotionsBlock {
  @include media-xs {
    display: none;
  }
}

.promotionDetailsPage {
  &__modal {
    .modal-paper {
      padding: 0;
      margin: 0;

      @include media-sm {
        border: none;
        border-radius: 0;
      }

      .modalContentWrapper {
        padding: 0;
        margin: 0;
        border-radius: inherit;

        .modal-rootButtons {
          top: 24px;
          right: 24px;
          position: absolute;

          .modal-buttonRoot {
            background: var(--secondary-main);
            width: 44px;
            border-radius: 8px;
            padding: 22px;
          }
        }
      }
    }
  }
}
[class|="page-modal--id-promotions/"] {
  align-items: flex-start;
    @include media-sm {
      align-items: flex-start;
    }
}
