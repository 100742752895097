.jackpot-widget {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: initial;
  border-radius: 5px;

  .swiper {
    width: 100%;
    overflow: hidden;

    &-wrapper {
      display: flex;
      gap: 10px;
    }

    &-slide {
      flex: none;
      flex-shrink: 1;

      img {
        width: auto;
        height: 155px;
      }
    }
  }

  &__games {
    display: flex;
    gap: 24px;

    @include media-md {
      display: grid;
      gap: 10px 16px;
      grid-template-columns: 1fr 1fr;
    }
    @include media-xs {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__game {
    display: flex;
    max-height: 185px;

    @media (min-width: $size-lg) {
      max-height: none;
    }

    @include media-sm {
      width: 100%;
    }
  }

  &__title {
    font-size: 12px;
  }

  &__thumbnail {
    height: 100%;
    width: auto;
    max-height: 185px;
    border-radius: 12px;
    overflow: hidden;
    @media (min-width: $size-lg) {
      max-height: none;
    }
    @include media-sm {
      max-height: 155px;
    }

    img {
      @include size(100%);
    }
  }
  &__amount {
    z-index: 9;
    position: absolute;
    background: var(--primary-main);
    border-radius: 100px;
    color: var(--primary-text);
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 500;
    bottom: 12px;
    left: 5px;
    line-height: 1;
    display: flex;
    align-items: center;

    body.is-ios & {
      bottom: 40px;
    }

    i {
      margin-right: 6px;
    }
  }

  &__content {
    position: relative;
  }

  &__jackpot-button {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;
  }

  &__link {
    .jackpot-widget-tax-check {
      cursor: pointer;
    }

    a {
      text-decoration: underline;
      height: 185px;
      padding: 0;

      @include media-sm {
        height: 155px;
      }
    }
  }

  &__button{
    @include media-xs {
      width: 100%;
    }

    span{
      @include media-xs{
        width: fit-content;
      }
    }

    &::after {
      @include svg-icon('custom');
      content: 'B';
      right: 0;
      margin-left: 10px;
      transform: rotate(90deg);
    }
  }

  &__buttons-panel {
    /* load more games button */
    margin-top: 20px;
    text-align: center;
    grid-column: 1 / 6;
    @include media-md {
      grid-column: 1 / 5;
    }
    @media (max-width: 954px) {
      grid-column: 1 / 4;
    }
    @include media-sm {
      grid-column: 1;
    }
    @include media-xs {
      margin-top: 6px;
    }
  }
}

@media screen and (max-width: 954px) and (orientation: landscape) {
  .jackpot-widget__amount {
    bottom: 20px;
  }
}
