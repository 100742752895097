@mixin custom-position {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  //width: calc(100% - 440px);
  //height: calc(100% - 124px);

  @include media-sm {
    top: auto;
    //width: 100%;
    //height: 100%;
  }
}

.support-modal {
  @include custom-position();

  &-copy-input {
    margin: 24px 0;
    position: relative;

    &__copy-button {
      position: absolute;
      bottom: 1.1em;
      right: 1em;
      min-width: min-content;
      height: 1em;
      padding: 0;
      min-height: 1em;

      &:after {
        @include svg-icon('custom');
        content: '2';
        color: var(--default-light);
      }
    }
  }

  .modal-background {
    @include custom-position();
    @include media-sm {
      bottom: 0;
      height: calc(100% - 76px);
    }
  }

  .modal-paper {
    width: 420px;

    .modalContentWrapper {
      .modal-titleRoot {
        margin-bottom: 8px;
      }

      .modal-rootButtons {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 16px;
        height: 24px;
        background: none;

        .modal-buttonRoot {
          &:before {
            font-family: 'Glyphter-lvc-regular';
          }
        }
      }

      .modal-contentRoot {
        color: var(--primary-light);
        font-size: 14px;

        .field-root {
          .input-root:after {
            content: '';
          }
        }

        .button-primary {
          width: 100%;
          margin-top: 24px;

          i {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
