.page-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 22px;
  background: var(--static-page-background);
  background-size: cover;
  background-position-x: right;
  background-position-y: center;
  border: 1px solid var(--progress-background);

  @include media-sm {
    border-radius: 0;
    margin: -12px -19px 15px -19px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin: 0;

    @include media-sm {
      padding: 0;
    }
  }

  &__description p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 8px 0 0;
    color:var(--balance-primary-light)
  }
}
