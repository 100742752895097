.content-support, .contact-us {
  position: relative;

  & > *:not(.page-header__contact-us):not(.form-header),
  & .form-header > *:not(.help-title) {
    display: none;
  }

  &-success {
    margin: 0 auto;
    text-align: center;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-xs {
      height: auto;
      font-size: 13px;
      text-transform: inherit;
      text-align: left;
      align-items: start;
      background: color(success, default);
      color: #fff;
      border-radius: 5px;
      position: relative;
    }

    .button-root {
      @include media-xs {
        position: absolute;
        top: 0;
        right: 0;
        min-width: auto;
        color: inherit;
        opacity: 0.5;
      }
    }

    p {
      margin: 30px 0;
      font-size: 14px;

      @include media-xs {
        margin: 5px 0;
        font-size: 12px;
      }
    }

    strong {
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: var(--primary-main);

      @include media-xs {
        font-weight: 700;
        font-size: 13px;
        text-transform: inherit;
        color: #fff;
      }
    }

    button {
      padding: 13px 15px 11px;
      font-size: 16px;
      line-height: 1.5;
      margin: 15px 0;
    }
  }

  .group-root--buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    margin-top: 2em;

    @include media-sm {
      display: flex;
      flex-direction: column;
    }

    .clear {
      clear: both;
    }

    > .group-root {
      margin: 5px 0 15px 0;
      width: 60%;
      float: left;

      @include media-sm {
        margin: 0 0 10px 0;
      }
    }

    .is-opened {
      width: 100%;
      float: none;
    }

    .button-send {
      display: flex;
      justify-content: flex-start;

      i {
        margin-left: 8px;
        height: 14px;
      }
    }

    .button-accent, .button-default {
      width: fit-content;
      align-self: flex-end;

      @include media-sm {
        max-width: 100%;
        width: 100%;
        justify-content: center;
      }
    }

    .file-attach-button {
      width: 100%;
      border-radius: 5px;
      margin-right: 10px;
      padding: 0 16px;
      font-size: 14px;
      display: flex;
      justify-content: flex-start;


      &::before {
        font-family: 'Glyphter-custom';
        content: '\0048';
        position: absolute;
        top: 50%;
        left: 0;
        width: 45px;
        padding: 3px;
        border-right: 1px solid;
        transform: translateY(-50%);
        transition: background .3s;
        font-size: 16px;
      }

      &::after {
        font-family: 'Glyphter-custom';
        content: '\0042';
        position: absolute;
        top: 50%;
        right: 2px;
        width: 10px;
        height: 6px;
        display: block;
        margin-top: -3px;
        transition: all .3s;
        transform: rotate(90deg);
      }
    }

    .opened {
      background: color(input, background);
      border: 1px solid var(--file-attachments-border);
      border-bottom: 1px solid transparent;
      color: var(--file-attachments-border);
      top: 1px;
      border-radius: 5px 5px 0 0;

      @include media-sm {
        background: var(--primary-dark);
        border: none;
        color: var(--primary-main);
      }

      &::after {
        transform: rotate(0);
        top: calc(50% - 5px);
        right: 5px;
      }
    }

    .button-accent + .group-root {
      margin-bottom: 15px;
    }
  }

  .customize-decorator {
    &--header {
      background: none;
      text-transform: none;
      padding: 20px 0 10px 0;
    }

    &--content {
      max-width: 600px;
      margin: 0 auto;
      padding: 0 0 20px 0;

      .notification-error {
        color: color(select, background);
        justify-content: center;

        p {
          font-size: 16px;
          text-align: center;
        }
      }

      .title {
        // color: var(--primary-main);
        color: var(--contactus-title);
        text-align: center;
        font-size: 24px;
        position: relative;
        top: -30px;
      }

      .link-trigger {
        > div, > span {
          display: inline-block;

          p {
            margin: 0;
          }
        }

        > span {
          color: var(--primary-gold-light);
          cursor: pointer;
          margin-left: 5px;
          margin-bottom: 10px;

          &:hover {
            color: var(--secondary-main);
          }
        }
      }

      .field-label, .field-select label[data-shrink="false"] {
        font-size: 12px;
        color: color(label, unfilled);
      }

      // input[disabled] {
      //   opacity: 0.65;
      // }

      .help-section {
        font-size: 14px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0 0 35px 0;

          > li + li {
            margin-top: 10px;
          }

          li {
            .markdown-root, .markdown-root p {
              display: inline;
            }

            &::before {
              content: "\2022";
              font-size: 2em;
              max-height: 1em;
              line-height: 0;
              padding-right: 0.2em;
              position: relative;
              top: 4px;
              left: auto;
              color: var(--primary-main);
              background: none;
            }
          }
        }
      }
    }

  }

  .file-attach-button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    border-radius: 5px;
    margin-right: 10px;
    padding: 0 15px;
    font-size: 14px;
    background: var(--balance--secondary-main);
    border: none;

    &::after {
      font-family: 'Glyphter-custom';
      content: '\0076';
      position: absolute;
      top: 48%;
      right: 15px;
      transform: translateY(-50%);
      font-size: 14px;
    }
  }

  .files-container {
    &--wrapper {
      //background: color(input, background);
      border-radius: 0 3px 3px;

      @include media-xs {
        max-width: none;
      }

      @include media-sm {
        //background: var(--primary-dark);
        border: none;
        color: inherit;
      }
    }

    &--content {
      margin: 0 auto;
      display: flex;
      flex-direction: row;

      @include media-sm {
        flex-direction: column;
        align-items: center;
      }

      .imageField {
        &-root {
          margin-bottom: 5px;
          height: 136px;

          @include media-sm {
            height: auto;
          }
        }

        &-selected {
          .imageField-buttons {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            position: absolute;
            width: 100%;
            height: 100%;
            background: none;
          }
        }

        &-content {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--primary-main);
          text-transform: uppercase;
          z-index: 1;

          &::before {
            font-family: 'Glyphter-custom';
            content: '\0047';
            position: absolute;
            width: 33px;
            height: 33px;
            top: 20px;
            font-size: 33px;
            color: var(--primary-main);
          }
        }

        &-clear {
          background: var(--error-main);
          padding: 10px;
          border-radius: 100%;
          min-width: auto;
          min-height: auto;
          font-size: inherit;
          top: 16px;
          right: 16px;
          z-index: 2;

          span {
            width: 14px;
            height: 14px;

            svg {
              fill: white;
            }
          }
        }

        &-buttons {
          border-radius: 4px;
          height: 100%;
          background: var(--secondary-main);
          justify-content: center;
          align-items: center;
          display: flex;

          .fileField-root {
            display: flex;

            @include media-sm {
              top: auto;
              width: 100%;
            }
          }

          .fileField-button {
            color: var(--default-main);
            height: 70px;
            display: flex;
            align-items: end;

            p {
              margin: 0;
            }

            @include media-sm {
              color: inherit;
              height: auto;
            }

            &:hover {
              background: none;
              color: color(button-accent, hover);

              @include media-sm {
                background: none;
                color: inherit;
              }
            }

            &::before {
              font-family: 'Glyphter-custom';
              content: '\0047';
              position: absolute;
              width: 33px;
              height: 33px;
              top: 15px;
              font-size: 33px;

              @include media-sm {
                width: 20px;
                height: 20px;
                top: calc(50% - 10px);
                left: 15px;
                font-size: 20px;
                color: inherit;
              }
            }
          }
        }
      }
    }

    &--row {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
      width: 33%;
      padding: 5px;
      color: var(--default-main);

      @include media-sm {
        width: 100%;
        padding: 0;
      }

      .imageField-imageWrapper {
        height: 168px;

        img {
          width: 100%;
          height: 100%;
          filter: brightness(30%);
        }
      }

      label {
        text-transform: uppercase;
        margin-bottom: 5px;
      }
    }
  }

  .header-content {
    padding: 24px;
    margin-bottom: 24px;
    background: var(--footer-content-image);
    background-position-x: right;
    background-position-y: center;
    background-size: cover;
    border-radius: 12px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 8px 0 0 0;
    }

    span {
      color: var(--primary-light);
    }
  }

  .help-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
  }

  .form-header {
    &__description {
      font-size: 14px;
      margin: 0;
    }

    &__mandatory {
      display: flex;
      font-size: 12px;
      margin: 8px 0;

      p {
        padding: 8px;

      }

      span {
        display: inline-block;
        align-self: end;
        font-size: 30px;
        padding: 0;
        font-weight: 700;
      }
    }
  }

  .form-error {
    position: absolute;
    right: 0;
    top: 24px;
    border-radius: 100px;
    max-width: 328px;
    min-width: auto;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: var(--primary-light);

      &::before {
        font-family: 'Glyphter-custom';
        content: '\0078';
        margin-right: 6px;
        font-size: 14px;
      }
    }

  }

  .form-fields {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 24px;
    padding-right: 50px;

    @include media-md {
      flex-wrap: wrap;
      padding-right: 0;
    }
  }

  .select-container {
    margin: 12px 0;
  }

  .field-name {
    &:not(.field-disabled) {
      input {
        padding-right: 30px;
        position: relative;
      }

      &::after {
        @include svg-icon('custom');
        position: absolute;
        content: 'A';
        top: 55%;
        right: 10px;
        color: var(--default-main);
        font-size: 14px;
      }
    }
  }

  .field-email {
    &:not(.field-disabled) {
      input {
        padding-right: 30px;
        position: relative;
      }

      &::after {
        @include svg-icon('custom');
        position: absolute;
        content: '\0063';
        top: 55%;
        right: 10px;
        color: var(--default-main);
        font-size: 14px;
      }
    }
  }
}

.page-decorator__wrapper {
  @include media-sm {
    .contact-us {
      padding-bottom: 50px;

      .group-root--buttons {
        display: flex;
        flex-direction: column;
        margin: 0;

        .button-root {
          width: fit-content;
        }
      }

      .container {
        background: none;

        .link-trigger {
          > div, > span {
            display: inline-block;

            p {
              margin: 0;
            }
          }

          > span {
            color: var(--primary-main);
            cursor: pointer;
            margin-left: 5px;
            margin-bottom: 10px;

            &:hover {
              color: var(--secondary-main);
            }
          }
        }

        .title {
          box-shadow: 0 1px 0 0 #0f0f10, 0 2px 0 0 #272628;
          margin-bottom: 12px;
        }

        .select-container {
          padding: 0;
          position: relative;
        }

        .field-root {
          padding: 0 10px;

          label {
            color: color(label, color);
            font-size: 12px;
          }

          input, .select-root .select-selectMenu, textarea {
            background: color(select, background);
            color: color(label, color);
          }

        }

        .field-checkbox {
          margin: 0;
          padding: 0;

          .checkbox-default {
            width: 13px;
            height: 13px;
            border-radius: 2px;
          }

          .checkbox-checked::before {
            padding: 2px;
            font-size: 9px;
            margin: 0;
            color: #fff;
            background: blue;
            border-radius: 2px;
          }
        }

        .select-container {
          position: relative;

          .input-input--hint {
            top: calc(50% - 2px);
            right: 60px;
          }

          .input-input--hint + span {
            margin-top: -20px;
          }

          .select-root {

            .select-selectMenu {
              line-height: 23px;
            }

            .select-select {
              background: color(select, background);
              align-items: center;
              color: color(label, color);
            }
          }

          .form-control-hint-block {
            top: 34px;
            right: 58px;
          }
        }
      }
    }
  }
}





