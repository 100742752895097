.content-terms-of-service .mobile-page-theme .container {
  background: unset;
  padding: 0;
  .title {
    display: none;
  }
  #static-page__anchor {
    padding: 0;
  }
}
.terms-of-service {
  margin: 0 auto;

  h1, h2 {
    margin: 0 0 20px 0;
    font-size: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--primary-light);

    @include media-xs {
      font-size: 16px;
    }
  }
  h1 {
    @include media-xs {
      margin-top: 10px;
    }
  }
  h2 {
    @include media-xs {
      margin: 20px 0;
    }
  }
  p {
    margin: 15px 0 15px 0;
    font-size: 14px;
    color: var(--primary-light);
    letter-spacing: initial;
    line-height: 18px;
    @include media-xs {
      margin: 0 0 14px 0;
      font-size: 12px;
    }
  }
  strong {
    display: block;
    color: var(--primary-light);
    font-weight: 700;
  }
  a {
    &:hover {
      color: var(--button-hover-color);
    }
  }
}
