@mixin footer-adaptive {
  @media (max-width: 1382px) {
    @content;
  }
}
.main-decorator__footer {
  position: relative;

  &_padding-for-menu {
    padding-bottom: $height-mobile-sticky-menu;
  }
}
.footer {
  background: var(--body-background);;
  position: relative;
  margin: auto;
  color: color(footer, text);
  transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

  &__privacy-policy-links {
    background-color: var(--footer-privacy-links);
    padding: 1.5rem 0;

    @include media-sm {
      & > ol {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  &__content {
    min-height: 326px;
    padding: 48px 24px 0px 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-position-x: right;
    background-position-y: center;
    background-size: cover;
    gap: 0 25px;

    @include footer-adaptive {
      justify-content: space-between;
      gap: 25px;
    }
    .brands {
      width: fit-content;
      display: flex;
      flex-direction: column;
      gap: 20px 0;

      &__group {
        display: flex;
        gap: 24px;
        align-items: center;
        width: 100%;

        @include media-xs {
          justify-content: center;
        }
      }
      &__theme-switcher {
        display: none;

        @include media-sm {
          display: block;
        }
      }
      &__social-links {
        display: flex;
        gap: 12px;

        @include media-xs {
          position: absolute;
          top: -60px;
          left: 120px;
        }

        .bottom, .top{
          display: flex;
          gap: 5px;
          justify-content: center;
        }

        .bottom{
          gap: 11px;
        }

        .social-links_bg{
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 20px;
          height: 20px;
          max-height: 30px;
        }

        .facebook {
          background-image: var(--footer-brand-facebook);
        }
        .youtube {
          background-image: var(--footer-brand-youtube);
        }
        .instagram {
          background-image: var(--footer-brand-insta);
        }
        .threads {
          background-image: var(--footer-brand-threads);
        }

        img {
          width: 35px;
          height: 35px;
        }
      }
      .logo-vegas {
        display: flex;
        width: 91px;
        height: 19px;
        background-size: 91px 19px;
        background-image: url('/cms/img/logoPromotions.svg');
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        margin-right: 48px;

        body.light-theme & {
          background-image: url('/cms/img/logoPromotions-dark.svg');

          @include media-md {
            background-size: 78px 44px;
          }
        }
      }
    }
    .link-column {
      display: flex;
      width: 50%;

      @media (max-width: 1200px) {
        width: 100%;
      }

      @include media-xs {
        order: 2;
      }

      .link-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: start;
        width: 100%;

        @include media-sm {
          gap: 24px;
        }
        @include media-xs {
          flex-direction: column;
        }

        .split-column {
          @include media-sm {
            display: flex;
            flex-direction: row;
            gap: 95px;
          }
        }

        .split-column .services,
        .split-column .support,
        .contacts {
          &__title {
            font-size: 1rem;
            font-weight: 500;
            color: var(--footer-list-title);
          }
          &__title {
            p {
              margin-top: 0;
            }
          }
        }

        .split-column .support,
        .split-column .services {
          &__links {
            display: flex;
            flex-direction: column;
            color: var(--footer-list-item);
            font-size: 0.875rem;

            .live-chat-button {
              white-space: nowrap;
              cursor: pointer;
              margin-bottom: 0.75rem;
            }
            .items p {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-bottom: 0.6rem;
              margin-top: 0;

              & a {
                margin-bottom: 0.75rem;
                text-decoration: none;
              }

              & a:last-child {
                margin-bottom: 0;
              }

              & > * {
                color: var(--footer-list-item);
              }
            }

            a{
              color: var(--footer-list-item);
              text-decoration: none;
            }
          }
        }

        .contacts {
          &__phone-numbers p {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            color: var(--footer-list-item);
            font-size: 0.875rem;
            margin: 0;
            gap: 0.75rem;

            strong:nth-child(odd) {
              color: var(--default-main);
            }

            & strong:nth-of-type(2)::after {
              content: '';
              display: block;
              width: 100%;
              border-bottom: 1px solid var(--secondary-main);
              margin-top: 0.75rem;
            }

            & > * {
              font-weight: 400;
            }
          }
        }
      }

      .policy {
        &__title {
          font-size: 1rem;
          font-weight: 500;
          color: var(--footer-list-title);
          margin-bottom: 0.5rem;

          p {
            margin-top: 0;
          }
        }

        &__links {
          display: flex;
          flex-direction: column;
          color: var(--footer-list-item);
          font-size: 0.875rem;

          p {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;

            a {
              color: var(--footer-list-item) !important;
              margin-bottom: 0.75rem;
              text-decoration: none;
            }
          }
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 75px;
      margin: 0 auto;

      @include media-xs {
        order: 1;
        position: relative;
      }

      @media (max-width: 1200px) {
        padding-bottom: 30px;
        margin: 0px;
        flex-direction: row;
        width: 100%;

        @include media-xs {
          padding-bottom: 15px;
        }
      }

      &__languges {
        width: 9rem;
      }

      &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;
        width: 100%;

        @include media-xs {
          justify-content: space-between;
          margin-bottom: 0;
        }
      }
      &__theme-switcher {
        margin-top: 0;
        display: block;
      }

      &__note {
        max-width: 30.313rem;
        min-height: fit-content;
      }
    }

    @include media-sm {
      flex-direction: column;
      gap: 20px 0;
    }
  }

  .third-party-logos {
    &__gambleaware {
      img {
        max-width: 150px;
      }
    }
    &__ibas {
      img {
        max-width: 100px;
      }
    }
    &__gamstop {
      img {
        width: 130px;
      }
    }
    @include media-md {
      &__gambleaware {
        img {
          max-width: 120px;
        }
      }
      &__ibas {
        img {
          max-width: 80px;
        }
      }
      &__gamstop {
        img {
          width: 100px;
        }
      }
    }
  }
  &__bottom {
    padding: 0px 24px 0px 24px;

    @include media-xs {
      margin-top: 40px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.75rem;
      color: var(--default-main);

      &__info{
        display: flex;
        flex-direction: row;

        @include media-xs {
          flex-direction: column;
        }
      }

      &__license-text {
        text-align: center;
        margin-bottom: 0.75rem;

        strong {
          font-weight: 400;
        }

        @include media-sm {
          margin-bottom: 20px;
        }
        a {
          color: color(footer, link);

          &:hover {
            color: color(footer, link);
          }
        }
        p {
          margin: 0;
          line-height: 22px;
          text-align: left;
        }
      }

      &__version {
        font-weight: 500;
        color: var(--footer-content-version)
      }
    }

    .brands {
      &__license-logos {
        margin-right: 48px;

        &_mobile-width {
          width: max-content;
        }

        @include media-sm {
          width: 100%;
        }

        @include media-xs {
          margin-bottom: 24px;
          display: flex;
          gap: 24px;
        }

        .logo-icon {
          min-height: 48px;
          display: block;
          float: left;
          position: relative;
          overflow: hidden;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;

          &__nq {
            background-image: var(--footer-licence-nq);
            width: 39px;
          }
          &__auditors {
            background-image: var(--footer-licence-auditors);
            width: 57.6px;
          }
          &__hunguard_hun {
            filter: grayscale(100%);
            background-image: var(--footer-licence-hunguard_eng);
            width: 105px;
          }
          &__hunguard_en {
            background-image: var(--footer-licence-hunguard_eng);
            width: 110px;
          }
        }
      }
    }
  }
}
