.user-balance {
  position: relative;
  background: color(settings-menu, shadow);

  .hr {
    margin-top: 5px;
    box-shadow: 0 -1px 0 0 color(select, shadow-left), 0 -2px 0 0 color(select, shadow-right);
  }

  .currency {
    font-size: 8px;
  }

  &__toggle {
    //padding: 0 10px 10px;
    padding: 7px 10px;

    strong {
      color: #fff;
    }

    &:hover {
      cursor: pointer;
    }

    &::after, &::before {
      transition: all .4s ease .1s;
      content: ' ';
      position: absolute;
      right: 10px;
      //top: 7px;
      top: 12px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 0 6px;
      border-color: var(--primary-main) transparent transparent transparent;
    }

    &::after {
      //top: 6px;
      top: 10px;
      border-color: color(settings-menu, shadow) transparent transparent transparent;
    }
  }

  &__popup {
    padding: 7px 10px;
    position: absolute;
    z-index: 11;
    background: color(select, background);
    width: 100%;
    transition: all .4s ease .1s;
    opacity: 0;
    visibility: hidden;
    margin-top: -35px;

    dl {
      margin: 0;

      dt, dd {
        float: left;
        width: 50%;
        margin: 0;
        padding: 3px 0;
        line-height: 1.5;
        min-height: 24px;
      }

      dd {
        text-align: right;
      }
    }

    .linearProgress-root {
      position: relative;

      .linearProgress-progressLine {
        width: 100%;
        height: 17px;

        svg {
          border-radius: 2px;
        }
      }

      .linearProgress-content {
        position: absolute;
        z-index: 10;
        line-height: 18px;
        text-align: right;
        color: color(button-accent, color);
        font-weight: 700;
        font-size: 12px;
        left: 5%;
      }
    }
  }
}

.show-details {

  .user-balance {

    &__toggle {
      &::after, &::before {
        transform: rotate(180deg);
      }

      &::after {
        top: 14px;
        //top: 8px;
      }

    }

    &__popup {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
    }
  }
}
