.push-notifications-modal {
  &__buttons {
    gap: 0 20px;
    margin-top: 20px;
  }
  &_hidden {
    &.modal-root {
      .modal-paper {
        display: none;
      }
    }
  }
}
