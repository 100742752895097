@mixin casino-header {
  background: color(casino, main);
  box-shadow: color(casino, box-shadow);
  background-image: linear-gradient(to bottom, color(casino, gold-light) 0%, color(casino, main) 50%, color(casino, gold-light) 50%, color(casino, gold-light) 100%);

  @include media-md {
    padding: 13px 10px;
    box-shadow: none;
    background-image: none;
    background: color(casino, main);
  }

  @include media-md-landscape {
    .header__container .header__group-right,
    .header__container .header__group-center,
    .header__container .header__group-left {
      padding: 0;
    }

    .header {
      padding: 16px 20px 16px 20px;
    }

    .header__container .header__group-right {
      order: 0;
      margin-left: auto;
      padding-right: 0;
    }

    .aside-menu-closed.aside-menu-mode-mobile {
      right: 430px;
      z-index: -1;
    }

    .header__group-right .header-menu__item {
      min-width: 0;
      padding-left: 5px;
    }

    .header__group-right .header__registration {
      display: none;
    }

    .header__container .header__group-left {
      flex: 0;
    }

    .aside-menu-closed.aside-menu-mode-mobile > .drawer-paperAnchorDockedLeft {
      transform: translateX(-100%);
    }

    .main-content .aside-menu-mode-mobile {
      position: fixed;
      right: 0;
      left: 0;
    }

    .drawer-paper .aside-menu {
      width: 100%;
      overflow-y: visible;
    }

    .aside-menu-panel__hot-links .group-root > * {
      width: 100%;
    }

    .aside-menu-panel {
      overflow-y: auto;
      max-height: 300px;
      padding-bottom: 95px;
    }
  }

  // Buttons
  & .button-accent {
    background: color(casino, dark-light);
    color: color(text, grey);

    &:hover {
      background: color(casino, dark-light);
      color: color(casino, main);
    }
  }

  // Menu links
  .header-menu__item {
    color: color(casino, dark);

    &:hover {
      color: color(casino, light);
    }

    @include media-md {
      & .button-label::before {
        color: color(casino, dark);
      }
    }
  }
}
@mixin betting-header {
  background: color(betting, main);
  box-shadow: color(betting, box-shadow);
  background-image: linear-gradient(to bottom, color(betting, green) 0%, color(betting, main) 50%, color(betting, green) 50%, color(betting, green) 100%);

  @include media-md {
    padding: 13px 10px;
    box-shadow: none;
    background-image: none;
    background: color(betting, main);
  }

  // Buttons
  & .button-accent {
    background: color(betting, gold);
    color: color(betting, dark-light);

    &:hover {
      background: color(betting, gold);
      color: color(betting, light);
    }
  }

  // Menu links
  .header-menu__item {
    color: color(betting, light);

    &:hover {
      color: color(betting, dark);
    }

    @include media-md {
      & .button-label::before {
        color: color(casino, light);
      }
    }
  }

  // Balance
  .balance__button {
    background-color: #fff;
    color: #111;
    overflow: hidden;
    height: 45px;

    & .balance__item {
      &:before {
        color: color(betting, green);
      }
    }

    & .caret {
      border: 0;
      height: 43px;

      &:after {
        background-color: transparent;
        color: color(betting, green);
      }
    }
  }
}
@mixin button-mobile {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.header {
  position: sticky;
  height: $header-desktop-height;
  top: 0;
  right: 0;
  left: 0;
  z-index: 25;
  opacity: 1;
  background: var(--primary-dark);

  a {
    font-weight: 500;
  }

  @include media-sm {
    height: $header-mobile-height;
    padding: 16px 20px 16px 20px;
  }

  &__container {
    position: relative;
    display: flex;
    height: 100%;
    margin: initial;
    padding: 0;
    max-width: initial;

    .header-menu-page-content {
      @include media-sm {
        display: none;
      }

      .static-header-menu {
        display: flex;
        gap: 12px;
        align-items: center;

        .button-root {
          display: flex;
          align-items: center;
          color: var(--primary-light-header);

          @media (max-width: 1470px) {
            width: auto;
            min-width: auto;

            span {
              display: none;
            }
          }

          i {
            height: 14px;
          }

          &:hover {
            color: var(--button-hover-color);
          }

          &.active{
            color: var(--button-active-color);
          }
        }


        .button-root:not(.button-small) {
          gap: 12px;
        }

        .button-root.button-small {
          gap:8px;
        }
      }
    }

    @include media-md {
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    @include media-sm {
      justify-content: flex-end;
    }
  }
  &__group-left {
    display: flex;
    gap: 12px;
    align-items: center;
    order: 1;
    flex: 1;

    @include media-sm {
      order: 3;
      flex: 0;

      @supports (-webkit-touch-callout: none) and (not(translate: none)) {
        margin-left: 12px;
      }
    }

    .button-secondary:not(.button-small) {
      height: 50px;
      text-transform: none;
    }

    .button-secondary {
      &.active {
        color: var(--button-active-color);
        background-color: var(--button-active-background);
      }

      @include media-sm {
        display: none;
      }
    }

    .header-link {
      color: var(--default-main);
      background: #2D2F30;
      font-weight: bold;
      padding: 0 20px;
      height: 50px;

      &:before {
        @include regular-vegas-separator;
        box-shadow: -1px 0 0 #000,-1px 0 0 var(--home-menu-icon-color);
      }
      &:hover {
        color: var(--primary-main);
        background: none;
      }
      &:active {
        color: var(--primary-main);
      }
      @include media-md {
        display: none;
      }
    }
    .header__games {
      order: 2;
    }
    .header__promotions {
      order: 5;
    }
    .header__vegas-tv {
      order: 4;
    }
    .header__betting {
      order: 1;
    }
    .header__live-games-page {
      order: 3;
    }
    .header__how-to-start {
      order: 7;
    }
  }

  &__group-center {
    display: flex;
    align-items: center;
    width: 251px;
    justify-content: start;
    order: 0;

    @include media-md {
      width: auto;
    }

    @include media-sm {
      position: absolute;
      left: 0;
      background: none;
    }
  }

  &__group-left, &__group-center, &__group-right {
    padding: 24px;

    @include media-sm {
      padding: 0;
    }

    @include media-md {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__group-center {
    @include media-md {
      padding-left: 24px;
    }
    @include media-sm {
      padding: 0;
    }
  }

  &__group-right {
    @include media-md {
      padding-right: 24px;
    }
    @include media-sm {
      padding: 0;
    }
  }

  &__group-right {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #fff;
    order: 2;

    @include media-sm {
      order: 2;
      gap: 12px;
    }

    @include media(null, $size-md) {
      order: 3;
    }
  }
  &__games, &__promotions, &__betting, &__registration {
    position: relative;
    padding-left: 40px;

    &::before {
      @include svg-icon();
      position: absolute;
      left: 16px;
      top: 20px;
    }
  }

  &__games:before {
    content: "J";
  }

  &__promotions:before {
    @include svg-icon('lvc');
    content: "g";
  }

  &__betting:before {
    content: "1";
  }

  &__registration:before {
    content: "a";
    top: 14px;
  }

  &__login {
    position: relative;
    padding-right: 40px;

    @include media-sm {
      padding-right: 0;
      width: 44px;
      min-width: 44px;
    }

   &:after {
     @include svg-icon();
     position: absolute;
     right: 16px;
     content: "m";
     top: 16px;

     @include media-xs{
       top: 50%;
       transform: translate(-60%, -50%);
       right: 0;
     }
   }
  }

  &__help {
    width: 76px;
    min-width: 76px;
    height: 30px;
    text-transform: none;
    padding: 8px 8px 8px 26px;
    border-radius: 100px;
    line-height: 14px;
    min-height: 30px;

    @include media-sm {
      display: none;
    }

    &::before {
      content: "?";
      left: 10px;
      color: var(--secondary-main);
      background: var(--default-main);
      border-radius: 50%;
      width: 14px;
      height: 14px;
      display: block;
      position: absolute;
    }
  }
  &__registration {
    @include media-xs {
      height: 32px;
      width: 118px;
      padding: 0;

      &::before{
        display: none;
      }
    }
  }
  &__search {
    display: none;

    @include media-xs {
      display: flex;
    }
  }
  @include media-xs {
    .header__search,
    .header__login {
      @include button-mobile;
      min-width: auto;
      position: relative;

      &:before {
        @include svg-icon;
        position: absolute;
        top: 9px;
      }
    }
    .header__search {
      .button-label:before {
        content: "\0059";
        font-weight: 700;
      }
    }
  }
  &__logo {
    display: flex;
    width: 155px;
    height: 33px;
    background-image: url('/cms/img/logoPromotions.svg');
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    margin-left: 16px;

    body.light-theme & {
      background: url("/cms/img/logoPromotions-dark.svg") center no-repeat;

      @include media-md{
        background-size: 78px 44px;
      }
    }

    @include media-md {
      width: 78px;
      background: url('/cms/img/logoPromotions.svg') center no-repeat;
      background-size: 78px 44px;
    }

    @include media-sm {
      margin: 0;
      width: 78px;
      height: 44px;
    }
  }
  & &__menu,
  & &__home {
    @include button-mobile;
    position: relative;
    background: var(--burger-background);
    padding: 0;
    height: 32px;
    min-height: 32px;
    border: 1px solid var(--burger-border);

    &::before {
      @include svg-icon;
      content: '\0076';
      position: absolute;
      color: var(--primary-light);
    }

    @include media-md {
      min-width: auto;
    }
  }
  .menu-opened {
    @include media-sm {
      background: var(--secondary-light);
      color: var(--primary-light);
      border: 1px solid var(--secondary-light);

      &::before {
        content: 'O';
        font-size: 12px;
      }
    }

  }
  & &__home {
    display: none;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    .button-label::before {
      content: '\0078';
    }
    @include media-md {
      display: flex;
    }
  }
  & &__casino {
    margin-right: auto;
    padding-left: 0;

    .button-label::before {
      content: '\005e';
    }

    &--live {
      .button-label::before {
        content: '\005d';
      }
    }
  }
  & &__verification {
    margin-right: auto;
    padding-left: 0;

    .button-label::before {
      content: '\0061';
      color: var(--error-main);
    }
  }
  & &__deposit {
    @include media-md {
      display: none;
    }
    .button-label::before {
      @include svg-icon('custom');
      content: '\0044';
      padding-right: 10px;
    }
  }
  & &__withdraw {
    position: relative;

    &::after {
      @include svg-icon('custom');
      content: '\0043';
      right: 0;
      margin-left: 10px;
      transform: rotate(-90deg);
    }

    @include media-xs {
      & {
        width: 32px;
        min-width: 32px;
        min-height: 32px;
        height: 32px;
        padding: 8px 16px;
        order: 2;
        font-size: 0;

        &::after {
          margin-left: 0;
          font-size: 14px;
        }
      }
    }

    @media (min-width: 481px) and (max-width: 1250px) {
      & {
        width: 44px;
        min-width: 44px;
        order: 2;
        font-size: 0;

        &::after {
          margin-left: 0;
          font-size: 14px;
        }
      }
    }
  }

  &__icon-links-group {
    display: flex;
    justify-content: center;
    margin-left: auto;
    padding-left: 18px;

    .button-root {
      padding-right: 5px;
      padding-left: 5px;
    }
    @include media-xs {
      display: none;
    }
  }
  & &__promotions--icon .button-label::before {
    content: '\0056';
    margin-top: -7px;
  }
  & &__free-spins--icon .button-label::before {
    content: '\006a';
  }
  & &__tournaments {
    margin-left: auto;

    @include media-md {
      padding-right: 0;
    }

    .button-label::before {
      content: '\006e';
    }
  }

  &__timer {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    transition: all .3s;

    &-area {
      padding: 8px 0;
      color: var(--primary-light);

      &-flex{
        display: flex;
        flex-direction: column;
        align-items: center;

        & .session-time{
          font-size: 12px;
          color: color(text, main);
        }
      }

      .is-logged-in & {
        cursor: help;
      }
    }

    .session-time__title {
      display: inline-block;
      font-size: inherit;
      color: color(text, main);
      margin-right: 4px;

      &:first-letter {
        text-transform: uppercase;
      }

      &:after {
        content: ':';
      }
    }
  }

  @include media-sm {
    & &__casino,
    & &__tournaments,
    &__verification,
    &__promotions {
      .button-label {
        font-size: 0;

        span {
          font-size: 0;
        }
        &::before {
          font-size: 20px;
        }
      }
    }
  }
}
