.promotion-card {
  &__body {
    position: relative;

    img {
      border-radius: 12px;
      width: auto;
    }

    #root[data-url="/my/current-bonuses"] & img {
      width: 100%;
    }
  }

  &__footer {
    .button-root {
      i {
        margin-right: 8px;
      }
    }
  }

  &__title {
    @include truncate;
    margin: 12px 0 4px 0;

    #root[data-url="/my/current-bonuses"] & {
      white-space: break-spaces;
    }
  }

  &__content {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;

    em {
      font-style: normal;
    }
  }

  &__terms-link {
    display: block;
    margin-bottom: -5px;
    padding: 7px 10px 6px;
    font-size: 12px;
    text-align: center;
    color: color(promotions-card, link);
  }

  &__condition {
    .markdown-root {
      em {
        font-size: 8px;
        font-style: normal;
      }
      p {
        line-height: 1.7;
      }
    }
  }

  p {
    margin: 0;
  }
}
