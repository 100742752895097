/* Generated by Glyphter (http://www.glyphter.com) on  Thu Oct 05 2023*/
@font-face {
  font-family: 'Glyphter-light';
  src: url('../fonts/Glyphter.eot');
  src: url('../fonts/Glyphter.eot?#iefix') format('embedded-opentype'),
       url('../fonts/Glyphter.woff') format('woff'),
       url('../fonts/Glyphter.ttf') format('truetype'),
       url('../fonts/Glyphter.svg#Glyphter') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-light-']:before{
  display: inline-block;
   font-family: 'Glyphter';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
  }
  .icon-light-stars:before{content:'\0041';}
