.transactionStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  gap: 1rem;
  @include media-sm{
   padding: 0;
   margin-top: 2rem;

  }

  &__fileIcon {
    margin-bottom: 3rem;
    margin-left: -3.1rem;

    @include media-sm{
      display: flex;
      justify-content: center;
      margin-left: 0rem;
    }


    &::before {
      @include svg-icon('custom');
      content: '\0036';
      position: absolute;
      color: var(--balance-primary-light);
      font-size: 50px;
      transition: all $main-transition-time;
    }
  }

  &__hint {
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    color: var(--balance-primary-light);
    @include media-sm{
     margin-top: 0.5rem;
    }

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__info {
    font-size: 1rem;
    color: var(--balance-primary-light);
    background: var(--deposit-modal-content-background);

      @include media-sm {
       text-align: center;
       margin-top: -5px;
    }
  }

  &-button__done {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    margin-top: 2rem;

    .button-label {
      display: flex;
      flex-direction: row;
    }
  }
}