@mixin snapshot-button {
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  padding: 0;

  &:before {
    @include svg-icon('lvc-regular');
    color: var(--default-main);
    font-size: 14px;

    &:hover {
      @include svg-icon('lvc');
    }
  }
}

.other-documents-form {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 10rem;
  overflow: auto;
  max-height: calc(100dvh - 184px);

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-sm {
    max-height: 75vh;
  }

  &__form {

    .imageField-root {
      .imageField-buttons {
        position: relative;
        display: inline-flex;
        width: 100%;

        .snapshotField-root {
          display: flex !important;

          .snapshotField-button {
            padding: 0;
            background-color: var(--balance--secondary-main);
            border-radius: 8px;
            height: 44px;

            span:first-child {
              display: none;
            }

            &:before {
              @include svg-icon('lvc-regular');
              color: var(--default-main);
              content: '\004b';
              font-size: 16px;
              position: absolute;
              right: 3.3rem;
            }
          }
        }

        .fileField-root {
          .fileField-button {
            position: absolute;
            top: -1rem;
            right: 0;
            background-color: var(--balance--secondary-main);
            padding: 0.1rem;
            margin-top: 1rem;
            border-radius: 8px;
            width: 1rem;
            max-width: 1rem;
            min-width: 2.2rem;
            height: 44px;

            span label {
              border-left: 1px solid #4C4E4F;
              height: 1.1rem;
              margin-top: 0.9rem;
              margin-left: -0.25rem;
            }

            span div p {
              display: none;
            }


          }
        }

      }

      .imageField-content {
        .file-size-hint {
          position: absolute;
          z-index: 1;
          font-size: 14px;
          top: 0.65rem;
          left: 1rem;
        }

        .snapshot-icon {
          @include snapshot-button;
          position: absolute;
          font-size: 16px;
          z-index: 1;
          top: 0.8rem;
          right: 2.9rem;

          &:before {
            content: 'K';
          }

        }

        .other-documents-form__name {
          display: none;
        }
      }
    }

    .imageField-selected {
      .imageField-content {
        .file-size-hint {
          display: none;
        }
      }
    }

    .iban-form {
      &--hint {
        color: var(--balance-primary-light);
        font-size: 14px;
        font-weight: 400;
        margin-top: 1rem;
        text-align: left;
        margin-bottom: 1.5rem;

        em {
          display: block;
          margin-top: 1rem;
          font-style: normal;

        }

        &--2 {
          margin-bottom: 0;
        }
      }

      &--hint-2,
      &--hint-3 {
        text-align: left;
        font-size: 14px;
        color: var(--balance-primary-light);
        margin-bottom: 1rem;
      }

      &--hint-2 {
        p:nth-child(2) {
          margin-bottom: 5px;
        }

        ul {
          margin: 0;
          padding-left: 1rem;
        }
      }

      &--hint-3 {
        margin-bottom: 2rem;

      }

      &--img {
        width: 9rem;
        background-image: url(cms/img/verification/ibanVerify.png);
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: 4rem;
      }


      &--buttons {
        padding: 1.5rem;
        border: 1px solid #4C4E4F;
        border-radius: 12px;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        .button-secondary {
          position: relative;
          color: var(--footer-list-title);
          display: inline-flex;
          align-items: center;
          border: 1px solid var(--footer-list-title);
          background-color: transparent;
          max-width: 5.6rem;

          .icon-custom-help::after {
            @include svg-icon('custom');
            content: '\0038';
            font-size: 16px;
            padding: 0 0.7rem 0 0.25rem;
          }

          &:hover {
            border: 1px solid var(--footer-list-title);
            background-color: transparent;
          }

        }
      }

      &__next-button {
        display: flex;
        padding: 15px 20px;
        border-radius: 8px;
        font-size: 14px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-decoration: none;
      }

      .profile-account-form__field--ibanAccount {
        margin-top: 1rem;

        .field-input {
          label {
            text-align: left;
          }
        }
      }
      .profile-account-form__field--ibanAccountOwnerName {
        text-align: left;
        margin-top: 1rem;

      }

      .profile-account-form__field--ibanBic {
        text-align: left;
        margin-top: 1rem;
      }

    }

    @include media-xs {
      flex-basis: none;
    }
  }

  &__confirm-button {
    margin-top: 20px;
  }

  .field-helperError,
  .imageField-helper {
    text-align: center;
  }

  .field-helperError {
    margin-bottom: 10px;
  }

  &__reset {
    margin: 1rem;
  }

  &__images {
    display: flex;
  }

  &__image-stacker {
    flex: 1;
    min-height: 200px;
    perspective: 900px;
  }

  &__image-stacker-item {
    z-index: 1;
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.3s;
    transform-origin: bottom;

    &:hover {
      &~.other-documents-form__image-stacker-item {
        transform: rotateX(-60deg);
      }
    }
  }

  &__image-item {
    &:not(.galleryItem-clone) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: none !important;
    }

    &.galleryItem-clone {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__max-file-count {
    color: #c5c5c5;
  }

  &__buttons {
    display: flex;
    gap: 2em;
    justify-content: space-between;
    width: 100%;

    p {
      display: none;
    }

    button {
      width: 100%;
      margin-top: 1.5rem;
    }
  }

  &__confirmation {
    margin-bottom: 20px;
  }

  .imageField-root {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .fileField {
    &-button {
      padding: 13px 0;
      margin: 13px 0;

      p {
        margin: 0;
      }

      @include snapshot-button();

      &::before {
        // @include svg-icon;
        // content: '\002a';
        // margin-right: 5px;
        // font-size: 16px;
        content: '$';
      }

      >span:first-child {
        width: auto;
        color: color(text, light);
        text-transform: none;
        font-weight: $font-weight-base;
        text-decoration: underline;
      }

      &:hover {
        background: none;

        span {
          text-decoration: none;
        }
      }
    }
  }

  &__confirmation-text {
    text-align: left;
    padding-left: 20px;
    margin-top: 1rem;
  }

  &__restart {
    position: absolute;
    top: 28px;
    left: 28px;
    display: flex;
    min-width: 0;
    min-height: 0;
    padding: 0;
    font-size: 14px;
    border: 0;
    background: none;
    overflow: hidden;
    transition: all 0.2s;

    &:before {
      content: 'b';
      display: inline-block;
      font-family: 'Glyphter';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      text-transform: initial;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-right: 5px;
      font-size: 15px;
      transform: rotate(180deg);
    }

    .button-label {
      transform: translateY(-100%);
      transition: transform .2s;
    }

    &:hover {
      color: #339999;
      background: none;

      .button-label {
        transform: translateY(0);
      }
    }
  }

  .imageField {
    &-selected {
      .imageField-imageWrapper {
        position: relative;
        align-self: center;

        &::before {
          @include svg-icon;
          content: '\0043';
          position: absolute;
          font-size: 20px;
          top: 5px;
          left: 5px;
          color: #161819;
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #FFCC33;
          border-radius: 99px;
        }
      }
    }

    &-helper {
      margin: 13px 0 0px;
    }

    &-image {
      max-width: 100%;
      max-height: 300px;
    }
  }

  .galleryItem-delete {
    background: #fffc;
    color: #000;
    z-index: 1;
    right: 8px;
    top: 8px;
    position: absolute;
    animation: none;

    @include media-sm {
      top: 40px;
      right: 16px;
    }

    &:hover {
      background: #000c;
      color: #fff;
    }
  }
}
