.content-switcher-container {
  display: flex;
  flex-direction: row;

  .title {
    color: var(--primary-disabled-color);
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
      font-size: 14px;
      line-height: 1;
      height: 14px;
    }

    &-left, &-right {
      &-active {
        color: var(--balance-primary-gold-light);
      }
    }
  }
  .content-switcher {
    height: 0;
    width: 0;
    display: none;

    &-label {
      cursor: pointer;
      text-indent: -9999px;
      width: 32px;
      height: 18px;
      display: block;
      border-radius: 100px;
      position: relative;
      border: 1px solid var(--primary-gold-light);
      margin: 0 10px;

      &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        background: var(--primary-gold-light);
        border-radius: 90px;
        transition: 0.3s;
      }

      &:active:after {
        width: 12px;
      }
    }

    &:checked + .content-switcher-label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
}
