.reality-check-modal {

  .modal-paper {
    width: 35em;

    .modalContentWrapper {
      height: 100%;
      min-height: auto;

      .modal-rootButtons {
        position: absolute;
        top: 24px;
        right: 24px;
        background: transparent;
        width: fit-content;
        height: fit-content;

        .modal-buttonRoot:before {
          @include svg-icon('custom');
          content: 'e';
        }
      }

      .modal-titleRoot {
        margin-bottom: 0.667em;
      }

      .field-root {
        margin-bottom: 24px
      }
    }
  }

  &__descriptor {
    margin-bottom: 2em;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: var(--balance-primary-light);
    }

    &-addon {
      display: inline;
      padding-right: 5px;

      p {
        display: inline;
        margin: 0;
      }
      & + .button-default {
        min-height: 0;
        padding: 0;

        &::after {
          bottom: 0;
          width: 100%;
        }
      }
    }
  }

  .field-disabled .input-disabled {
    .input-input {
      background: var(--body-background);
    }

    &:after {
      display: none;
    }
  }

  .input-root:before {
    @include svg-icon('custom');
    position: absolute;
    content: '\0067' !important;
    top: 14px;
    right: 16px;
    left: auto;
    color: var(--default-main);
    background: none;
    transform: none;
    transition: none;
    font-size: 14px;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;

    @include media-xs {
      flex-direction: column;
    }

    & > * {
      width: 100%;
    }

    .button-label:after {
      @include svg-icon('custom');
      font-size: .9em;
      margin-left: 5px;
    }

    .signOut {
      font-size: 14px;
      .button-label:after {
        content: '\0068';
      }
    }
    .keepPlaying {
      font-size: 14px;
      .button-label:after {
        content: '\0069';
        padding-top: 3px;
      }
    }

    @include media-xs {
      margin: 0;
    }

    .button-default {
      background: color(popup, summary-background);
    }

    button,
    button + button {
      padding: 13px 15px 11px;
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }
}
