.protection {
  position: relative;
  max-width: 950px;
  margin: 4% auto;
  border-radius: 12px;
  @include media-xs {
    width: 100%;
    overflow: hidden;
    margin: 0;

    &__login-form-wrapper,
    &__content-wrapper {
      width: 50%;
      display: block;
      padding: 10px;
    }

    &__login-form-wrapper {
      display: none;

      .protection__wrapper.animation & {
        display: block;
      }
    }

    &__wrapper {
      position: relative;
      display: flex;
      width: 200%;
      margin-left: 0;
      transition: margin-left .4s ease;

      &.animation {
        margin-left: -100%;
      }
    }
  }
  &__image {
    @include webp-bg-img('/cms/img/bg-new.webp', '/cms/img/bg-new.jpg');
    padding: 25.7% 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: {
      color: #161616;
      repeat: no-repeat;
      size: 100% auto;
    }
  }
  &__content {
    position: relative;
    padding: 40px 5% 20px;
    background-color:  var(--protection-background-login);
    text-align: center;
  }
  &__title {
    margin: 0 0 20px 0;
    line-height: 1.2;
    font-size: 32px;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--protection-list-title);
    @include media-xs {
      padding: 0 15px;
      font-size: 23px;
    }
  }
  &__soon {
    margin: 0 0 20px 0;
    line-height: 1.2;
    font-size: 27px;
    letter-spacing: 0.05em;
    color: var(--protection-soon);
    text-transform: initial;
    @include media-xs {
      font-size: 20px;
    }
  }
  &__text {
    margin: 0 0 10px 0;
    font-size: 13px;
    line-height: 19.5px;
    letter-spacing: normal;
    color: var(--protection-text);
    @include media-xs {
      margin-bottom: 0;
    }
  }
  &__login-form {
    opacity: 0;
    position: absolute;
    top: 5%;
    left: 50%;
    width: 200px;
    margin: 4% 0 0 -120px;
    padding: 20px;
    border-radius: 5px;
    background-color:  var(--modal-background-login);
    box-sizing: content-box;
    transition: opacity 1s ease-in-out 2s;
    z-index: 2;
    &:hover,
    &:focus {
      opacity: 1;
      transition: opacity 0.5s ease-in-out 0s;
    }
  }
  &__login-form_mobile {
    @include media-xs {
      position: initial;
      width: 100%;
      margin: 0;
      padding: 15px;
      opacity: 1;
      box-sizing: inherit;
      background-color:var(--protection-background);
    }
  }
  &__login-form-text {
    margin: 0 0 10px 0;
    font-size: 13px;
    text-align: left;
    line-height: 15.6px;
    letter-spacing: normal;
  }
  &__refresh {
    background-color:var(--protection-background);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    text-align: center;
  }
  &__refresh-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 23px;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    &::before {
      @include svg-icon('custom');
      content: '\004b';
      padding-right: 10px;
    }
    @include media-xs {
     font-size: 20px;
    }
  }
  &__languages {
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__language-wrap {
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color:  var(--modal-background-login);;
    padding: 3px;
    &:last-of-type {
      margin-left: -10px;
    }
  }
  &__language {
    display: inline-block;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin: 5px;
    overflow: hidden;

    img {
      @include size(100%);
    }
  }
  &__language.active{
    z-index: 1;
    outline-style: solid;
    outline-color: #e2a415;
    outline-offset: 3px;
    outline-width: 2px;
  }
}
