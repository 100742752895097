.select-locale {
  .select-icon {
    right: -15px;
    display: none;
  }

  &__flag {
    flex: 0 0;
    max-width: none;
    margin-right: 10px;

    @include media-sm {
      width: 24px;
    }
  }

  &__lang-name {
    margin-right: 10px;

    @include media-sm {
      display: none;
    }
  }

  .select-selectMenu {
    .select-locale__item:hover {
      border-left-color: transparent;
    }
  }

  .field-select {
    & > div {
      &::before {
        display: none;
      }
      &:hover {
        &::before {
          display: none;
        }
      }
    }
  }

  .select-focused {
    border: none;

    &::before,
    &::after {
      display: none;
    }
  }

  ul {
    padding-bottom: 5px;
  }

  &__selector {
    .select-menuPaper {
      margin-left: -5px;
      min-width: auto !important;
      background: var(--select-menu-background);
      width: 200px;

      li {
        padding: 0;
        color: var(--primary-main)!important;

        .select-locale__lang-name {
          display: block;
          width: 100%;
          margin: 0;
          padding: 5px 10px;
        }

        .selected-locale {
          color: var(--select-item-hover-color);
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }

  .field-root {
    display: inline-flex;

    .field-helper {
      display: none;
    }

    .radioGroup-root {

      &:after {
        content: '';
        cursor: pointer;
        text-indent: -9999px;
        width: 116px;
        height: 1.5px;
        display: block;
        position: relative;
        margin: 0 10px;
        background: var(--default-main);
        top: 5.6px;
        left: -12px;
        transition: 0.3s;
      }

      span {
        height: auto;
        padding: 0;
        width: auto;
      }

      label {
        width: 58px;
        flex: inherit;
        padding-bottom: 4px;

        .formControlLabel-label {
          padding: 0;
          border: none;
          overflow: hidden;

          .select-locale__flag {
            margin: 0;
            height: 20px;
            width: 20px;
            border-radius: 50%;
          }

          .select-locale__label {
            text-transform: uppercase;
            color: var(--footer-list-item);
            font-weight: 500;

            .select-locale__lang-name {
              margin-left: 8px;
            }
          }
        }

        &:active:after {
          width: 12px;
        }

        .radio-checked + .formControlLabel-label {
          &:after {
            content: '';
            position: absolute;
            bottom: -7px;
            left: -1px;
            width: 59px;
            height: 2px;
            background: #{color(primary, gold-light)};
            transition: 0.3s;
          }
        }
      }
    }
  }
}
