.empty-decorator {
    &__header {
      display: block;
      padding: 16px 20px;
      background: #000;

      img {
        height: 44px;
      }
    }

    &__content {
    .container {
      &-flex {
        display: flex;
      }
      &-page {
        width: 100%;
        padding: 0 64px;

        .stepper-wrap {
          @include media-sm {
            padding: 16px 20px;
          }
        }

        @include media-sm {
          padding: 0 16px;
        }

        @include media-xs {
          padding: 0;
        }
      }
    }
  }
}
