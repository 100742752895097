.winner-list-widget {
  @include media-sm {
    display: none;
  }

  overflow: hidden;
  &__placeholder {
    opacity: 1;
    transition: all 0.3s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__content {
    text-align: center;
    background-position: center;
    background: var(--winner-list-background);margin: 0 24px;
    background-size: contain;
    background-color: #F1F1F105;
    padding: 12px;
    border-radius: 10px;

    body.light-theme & {
      background-color: var(--winner-list-background);
    }

    .swiper-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .swiper-slide {
      margin: 0 24px;
      width: 250px;
    }
  }
  &__image {
    position: relative;
    overflow: hidden;
    &--loaded {
      .winner-list-widget__placeholder {
        opacity: 0;
      }
    }
    .image-img {
      width: 72px;
      object-fit: cover;
      height: 100px;
      border-radius: 10px;
      margin-right: 12px;
    }
    .image-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      font-weight: $font-weight-alt;
      position: relative;
      width: 131px;
    }
  }
  &__amount {
    position: relative;
    font-size: 14px;
    color: var(--winner-list-amount-color);
    padding: 3px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: left;

    span {
      margin-right: 2px;
    }
  }
  &__created-time {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    text-align: center;
    font-size: 12px;
    color: var(--balance-primary-light);
    font-weight: 400;
  }
  &__user {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    text-align: center;
    font-size: 12px;
    color: var(--balance-primary-light);
    font-weight: 400;
  }
  &__title {
    font-size: 20px;
    margin-bottom: 5px;
    padding: 3px;
  }
  &__game-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    padding: 0;
    width: 100%;

    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0px;
      display: block;
      width: fit-content;
      margin: 6px 0px 12px 0px;
      font-size: 16px;
      color: var(--balance-primary-light);
    }
  }
  &__game-link {
    text-decoration: none;

    &.button-root {
      min-width: auto;
      height: auto;
      padding: 0;
      line-height: initial;
      color: var(--balance-primary-light);
      font-size: 16px;
      font-weight: 700;
      width: 100%;
      display: flex;
      justify-content: flex-start;

      &:hover {
        text-decoration: none;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0px;
        display: block;
        width: fit-content;
        margin: 6px 0px 12px 0px;
      }
    }
  }
}
