@import 'styles/fonts.scss';
@import 'styles/vars.scss';
@import 'styles/common.scss';
@import 'styles/notifications.scss';
@import 'styles/forms/buttons.scss';
@import 'styles/forms/inputs.scss';
@import 'styles/tables.scss';
@import 'styles/post-login-common.scss';
@import 'styles/typography.scss';
@import 'styles/ui.scss';
@import '../cms/public/fonts/glyphter/Glyphter.css';
@import '../cms/public/fonts/glyphter-custom/Glyphter.css';
@import '../cms/public/fonts/glyphter-lvc/Glyphter.css';
@import '../cms/public/fonts/glyphter-lvc-regular/Glyphter.css';
@import '../cms/public/fonts/glyphter-light/Glyphter.css';

@import 'decorators/Header/Header';
@import 'decorators/Footer/Footer';
@import 'decorators/Footer/FooterMenu/FooterMenu';
@import 'decorators/GameDecorator/styles/GameDecorator';
@import 'decorators/PopupDecorator/PopupDecorator';
@import 'decorators/MobilePageDecorator/MobilePageDecorator';
@import 'decorators/CustomizeDecorator/CustomizeDecorator';
@import 'decorators/EmptyDecorator/EmptyDecorator';
@import 'decorators/CustomScrollBar/CustomScrollBar';

@import 'modals/Modals';
@import 'modals/BonusForfeitModal/BonusForfeitModal';
@import 'modals/CumulativeDepositLimitModal/CumulativeDepositLimitModal';
@import 'modals/DepositModal/DepositModal';
@import 'modals/ForgotPasswordModal/ForgotPasswordModal';
@import 'modals/WithdrawalModal/WithdrawalModal';
@import 'modals/RegistrationModal/RegistrationModal';
@import 'modals/LimitsConfirmationModal/LimitsConfirmationModal';
@import 'modals/LoginModal/LoginModal';
@import 'modals/GamesSearchModal/GamesSearchModal';
@import 'modals/SofValidationModal/SofValidationModal';
@import 'modals/SubmitFormModal/SubmitFormModal';
@import 'modals/SuccessVerificationModal/SuccessVerificationModal';
@import 'modals/RealityCheckModal/RealityCheckModal';
@import 'modals/PushNotificationModal/PushNotificationModal';
@import 'modals/HowToStartModal/HowToStartModal';
@import 'modals/PepModal/PepModal';
@import 'modals/CookiePolicyModal/CookiePolicyModal';
@import 'modals/SetupCookieModal/SetupCookieModal';
@import 'modals/PromoLockRuleModal/PromoLockRuleModal';
@import 'modals/GameInfoModal/GameInfoModal';
@import 'modals/CancelFreespinModal/CancelFreespinModal';
@import 'modals/ContactUsModal/ContactUsModal';
@import 'modals/SupportModal/SupportModal';
@import 'modals/TaxableJackpotModal/TaxableJackpotModal';
@import 'modals/SearchModal/SearchModal';

@import 'notifications/NoteInfo';

@import 'components/Title/Title';
@import 'components/Subtitle/Subtitle';
@import 'components/Separator/Separator';
@import 'components/Items/Items';
@import 'components/Form/Form';
@import 'components/Description/Description';
@import 'components/Buttons/Buttons';
@import 'components/Panel/Panel';

@import 'components/AccountValidationForm/AccountValidationForm';
@import 'components/ActivityForm/ActivityForm';
@import 'components/Altenar/Altenar';
@import 'components/AltenarUserNotValidated/AltenarUserNotValidated';
@import 'components/AsideMenu/AsideMenu';
@import 'components/Authentication/Authentication';
@import 'components/AvailableLimitAmount/AvailableLimitAmount';
@import 'components/Balance/Balance';
@import 'components/BalanceWidget/BalanceWidget';
@import 'components/Banner/Banner';
@import 'components/BetLimitForm/BetLimitForm';
@import 'components/Bia/Bia';
@import 'components/Bingo/Bingo';
@import 'components/ChoiceGroupList/ChoiceGroupList';
@import 'components/CoolOffLimitForm/CoolOffLimitForm';
@import 'components/ContactUsForm/ContactUsForm';
@import 'components/CurrentBonuses/CurrentBonuses';
@import 'components/DepositForm/DepositForm';
@import 'components/DocumentProof/DocumentProof';
@import 'components/DocumentProofForm/DocumentProofForm';
@import 'components/DocumentProofStatus/DocumentProofStatus';
@import 'components/Documents/Documents';
@import 'components/DocumentsList/DocumentsList';
@import 'components/ExpiringVerificationCode/ExpiringVerificationCode';
@import 'components/FormControlHint/FormControlHint';
@import 'components/ForgotPasswordForm/ForgotPasswordForm';
@import 'components/GameList/GameList';
@import 'components/GameNameFilter/GameNameFilter';
@import 'components/GameProviderLicense/GameProviderLicense';
@import 'components/GameSearch/GameSearch';
@import 'components/GamesFilter/GamesFilter';
@import 'components/GameProvidersFilter/GameProvidersFilter';
@import 'components/GameSlider/GameSlider';
@import 'components/GameSummary/GameSummary';
@import 'components/GameThumbnail/GameThumbnails';
@import 'components/HomeBannerPostLogin/HomeBannerPostLogin';
@import 'components/HomeBannerPreLogin/HomeBannerPreLogin';
@import 'components/HomeContent/HomeContent';
@import 'components/HomeMenu/HomeMenu';
@import 'components/HomeSlider/HomeSlider';
@import 'components/HowToStart/HowToStart';
@import 'components/HowToStartWidget/HowToStartWidget';
@import 'components/IframeForPaymentProvider/IframeForPaymentProvider';
@import 'components/Indicator/Indicator';
@import 'components/JackpotBannerEgt/JackpotBannerEgt';
@import 'components/JackpotWidget/JackpotWidget';
@import 'components/LiveChatWidget/LiveChatWidget';
@import 'components/LimitAmount/LimitAmount';
@import 'components/LocalTime/LocalTime';
@import 'components/LoginForm/LoginForm';
@import 'components/LoginToPlayForm/LoginToPlayForm';
@import 'components/LoginViaThirdParty/LoginViaThirdParty';
@import 'components/LoyaltyPointsConversionForm/LoyaltyPointsConversionForm';
@import 'components/LoyaltyProgram/LoyaltyProgram';
@import 'components/LvcVerification/LvcVerification';
@import 'components/LvcVerification/LvcVerificationStepZero/LvcVerificationStepZero';
@import 'components/LvcVerification/LvcVerificationStepOne/LvcVerificationStepOne';
@import 'components/LvcVerification/LvcVerificationStepTwo/LvcVerificationStepTwo';
@import 'components/LvcVerification/LvcVerificationStepTwoIdenfy/LvcVerificationStepTwoIdenfy';
@import 'components/LvcVerification/LvcVerificationStepThree/LvcVerificationStepThree';
@import 'components/LvcVerification/LvcVerificationStepThreeAmatic/LvcVerificationStepThreeAmatic';
@import 'components/LvcVerification/LvcVerificationUploadDocuments/LvcVerificationUploadDocuments';
@import 'components/LvcVerification/LvcVerificationWaitingForApproval/LvcVerificationWaitingForApproval';
@import 'components/LvcVerification/LvcVerificationConfirmDocumentDetails/LvcVerificationConfirmDocumentDetails';
@import 'components/LvcVerification/LvcVerificationConfirmSnapshots/LvcVerificationConfirmSnapshots';
@import 'components/LvcVerification/LvcVerificationIdenfyFailed/LvcVerificationIdenfyFailed';
@import 'components/MobileAppBanner/MobileAppBanner';
@import 'components/Monetary/Monetary';
@import 'components/OtherDocumentsForm/OtherDocumentsForm';
@import 'components/PageSlider/PageSlider';
@import 'components/PaymentProviderIcons/PaymentProviderIcons';
@import 'components/PlayerAffordabilityForm/PlayerAffordabilityForm';
@import 'components/PlayerDocument/PlayerDocument';
@import 'components/ProfileAccountForm/ProfileAccountForm';
@import 'components/ProfileForm/ProfileForm';
@import 'components/ProfilePushNotifications/ProfilePushNotifications';
@import 'components/ProgressBar/ProgressBar';
@import 'components/PromotionCard/PromotionCard';
@import 'components/PromotionsForm/PromotionsForm';
@import 'components/ProtectionLoginForm/ProtectionLoginForm';
@import 'components/RegisterViaThirdParty/RegisterViaThirdParty';
@import 'components/Registration/Registration';
@import 'components/RegistrationForm/RegistrationForm';
@import 'components/RouterMenu/RouterMenu';
@import 'components/SearchContent/SearchContent';
@import 'components/SelectLocale/SelectLocale';
@import 'components/SessionSummary/SessionSummary';
@import 'components/SessionTime/SessionTime';
@import 'components/SettingsMenu/SettingsMenu';
@import 'components/ShuftiproVerificationForm/ShuftiproVerificationForm';
@import 'components/Snapshot/Snapshot';
@import 'components/SofValidation/SofValidation';
@import 'components/SofValidationForm/SofValidationForm';
@import 'components/StaticPageBanner/StaticPageBanner';
@import 'components/StatusForm/StatusForm';
@import 'components/Stepper/Stepper';
@import 'components/SwedenResponsibleButtons/SwedenResponsibleButtons';
@import 'components/TournamentsForm/TournamentsForm';
@import 'components/Transactions/Transactions';
@import 'components/TransactionStatus/TransactionStatus';
@import 'components/UnexpectedError/UnexpectedError';
@import 'components/UserIdentification/UserIdentification';
@import 'components/UserBalance/UserBalance';
@import 'components/ValidationForm/ValidationForm';
@import 'components/Verification/Verification';
@import 'components/VerificationForm/VerificationForm';
@import 'components/VerificationInIframe/VerificationInIframe';
@import 'components/VerifyViaMobileOrSmartIdForm/VerifyViaMobileOrSmartIdForm';
@import 'components/WinnerListWidget/WinnerListWidget';
@import 'components/WithdrawalForm/WithdrawalForm';
@import 'components/SnapshotFileField/SnapshotFileField';
@import 'components/TakeSnapshotForm/TakeSnapshotForm';
@import 'components/TakeSnapshotModal/TakeSnapshotModal';
@import 'components/ViewImageModal/ViewImageModal';
@import 'components/GeneralAlarm/GeneralAlarm';
@import 'components/FeeConfirmation/FeeConfirmation';
@import 'components/FeeModal/FeeModal';
@import 'components/SuccessfulPage/SuccessfulPage';
@import 'components/ToogleSwitch/ToggleSwitch';
@import 'components/ThemeSwitcher/ThemeSwitcher';
@import 'components/HelpBar/HelpBar';
@import 'components/CommunicationForm/CommunicationForm';
@import 'components/PageHeader/PageHeader';
@import 'components/ProgressLine/ProgressLine';
@import 'components/AppBanner/AppBanner';
@import 'components/CurrentPaymentDetailFields/CurrentPaymentDetailFields';
@import 'components/SnapshotParent/SnapshotParent';

@import 'pages/Home/Home';
@import 'pages/Activation/Activation';
@import 'pages/AuthorizationRequired/AuthorizationRequired';
@import 'pages/ForgotPassword/ForgotPassword';
@import 'pages/ResetPassword/ResetPassword';
@import 'pages/SetPassword/SetPassword';
@import 'pages/Limits/Limits';
@import 'pages/Transactions/Transactions';
@import 'pages/Support/Support';
@import 'pages/StaticPage/StaticPage';
@import 'pages/NotFound/NotFound';
@import 'pages/GameInfoPage/GameInfoPage';
@import 'pages/Blocked/Blocked';
@import 'pages/Protection/Protection';
@import 'pages/ResponsibleGamblingQuestionnaire/ResponsibleGamblingQuestionnaire';
@import 'pages/PendingRounds/PendingRounds';
@import 'pages/AuthorizationRequired/AuthorizationRequired';

/* Forms */
@import 'components/GameTransactionsForm/GameTransactionsForm';
