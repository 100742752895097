.post-login {
  &-page {
    padding: 0;

    .change-password-content {
      width: 100%;

      .grid-root {
        flex-direction: row;
        justify-content: inherit;
        gap: 24px;

        @include media-sm {
          flex-direction: column;
        }

        .gridItem-root {
          flex-basis: auto;
          flex-grow: 1;
        }
      }
    }

    .account {
      &__content {
        .tournaments__info {
          @include media-sm {
            margin: 0 -19px;
          }
        }
      }
    }
  }
  &__title {
    margin-bottom: 0;

    @include media-sm {
      width: 100%;
    }
  }
  &__notice {
    padding: 16px 0 28px 0;
    font-size: 12px;
    line-height: 18px;
    color: var(--balance-primary-light);

    i {
      margin-right: 8px;
    }

    @include media-sm {
      width: 100%;
    }
  }
}

/* Menu */

.post-login__menu {
  margin-right: 20px;
  border-radius: 5px;
  width: 230px;
  max-width: 230px;

  @include media-xs {
    display: none;
  }
}
.post-login-menu {
  position: sticky;
  position: -webkit-sticky;
  top: $header-collapsed-height;

  &_wrap {
    background: color(modal, header);
    border-radius: 5px;

    .aside-menu__hot-links {

      .logged-in {
        gap: 10px;
        display: flex;
      }

      .aside-menu__deposit, .aside-menu__withdrawal {
        padding-top: 26px;
        width: 100%;

        &::before {
          font-family: 'Glyphter-custom';
          top: 8px;
          position: absolute;
        }
      }

      .aside-menu__deposit {
        &::before {
          content: '\0044';
        }
      }

      .aside-menu__withdrawal {
        &::before {
          content: '\0043';
          transform: rotate(-90deg);
        }
      }

      .button-default::after {
        content: none;
      }
    }
  }

  .settings-menu__item-override {

    a:not(.fpLink) {
      margin: 0;
      padding: 10px 5px 10px 14px;
      border-top: 1px solid color(details-summary, border-top);
    }

    .details-root {
      border: 0;
      background: none;
      box-shadow: none;
      text-transform: uppercase;

      .detailsSummary-root {
        min-height: 0;
        padding: 0;

        .settings-menu {
          .settings-menu__item-override {
            background: color(settings-menu, background);
            padding: 10px 10px 10px 28px;
            border: initial;
            border-radius: 0;
            border-bottom: 1px solid color(settings-menu, border);
            box-shadow: inset 0 -1px 0 color(settings-menu, shadow);
            outline: none;
            text-transform: none;
            font-weight: bold;

            a {
              color: color(settings-menu, link);

              &.active, &:hover {
                color: color(settings-menu, active-link);
              }

            }
          }
          .settings-menu__item-override:last-of-type {
            border-bottom: initial;
            box-shadow: initial;
          }
        }
      }
      .detailsSummary-content {
        margin: 0;
        padding: 15px 5px 15px 14px;
        color: color(details-summary, color);
        border-top: 1px solid color(details-summary, border-top);
        border-bottom: 1px solid color(details-summary, border-bottom);

        &::after {
          content: '';
          position: absolute;
          top: 45%;
          right: 20px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid var(--post-login-menu-link-color);
        }
        &:hover {
          color: var(--primary-main);
        }
      }
      &.details-expanded {
        .detailsSummary-content {
          color: #fff;

          &::after {
            transform: rotateX(180deg)
          }
        }
      }
    }
    .settings-menu {
      width: 100%;
    }
    > a {
      z-index: 2;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: border $main-transition-time;
      }
      &.active,
      &:hover {
        color: var(--primary-main);
        transition: color $main-transition-time;

        &::before {
          color: var(--primary-main);
          transition: color $main-transition-time;
        }
        &::after {
          z-index: -1;
        }
      }
      &.active {
        &::after {
          width: calc(100% + 7px);
        }
      }
    }
    &--limits {
      a {
        margin: 0;
        padding: 15px 5px 15px 14px;
        color: color(details-summary, color);
        border-top: 1px solid color(details-summary, border-top);
        border-bottom: 1px solid color(details-summary, border-bottom);
        text-transform: uppercase;
        &:hover {
          color: var(--primary-main);
        }
      }
    }
    &--logout {
      a {
        color: var(--primary-main);

        &::before {
          content: '\0043';
          font-family: 'Glyphter-custom';
          font-size: 16px;
          margin-right: 8px;
        }
      }
    }
  }
}

/* Content */

.post-login-content-wrap {
  &--account-validation,
  &--sof-validation {
    display: flex;
  }
  &:not(&--account-validation):not(&--sof-validation):not(&--documents):not(&--reset-password) {
    @include media-xs {
      width: 100%;
      padding: 11px 9px;
      background: color(input-dark, background);
    }
  }
  &--profile {
    border-radius: 5px;
  }
  &--reset-password {
    padding: 10px 5px 30px;
    width: 100%;

    h1 {
      text-transform: uppercase;
    }

    @include media-xs {
      padding: 11px 9px;
    }
  }
  &_transactions {
    .post-login-content {
      padding: 0;
    }
  }

  &--current-bonuses {
    .bonus {
      &-info {
        border-bottom: 1px solid color(select, background);
        margin-bottom: 22px;
        padding-bottom: 25px;

        .linearProgress-root {
          position: relative;

          .linearProgress-content {
            position: absolute;
            z-index: 10;
            line-height: 18px;
            text-align: right;
            color: color(button-accent, color);
            font-weight: 700;
            font-size: 12px;
            left: 5%;
          }
        }

        label {
          color: var(--input-label-unfilled-color);
          position: relative;
        }

        @include custom-hint;

        div {
          font-size: 24px;
        }

        .linearProgress-line {
          border-radius: 4px;
          height: 17px;
        }
      }

      &-row {

        .notification-root {
          color: color(select, background);
          padding: 19px 29px;

          .notification-message {
            width: 100%;
          }

          p {
            font-size: 16px;
            text-align: center;
          }
        }

        h3 {
          color: var(--primary-light);
          font-size: 20px;
          letter-spacing: 0.5px;
          margin: 14px 0;
          line-height: 1;
        }

        .table-root {
          th, td {
            text-transform: none;
            padding: 8px;
            font-size: 12px;
            text-align: left;
            color: #666;

            a {
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }

            button {
              min-width: auto;
              min-height: auto;
              padding: 5px;
            }
          }
        }
      }

      &-forfeit {
        border-top: 1px solid color(select, background);
        margin-top: 22px;
        padding-top: 25px;

        button {
          margin: 20px 0;
        }

         > p, .markdown-root p {
          margin: 0;

          strong {
            color: #fff;
          }
        }


      }

      &-buttons {
        margin-top: 20px;

        a {
          margin-right: 10px;
        }
      }
    }

    .currency {
      font-size: 8px;
      text-transform: uppercase;
    }

    @include media-xs {
      dt {
        font-weight: bold;
      }

      dl, dd {
        margin: 0;
      }
    }
  }
}

.forfeit-modal {
  @include media-xs {
    .modal-paper .modalContentWrapper {
      background-color: color(footer, background);
      margin: 10px;

      div.modal-titleRoot {
        background: none;
        border-bottom: 0;
        margin-bottom: 2px;
        box-shadow: 0 1px 0 0 color(notice, hr), 0 2px 0 0 color(notice, background);

        h2 {
          margin-top: 0;
        }
      }

      div.modal-contentRoot {
        > div.grid-root {
          padding: 0;
        }
      }

      .confirmation-modal__buttons {
        border-top: 0;
        margin-top: 2px;
        box-shadow: 0 -1px 0 0 color(notice, hr), 0 -2px 0 0 color(notice, background);
        padding-top: 14px;
      }
    }
  }
}

.post-login-content {
  flex-direction: column;

  .adornment-password {
    @include media-xs {
      color: var(--primary-light);
    }
  }

  .container {
    flex: 1;
    padding: 0;
  }

  .select-root .select-selectMenu {
    line-height: 1.5;
  }

  .fields-container {
    display: grid;
    gap: 24px;

    @include media-to-sm {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    .gridItem-root {
      &__nickName {
        display: none;
      }

      &__offlineReference {
        .input-root {
          border: 3px solid var(--active-text-color);
          border-radius: 7px;

          > .input-inputDisabled {
            -webkit-text-fill-color: var(--active-text-color);
            color: var(--active-text-color);
          }
        }
      }

      .select-searchInput::before, .select-searchInput::after {
        content: none;
        background: none;
      }

      @include media-xs {
        padding: 0;
      }
    }
  }
  .item {
    background-color: var(--post-login-content-background);
  }
}
.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: right;

  .button-root {
    margin: 24px 0;

    i {
      margin-left: 8px;
    }

    @include media-xs {
      width: 100%;
    }
  }
}
#root[data-url="/my/limits"] {
  @include media-sm {
    .gridItem-root {
      width: 100%;
    }
  }

  .post-login-content .post-login__title {
    display: none;
  }
}
