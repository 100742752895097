.status-indicator {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  width: fit-content;
  padding: 0.667em 1em;
  border-radius: 2em;
  align-items: center;
  line-height: 1em;
  display: flex;

  &-info {
    background: var(--amount-info-background);

    &:before {
      @include svg-icon('custom');
      content: '\0070';
      color: var(--primary-main);
      margin-right: .5em;
    }
  }

  &-success {
    background: var(--amount-info-background);

    &:before {
      @include svg-icon('lvc');
      content: 'P';
      color: var(--status-ok);
      margin-right: .5em;
    }
  }

  &-error {
    border: 2px solid color(error, main);
    background: color(error, dark);

    &:before {
      @include svg-icon('custom');
      content: '\0051';
      margin-right: .5em;
    }
  }
}
