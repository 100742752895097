.payment-provider-icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 30px;

  @include media-sm {
    margin: 0;
    justify-content: center;
    gap: 10px;
  }
  .payment-provider-icon {
    overflow: hidden;
    display: flex;
    align-items: center;

    @include media-sm {
      margin: 0;
    }
  }
  .seedPaymentProviderIcon__image {
    max-height: 40px;
    filter: brightness(3) contrast(0) grayscale(100%);
  }
  .payment-provider-icon {
    &--210054 .seedPaymentProviderIcon__image,
    &--210055 .seedPaymentProviderIcon__image {
      filter: none;
    }
  }
}
