@mixin button-secondary-box-shadow($color: var(--primary-dark)) {
  box-shadow: inset 0 0 0 1px $color;
}
@mixin button-accent($background: var(--button-golden-background), $color: var(--button-default-color)) {
  color: $color;
  background-color: $background;

  &.button-disabled {
    color: var(--primary-disabled-color);
    background: var(--primary-disabled-background);
    cursor: inherit;
  }

  &:hover:not(.button-disabled) {
    :not(.mobile) & {
      background-color: var(--button-hover-golden-background);
      color: var(--button-default-color);
    }
  }
}
@mixin button-primary($background: var(--button-golden-background), $color: var(--button-default-color)) {
  color: $color;
  background-color: $background;

  &.button-disabled {
    color: var(--primary-disabled-color);
    background: var(--primary-disabled-background);
    cursor: inherit;
  }

  &:hover:not(.button-disabled) {
    :not(.mobile) & {
      background-color: var(--button-hover-golden-background);
      color: var(--button-default-color);
    }
  }
}
@mixin button-secondary($color: var(--primary-light), $background: var(--secondary-main)) {
  color: $color;
  background-color: $background;

  &.button-disabled {
    color: var(--default-light);
    background: var(--secondary-disabled-background);
    cursor: inherit;
  }

  &:hover:not(.button-disabled) {
    :not(.mobile) & {
      background-color: var(--secondary-light);
    }
  }
  &.active {
    .light-theme & {
      background-color: var(--button-golden-background);

      &:hover:not(.button-disabled) {
        :not(.mobile) & {
          background-color: var(--button-hover-golden-background);
        }
      }
    }
  }
}
@mixin button-default($color: var(--default-main), $background: var(--default-normal)) {
  color: $color;
  border: 1px solid $background;

  &.button-disabled {
    color: var(--secondary-light);
    border: 1px solid var(--secondary-main);
    cursor: inherit;
  }

  &:hover:not(.button-disabled) {
    color: var(--primary-light);
    border: 1px solid var(--default-light);
  }
}
@mixin button-danger($color: var(--primary-dark), $background: color(attention)) {
  min-width: 100px;
  position: relative;
  font-size: 12px;
  color: $color;
  background: $background;

  &:hover {
    color: color(button-danger, hover);
    background: $background;
  }
}
@mixin button-link($background: transparent, $color: color(link, default)) {
  color: $color;
  background-color: $background;
  text-decoration: underline;
  padding: 0;
  font-size: inherit;
  min-width: 0;
  min-height: 0;
  text-align: left;

  &:hover {
    text-decoration: none;
    background: $background;
  }
}

@mixin button-base() {
  border-radius: 8px;
  font-size: 14px;
  font-family: $font-primary;
  color: var(--body-text-dark-color);
  text-align: center;
  text-transform: none;
  text-decoration: none;
  line-height: 14px;
  cursor: pointer;

  i {
    height: 14px;
  }

  &.button-loading {
    color: transparent;
  }
}

.button-root {
  min-width: 95px;
  height: 44px;
  padding: 15px 20px 15px 20px;
  @include button-base;
}
.button-small {
  @include button-base;
  width: auto;
  min-width: auto;
  height: 30px;
  padding: 8px;
  border-radius: 100px;
  line-height: 14px;
  min-height: 30px;
}
.button-medium {
  min-width: 89px;
  min-height: 34px;
  padding: 10px;
  @include button-base;
}
.button-accent {
  @include button-accent;
}
.button-primary {
  text-transform: none;
  @include button-primary;
}
.button-secondary {
  transition: color $main-transition-time, background-color $main-transition-time;
  @include button-secondary;
}
.button-default {
  @include button-default;
  white-space: nowrap;
}
.button-danger {
  background: color(attention);
  @include button-danger;
}
.button-qrcode {
  padding: 0;
  min-width: 42px;
  height: 42px;

  span {
    width: inherit;
    height: inherit;
  }

  img {
    width: inherit;
    height: inherit;
  }

  & + .button-root {
    min-width: unset;
  }
}
.button-link {
  @include button-link;
}
.button-danger {
  @include button-link($color: var(--error-main));
}

.group-root--buttons {
  margin-top: 15px;
  margin-bottom: 15px;

  button + button {
    margin-left: 15px;
  }
}
.group-root--password-buttons {
  @include media-sm {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .button-root {
      margin: 0;
    }
  }
}
