.header__balance {
  &-profile {
    padding: 0 0 0 12px;
    height: auto;
    min-height: auto;

    @include media-md {
      display: none;
    }

    .button-label {
      display: flex;
      flex-direction: column;
      line-height: 1;
      color: var(--balance-primary-light);

      & > div {
        display: flex;
        flex-direction: row;
      }
    }

    .balance__button {
      &--nick-name {
        width: 100%;
        font-size: 16px;
        line-height: 1.5;
      }

      &--balance,
      &--free-spins,
      &--award-points {
        position: relative;
        padding: 0 10px 0 24px;
        border-right: 1px solid var(--secondary-light);

        &::before {
          position: absolute;
          @include svg-icon;
          top: -2px;
          left: 0;
          font-size: 15px;
          color: var(--primary-main);
        }
      }
      &--balance {
        margin-right: 10px;
        white-space: nowrap;

        &::before {
          content: var(--balance-coins-icon);
        }
      }
      &--award-points {
        margin-right: 10px;

        &::before {
          content: var(--balance-trophy-icon);
        }
      }
      &--free-spins {
        border-right: none;

        &::before {
          content:var(--balance-money-icon);
        }
      }
    }
  }

  &-profile-buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-left: 1px solid var(--secondary-main);

    @include media-md {
      border-left: none;
    }
  }

  &-menu {
    padding: 0 20px 0 36px;
    border: none;
    color: var(--balance-primary-light);
    border-radius: 0;
    justify-content: left;
    height: 16px;
    min-height: auto;
    font-size: 16px;

    @include media-md {
      border: none;
      min-width: 44px;

      .button-label {
        display: none;
      }
    }

    @include media-sm {
      padding: 0;
    }

    &::before {
      content: "E";
      position: absolute;
      @include svg-icon;
      top: -2px;
      left: 10px;
      font-size: 18px;
      color: var(--default-main);

      @include media-sm {
        font-size: 24px;
      }
    }

    &:hover {
      background: none;
    }
  }
}

.balance__popover {
  display: flex;
  flex-direction: column;
  min-width: 371px;
  margin-top: 33px;
  overflow: visible;
  border-radius: 0 0 12px 12px;
  color: var(--balance-primary-light);
  background:  var(--secondary-main);

  @include media-sm {
    min-width: auto;
  }

  .header__deposit {
    margin: 10px 0;
    display: none;

    @include media-md {
      display: inline-block;
    }
  }

  .balance {

    &__scroll-wrapper {
      overflow: auto;
      font-size: 14px;
      background-color: var(--balance-popover-background);

      @include media-md {
        margin-bottom: 12px;
        background: var(--secondary-main);
        border-radius: 12px;
      }

      .static-hide {
        display: none;
      }
    }

    &__balance {
      background: var(--balance-popover-bgimage);

      @include media-sm {
        background: var(--secondary-main);
        border-radius: 12px;
      }
    }

    &__row {
      padding: 25px;
      gap: 10px;
      display: flex;
      flex-direction: column;

      @include media-sm {
        padding: 12px;
        gap: 0;
      }

      &--balance, &--real, &--promo, &--betting-bonus, &--wagering-requirement {
        padding: 0 0 0 20px;
        position: relative;
        display: flex;
        justify-content: space-between;

        &::before {
          position: absolute;
          @include svg-icon;
          left: 0;
          top: 3px;
          color: var(--primary-main);
        }
      }

      &--balance::before {
        @include svg-icon('custom');
        content: var(--balance-total-icon);
      }

      &--real {
        @include media-sm {
          padding: 10px 10px 10px 30px;
          border-radius: 8px 8px 0 0;
        }

        &::before {
          @include svg-icon('lvc-regular');
          content: var(--balance-lvc-coins-icon);

          @include media-sm {
            top: 14px;
            left: 10px;
          }
        }
      }

      &--promo {
        @include media-sm {
          padding: 10px 10px 10px 30px;
        }

       &:before {
         @include svg-icon('lvc-regular');
         content: var(--balance-lvc-trophy-icon);

         @include media-sm {
           top: 14px;
           left: 10px;
         }
       }
      }

      &--betting-bonus {
        @include media-sm {
          border-radius: 0 0 8px 8px;
          padding: 10px 10px 10px 30px;
        }

        &::before {
          @include svg-icon('lvc-regular');
          content: var(--balance-lvc-trophy-icon);

          @include media-sm {
            top: 14px;
            left: 10px;
          }
        }
      }

      &--wagering-requirement {
        @include media-sm {
          padding: 10px 10px 10px 30px;
        }
        &::before {
          @include svg-icon('lvc-regular');
          content: '"';

          @include media-sm {
            top: 14px;
            left: 10px;
          }
        }
      }

      &--balance {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--secondary-light);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .balance__title {
          p {
            margin: 0;

            strong {
              font-weight: 500;
            }
          }
        }

        @include media-sm {
          padding-left: 44px;
          border-bottom: none;
          padding-bottom: 0;

          .balance__title {
            p {
              font-size: 12px;
              line-height: 12px;
              font-weight: 500;

              strong {
                font-weight: 500;
                display: block;
              }
            }
          }

          .balance__value {
            color: var(--balance-popover-total);
            font-size: 20px;
            font-weight: bold;
          }
        }

        &:before {
          color: var(--primary-main);

          @include media-sm {
            color: var(--primary-light);
            background: var(--secondary-disabled-background);
            border-radius: 100px;
            padding: 7px;
          }
        }
      }

      &::before {
        @include svg-icon;
        position: absolute;
        font-size: 15px;
        left: 12px;
        color: var(--primary-main);
      }

      & > .balance-mobile {
        display: flex;
        gap: 2px;
        flex-direction: column;

        @include media-sm {
          background: var(--balance-popover-bgimage-mobile);
          margin-top: 24px;
          border-radius: 12px;
        }
      }

      &--free-spin,
      &--award-point {
        flex-direction: column;
        text-align: center;
        font-weight: 500;
        position: relative;
        padding-top: 35px;

        &::before {
          @include svg-icon('lvc');
          position: absolute;
          top: 0;
          left: calc(50% - 15px);
          background: var(--secondary-main);
          height: 30px;
          width: 30px;
          border-radius: 50%;
          line-height: 30px;
        }

        .balance__value {
          font-size: 24px;
          color: var(--primary-gold-light);
        }

        .balance__title {
          white-space: nowrap;

          @include media-sm {
            margin-left: 8px;
            white-space: inherit;
          }
        }
      }

      &--free-spin::before {
        content: var(--balance-lvc-spin-icon);
      }

      &--award-point::before {
        content: var(--balance-lvc-frame-icon);
      }

    }

    &__free-block {
      display: flex;
      gap: 22px;
      padding: 24px;

      @include media-sm {
        padding: 12px;
      }
    }

    &__nick-name::before {
      @include svg-icon('custom');
      content: '\0041';
    }

    &__real::before {
      content: '\0049';
    }

    &__promo::before {
      content: '\0047';
    }

    &__betting-bonus::before {
      content: '\002c';
    }

    &__live-casino-bonus::before {
      content: '\220f';
    }

    &__cashback::before {
      content: '\0067';
    }

    &__saving-cash::before {
      content: '\0071';
    }

    &__free-spins, &__award-points {
      width: 150px;
      height: 112px;
      background: var(--secondary-disabled-background);
      align-items: center;
      border-radius: 12px;
      padding: 12px;
      position: relative;
      justify-content: flex-start;

      @include media-sm {
        width: 100%;
        height: auto;
      }
    }

    &__logout::before {
      @include svg-icon('custom');
      content:'\0043';
    }

    &__row--loyalty {
      text-decoration: none;

      &.bronze {
        color: color(loyalty-statuses, bronze);
      }

      &.silver {
        color: color(loyalty-statuses, silver);
      }

      &.gold {
        color: color(loyalty-statuses, gold);
      }

      &.platinum {
        color: color(loyalty-statuses, platinum);
      }

      .balance__title::before {
        content: '\006e';
      }
    }

    &__wagering-requirement {
      padding: 10px 0 0 0;
      position: relative;
      flex-direction: column;
      gap: 10px;
      border-top: 1px solid var(--secondary-light);

      @include media-sm {
        padding: 0 0 10px 0;
        border-radius: 0 0 8px 8px;
      }

      .balance__title {
        span {
          margin-top: 5px;
          font-size: 0.75em;
        }
      }

      .linearProgress-root {
        @include linear-progress();
        @include media-sm {
          padding: 0 10px;
        }
      }

      .balance__value {
        span {
          margin-bottom: 4px;
          font-size: 12px;
        }
      }
    }

    &__free-spins-update-button {
      display: flex;
      min-width: 0;
      min-height: 0;
      margin-left: 5px;
      padding: 0;
      line-height: 0;
      letter-spacing: normal;
      color: color(text, main);
      border: 0;
      background: none;

      .button-label {
        font-size: 0;
      }

      &::before {
        @include svg-icon;
        content: '\0077';
        font-size: 20px;
      }

      &:hover {
        &::before {
          color: color(accent, light);
        }
      }

      &.button-loading {
        animation: rotate 1s linear infinite;
        color: color(accent, light);
      }

      svg {
        opacity: 0;
      }
    }

    .button-secondary, .button-accent {
      margin: 16px 0 6px 0;
    }

    .custom-width {
      @include media-md {
        min-width: 135px;
        padding: 0;
        font-size: 14px;
      }
    }

    &__button-wrapper {
      @include media-md {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
      }

      .button-secondary, .button-accent {
        margin: 16px 0 6px 0;
      }

      .custom-width {
        @include media-md {
          min-width: 135px;
          padding: 0;
          font-size: 14px;
        }
      }
    }
    &__game-transactions {
      display: block;
      margin: 24px 0 14px 0;

      @include media-md {
        margin: 5px 0 5px 0;
      }
    }
  }
}

.menu__popover {

  @include media-sm {
    margin-top: 16px;
  }

  .menu {
     &-profile {
      border-radius: 0;

      &::before {
        content: var(--balance-lvu-user-icon)
      }
    }

    &-finance {
      border-radius: 0;

      &::before {
        content: var(--balance-lvu-finance-icon)
      }
    }

    &-logout {
      border-radius: 0 0 8px 8px;

      &::before {
        content: var(--balance-lvu-logout-icon);
      }
    }

    &-settings {
      border-radius: 0;

      &::before {
        content: var(--balance-lvu-settings-icon);
      }
    }
  }
}

.payment__popover {

  .header {
    &__deposit, &__withdraw {
      position: relative;
      padding-left: 40px;

      &::before {
        @include svg-icon('custom');
        position: absolute;
        left: 15px;
        top: 13px;
        color: var(--primary-main);
      }
    }

    &__deposit {
      border-radius: 0;

      &::before {
        content: var(--custom-arrow-up-icon);
        transform: var(--arrow-rotate-angle);
      }
    }

    &__withdraw {
      border-radius: 0 0 8px 8px;

     &::before {
       content: var(--custom-arrow-down-icon);
     }
    }
  }
}

.payment__popover, .menu__popover {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  margin-top: 29px;
  overflow: visible;
  color: var(--balance-primary-light);
  background:  var(--secondary-main);
  border-radius: 0 0 8px 8px;

  .popup-menu-link {
    position: relative;
    padding-left: 40px;

    &::before {
      @include svg-icon('lvc-regular');
      position: absolute;
      left: 15px;
      top: 15px;
      color: var(--primary-main);
    }
  }

  @include media-sm {
    margin-top: 16px;
  }

  .button-secondary {
    text-align: left;
    font-weight: 500;

    &:hover {
      color: inherit;
      background: var(--balance-popover-hover);
    }
  }
}

.menu__popover {
  margin-top: 53px;

  @include media-sm {
    margin-top: 31px;
  }
}
