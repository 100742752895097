@mixin snapshot-button {
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  padding: 0;

  &:before {
    @include svg-icon('lvc-regular');
    color: var(--default-main);
    font-size: 14px;
  }

  &:hover {
    &:before {
      @include svg-icon('lvc');
    }
  }
}
@mixin snapshot-button-small {
  flex-direction: row;
  font-size: 9px;

  & > span {
    width: auto;
    margin-left: 5px;
  }

  &:before {
    font-size: 14px;
  }
}
.snapshot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  border-bottom: 1px solid var(--secondary-main);
  padding-bottom: 28px;

  &:last-child {
    border: none;
    padding: 0;

    @include media-sm {
      grid-column: 1;
    }
  }

  &__headers {
    margin-bottom: 8px;

    @include media-xs {
      display: flex;
      margin-bottom: 5px;
    }
  }
  &__header {
    color: var(--primary-light);
    font-size: 14px;

    @include media-xs {
      color: var(--input-label-mobile-color);
      margin-right: 5px;
    }
  }
  &__content {
    background: var(--secondary-main);
    border-radius: 8px;

    @include media-xs {
      min-height: auto;
    }

    &-wrapper {
      display: flex;
      gap: 10px;
    }
    .snapshot-image {
      &__wrapper {
        width: auto;
        position: relative;
        display: flex;
        border-radius: 8px;
        overflow: hidden;

        @include media-sm {
          width: 100%;
          min-height: auto;
        }

        .snapshot__image {
          height: 168px;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &__content:has(&__content-wrapper):has(&-image__wrapper) {
    background: none;
  }
  &__message {
    font-size: 14px;
    color: var(--default-main);
    line-height: 1;
    text-align: left;
    margin-right: auto;
    padding: 15px;
    white-space: nowrap;

    & + .markdown-root {
      position: absolute;
      bottom: -30px;
      z-index: 1000;

      p {
        font-size: 9px;
        color: var(--error-main);
        margin: 0;
      }
    }
  }
  &__error {
    position: absolute;
    font-size: 9px;
    color: #c84b4b;
    margin: 0;
    bottom: -22px;
    left:2px;
  }
  &__buttons {
    overflow: hidden;
    padding: 15px;
    gap: 10px;
    display: flex;
  }
  &__snapshotField,
  &__fileField {
    @include media-xs {
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
    }
  }

  &__snapshotField {
    border-right: 1px solid color(label, unfilled);

    &-button {
      @include snapshot-button;

      .button-label + span {
        display: none;
      }

      &:before {
        content: 'K';
      }
    }
  }

  &__fileField {
    .snapshotFileField {
      @include size(100%);
    }
  }
  .snapshotFileField {
    border-left: 1px solid var(--default-normal);
    padding-left: 10px;
    height: 14px;

    &__fileButton {
     @include snapshot-button;

      &:before {
        content: '$';
      }
    }
    &__fileLabel {
      top: 0;
      cursor: pointer;
    }
  }

  &-image__wrapper.success {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary-main);
    font-size: 14px;
    padding: 15px;
    line-height: 1;

    &:before {
      @include svg-icon('custom');
      content: '\004c';
      margin-right: 10px;
    }
  }

  &-image__wrapper + &__buttons {
    position: absolute;
    max-width: 100%;
    bottom: 0;
    border-radius: 0 0 5px 5px;

    .snapshot__snapshotField,
    .snapshot__fileField {
      height: 27px;
    }
    .snapshotFileField {
      &__fileButton {
        flex-direction: row;
        font-size: 9px;

        &:before {
          font-size: 10px;
        }
      }
    }
    .snapshotFileField {
      &__fileButton {
        @include  snapshot-button-small;
      }
    }
    .snapshot__snapshotField-button {
      @include snapshot-button-small;

      .button-label + span {
        display: none;
      }
    }
  }
  &__view-more {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--error-main);
    margin: 0;
    border-radius: 100px;
    cursor: pointer;
    top: 12px;
    right: 12px;
    min-width: 34px;
    width: 34px;
    height: 34px;
    padding: 10px;
    min-height: 34px;

    .button-label {
      width: auto;
    }

    .button-label + span {
      display: none;
    }

    &:before {
      @include svg-icon('lvc-regular');
      content: 'E';
      color: var(--primary-light);
    }

    &:hover {
      background: var(--error-main);

      &:before {
        @include svg-icon('lvc');
      }
    }
  }

  &__uploadedImage {
    margin-left: 10px;
  }
}
