.reset-password {
  .container {
    max-width: 800px;
    margin-top: 15px;
    padding: 30px;
  }
  .reset-password__newPassword {
    margin-bottom: 24px;
  }
  .button-accent {
    margin-top: 20px;
    i {
      margin-left: 8px;
    }
  }
  &__success {
    padding: 50px 0;
  }
}
