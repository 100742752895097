.playerVerificationStep3 {
  .playerVerificationForm3 {
    &__details {
      @include media-sm {
        padding: 0 20px;
      }
    }
    &__grid {
      gap: 18px;
    }
    &__header {
      font-size: 32px;
      line-height: 42px;
      font-weight: 700;
      margin: 0 0 10px 0;
      padding: 0;

      @include media-sm {
        font-size: 24px;
        line-height: 34px;
      }
    }

    &__title-block {
      grid-column: 1 / 3;

      @include media-sm {
        grid-column: 1;
      }
    }

    &__subheader {
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
      text-transform: uppercase;
      padding: 12px 0;
      grid-column: 1 / 3;

      @include media-sm {
        grid-column: 1;
      }
    }

    &__subdescription {
      font-size: 16px;
      line-height: 24px;
      grid-column: 1 / 3;

      em {
        font-style: normal;
        color: var(--primary-gold-light);
      }

      @include media-sm {
        grid-column: 1;
      }

      p {
        margin: 0;
      }
    }

    &__description {
      font-size: 16px;
      line-height: 1;
      font-weight: 600;

      @include media-sm {
        font-size: 14px;
        margin-bottom: 10px;
      }

      p {
        margin: 0;
      }
    }

    &__manual-verification-text {
      @include info-box();
      margin: 0;
      border: 1px solid var(--primary-gold-light);
      background: var(--secondary-disabled-background);
      font-weight: 700;

      @include media-sm {
        display: none;
      }
    }

    &__fields {
      background: var(--secondary-disabled-background);
      border-radius: 12px;
      padding: 24px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 18px;
      max-width: 580px;

      hr {
        width: 100%;
        border-top: 1px solid var(--secondary-main);
        border-left: none;
        border-right: none;
        border-bottom: none;
        grid-column: 1 / 3;
        margin: 12px 0;

        @include media-sm {
          grid-column: 1;
        }
      }

      .field-success {
        grid-column: 1 / 3;

        @include media-sm {
          grid-column: 1;
        }

        .field-label {
          white-space: nowrap;

          .markdown-root p {
            margin: 0;

            strong {
              font-size: 1em;
              font-weight: 400;
              color: var(--balance-default-light);
            }
          }
        }
      }

      .fields-container__grid-root {
        &--passportNumber, &--nationalIdCardNumber {
          grid-column: 1 / 3;

          @include media-sm {
            grid-column: 1;
          }
        }
      }

      .gridItem-root {
        .field-input .input-root {
          &:after {
            @include svg-icon('lvc-regular');
          }

          &:hover {
            &:after {
              @include svg-icon('lvc');
            }
          }
        }
      }

      .gridItem-root {
        .field-input .input-root {
          &:after {
            position: absolute;
            top: 14px;
            right: 16px;
            left: auto;
            color: var(--default-main);
            background: none;
            transform: none;
            transition: none;
            font-size: 14px;
          }
        }
      }

      .fields-container__grid-root {
        &--passportNumber {
          .field-input .input-root {
            position: relative;

            &:after {
              content: '4';
            }
          }
        }

        &--addressCardNumber {
          .field-input .input-root {
            position: relative;

            &:after {
              content: '+';
            }
          }
        }
      }

      @include media-sm {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &-notice {
      padding: 8px 0;
      margin-bottom: 30px;

      i {
        margin-right: 8px;
      }

    }

    @include media-xs {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
}

