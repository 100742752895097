.customize-decorator {

    &--header {
        text-align: center;
        font-size: 24px;
        color: color(modal, title);;
        margin: 0;
        padding: 30px 0;
        font-weight: 400;
        background: color(modal, header);
        min-width: 580px;
        text-transform: uppercase;
        line-height: 1;
    }

    &--content {
        padding: 30px 28px 26px;
    }

    &--close {
        @include close-button;
        @include size(32px);
        background: #464546;
        border-radius: 50%;
        position: absolute;
        top: 13px;
        right: 16px;
        background: var(--secondary-main);

        // &:hover {
        //   background: #464546;
        // }
    }
}
