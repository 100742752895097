.staticPageWrapperFaq {
  margin: 0 auto;

  h2 {
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: color(notice, color);

    @include media-xs {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  h3 {
    color: var(--balance-primary-light);
    font-size: 20px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
    color: var(--balance-primary-light);

    @include media-md {
      padding-right: 24px;
    }
  }

  .header {
    padding: 24px;
    margin-bottom: 24px;

    h1 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      margin: 0;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 8px 0 0 0;
    }

    span {
      color: var(--primary-light);
    }
  }

  .faq-content {
    position: relative;
    border-bottom: 1px solid var(--secondary-light);
    padding: 24px;
    cursor: pointer;
    transition: max-height 3s;

    p {
      display: none;
      max-height: 0;
      overflow: hidden;
      height: auto;
      color: var(--balance-primary-light);
      transition: max-height 0.5s, margin-bottom 0.5s;
      font-size: 14px;

      @include media-xs {
        margin-left: 0;
        font-size: 12px;
      }
    }

    &::after {
      @include svg-icon('custom');
      position: absolute;
      content: '\0042';
      top: 24px;
      right: 24px;
      color: var(--faq-icon);
      transition: transform 0.3s ease;
      font-size: 16px;
      transform: rotate(180deg);
    }


    &:last-child {
      border-bottom: none;
      margin-bottom: 45px;
    }
  }

  .show {
    background: var(--faq-que-background);
    border-radius: 12px;
    margin-top: 5px;

    &::after {
      transform: rotate(0deg);
      color: var(--faq-icon-selected);
    }
  }

  .show p {
    display: block;
    max-height: 500px;
    padding-top: 10px;
    border-radius: 0 0 12px 12px;
    margin-top: 0;
    margin-bottom: 0;
    background: inherit;

    @include media-xs {
      margin-left: 0;
    }
  }
}

@include media-xs {
  .content-promotions {
    .mobile-page-theme {

      .title {
        display: none;
      }

      .container {
        background: none;

        .static-page__container {
          padding: 0;
        }
      }
    }
  }
}
