.cookies-policy-page-content {
  h2 {
    margin-top: 14px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  b, strong {
    color: color(notice, color);
  }

  ul li {
    list-style-type: disc;

    &::before {
      content: none;
    }
  }
}
