.protection-login-form {
  .group-root--buttons {
    margin: 13px 0 0 0;
    text-align: left;
  }
  .group-root--buttons .button-root {
    min-width: 71px;
    min-height: 40px;
    padding: 0 12px;
    color: color(background, header);
    font-size: 14px;
    font-weight: 300;
    line-height: 40px;
    letter-spacing: 0.05em;
  }
  .protection-login__field label {
    padding-bottom: 5px;
    font-size: 12px;
    text-align: left;
  }
}
