@mixin snapshot-button {
  min-width: 14px;
  min-height: 14px;
  width: 14px;
  height: 14px;
  padding: 0;

  &:before {
    @include svg-icon('lvc-regular');
    color: var(--default-main);
    font-size: 14px;
  }

  &:hover {
    &:before {
      @include svg-icon('lvc');
    }
  }
}
.snapshot-common {
  margin: 5px 0;

  &__header {
    margin-bottom: 8px;
    color: var(--primary-light);
    font-size: 14px;
    text-align: left;

    @include media-xs {
      color: var(--input-label-mobile-color);
      margin-right: 5px;
    }
  }

  &__content {
    text-align: left;
  }

  &__image-wrapper {
    width: fit-content;
    position: relative;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
  }

  &__image {
    height: 168px;
    object-fit: contain;
    width: 100%;
  }

  &__field {
    display: flex;
    background: var(--secondary-main);
    border-radius: 8px;

    &-message {
      color: var(--default-main);
      font-size: 14px;
      line-height: 1;
      margin-right: auto;
      padding: 15px;
      text-align: left;
      white-space: pre-wrap;
    }
  }

  &__buttons {
    overflow: hidden;
    padding: 15px;
    gap: 10px;
    display: flex;
    align-items: center;
  }

  &__camera-button {
    @include snapshot-button;

    .button-label + span {
      display: none;
    }

    &:before {
      content: 'K';
    }
  }

  .snapshotFileField {
    border-left: 1px solid var(--default-normal);
    padding-left: 10px;
    height: 14px;

    &__fileButton {
      @include snapshot-button;

      &:before {
        content: '$';
      }
    }
    &__fileLabel {
      top: 0;
      cursor: pointer;
    }
  }

  &__remove-image-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--error-main);
    margin: 0;
    border-radius: 100px;
    cursor: pointer;
    top: 12px;
    right: 12px;
    min-width: 34px;
    width: 34px;
    height: 34px;
    padding: 10px;
    min-height: 34px;

    .button-label {
      width: auto;
    }

    .button-label + span {
      display: none;
    }

    &:before {
      @include svg-icon('lvc-regular');
      content: 'E';
      color: var(--primary-light);
    }

    &:hover {
      background: var(--error-main);

      &:before {
        @include svg-icon('lvc');
      }
    }
  }

  &__error {
    color: var(--error-main);
    text-align: left;
  }

  &__uploaded {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--primary-main);
    font-size: 14px;
    padding: 15px;
    line-height: 1;

    &:before {
      @include svg-icon('custom');
      content: '\004c';
      margin-right: 10px;
    }
  }
}
