.how-to-start {
  display: block;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 0;

  &::after {
    content: none;
  }

  .title {
    height: 20px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding: 3px 16px 0;
    margin-top: -19px;
    border-radius: 4px 4px 0 0;
    color: color(main-menu, link-hover);
    font-size: 16px;
    font-weight: 300;
    text-transform: uppercase;
    background: color(background, body);
    z-index: 1;
  }

  .steps {
    height: 50px;
    margin-top: -20px;
    overflow: hidden;
    @include regular-vegas-linear-gradient;

    .step {
      width: 37%;
      float: left;
      display: block;
      position: relative;
      font-weight: 300;
      line-height: 52px;

      .number {
        margin-right: 2px;
        color: color(link, default);
        font-size: 28px;
      }

      .postfix {
        width: 0;
        position: relative;
        top: -12px;
        color: color(link, default);
        font-size: 10px;
        display: inline-block;
        text-transform: none;
      }

      .caption {
        color: color(main-menu, link-hover);
        font-size: 11px;
        text-transform: uppercase;
      }
    }

    .step2 {
      width: 26%;
    }
  }
}
