.items {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1280px;
}
.item {
  background: var(--primary-light);
  padding-bottom: 30px;

  &:last-child {
    flex: 1;
  }
  &:not(:last-child){
    margin-bottom: 20px;
  }
}
