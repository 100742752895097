.registration-modal {

  .modal-paper {
    @include modal-fullscreen-mode;
    padding: 0;

    @include media-xs {
      background: color(popup, summary-background);;
    }

    .modalContentWrapper .modal-titleRoot  {
      text-align: center;
      background: var(--modal-title-background);

      @include media-sm {
        background: none;
        border-radius: 5px;
        margin: 0 0 15px 0;
      }

      h2 {
        font-size: 24px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        line-height: 1;
      }

      @include media-sm {
        h2 {
          padding: 11px 10px 9px;
          border-radius: 4px 4px 0 0;
          background: color(popup, summary-background);
          box-shadow: 0 1px 0 0 color(notice, hr), 0 2px 0 0 color(notice, background);
          color: #f1f1f1;
          font-size: 14px;
          font-weight: 300;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: .5px;
          text-align: left;
          margin: 0;
        }
      }
    }

  }

  &:not(.registration-modal--error) {
    .modalContentWrapper {
      position: relative;
      padding: 0;

      @include media-md {
        position: static;
      }

      @include media-xs {
        background: color(input-dark, background);
        margin: 10px;
      }
    }
  }

}

.registration-form {

  .content-wrapper {
    padding-bottom: 32px;

    @include media-sm {
      padding: 0;
    }

    .form-title p {
      color: var(--error-main);
    }
  }

  &-modal {
    width: calc(100% - 440px);
    left: auto;
    right: 0;
    font-size: 14px;

    h2 {
      margin-bottom: 10px;
    }

    .markdown-root {

      p {
        margin: 0;
      }

      strong {
        color: var(--primary-main);
        font-weight: normal;
      }
    }

    .field-root {
      margin: 22px 0;

      label {
        display: block;
        color: var(--default-light);
      }
    }

    &-field {
      width: 100%;
    }

    @include media-sm {
      width: 100%;
    }

    .modal-background {
      left: auto;
      top: auto;
      right: 0;
      bottom: 0;
      width: calc(100% - 440px);
      height: calc(100% - 100px);

      @include media-sm {
        width: 100%;
        height: calc(100% - 142px);
      }
    }
  }

  &__stepper {
    @include media-sm {
      padding: 16px 0;
    }

    .gridItem-root {
      flex-basis: auto;
    }
  }

  &--mobile {
    padding: 0 15px;
    .notification-root .notification-message {
      position: relative;

      h2 {
        font-size: 13px;
        text-transform: inherit;
        font-weight: bold;
        color: #fff;
      }

      .button-root {
        min-width: auto;
        min-height: auto;
        position: absolute;
        top: -5px;
        right: -5px;
        font-size: 15px;
        opacity: .2;
        font-weight: 700;
        color: #fff;
        text-transform: inherit;
      }
    }
  }

  .content-wrapper {
    @include media-xs {
      width: 100%;
      min-width: auto;
      margin-top: 0;
    }

    .notification {
      &-root {
        padding: 19px 29px;
        justify-content: center;

        p {
          font-size: 16px;
          color: color(select, background);
        }
      }

      &-message {
        width: 100%;
        text-align: center;
      }
    }
  }

  .privacy-policy-text {
    font-size: 12px;
    color: var(--default-light);

    a {
      color: var(--default-main);
    }
  }

  .form-title {
    color: var(--primary-light);
    margin-bottom: 24px;

    h4 {
      text-transform: none;
      font-size: 32px;
      font-weight: 700;
      margin: 14px 0 10px 0;
      line-height: 42px;
      color: var(--balance-primary-light);

      @include media-sm {
        font-size: 24px;
        line-height: 34px;
        margin-top: 0;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }
  }

  .button-default {
    background: var(--primary-dark);
  }

  .registration-code {

    &--wrapper {
      margin: 0 10px;
      border-bottom: 1px solid var(--primary-dark);

      .field-select {
        svg {
          fill: var(--primary-main);
        }
      }

      p {
        font-size: 10px;
        color: color(label, unfilled);
        margin-top: 20px;
      }
    }

    &--block {
      display: flex;
      flex-direction: row;
      gap: 0 5%;

      > div {
        flex-basis: 47%;
      }
    }
  }

  .support-section {
    padding: 16px 0;
    font-size: 12px;
    color: var(--balance-primary-light);
    line-height: 1.5;
  }

  .registration-form__successful {
    p {
      font-size: 14px;

      strong {
        color: #fff;
      }
    }
  }

  .fields-container, .form-title, .registration-code--wrapper, .support-section, .registration-form__successful {
    position: relative;
    justify-content: center;

    h2 {
      text-transform: uppercase;
      font-size: 26px;
      font-weight: 400;
      color: var(--primary-main);
    }

    .field-root .field-helper:not(:empty) {
      @include media-xs {
        font-size: 12px;
      }
    }

    .button-root {
      margin-top: 13px;
    }

    .full-width {
      width: 100%;
      gap: 0 24px;
    }

    .privacy-policy-text {
      color: color(label, unfilled);
      font-size: 75%;
      width: 50%;
      position: absolute;
      top: 113px;
      right: -10px;

      @include media-sm {
        position: static;
        width: 100%;
      }

      p {
        line-height: 1.8;
        font-size: 10px;

        a {
          text-decoration: none;
        }
      }
    }

    &__grid-root {
      .group-root__password,
      .group-root__confirmPassword {

        .registration-tips {
          margin-top: 1em;
          display: flex;
          gap: 10px;
          width: 100%;
          flex-wrap: wrap;
        }
      }

      .form-control-hint-block {
        top: 15px;
        right: 15px;
      }

      .group-root .field-root .input-root .input-input {
        padding-right: 70px;

        @supports (-webkit-touch-callout: none) {
          transition: background-color 9999s ease-in-out 0s;
        }
      }

      &--nickName, &--phoneNumber, &--promoCode, &--email, &--confirmEmail {
        .field-input .input-root {
          position: relative;

          &:hover:after {
            @include svg-icon('lvc');
          }

          &:after {
            position: absolute;
            left: inherit;
            right: 45px;
            top: 15px;
            width: 14px;
            height: 14px;
            font-size: 14px;
            display: block;
            transition: none;
            transform: none;
            background: none;
            color: var(--default-main);
          }
        }
      }

      &--confirmEmail {
        order: 3;

        .input-root:after {
          content: 'n';
        }
      }

      &--password, &--confirmPassword {
        button.adornment-password {
          right: 30px;
        }
      }

      &--password {
        order: 4;
      }

      &--confirmPassword {
        order: 5;
      }

      &--birthDate {
        order: 6;

        @include media-xs {
          input {
            width: 100vw;
          }
        }
      }

      &--campaignId {
        order: 8;
        max-width: 100%!important;
        flex-basis: auto;
      }

      &--nickName {
        order: 0;

        .input-root:after {
          content: 'o';
        }
      }

      &--phoneNumber {
        order: 1;

        .input-root:after {
          right: 45px;
        }
      }

      &--email {
        order: 2;

        .input-root:after {
          content: 'n';
        }
      }

      &--promoCode {
        order: 7;

        .input-root:not(.input-disabled):after {
          content: 'g';
        }
      }

      &--ageAndPolicyChecked {
        order: 9;
      }

      &--publicPerson {
        order: 10;
      }

      &--campaignsEnabled {
        order: 11;
      }
    }


    @media (min-width: 768px) {
      &__grid-root {
        max-width: calc(50% - 12px);
        padding: 12px 0;

        &--ageAndPolicyChecked, &--receiveEmail, &--publicPerson {
          .field-helper:empty {
            font-size: 0;
            margin: 0;
          }
        }

        &--promoCode {
          .field-helper:not(:empty) {
            color: var(--home-menu-item-color);
            border-bottom: 1px solid var(--home-menu-item-color);
            position: absolute;
            bottom: -40px;
            width: calc(200% + 20px);
            font-size: 11px;
            padding-bottom: 15px;
          }

        }
      }
    }
  }
  @media (max-width: 768px) {
    .fields-container, .form-title, .registration-code--wrapper, .support-section, .registration-form__successful {
      min-width: 0;
      margin: 0 auto;
      width: 100%;
      left: 0;
      transform: none;
    }
  }
  &__successful {
    margin-top: 20px;
    text-align: center;

    .markdown-root {
      margin-bottom: 20px;
    }
  }
  &__gdpr-notification {
    font-size: 14px;
    text-align: justify;
    color: color(text, main);
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0;

    @include media-sm {
      margin-bottom: 20px;
      justify-content: space-between;
    }
    button + button {
      margin-left: 30px;
    }
  }

  .fields-container__grid-root {
    .tag {
      @include tag();
      font-size: 12px;
      line-height: 1;

      &:before {
        top: 6px;
      }
    }

     &--receiveEmail {
       order: 10;
       max-width: 100%;
       flex-basis: auto;

       .group-root {
         .field-root {
           width: 100%;
         }
       }

       label {
         span:nth-child(2) {
           display: flex;
           width: 100%;
           justify-content: space-between;

           @include media-sm {
             flex-direction: column;
             gap: 16px;
           }
         }
       }
     }
    &--publicPerson {
      order: 12;
      max-width: 100%;
      flex-basis: auto;
    }
    &--ageAndPolicyChecked {
      order: 11;
      max-width: 100%;
      flex-basis: auto;
    }
    &--campaignsEnabled {
      order: 13;
      display: none;
    }
  }
  .fields-container__grid-root--campaignsEnabled{
    display: none;
  }

  .lvc-snow {
    margin-right: 4px;

    &:before {
      font-size: 10px;
    }
  }


  .fields-container__grid-root {
    &--ageAndPolicyChecked, &--receiveEmail, &--publicPerson {
      @include media-sm {
        flex-basis: 100%;
      }
    }
  }

  .fields-container__grid-root--selected-country-ROU {
    &.fields-container__grid-root--birthDate {
      display: none;
    }
  }
  &__logging-in {
    margin-top: 10px;
    text-align: center;

    &-text {
      margin-top: 10px;
    }
  }

  &--mobile {
    .fields-container .gridItem-typeItem {
      margin-bottom: 12px;

      .field-root .field-helper:empty, .field-root .field-helperEmpty {
        font-size: 0;
        margin: 0;
      }
    }
  }
}

.publicPersonPopup #chat-widget-container {
  display: none;
}

.stepone-next-step{
  margin-left: -8px;
}
