.account__current-bonuses {
  display: block;

  h3 {
    margin: 0 0 12px 0;
  }

  label {
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;

    i {
      color: var(---icon-color);
      margin-right: 8px;
      height: 14px;
    }

    .backgrounded {
      @include icon-backgrounded();
    }
  }

  .group-root {
    width: 100%;
  }

  .bonus {
    &-info {
      width: 100%;
      margin-bottom: 36px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      &--container {
        display: flex;
        flex-direction: column;
        width: 49%;
      }

      @include media-sm {
        flex-direction: column;
        gap: 8px;
      }
      .lvc-todo-list{
        color: var(--lvc-todo-list);
      }

      &--details {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
         &--container{
          display: flex;
          flex-direction: column;
         }
         &--turnover{
          display: flex;
          justify-content: space-between;
         }

        @include media-sm {
          width: 100%;
        }
      }

      .linearProgress-root {
        @include linear-progress();
        width: 100%;
        margin-top: 15px;

        .content-nullable {
          color: var(--balance-primary-light);
        }

        @include media-sm {
          width: 100%;
        }
      }

      .post-login__title {
        margin: 0;
      }

      &-not-found {
        background-image: var(--notfound-background-image);
        background-position: center center;
        background-repeat: no-repeat;
        overflow: hidden;
        height: 8rem;

        &__container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &__markdown{
            text-align: center;
          }
        }

        strong {
          font-weight: normal;
          display: block;
        }
      }
    }

    &-forfeit {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--default-light);
      border-radius: 12px;
      padding: 24px;
      position: relative;
      font-size: 16px;
      gap: 12px;

      h3 {
        margin: 0;
      }

      .button-root {
        background: var(--forfeit-background);
        border-color: var(--forfeit-border);
        i {
          margin-right: 8px;
        }
      }

      @include media-sm {
        flex-direction: column;

        .button-root {
          width: 100%;
        }
      }

      p {
        margin: 0;
      }

      .markdown-root {
        strong {
          color: var(--cancel-bonus-color);
          font-weight: normal;
        }
      }

      &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.1;
        background-size: cover;
        background: var(--action-bonus-block-bg) no-repeat center;
      }
    }

    &-row {
      @include transaction-table();
      @include custom-scrollbar();
      margin-bottom: 36px;

      > div {
        overflow: auto;
      }

      .table-found {
        font-size: 14px;
        color: var(--default-main);
      }

      table.custom-table td, table.custom-table .custom-table-head tr th {
        padding: 12px;
        white-space: break-spaces;
        text-align: left;

        .icon-custom {
          &-ready {
            color: var(--status-ok);
          }
          &-cancelled {
            color: var(--primary-gold-light);
          }
        }

        &:after {
          content: none;
        }

        .button-root {
          height: auto;
          text-decoration: none;
          font-weight: normal;
          color: var(--primary-gold-light);
        }
      }
    }

    &-cell {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &--content {
        position: relative;

        img {
          max-height: 184px;
          width: 100%;
          object-fit: cover;
        }
      }

      &--left {
        position: absolute;
        bottom: 10px;
        left: 10px;
        background: var(--primary-main);
        border-radius: 100px;
        color: var(--primary-dark);
        padding: 8px 12px;
        font-weight: 500;
        font-size: 12px;

        @include media-sm {
          display: none;
        }

        i {
          margin-right: 8px;
          line-height: 1;
        }
      }

      &--overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        background: var(--secondary-disabled-background);
        opacity: .95;
        overflow: hidden;
        border: 1px solid var(--primary-main);
        border-radius: 12px;
        display: none;
        font-size: 16px;
        line-height: 24px;
        padding: 24px;

        @include media-sm {
          display: flex;
          align-items: center;
          border: none;
        }

        p {
          margin: 0;
        }

        strong {
          display: block;
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        em {
          font-style: normal;
          color: var(--primary-main);
        }
      }

      &:hover {
        .bonus-cell--overlay {
          display: block;
        }

        .bonus-cell--left {
          display: none;
        }
      }

      .button-root {
        i {
          margin-right: 8px;
          line-height: 1;
          font-size: 12px;
        }
      }

      .button-default {
        width: 100%;
      }
    }
  }

  .tournaments__info {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: space-between;
    align-items: center;

    &-subscribe {
      margin-top: auto;
    }

    @include media-sm {
      flex-direction: column;
    }

    .button-root {
       @include media-sm {
        width: 100%;
      }

      i {
        margin-left: 8px;
      }
    }
  }
}
