.lvcVerification {
  div div .lvc-snow:before {
    font-size: 10px;
  }

  &__logo {
    padding: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--body-background);

    .button-link {
      color: var(--primary-section-background) inverse;
      width: 24px;
      height: 24px;
    }

    &--img {
      width: 156px;
      height: 34px;
      color: var(--balance-primary-light);
      background: url('/cms/img/logoPromotions.svg') center no-repeat;
    }

    @include media-md {
      padding: 16px 20px;
      margin-bottom: 18px;
      position: sticky;
      top: 0;
      z-index: 10;

      img {
        width: 42px;
      }
    }
  }

  &__header-wrapper {
    .lvcVerification {
      &__title {
        text-align: center;
        font-size: 24px;
        color: color(modal, title);
        margin: 0;
        padding: 30px 0;
        font-weight: 400;
        background: color(modal, header);
        min-width: 580px;
        line-height: 1;

        @include media-xs {
          text-align: left;
          min-width: auto;
        }

        &.quickVerify {
          @include media(null, $size-xs) {
            font-size: 18px;
            padding: 27px 0;
            text-transform: uppercase;
          }
        }
      }
      &__close {
        @include close-button;
        @include size(32px);
        background: #464546;
        border-radius: 50%;
        position: absolute;
        top: 13px;
        right: 16px;

        &:hover {
          background: #464546;
        }
      }

      &__stepper {
        @include media-xs {
          display: none;
        }
      }
    }
  }
  &__notification-wrapper {
    .notification {
      @include media(null, $size-xs) {
        &-root {
          padding: 19px 29px;
          justify-content: center;
          font-size: 16px;
          color: color(select, background);

          p {
            font-size: 16px;
            color: color(select, background);
          }
        }

        &-message {
          width: 100%;
          text-align: center;
        }
      }
    }
    @include media-xs {
      margin: 0;
    }
  }
  &__content-wrapper {
    padding: 0 0 30px;

    @include media-xs {
      padding: 0;
    }

    .lvcVerification {
      &__header {
        margin: 0 0 12px 0;

        @include media-xs {
          font-size: 24px;
          line-height: 32px;
        }
        @include media-xs {
          &__buttons .button-root span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      &__description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;

        p {
          margin: 0;
        }

        @include media-sm {
          margin-bottom: 12px;
        }
      }
      &-buttons-block {
        margin-top: 30px;
        display: flex;
        gap: 24px;
        justify-content: space-between;

        .lvcVerification__buttons-wrapper {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          max-width: 580px;

          .button-secondary {
            color: color(primary, light);
            background: color(secondary, main);
          }

          @include media-sm {
            flex-direction: column-reverse;
            gap: 24px;
            margin-bottom: 10px;
            padding: 0 20px;
          }
        }

        .button-default {
          i {
            margin-right: 8px;

            &:before {
              transform: rotate(-90deg);
            }
          }
        }

        .button-accent {
          &-amatic {
            background: var(--status-ok);
          }
        }

        .button-accent i, .button-secondary i {
          margin-left: 8px;
        }

        &_quick-verify-mode {
          .choice-data-buttons {
            display: flex;
            flex-wrap: nowrap;
            gap: 24px;

            .button-secondary .button-accent .button-label {
              white-space: nowrap;
            }

            .button-secondary span i {
              margin-left: 8px;
            }
          }

          @include media-sm {
            flex-wrap: wrap-reverse;

            .button-default {
              width: 100%;
            }

            .choice-data-buttons {
              width: 100%;
              flex-wrap: wrap-reverse;

              .button-secondary,
              .button-accent {
                width: 100%;
              }
            }
          }
        }
      }
      &__mandatory {
        margin-top: 42px;
        text-align: center;

        @include media-xs {
          display: none;
        }
      }
    }
    & > .container {
      padding: 0;
    }
  }
}
.verification.mobile-page-theme > .container .verification--lvcVerification {
  padding: 0;
}
.verification {
  &--lvcVerification {
    .lvcVerification {
      &__header {
        margin: 12px 0;
        text-align: center;

        @include media-sm {
          margin: 12px 0 4px 0;
          text-align: left;
          font-size: 24px;
          line-height: 34px;
        }
      }
      &__description {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 48px;
        text-align: center;

        @include media-sm {
          margin-bottom: 50px;
          text-align: left;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

body.light-theme .lvcVerification {
  &__logo {
    &--img {
      background: url('/cms/img/logoPromotions-dark.svg') center no-repeat;
    }
  }
}
