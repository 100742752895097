.tournaments {
  @include custom-scrollbar();
  @mixin tournaments-button {
    height: 28px;
    min-height: 28px;
    border-radius: 100px;
    font-size: 12px;
    line-height: 1;
    padding: 8px 24px;

    i {
      margin-right: 10px;
    }
  }

  // Buttons
  &__buttons {
    text-align: left;
    margin: 32px 0;
    gap: 10px;
    display: flex;
    flex-wrap: wrap;

    @include media-sm {
      margin: 18px 0;
    }

    button {
      @include tournaments-button;
    }

    button {
      @include media-sm {
        min-width: initial;
        white-space: nowrap;
        padding: 6px 12px;
      }
    }
  }

  // List
  &__list {
    padding-right: 24px;
    gap: 24px;
    display: flex;
    flex-direction: column;

    @include media-sm {
      gap: 18px;
    }

    @include media-md {
      margin-bottom: 24px;
    }

    .button-root {
     i {
       margin-right: 8px;
     }
    }

    .markdown-root {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      text-align: left;
      color: var(--primary-light);

      @include media-sm {
        width: 100%;
      }

      p {
        text-align: center;
      }
    }

    &__wrap {
      position: relative;
      overflow-x: auto;

      @include media-md {
        height: min-content;
      }
    }

    button {
      flex: 0 0 auto;
      width: fit-content;
      word-break: break-all;
      padding: 20px 24px;
      height: auto;
    }

    @include media-sm {
      position: relative;
      display: flex;
      padding: 0;

      button {
        max-width: fit-content;
      }
    }
  }

  // Info
  &__info {
    border-radius: 12px;
    padding: 24px;
    background: var(--tournment-background);
    background-position-x: right;
    background-position-y: center;
    background-size: cover;

    @include media-sm {
      margin-top: -12px;
      width: calc(100% + 38px);
      max-width: calc(100% + 38px);
      flex-basis: auto;
      border-radius: 0;
    }

    h3 {
      margin: 0 0 8px 0;
    }

    .markdown-root {
      text-align: left;
      font-size: 16px;
      line-height: 24px;

      p {
        margin: 0;

        strong {
          font-weight: normal;
          display: block;
          font-size: 16px;
          line-height: 24px;
          margin: 4px 0;
        }
      }
    }

    h1 {
      font-size: 30px;
    }
  }

  // Body
  &__body {
    min-height: 285px;
    margin-bottom: 25px;
  }

  // Item
  &__item-content {
    color: var(--balance-primary-light);
    background: var(--tournment-item-background);
    padding: 24px;
    text-align: left;

    &-title {
      max-width: 100%;
      word-break: break-all;
    }

    .content-switcher-container {
      margin-bottom: 18px;

      @include media-sm {
        margin-bottom: 24px;
      }

      .title {
        i {
          margin-right: 10px;
        }
      }
    }

    h3 {
      margin-top: 0;
    }
  }

  &__description {
    padding-right: 10px;

    @include media-xs {
      padding-right: 0;
    }
  }

  &__time-block {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;

    span {
      display: block;

    }
  }

  &__prize-pool {
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__prize-pool-title {
    color: var(--balance-default-main);
    font-size: 14px;
  }

  &__prize-pool-block {
    @include media-xs {
      text-align: left;
    }
  }

  &__prize-pool-item {
    font-size: 14px;
    font-weight: 600;
    display: block;
  }

  &__time {
    margin-bottom: 10px;

    @include media-sm {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
  }

  &__win-criteria,
  &__participants {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__time-title {
    color: var(--balance-default-main);
    font-weight: 400;
  }

  &__win-criteria-title {
    color: var(--balance-default-main);
    font-weight: 400;
  }
  &__win-criteria {
    p {
      margin: 0;
    }
  }

  &__participants-title {
    font-weight: 400;
    color: var(--balance-default-main);

    strong {
      display: block;
      color: var(--balance-primary-light);
    }
  }

  &__table {
    @include media-sm {
      overflow: auto;

      .custom-table {
        display: table;

        .custom-table-head .custom-table-row .custom-table-cell,
        .custom-table-body .custom-table-row .custom-table-cell {
          padding: 19px 12px;
        }
      }
    }
  }

  &__links {
    font-size: 14px;

    a {
      text-decoration: var(--tournaments-link);

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  &__opt-in {
    .tournaments__content_loading {
      justify-content: flex-start;
      min-height: 42px; // Same as button height
    }

    .buttons {
      text-align: left;
    }

    .notification-root {
      margin-bottom: 0;
      background: var(--primary-dark);

      .notification-message {
        color: var(--primary-light);

        a {
          color: var(--primary-light);
        }
      }
    }
  }

  // Loading
  &__content_loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  // Scroll
  &__scroll {
    @include transaction-table();

    .custom-table {
      margin: 0;

      @include media-sm {
        overflow: visible;
        margin-bottom: 10px;
      }

      thead {
        tr {
          background: var(--secondary-disabled-background);
          border-radius: 12px 12px 0 0;
        }
        th:first-child {
          border-top-left-radius: 12px;
          border-top: 1px solid transparent;
        }
        th:last-child {
          border-top-right-radius: 12px;
          border-top: 1px solid transparent;
        }
      }
      tr:nth-child(even) {
        background: var(--secondary-disabled-background);
      }

      .custom-table-body {
        tr:nth-child(odd) {
          background: var(--secondary-main);
        }
        .custom-table-row {
          cursor: inherit;
        }
        .custom-table-row:last-child {
          td:first-child {
            border-bottom-left-radius: 12px;
            border-bottom: 1px solid transparent;
          }
          td:last-child {
            border-bottom-right-radius: 12px;
            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }
}
