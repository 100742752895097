.profile-account-form {
  .payment-select-field__img {
    margin: 0;
  }
}

/** Bank account */
.profile-account-form--1001 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--bankAccount,
      &--bankName,
      &--bankAccountOwnerName {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/
/* **/

/** Entercash - EUR */
.profile-account-form--1079 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--ibanAccountOwnerName,
      &--ibanAccount,
      &--ibanBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/

/** Entercash - not EUR */
.profile-account-form--1080 {
  @media (min-width: 480px) {
    .profile-account-form__field {
      &--bankAccountOwnerName,
      &--bankAccount,
      &--bankBic {
        max-width: 45%;
        flex-basis: 45%;
      }
    }
  }
}
/* **/
.profile-account-form {
  &.profile-account-form__field--ibanBSB {
    display: none;
  }

  & > .gridItem-root {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 12px;

    @include media-sm {
      grid-template-columns: repeat(1, 1fr);
    }

    .form-control-hint-block_offset {
      top: 11px;
      right: 29px;
      @include media-xs {
        top: 32px;
        right: 50px;
      }
    }
  }

}

.stored-payment-details {
  overflow-x: auto;
  @include custom-scrollbar();
  @include transaction-table();

  h2 {
    font-size: 21px;
    margin-bottom: 20px;

    @include media-xs {
      font-size: 15px;
      font-weight: normal;
      text-transform: uppercase;
    }
  }



  .mobile-table-row {
    background: color(background, body);
    padding: 10px 15px;
    border-radius: 5px;
    margin: 5px 0;

    dl {
      color: var(--primary-main);
      margin: 0;

      dt, dd {
        display: inline-block;
        margin: 0;
        line-height: 1.5;
      }

      dt {
        width: 40%;
      }

      dd {
        width: 60%;
      }
    }

    dl.hidden {
      color: inherit;
      box-shadow: 0 -1px 0 0 color(notice, background), 0 -2px 0 0 color(notice, hr);
      width: calc(100% + 30px);
      padding: 0;
      margin: 0;


    }

    .hidden {
      &--reference {
        width: 59%;
        word-break: break-all;
      }
    }

    .show-hidden + .hidden {
      padding: 10px 15px;
      margin: 10px 0 0 -15px;
    }
  }
  div[role=progressbar] svg {
    min-width: 10px;
  }
}
