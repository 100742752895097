.fields-container {
  .gridItem-typeItem {
    width: 100%;
  }
}

.field-input {
  &:not(.field-disabled) .phone-number-wrapper {
    display: flex;
    border-radius: 8px;
    border: 1px solid var(--secondary-main);

    .input-root {
      &:hover:after {
        @include svg-icon('lvc');
      }

      &:after {
        content: var(--phone-input-icon);
        position: absolute;
        left: inherit;
        right: 15px;
        top: 15px;
        width: 14px;
        height: 14px;
        font-size: 14px;
        display: block;
        transition: none;
        transform: none;
        background: none;
        color: var(--default-main);
      }
    }

    &:hover {
      color: var(--primary-light);
      border: 1px solid var(--default-light);
    }

    .input-root {
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;

      input {
        padding-left: 5px;
        border: none;

        &:active, &:focus, &:hover {
          border: none;
        }
      }

      input {
        border-radius: 0 8px 8px 0;
      }
    }
    .select-select {
      display: flex;
      align-items: center;
      border-radius: 8px 0 0 8px;
      width: 85px; // needed for label floating animation
      border: none;

      &:active, &:focus, &:hover {
        border: none;
      }

      @include media-sm {
        width: 80px;
      }

      .group-root {
        display: flex;
        align-items: center;

        img {
          width: 26px;
          margin-right: 5px;
        }
      }
    }
    .select-root .select-icon {
      @include media-sm {
        display: none;
      }
    }
  }
}

.field-disabled {
  label {
    position: initial;
    color: var(--balance-default-light);
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins, "Segoe UI", sans-serif;
    transform: none;
    margin-bottom: 8px;
  }
}

.field-input.field-empty {
  .input-root {
    &:after {
      @include svg-icon('lvc-regular')
    }
  }

  button.adornment-password {
    &:before {
      @include svg-icon('glyphter');
      content: '\0021';
    }
  }

  &:hover {
    button.adornment-password {
      &:before {
        @include svg-icon('custom');
        content: 'b';
      }
    }
  }

  &:active {
    button.adornment-password {
      &:before {
        @include svg-icon('glyphter');
        content: '9';
      }
    }
  }
}

.field-input,
:not(.select-locale) > .field-select,
.select-root,
.field-root,
.field-string,
.field-phoneNumberField {
  width: 100%;
  max-width: 100%;

  legend + div {
    &:after, &:before {
      content: none;
    }
  }

  .input-root {
    &:after {
      @include svg-icon('lvc')
    }
  }

  button.adornment-password {
    &:before {
      @include svg-icon('lvc');
    }
  }

  &:not(.field-disabled) label,
  legend {
    position: relative;
    color: var(--balance-primary-light);
    transition: none;
    transform: none;
    padding-bottom: 8px;
    font-size: 14px;
    z-index: 2;
    font-family: Poppins, "Segoe UI", sans-serif;

    @include media-xs {
      color: var(--primary-light-color)
    }

    &[data-shrink="false"] {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }
  &.field-disabled {
    label,
    legend {
      color: color(label, disabled);

      & + div,
      & + .phone-number-wrapper > div {
        &::before {
          background: color(input, disabled) !important;
        }
        .select-root .select-icon {
          fill: color(input, disabled) !important;
        }
      }

      & + div .select-root,
      &[data-shrink="true"] + div {
        input,
        .select-disabled {
          color: color(input, disabled);
          -webkit-text-fill-color: color(input, disabled);
        }
      }
    }
    .input-disabled, .select-disabled {
      &:after {
        @include svg-icon('lvc');
        content: 'l';
        position: absolute;
        left: inherit;
        right: 15px;
        top: 15px;
        width: 14px;
        height: 14px;
        font-size: 14px;
        display: block;
        transition: none;
        transform: none;
        background: none;
        color: var(--lock-icon-color);
      }
    }
  }
  .input-multiline {
    padding: 0;
  }
}

.field-input.field-empty .input-root {
  > .input-input--type--date {
    &:after {
      pointer-events: none;
      @include svg-icon('lvc-regular')
    }
    &:hover {
      &:after {
        @include svg-icon('lvc');
      }
    }
  }
}

:not(.select-locale) > .field-select label + div,
:not(.select-locale) > .field-select legend + div,
.field-input .input-root,
.field-root,
.field-checkbox,
.field-input .phone-number-wrapper {
  margin: 0;

  > .input-inputDisabled, .select-disabled, .checkbox-disabled {
    background: var(--settings-input-background);
    border: 1px solid var(--secondary-disabled-background);
    color:var(--disable-input-text);
    -webkit-text-fill-color:var(--disable-input-text);

    &:hover {
      background: var(--settings-input-background)!important;
      border: 1px solid var(--secondary-disabled-background);
    }

    label {
      color: var(--default-light);
    }
  }

  > .input-input--type--date:not(.input-inputDisabled) {
    appearance: none;

    &:hover {
      :after {
        @include svg-icon('lvc');
      }
    }

    &:after {
      @include svg-icon('lvc');
      position: absolute;
      top: 14px;
      right: 16px;
      left: auto;
      color: var(--default-main);
      background: none;
      transform: none;
      transition: none;
      font-size: 14px;
      content: 'J';
    }
  }
}
:not(.select-locale) > .field-select > div,
.field-input .input-root:first-child {
  margin-bottom: 5px;
  &:after {
    content: none;
  }
}
.field-root {
  .field-helper {
    margin-top: 5px;
    font-size: 10px;
    font-family: Poppins, Roboto, Segoe UI;

    &:empty {
      font-size: 0;
      margin: 0;
    }

    &Hint {
      color: color(input, hint);
    }
    &Error {
      color: var(--error-main);
    }
  }
}
.input-focused:not(.input-error) + .field-helperHint {
  color: color(input, hint);
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"]:not(.dateInput-input),
.input-input--type--date,
.dateInput-root, // Safari
.select-selectMenu,
.periodSelect_Select-selectMenu,
textarea.input-inputMultiline,
.periodSelect_Select-select {
  max-width: 100%;
  height: 44px;
  padding: 15px 35px 15px 15px;
  line-height: 1;
  font-size: 14px;
  border-radius: 8px;
  -webkit-text-fill-color: var(--primary-light);
  opacity: 1;
  color: var(--primary-light);
  border: 1px solid var(--secondary-main);
  background: var(--balance--secondary-main); // fix for safari, otherwise text is invisible
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;

  &::-webkit-date-and-time-value {
    text-align: left;
  }

  &:before, &:after {
    background: none;
    color: initial;
  }

  &::placeholder {
    color: var(--default-main);
  }

  .input-inputDisabled {
    background: var(--secondary-disabled-background);
    border: 1px solid var(--secondary-disabled-background);
  }

  &:not(.input-inputDisabled) {
    &:hover {
      color: var(--primary-light);
      border: 1px solid var(--default-light);

      &:after {
        @include svg-icon('lvc')
      }
    }

    &:active, &:focus {
      color: var(--primary-light);
      border: 1px solid var(--primary-light);
      border-radius: 8px;
    }
  }

  &.select-disabled {
    border: none;

    &:before {
      background: none;
    }

    &:active, &:focus, &:hover {
      border: none;
    }
  }
}
input[type="email"],
input[type="password"] {
  padding-right: 45px;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"]:not(.dateInput-input),
.input-input--type--date,
.dateInput-root {
  @include isMozillaFirefox {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.input-before {
  color: color(input, text);
}
.dateInput-root { // Safari
  -webkit-appearance: initial;
}
textarea.input-inputMultiline {
  height: 100%;
  line-height: 1.5;
}
.field-input,
:not(.select-locale) > .field-root.field-select {
  .input-underline,
  label + div,
  .phone-number-wrapper > div,
  > div {
    &::before {
      content: none;
    }
    &:hover {
      &::before {
        content: none;
      }
    }
  }
  .input-focused,
  .select-focused,
  label + div:focus {
    &::after {
      content: none;
    }
  }
}
:not(.select-locale) > .field-error {
  .input-error,
  &.field-select label + div,
  &.field-select .select-focused,
  .phone-number-wrapper > div {
    &::after {
      content: none;
    }
    input{
      border: 1px solid var(--error-main);
      background: var(--error-dark);
    }
  }
  .checkbox-default {
    border: 1px solid var(--error-main);
    background: var(--error-dark);
  }
}
input {
  outline: none;
}

/* Remove background from autofilled inputs in Chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--primary-light);
  -webkit-box-shadow: 0 0 0px 1000px var(--secondary-main) inset;
  transition: background-color 5000s ease-in-out 0s;
}

/** */

input,
.dateInput-input {
  &::-moz-placeholder {
    color: color(input, text);
  }
  &::-webkit-input-placeholder {
    color: color(input, text);
  }
  &::-webkit-calendar-picker-indicator{
    right: -10px;
    margin: 0;
    padding: 0;
    filter: invert(100%);
  }
}
input:not(.dateInput-input) {
  &::-moz-placeholder {
    font-size: 12px;
    line-height: 40px;
    opacity: 1!important;
  }
  &::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 40px;
    opacity: 1;
  }
}
button.adornment-password {
  position: absolute;
  right: 0;
  align-self: center;
  width: 40px;
  height: 40px;
  color: var(--adornment-password-color);

  @include media-xs {
    color: color(label, color);
  }

  svg {
    opacity: 0;
  }
  &::before {
    @include svg-icon('lvc-regular');
    position: absolute;
    color: var(--default-main);
    font-size: 14px;
  }
}
input[type='text'] + .adornment-password {
  &::before {
    content: 'a';
  }
}
input[type='password'] + .adornment-password {
  &::before {
    @include svg-icon('custom');
    content: 'b';
  }
}

/* input date hide native calendar icon */
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0.9em;
  top: 0.7em;
  z-index: 1;
  -webkit-appearance: none;
  opacity: 0;
  height: 93%;
  width: 1.5em;
}

/* Select */

.select-root, .periodSelect_Select-root {
  position: relative;

  .select-selectMenu {
    display: flex;
    align-items: center;
    padding-right: 32px;
    font-family: Poppins, Roboto, Segoe UI;

    &:after {
      font-family: 'Glyphter-lvc-regular';
      content: "\0051";
      position: absolute;
      width: 14px;
      height: 14px;
      right: 15px;
      top: 15px;
      font-size: 14px;
      line-height: 1;
      color: var(--default-main);
      -webkit-text-fill-color: var(--default-main);
    }
  }
  .select-icon, .periodSelect_Select-icon {
    display: none;
    top: 6px;
    right: 0;
    fill: var(--default-main);
  }


}
.select-select:focus {
  background: var(--secondary-main);
  border-radius: 8px;
}
.select-menuModal {
  .applied-country--swe & {
    z-index: 1502; // 1501 is z-index responsible widget
  }

  .select-menuPaper {
    margin: auto;
    background: var(--select-menu-background);

    ul {
      padding: 0;

      li {
        &.select-search {
          padding-top: 40px;
          // overflow: auto; [1]

          .select-searchInput {
            background: var(--select-menu-background);
            position: fixed; // [1] instead of `position: sticky` because Safari doesn't support `sticky` inside parent element with `overflow: auto`
            transform: translate3d(0, 0, 0); // Fix for Safari
            width: 100%;

            input {
              @supports (-webkit-touch-callout: none) {
                width: auto;
              }
            }
            &:after, &:before {
              background: none;
            }

            .select-searchIcon {
              fill: var(--select-icon-color);
            }
          }
        }
        &:not(.select-search) {
          height: 100%;
          transition: all 0.3s;
          padding: 5px;
          color: var(--select-menu-item-color);
          font-family: Poppins, Roboto, Segoe UI;
        }
      }

      li:hover {
        color: var(--select-item-hover-color);
      }
    }
  }
}

/* Checkbox */

.field-checkbox {
  margin-top: 10px;

  label {
    margin-right: 0;
    margin-left: 0;
    align-items: flex-start;

    span {
      font-family: Poppins, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    }
  }
  .checkbox-default {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 5px;
    background: var(--secondary-main);

    &:not(.checkbox-checked):hover {
      background: var(--secondary-light);
    }

    &.checkbox-disabled {
      border-color: color(input, disabled);
    }
    &::before {
      @include svg-icon;
      content: '\0075';
      margin-left: 3px;
      color: transparent;
      font-size: 13px;
      font-weight: $font-weight-alt;
    }
    svg {
      display: none;
    }
    + span {
      color: var(--balance-primary-light);
      font-size: 14px;
      line-height: 20px;
    }
    &.checkbox-disabled + span {
      color: color(input, disabled);
    }
  }

  .checkbox-checked {
    background: color(primary, gold-light);

    &.checkbox-disabled {
      border-color: color(input, disabled);
      background: color(input, disabled);
    }

    &::before {
      color: color(primary, dark);
    }
  }
}

/* Phone number */

.input-root {
  &:before, &:after {
    content: none;
  }

  &:after {
    @include svg-icon();
  }
}

#menu-phoneNumber_code {
  .selectItem-root {
    .phone-number__code {
      padding-right: 5px;
    }
    img {
      width: 26px;
      margin-right: 10px;
    }
  }
}

/* Button group */

.field-inputValues {
  .input-valuesLabel {
    font-size: 12px;
  }
  .input-values {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    transition: all 0.3s;

    .input-value {
      flex: 1;
      white-space: nowrap;
      border: 1px solid color(input, border);

      + .input-value {
        margin-left: -1px;
      }
      button {
        width: 100%;
        min-width: 0;
        color: color(input, text);
        font-size: 16px;
        font-weight: $font-weight-base;
        text-transform: none;
        border-radius: 0;

        &:hover {
          background: transparent;
        }
        @include media-xs {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
      &.input-selectedValue {
        z-index: 5;
        border-color: var(--primary-main);

        button {
          color: var(--primary-dark);
        }
      }
    }
  }
}

/* Radio group */

.field-radioGroup {
  display: flex;

  legend {
    color: var(--input-label-filled-color);
  }
}
.radioGroup-root {
  flex-direction: initial;
  padding: 1px 0 0 1px;

  label {
    flex: 1;
    min-width: 33.33333%;
    margin: -1px 0 0 -1px;
  }
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 5px;
    font-size: 12px;
    line-height: 1;
  }
  .formControlLabel-label {
    border: 1px solid color(input, border);
    border-radius: 0;
    text-align: center;
    color: var(--input-color);
    background: transparent;

    @include media-xs {
      color: var(--input-color-mobile);
    }
  }
  .radio-checked,
  .radio-default {
    display: none;
  }
}
.radio-checked + .formControlLabel-label {
  border-color: var(--primary-main);
  z-index: 2;
}

/* Radio list */

.radio-list {
  display: block;

  span {
    width: auto;
    padding: 0;
  }
  .formControlLabel-root {
    display: block;
    min-width: 0;
    float: left;
    clear: both;
    text-align: left;
  }
  .radio-default {
    display: inline-flex;
    vertical-align: top;

    svg {
      width: 20px;
      height: 20px;
      fill: var(--primary-main);
    }
  }
  .formControlLabel-label {
    display: inline-flex;
    max-width: calc(100% - 20px);
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    border: 0;
  }
  label + label {
    margin-left: 0;
  }
}


/* Games  filter (search)*/
.games-filter__name {
  border-radius: 8px;

  &:hover, &:active {
    .input-before {
      color: var(--primary-light);
    }

    input {
      color: var(--primary-light);


      &::placeholder {
        color: var(--primary-light);
      }
    }
  }

  .input-before {

    .game-name-filter {
      &__icon {
        &::before {
          @include svg-icon;
          vertical-align: middle;
          font-size: 12px;
        }
      }
    }

    .search, .reset {
      position: absolute;
      top: 10px;
    }

    .search {
      left: 15px;

      &:before {
        content: 'Y';
      }
    }

    .reset {
      right: 15px;

      &:hover {
        cursor: pointer;
      }

       &:before {
         content: 'O';
         color: var(--default-main);
       }
    }
  }

  input {
    background: var(--aside-input-background);
    border: 1px solid var(--secondary-light);
    padding-left: 40px;

    &:hover {
      color: var(--primary-light);
      background: var(--secondary-main);
    }

    &.input-filled {
      padding-left: 15px;
      padding-right: 40px;
      background: var(--secondary-disabled-background);
      border: 1px solid var(--secondary-light);
    }
  }
}

.input-input {
  font-family: $font-primary;
}
