.router-menu {
  height: $height-mobile-sticky-menu;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: var(--secondary-main);
  z-index: 1600;

  &__casino,
  &__sport,
  &__promotions,
  &__chat,
  &__megaversum {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: var(--default-main);
    text-decoration: none;

    &:before {
      font-size: 25px;
      margin-bottom: 5px;
    }
  }

  &__casino {
    &:before {
      @include svg-icon;
      content: 'J';
    }
  }

  &__sport {
    &:before {
      @include svg-icon;
      content: '1';
    }
  }

  &__promotions {
    &:before {
      @include svg-icon('lvc');
      content: 'g';
    }
  }

  &__chat {
    &:before {
      @include svg-icon('custom');
      content: '0';
    }
  }

  &__megaversum {
    &:before {
      @include svg-icon('lvc');
      content: ',';
    }
  }
}
