/* Vegas Tv Page */

.live-games-page-page {
  margin: -15px;
  padding: 15px;
  background-image: var(--live-games-background-image);
  background-position: top center;
  background-size: 100%;

  @include media-xs {
    margin: 0;
  }

  &.static-page-content .content-blocks {
    grid-template-columns: repeat(auto-fill, 250px);

    .content-block {
      background-color: transparent;
    }

    @include media-sm {
      justify-content: center;
    }
  }

  &__about-games {
    &-section {
      position: relative;
      padding: 15px;
      margin: 0 -15px;
      margin-bottom: 30px;

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: var(--live-games-section-icon);
        transform: translateX(-50%);
      }
    }

    &-title,
    &-description {
      text-align: center;
    }

    &-description {
      font-size: 14px;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  &-content + .game-list{
    grid-template-columns: repeat(3, 1fr);

    @include media-sm {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-xs {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
