/* Roboto */

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* Icons */
@font-face {
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter/Glyphter.woff') format('woff');
}

// Glyphter custom
@font-face {
  font-family: 'Glyphter-custom';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter-custom/Glyphter.woff') format('woff');
}

@font-face {
  font-family: 'Glyphter-lvc';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter-lvc/Glyphter.woff') format('woff');
}

@font-face {
  font-family: 'Glyphter-lvc-regular';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter-lvc-regular/Glyphter.woff') format('woff');
}
@font-face {
  font-family: 'Glyphter-light';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('/cms/fonts/glyphter-light/Glyphter.woff') format('woff');
}

@font-face {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('FontAwesome'), local('FontAwesome-Regular'), url('/cms/fonts/awesome/fontawesome-webfont.c8ddf1e5.woff') format('woff');
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
