.depositThanks {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;

  @include media-xs {
    position: fixed;
  }

  div {
    position: relative;
    background-color: #e0a32d;
    width: 400px;
    height: 247px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    color: #000000;
    font-weight: 600;
    font-size: 25px;

    @include media-xs {
      width: 75%;
      margin: 0 auto;
      font-size: 20px;
    }
  }

  &__info {
    color: #f1f1f1;
    background-color: #398f68;
    padding: 19px 29px;
    margin: 0 -15px;

    @include media-xs {
      margin: 0;
      border-radius: 5px;
      padding: 10px 15px;
    }
  }

  &__continue-button {
    margin-top: 10px;
  }

  &__close {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 15px;
    height: 15px;
    background: #e7b85e;
    border-radius: 50%;
    outline: 0;
    opacity: 1;
    cursor: pointer;
    min-width: auto;
    min-height: auto;
    padding: inherit;

    &::before {
      content: 'X';
      position: absolute;
      color: #e0a32d;
      top: 1px;
      left: 50%;
      font-size: 10px;
      font-weight: 800;
      transform: translateX(-50%);
    }
  }
}

.successful-page {
  justify-content: center;

  &__logo {
    display: block;
    padding: 16px 20px;
    background: #000;

    img {
      height: 44px;
    }
  }

  &-verification, &-registration {

    .modal-background {
      left: auto;
      top: auto;
      right: 0;
      bottom: 0;
      width: calc(100% - 240px);
      height: calc(100% - 100px);

      @include media-sm {
        width: 100%;
        height: calc(100% - 149px);
      }
    }

    .modal-paper {
      .modalContentWrapper {
        .modal-titleRoot {
          margin-bottom: 8px;
        }
        .modal-contentRoot {
          gap: 24px;
          display: grid;
          color: var(--primary-light);
          font-size: 14px;
          line-height: 20px;
        }
      }
    }



    label {
      margin-bottom: 8px;
    }
    .markdown-root {
      color: var(--primary-light);
      font-size: 14px;
      line-height: 20px;

      p {
        margin: 0;
      }

      strong {
        font-weight: normal;
        margin-top: 8px;
        display: block;
      }
    }

    .button-root {
      width: 100%;
    }
  }

  &-registration {
    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    width: calc(100% - 440px);
    height: calc(100% - 100px);
    @include media-sm {
      width: 100%;
      top: 80px;
    }

    .modal-background {
      width: calc(100% - 440px);
      background: var(--registration-success-background);

      @include media-sm {
        width: 100%;
      }
    }

    .markdown-root {
      strong {
        color: var(--primary-main);
        display: inline;
      }
    }
  }
}

.playerVerificationWaitingForApproval {
  &__content {
    gap: 40px;

    @include media-xs {
      gap: 0;
    }

    .aspect-ratio-16x9 {
      position: relative;
      padding-bottom: 56.25%;

      iframe {
        border: none;
        bottom: 0;
        display: block;
        height: 100%;
        left: 0;
        max-height: 380px;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }
    }
    .lvcVerification__header {
      color: color(modal, title-mobile);
      margin-bottom: 20px;
      font-size: 24px;
      text-align: left;

      @include media-xs {
        padding: 11px 10px 9px;
        margin: 0 -12px 12px;
        background: color(popup, summary-background);
        box-shadow: 0 1px 0 0 color(notice, hr), 0 2px 0 0 color(notice, background);
      }
    }
  }
}
