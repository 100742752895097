.attention {
  &__wrapper {
    position: relative;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--alarm-color);
    overflow: hidden;
    border-radius: 10px;
    margin: 12px 22px 10px 22px;

    @include media-xs {
      padding: 12px 35px 12px 40px;
    }

    @include media-md-landscape {
      padding: 12px 35px 12px 40px;
    }

    &:before {
      @include svg-icon('custom');
      content: '\0051';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 31px;
      font-size: 24px;
      color: var(--alarm-icon-color);
      background-color: var(--alarm-color);

      @include media-xs {
        font-size: 25px;
        left: 8px;
      }

      @include media-md-landscape {
        font-size: 25px;
        left: 8px;
      }
    }

    body.is-ios & {
      @include media-xs {
        & ~ .main-content {
          .aside-menu-mode-mobile {
            .drawer-paperAnchorDockedLeft {
              .aside-menu {
                &-panel {
                  .sports-betting {
                    padding-bottom: 150px;
                  }
                }
              }
            }
          }
        }
      }
    }

    & ~ .main-content {
      .aside-menu-mode-mobile {
        .drawer-paperAnchorDockedLeft {
          .aside-menu {
            &-panel {
              .sports-betting {
                padding-bottom: 70px;
              }
            }
          }
        }
      }
    }
  }

  &__container {
    position: relative;
    width: 90%;
    overflow: hidden;
    height: 2rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media-lg {
      width: 85%;
    }
    @include media-md {
      width: 82%;
    }
    @include media-sm {
      width: 76%;
    }
    @media (max-width: 520px) {
      width: 72%;
    }
  }

  &__message {
    position: absolute;
    color: var(--alarm-icon-color);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    line-height: 0.5rem;
    width: 100%;
    transform: translateX(100%);

    // Mixin to apply scroll animation class based on step, adding 5s every 10 steps
    @mixin scroll-animation-class($step) {
      // Calculate durations based on step
      $mobile-duration: 20s + (10s * (($step / 100) - 1));
      $desktop-duration: 30s + (10s * (($step / 100) - 1));

      &.scroll-left-#{$step} {
        @include media-xs {
          animation: scroll-left-#{$step}-mobile #{$mobile-duration} linear infinite;
        }
        @include media-to-xs {
          animation: scroll-left-#{$step}-desktop-sm #{$desktop-duration} linear infinite;
        }
        @include media-to-sm {
          animation: scroll-left-#{$step}-desktop-sm #{$desktop-duration} linear infinite;
        }
        @include media-to-md {
          animation: scroll-left-#{$step}-desktop-md #{$desktop-duration} linear infinite;
        }
        @include media-to-lg {
          animation: scroll-left-#{$step}-desktop-lg #{$desktop-duration} linear infinite;
        }
        @include media-to-xl {
          animation: scroll-left-#{$step}-desktop-xl #{$desktop-duration} linear infinite;
        }
      }
    }

    // Generate scroll animation classes for each step from 10 to 400
    @for $i from 1 through 40 {
      @include scroll-animation-class($i * 10);
    }

    // Mixin to define keyframes for mobile animations with negative shift based on step
    @mixin scroll-left-mobile($step) {
      $shift: -42% - (46% * (($step / 10) - 1));

      @media (max-width: 390px) {
        $shift: -42% - (53% * (($step / 10) - 1));
      }

      @keyframes scroll-left-#{$step}-mobile {
        0% { transform: translateX(100%); }
        100% { transform: translateX(#{$shift}); }
      }
    }

    // Mixin to define keyframes for desktop animations with size-based shifts for each screen size
    @mixin scroll-left-desktop($step) {
      $shift-sm: (18% * $step / 10) * -1;
      $shift-md: (15% * $step / 10) * -1;
      $shift-lg: (12% * $step / 10) * -1;
      $shift-xl: (8% * $step / 10) * -1;

      @keyframes scroll-left-#{$step}-desktop-sm {
        0% { transform: translateX(100%); }
        100% { transform: translateX(#{$shift-sm}); }
      }
      @keyframes scroll-left-#{$step}-desktop-md {
        0% { transform: translateX(100%); }
        100% { transform: translateX(#{$shift-md}); }
      }
      @keyframes scroll-left-#{$step}-desktop-lg {
        0% { transform: translateX(100%); }
        100% { transform: translateX(#{$shift-lg}); }
      }
      @keyframes scroll-left-#{$step}-desktop-xl {
        0% { transform: translateX(100%); }
        100% { transform: translateX(#{$shift-xl}); }
      }
    }

    // Generate keyframes for each step from 10 to 400 for both mobile and desktop animations
    @for $i from 1 through 40 {
      @include scroll-left-mobile($i * 10);
      @include scroll-left-desktop($i * 10);
    }

    // Message styling with responsive font size and height
    &__message {
      position: absolute;
      color: var(--alarm-icon-color);
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 0.5rem;
      width: 100%;
      transform: translateX(100%);

      @include media-xs {
        font-size: 10px;
        max-height: 70px;
      }

      @include media-md-landscape {
        font-size: 10px;
        max-height: 70px;
      }
    }
  }

  &__wrapper &__close {
    &-button {
      @include close-button;
      @include size(25px);
      position: absolute;
      min-height: 25px;
      min-width: 25px;
      top: 50%;
      right: 30px;
      margin-top: -13px;
      border-radius: 50%;

      &:before {
        color: var(--alarm-icon-color);
      }

      @include media-xs {
        width: 20px;
        height: 20px;
        right: 10px;
      }

      @include media-md-landscape {
        @include size(20px);
        right: 10px;
      }
    }
  }

  &__text_open {
    @include media-sm {
      height: auto;
    }
  }
}
